import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { utils } from 'service';

function EventListItem({ event, editable, onEdit, location }) {
  return (
    <div className="wrap--event">
      {!!event.avatar && (
        <div className="content--left">
          <Link
            to={{
              pathname: `/dashboard/event/${event.id}`,
              state: { from: location.pathname },
            }}
          >
            <div className="event-item-hover">
              {!!event.avatar && <img alt="Event" src={event.avatar} />}
            </div>
          </Link>
        </div>
      )}
      <div className="content--right">
        <Link
          to={{
            pathname: `/dashboard/event/${event.id}`,
            state: { from: location.pathname },
          }}
        >
          <h5 className="e-title">{event.title}</h5>
        </Link>
        <div className="e-datetime">
          <span className="date">
            Start: &nbsp;
            {utils.formatValue(event.start, 'datetime')}
          </span>
          {event.end && (
            <span className="time">
              End: &nbsp;
              {utils.formatValue(event.end, 'datetime')}
            </span>
          )}
        </div>
        <p className="par">Venue: {event.venue || ''}</p>
        <p className="par">Address: {event.address || ''}</p>
        <p className="par">Coordinator: {event.coordinator || ''}</p>
        <p className="par">
          Price:&nbsp;
          {typeof event.price === 'number' ? utils.formatValue(event.price, 'currency') : ''}
        </p>
        <p className="par">Online Tickets: {event.onlineTickets === true ? 'Yes' : 'No'}</p>
        {editable && (
          <div className="button-container">
            <button type="button" className="button neutral border--red calendar" onClick={onEdit}>
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

EventListItem.propTypes = {
  location: PropTypes.object.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    venue: PropTypes.string,
    address: PropTypes.string,
    title: PropTypes.string.isRequired,
    coordinator: PropTypes.string,
    price: PropTypes.number,
    avatar: PropTypes.string,
    onlineTickets: PropTypes.bool.isRequired,
    start: PropTypes.any.isRequired,
    end: PropTypes.any,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default withRouter(EventListItem);
