import React from 'react';
import { Loading } from 'aws-amplify-react';
import LoadingComponent from './index';

class FaithmoAuthLoading extends Loading {
  // eslint-disable-next-line class-methods-use-this, no-unused-vars
  showComponent(_theme) {
    return (
      <div className="Form__formContainer">
        <div className="Section__sectionBody">
          <LoadingComponent />
        </div>
      </div>
    );
  }
}

export default FaithmoAuthLoading;
