import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import { Avatar, SwitchCheckBox, Tooltip, InputBox } from 'components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import validate from './validate';

const getSuggestions = (value, users, exceptions = []) => {
  const escapedValue = value.trim().escapeRegexCharacters();

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(`${escapedValue}`, 'i');

  return users.filter(
    user =>
      !exceptions.find(id => id === user.id) &&
      (regex.test(`${user.profile.firstName} ${user.profile.lastName}`) ||
        regex.test(`${user.profile.ID}`)),
  );
};

class GroupCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatar: null,
      value: '',
      suggestions: [],
      selectedUser: null,
      group: {
        title: null,
        leader: null,
        desc: null,
        isPrivate: false,
      },
      errors: {},
    };
  }

  handleChange = (name, value) => {
    const { group } = this.state;
    this.setState({ group: { ...group, [name]: value } });
  };

  handleChangeImage = image => {
    if (image) {
      this.setState(() => ({ avatar: image }));
    }
  };

  handleCreate = () => {
    const { onSave } = this.props;
    const { group, avatar, selectedUser } = this.state;

    if (selectedUser) group.leader = selectedUser.id;

    const errors = validate(group);
    const errorCnt = Object.keys(errors).length;
    if (errorCnt) {
      this.setState({ errors });
      return;
    }

    onSave(group, avatar);
  };

  handleSuggestionInputChange = (event, { newValue }) => {
    const { group } = this.state;
    this.setState({ value: newValue, group: { ...group, leader: '' } });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { users } = this.props;
    this.setState({
      suggestions: getSuggestions(
        value,
        users.data,
        users.data.filter(user => user.unlinked).map(user => user.id),
      ),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { group } = this.state;
    this.setState({
      group: { ...group, leader: suggestion.id },
      value: `${suggestion.profile.firstName} ${suggestion.profile.lastName}`,
    });
  };

  render() {
    const { avatar, group, errors, value, suggestions } = this.state;
    const { users } = this.props;

    const inputProps = {
      placeholder: 'Start Typing...',
      value,
      className: 'form-control',
      onChange: this.handleSuggestionInputChange,
    };

    return (
      <div className="group-create">
        <div className="form-group row">
          <label htmlFor="group-title" className="col-md-3 col-sm-12 form-label">
            Group Name:
          </label>
          <InputBox
            className="col-md-6 col-sm-12"
            maxLength={38}
            type="text"
            placeholder="Type group name"
            id="group-title"
            onChange={e => this.handleChange('title', e.target.value)}
            value={group.title || ''}
            error={errors.title}
          />
        </div>
        <div className="form-group row">
          <label htmlFor="group-leader" className="col-md-3 col-sm-12 form-label">
            Group Leader:
          </label>
          <div className="col-md-6 col-sm-12">
            {!users.isLoading && (
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={suggestion =>
                  `${suggestion.profile.firstName} ${suggestion.profile.lastName}`
                }
                renderSuggestion={suggestion => (
                  <span>
                    {suggestion.profile.firstName} {suggestion.profile.lastName}
                  </span>
                )}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
              />
            )}
            <span className="helper-text error">{errors.leader}</span>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="group-description" className="col-md-3 col-sm-12 form-label">
            Group Description:
          </label>
          <div className="col-md-9 col-sm-12">
            <textarea
              className="form-control"
              id="group-description"
              placeholder="Type group description"
              rows="3"
              onChange={e => this.handleChange('desc', e.target.value)}
              value={group.desc || ''}
              style={{ minHeight: 120 }}
            />
            <span className="helper-text error">{errors.desc}</span>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="group-private" className="col-md-3 col-sm-12 form-label">
            Private Group:
            <Tooltip title="Making a group ‘private’ will hide it from the view of any non-admin. Members of the group will have to be manually added by an admin.">
              <IconButton centerRipple color="primary" aria-label="What is Private" component="div">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </label>
          <div className="col-md-9 col-sm-12">
            <SwitchCheckBox
              labelNo="No"
              labelYes="Yes"
              checked={!!group.isPrivate}
              onChange={e => this.handleChange('isPrivate', e.target.checked)}
            />
            <span className="helper-text error">{errors.isPrivate}</span>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="group-avatar" className="col-md-3 col-sm-12 form-label">
            Add Group Image:
          </label>
          <div className="col-md-9 col-sm-12">
            <Avatar
              type="group"
              style={{ marginLeft: 0, marginRight: 'auto' }}
              onChange={this.handleChangeImage}
              width={96}
              height={96}
              editable
              modalSize="large"
              src={avatar}
            />
            <span className="helper-text error">{errors.avatar}</span>
          </div>
        </div>

        <div className="action-group">
          <button type="button" className="btn-save" onClick={this.handleCreate}>
            Create Group
          </button>
        </div>
      </div>
    );
  }
}

GroupCreate.propTypes = {
  onSave: PropTypes.func.isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default GroupCreate;
