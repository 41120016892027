/**
 * Main store function
 */
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export const configureStore = (initialState = {}) => {
  // Middleware and store enhancers
  const enhancers = [applyMiddleware(thunk)];

  if (process.env.NODE_ENV === 'development') {
    // Enable DevTools only when rendering during development
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);
  }
  return createStore(rootReducer, initialState, compose(...enhancers));
};

export default { configureStore };
