import Geocode from 'react-geocode';
import Axios from 'axios';
import { BASE64_IMAGE_REGEXP } from 'config/regexrs';
import { api, logger } from 'service';
import { sendNotfication } from '../../utils';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const RECEIVE_EVENT = 'RECEIVE_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT_IMG = 'UPDATE_EVENT_IMG';

export const fetchEventsRequest = churchID => async dispatch => {
  try {
    dispatch({ type: REQUEST_EVENTS });
    const { events } = await api(`/church/${churchID}/events`);
    const eventsData = events
      .map(item => ({
        ...item,
        price: typeof item.price === 'number' ? item.price / 100 : null,
      }))
      .sort((a, b) => a.start - b.start);
    dispatch({ type: RECEIVE_EVENTS, payload: { events: eventsData } });
  } catch (error) {
    logger.warn('fetchEventsRequest action', error);
    dispatch({ type: RECEIVE_EVENTS, payload: { events: [] } });
  }
};

export const createEventRequest = (event, churchID) => async dispatch => {
  try {
    const cEvent = {
      title: event.title,
      price: event.price === null || event.price === '' ? null : parseFloat(event.price),
      coordinator: event.coordinator,
      address: event.address,
      venue: event.venue,
      onlineTickets: !!event.onlineTickets,
      start: event.start,
      end: event.end,
      link: event.link,
      desc: event.desc,
      churchID,
    };
    if (event.groupID) cEvent.groupID = event.groupID;
    const { eventID } = await api(`/event`, 'post', {
      ...cEvent,
      price: typeof cEvent.price === 'number' ? Math.floor(parseFloat(cEvent.price) * 100) : null,
    });
    cEvent.id = eventID;
    if (cEvent.address) {
      const geoResponse = await Geocode.fromAddress(cEvent.address);
      const { lat, lng } = geoResponse.results[0].geometry.location;
      cEvent.lat = lat;
      cEvent.lng = lng;
    } else {
      cEvent.lat = null;
      cEvent.lng = null;
    }
    if (cEvent.groupID === 'churchwide') {
      await sendNotfication('event_create', { churchID: churchID, eventID: eventID });
    } else {
      await sendNotfication('event_group_create', {
        churchID: churchID,
        groupID: cEvent.groupID,
        eventID: eventID,
      });
    }
    dispatch({ type: CREATE_EVENT, payload: { event: cEvent } });
    return cEvent;
  } catch (error) {
    logger.warn('createEventRequest action', error);
    throw error;
  }
};

export const fetchEventRequest = eventID => async dispatch => {
  try {
    const { event } = await api(`/event/${eventID}`);
    if (event.address) {
      const geoResponse = await Geocode.fromAddress(event.address);
      const { lat, lng } = geoResponse.results[0].geometry.location;
      event.lat = lat;
      event.lng = lng;
    } else {
      event.lat = null;
      event.lng = null;
    }
    const eventData = {
      ...event,
      price: typeof event.price === 'number' ? event.price / 100 : null,
    };
    dispatch({ type: RECEIVE_EVENT, payload: { id: eventID, event: eventData } });
    return eventData;
  } catch (error) {
    logger.warn('fetchEventRequest action', error);
    throw error;
  }
};

export const updateEventRequest = (eventID, event) => async dispatch => {
  try {
    const uEvent = {
      title: event.title,
      price: event.price === null || event.price === '' ? null : parseFloat(event.price),
      coordinator: event.coordinator,
      address: event.address,
      venue: event.venue,
      onlineTickets: !!event.onlineTickets,
      start: event.start,
      end: event.end,
      link: event.link,
      desc: event.desc,
    };
    if (event.groupID) uEvent.groupID = event.groupID;
    await api(`/event/${eventID}`, 'put', {
      ...uEvent,
      price: typeof uEvent.price === 'number' ? Math.floor(parseFloat(uEvent.price) * 100) : null,
    });
    uEvent.id = eventID;
    if (uEvent.address) {
      const geoResponse = await Geocode.fromAddress(uEvent.address);
      const { lat, lng } = geoResponse.results[0].geometry.location;
      uEvent.lat = lat;
      uEvent.lng = lng;
    } else {
      uEvent.lat = null;
      uEvent.lng = null;
    }
    dispatch({ type: UPDATE_EVENT, payload: { id: eventID, event: uEvent } });
    return uEvent;
  } catch (error) {
    logger.warn('updateEventRequest action', error);
    throw error;
  }
};

export const deleteEventRequest = eventID => dispatch =>
  api(`/event/${eventID}`, 'delete')
    .then(() => dispatch({ type: REMOVE_EVENT, payload: { id: eventID } }))
    .catch(error => {
      logger.warn('deleteEventRequest action', error);
    });

export const fetchEventAttendeesRequest = async eventID => {
  try {
    const { participants } = await api(`/event/${eventID}/participants`);
    return participants;
  } catch (error) {
    logger.warn('fetchEventAttendeesRequest action', error);
    return [];
  }
};

export const updateEventAvatarRequest = (eventID, image) => async dispatch => {
  try {
    const data = image.replace(BASE64_IMAGE_REGEXP, '');
    let type = image.match(BASE64_IMAGE_REGEXP);
    type = type[1].toLowerCase();
    const { uploadURL, fullpath } = await api(`/event/${eventID}/avatar`, 'put', {
      type,
      encoding: 'base64',
    });
    await Axios.put(uploadURL, Buffer.from(data, 'base64'), {
      headers: {
        'Content-Type': `image/${type}`,
      },
    });
    dispatch({
      type: UPDATE_EVENT_IMG,
      payload: { id: eventID, avatar: fullpath },
    });
    return { avatar: fullpath };
  } catch (error) {
    logger.warn('updateEventAvatarRequest action', error);
    throw error;
  }
};

export const deleteEventAvatarRequest = eventID => async dispatch => {
  try {
    await api(`/event/${eventID}/avatar`, 'delete');
    dispatch({
      type: UPDATE_EVENT_IMG,
      payload: { id: eventID, avatar: null },
    });
    return { avatar: null };
  } catch (error) {
    logger.warn('updateEventAvatarRequest action', error);
    throw error;
  }
};
