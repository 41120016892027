import React from 'react';
import PropTypes from 'prop-types';
import { List, Modal, Slide } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NoticationItem from 'components/NotificationItem';
import './NotificationListModal.css';

const NotificationListModal = ({ notifications, newCnt, open, onClose, onSelect, onRemove }) => (
  <Modal open={open} onClose={onClose} disableRestoreFocus closeAfterTransition>
    <Slide in={open} direction="left">
      <div className="card notification-list">
        <div className="card-header">
          <div className="title">
            <NotificationsIcon style={{ margin: -8, fontSize: '28px', color: 'white' }} />
            <p>
              Notifications
              <span className="noti-count">{newCnt}</span>
            </p>
          </div>
          <div className="back button">
            <button type="button" onClick={onClose}>
              <DoubleArrowIcon />
            </button>
          </div>
        </div>
        <div className="card-block">
          <List>
            {notifications.map(item => (
              <NoticationItem key={item.id} item={item} onSelect={onSelect} onRemove={onRemove} />
            ))}
          </List>
        </div>
      </div>
    </Slide>
  </Modal>
);

NotificationListModal.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  newCnt: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationListModal;
