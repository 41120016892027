import moment from 'moment';
import { aws, logger } from 'service';
import virtualLink from './schema/virtualLink';

const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const titleCase = name =>
  name
    ? name
        .split('_')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ')
    : '';

export const getLinkFieldErrors = async link => {
  const errors = {};
  try {
    await virtualLink.validateAsync(link, { abortEarly: false });
  } catch (e) {
    for (const { path, type } of e.details) {
      const lastPath = path.pop();
      const lastType = type.split('.').pop();
      const key = lastPath === 'link_path' ? 'link' : 'phone number';
      errors[lastPath] = lastType === 'empty' ? `A ${key} is required.` : `Please input a valid ${key}.`;
    }
    return errors;
  }
  return null;
};

export const phoneNumberFormatter = val => {
  if (val.length < 4) return val;
  const parts = [];
  for (let i = 0; i < 6; i += 3) {
    parts.push(val.slice(i, i + 3));
  }
  const last = val.slice(6);
  if (last) parts.push(last);

  return parts.join('-');
};

export const createNotificationObj = (fromUserID, notification, toUserID) => ({
  id: `g${fromUserID + ((Math.random() * 1000000) % 10000000)}`,
  type: 'group',
  subject: notification.title,
  message: notification.message,
  from: fromUserID,
  to: toUserID,
  isRead: false,
  created: new Date().getTime(),
});

export const setDate = (day, timeStamp) => {
  const currentWeekday = moment().weekday();
  const expectedWeekday = DAYS.indexOf(day);
  const diffDays = expectedWeekday > currentWeekday ? expectedWeekday : 7 + expectedWeekday;
  const givenTime = moment(timeStamp);
  return timeStamp
    ? moment().day(diffDays).hour(givenTime.hour()).minute(givenTime.minute()).valueOf()
    : null;
};

export const sendNotfication = (notiType, properties) =>
  new Promise((resolve, reject) => {
    // BEGIN :: NOTIFICATIONS //
    // Create publish parameters
    const notificationParams = {
      Message: JSON.stringify({
        type: notiType,
        properties,
      }),
      TopicArn: process.env.REACT_APP_EMAILER_SNS_ARN,
    };
    // Create promise and SNS service object
    const publishTextPromise = aws.SNS.publish(notificationParams).promise();
    // Handle promise's fulfilled/rejected states
    publishTextPromise
      .then(res => {
        logger.info(`Notifications MessageID is ${res.MessageId}`);
        resolve();
      })
      .catch(err => {
        logger.error(err);
        reject();
      });
    // END :: NOTIFICATIONS //
  });
