import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, IconButton } from '@material-ui/core';
import { SWIPER_CONFIG } from 'config/swiper';
import { Loading, Avatar, Tooltip } from 'components';
import { utils } from 'service';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import GroupLogo from 'assets/images/Usermanagement.png';
import Gifts from 'assets/images/TotalGiftIcon.png';
import PrayerReq from 'assets/images/PrayerReqIcon.png';
import HonoredMemberStar from 'assets/images/groupstar_medium.png';
import { COUNTRIES } from 'config/supported_address';

const UserProfileDetails = ({
  handleEPClick,
  user,
  gHistory,
  prayers,
  groups,
  editable,
  showGivingHistory,
  showPryerRequests,
}) => (
  <div className="u-profile">
    <div className="row up-prop">
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12} sm={5} md={5}>
            <div className="item-label">First Name:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className="item-value">{user.profile.firstName || ''}</div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <div className="item-label">Last Name:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className="item-value">{user.profile.lastName || ''}</div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <div className="item-label">ID#:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className="item-value">{user.profile.ID || ''}</div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <div className="item-label">Email Address:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className="item-value">{user.email || ''}</div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <div className="item-label">Occupation:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={7}>
            <div className="item-value">{user.profile.occupation || ''}</div>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid item xs={12} sm={5} md={6}>
            <div className="item-label">Home Address:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <div className="item-value">
              {`${user.contacts.address.address || ''}`}
              <br />
              {user.contacts.address.city && `${user.contacts.address.city},`}
              <span>&nbsp;</span>
              {`${user.contacts.address.state || ''} ${user.contacts.address.zip || ''}`}
              <br />
              {`${
                (
                  COUNTRIES.find(country => user.contacts.address.country === country.code) || {
                    country: user.contacts.address.country || '',
                  }
                ).country
              }`}
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            <div className="item-label">Home Phone Number:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <div className="item-value">{user.contacts.homePhone || ''}</div>
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            <div className="item-label">Cell Phone Number:</div>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <div className="item-value">{user.contacts.cellPhone || ''}</div>
          </Grid>
        </Grid>
      </Grid>
    </div>
    {user.profile.member.type === 'honored' && (
      <div>
        <div className="honored_member">
          <div className="honored_member_logo">
            <img src={HonoredMemberStar} alt="HonoredMember Star" />
          </div>
          <div className="honored_member_detail">
            <p className="hm-title">Honored Member</p>
            <p className="hm-subtitle">Position: {user.profile.member.position || ''}</p>
            <p className="hm-detail">{user.profile.member.description || ''}</p>
          </div>
        </div>
      </div>
    )}
    <div className="up-notes">
      <Tooltip
        title="Notes about this user are private and can only be viewed on this page.  They are not visible to the user or anyone else who does not have the user administrator permission."
        style={{ float: 'left' }}
      >
        <IconButton centerRipple color="primary" aria-label="Note" component="div">
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <p className="notes">Notes:</p>
      <p className="notes-detail">{user.profile.notes || ''}</p>
    </div>
    {editable && (
      <div className="up-editprofilebtn">
        <button type="button" className="button neutral border--red" onClick={handleEPClick}>
          Edit Profile
        </button>
      </div>
    )}
    <div className="up-groups">
      <GroupsDetail data={groups.data} isLoading={groups.isLoading} />
    </div>
    <Grid container spacing={2} className="up-bottomCard">
      {showGivingHistory && (
        <Grid item xs={12} md={showPryerRequests ? 6 : 12} className="up-gifts">
          <GiftsDetail history={gHistory.history} isLoading={gHistory.isLoading} />
        </Grid>
      )}
      {showPryerRequests && (
        <Grid item xs={12} md={showGivingHistory ? 6 : 12} className="up-prayer">
          <PrayersDetail data={prayers.data} isLoading={prayers.isLoading} />
        </Grid>
      )}
    </Grid>
  </div>
);

const GroupsDetail = React.memo(({ data, isLoading }) => {
  const chunks = [];
  if (!isLoading && data && data.length) {
    data.forEach(item => {
      if (!chunks.length || chunks[chunks.length - 1].length === 5) {
        chunks.push([]);
      }
      chunks[chunks.length - 1].push(item);
    });
  }

  return (
    <div className="card">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="card-header">
            <div className="title">
              <img src={GroupLogo} alt="GroupLogo" />
              <p>{data && data.length ? `Groups (${data.length})` : `No Groups`}</p>
            </div>
          </div>
          <div className="card-block">
            {data && data.length ? (
              <Swiper {...SWIPER_CONFIG}>
                {chunks.map((chunk, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SwiperSlide key={index}>
                    <div className="up-groups-detail">
                      {chunk.map((item, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={idx} className="groups-item swaper-item">
                          <Avatar
                            type="group"
                            width={64}
                            height={64}
                            badge={item.role === 'leader' ? 'star' : false}
                            src={item.avatar}
                          />
                          <div className="member-detail">
                            <p className="name">{item.title}</p>
                            <p>Member Since: {utils.formatValue(item.joinDate, 'date')}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div />
            )}
          </div>
        </>
      )}
    </div>
  );
});

GroupsDetail.defaultProps = {
  data: [],
  isLoading: false,
};

GroupsDetail.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      isPrivate: PropTypes.bool.isRequired,
      desc: PropTypes.string,
      joinDate: PropTypes.number.isRequired,
      role: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
    }),
  ),
  isLoading: PropTypes.bool,
};

const GiftsDetail = React.memo(({ history, isLoading }) => (
  <div className="card">
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <div className="card-header">
          <div className="title">
            <img src={Gifts} alt="GiftLogo" />
            <p>{history && history.length ? `Gifts (${history.length})` : `No Recent Gifts`}</p>
          </div>
        </div>
        <div className="card-block">
          {history && history.length ? (
            <Swiper {...SWIPER_CONFIG}>
              {history.map(gh => (
                <SwiperSlide key={gh.date + gh.productName}>
                  <div className="up-giftsInfo">
                    <div className="left-pane">
                      <p className="up-giftsName">{gh.productName}</p>
                      <p className="up-giftsDate">{utils.formatValue(gh.date, 'date_long')}</p>
                    </div>
                    <div className={`price ${gh.total < 0 ? 'color-red' : ''}`}>
                      {utils.formatValue(gh.total / 100, 'currency')}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div />
          )}
        </div>
      </>
    )}
  </div>
));

GiftsDetail.defaultProps = {
  history: [],
};

GiftsDetail.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      created: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      productName: PropTypes.string.isRequired,
    }),
  ),
  isLoading: PropTypes.number.isRequired,
};

const PrayersDetail = React.memo(
  withRouter(({ data, isLoading, location }) => {
    const prayers = data.filter(item => !item.archived).sort((a, b) => b.created - a.created);
    const count = prayers ? prayers.length || 0 : 0;

    return (
      <div className="card">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="card-header">
              <div className="title">
                <img src={PrayerReq} alt="PrayerLogo" />
                {count ? (
                  <p>{`Prayer Requests (${count > 10 ? '10+' : count})`}</p>
                ) : (
                  <p>No New Prayer Requests</p>
                )}
              </div>
            </div>
            <div className="card-block">
              {data && data.length ? (
                <Swiper {...SWIPER_CONFIG}>
                  {prayers.slice(0, 10).map(item => (
                    <SwiperSlide key={item.id}>
                      <div className="up-prayerItem">
                        <Link
                          to={{
                            pathname: `/dashboard/prayer/${item.id}`,
                            state: { from: location.pathname },
                          }}
                        >
                          <p>{utils.formatValue(item.created, 'date')}</p>
                          <p className="up-Pdetail">
                            {item.desc && item.desc.length > 140
                              ? `${item.desc.substr(0, 140)}...`
                              : item.desc || ''}
                          </p>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div />
              )}
            </div>
          </>
        )}
      </div>
    );
  }),
);

PrayersDetail.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      created: PropTypes.number.isRequired,
      archived: PropTypes.number.isRequired,
      desc: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
};

UserProfileDetails.propTypes = {
  handleEPClick: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    profile: PropTypes.object.isRequired,
    contacts: PropTypes.object.isRequired,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  showGivingHistory: PropTypes.bool.isRequired,
  showPryerRequests: PropTypes.bool.isRequired,
  prayers: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  gHistory: PropTypes.shape({
    history: PropTypes.array.isRequired,
    isLoading: PropTypes.number.isRequired,
  }).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withRouter(UserProfileDetails);
