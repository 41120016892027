import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ASSETS_CDN } from 'config/assets';
import StyledModal from 'components/StyledModal';
import PersonImg from 'assets/images/wepik-export-20230524040723Ijko.png';
import Menubar from 'components/Menubar';
import lightGreenCircle from 'assets/images/lightGreenCircle.svg';
import lightPinkCircle from 'assets/images/lightPinkCircle.svg';
import Alarm from 'assets/images/alarm.svg';
import AppImg from 'assets/images/home_top_app.png';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // openUser: false,
      openChurch: false,
    };
  }

  // onOpenUserModal = () => {
  //   this.setState({ openUser: true });
  // };

  // onCloseUserModal = () => {
  //   this.setState({ openUser: false });
  // };

  onOpenChurchModal = () => {
    this.setState({ openChurch: true });
  };

  onCloseChurchModal = () => {
    this.setState({ openChurch: false });
  };

  render() {
    const { openChurch } = this.state;
    const { location } = this.props;

    return (
      <div className="header">
        <img src={lightGreenCircle} alt="lightGreenCircle" className="light-green-circle" />
        <img src={lightPinkCircle} alt="lightPinkCircle" className="light-pink-circle" />
        <Menubar />
        <div className="row header-content-main">
          <div className="col-sm-12 col-md-5 header-content-main-left">
            <h1 className="title--main">Pray, Give</h1>
            <p className="title-info--main">Stay Connected</p>
            <p className="title-info--main-text">
              Experience seamless congregation management with Faithmo - the ultimate CRM software.
              Strengthen bonds, streamline communication, and elevate community engagement.
            </p>
            <div className="signup-container">
              <button
                type="button"
                className="button blue"
                onClick={this.onOpenChurchModal}
                style={{ marginRight: '100px' }}
              >
                Church Signup
              </button>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 header-content-main-right">
            <div className="logoIcon" />
            <img src={PersonImg} alt="PersonImg" />
            <div className="app-image">
              <img src={AppImg} alt="AppImg" />
              <div className="alarm-image">
                <img src={Alarm} alt="Alarm" />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-sub-header">
          <div className="signup-container">
            <button type="button" className="button blue" onClick={this.onOpenChurchModal}>
              Church Signup
            </button>
          </div>
          <p className="title-info--main-text">
            Experience seamless congregation management with Faithmo - the ultimate CRM software. Strengthen
            bonds, streamline communication, and elevate community engagement.
          </p>
        </div>
        <StyledModal
          open={openChurch}
          onClose={this.onCloseChurchModal}
          maxWidth="md"
          fullWidth
          className="header-signup-modal"
          style={{
            minHeight: 372,
          }}
          padding={0}
        >
          <div className="layer--dark" />
          <h6 className="header-title" style={{ zIndex: 1 }}>
            Let&apos;s sign you up! Please complete all 3 steps.
          </h6>
          <Link
            className="button white-font"
            style={{ zIndex: 1 }}
            to={{
              pathname: '/signup',
              state: {
                isUserForm: false,
                from: location.pathname,
              },
            }}
          >
            Get started
          </Link>
        </StyledModal>
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Header);
