import axios from 'axios';
import { api, logger } from 'service';
import { BASE64_IMAGE_REGEXP } from 'config/regexrs';
import { userUpdateSuccess } from './AuthActions';
import { fetchChurchRequest } from './ChurchActions';

export const RECEIVE_USERS = 'RECEIVE_USERS';
export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_IMG = 'UPDATE_USER_IMG';

export const fetchUsersRequest = churchID => async dispatch => {
  try {
    dispatch({ type: REQUEST_USERS });
    const { subscribers } = await api(`/church/${churchID}/subscribers`);
    const sortedUsers = subscribers.sort((a, b) => b.created - a.created);
    dispatch({
      type: RECEIVE_USERS,
      payload: { users: sortedUsers },
    });
    return sortedUsers;
  } catch (error) {
    dispatch({ type: RECEIVE_USERS, payload: { users: [] } });
    logger.warn('fetchUsersRequest action', error);
    return [];
  }
};

export const fetchUserRequest = (userID, churchID) => async dispatch => {
  try {
    const { user } = await api(`/church/${churchID}/subscribers/${userID}`);
    dispatch({ type: RECEIVE_USER, payload: { id: userID, user } });
    return user;
  } catch (error) {
    logger.warn('fetchUserRequest action', error);
    throw error;
  }
};

export const updateUserRequest = (userID, editUser) => async (dispatch, getState) => {
  const {
    auth: { user },
    church: { id },
  } = getState();

  const uUser = {
    contacts: editUser.contacts,
    profile: {
      ...editUser.profile,
      member: {
        ...editUser.profile.member,
        position: editUser.profile.member.type === 'honored' ? editUser.profile.member.position : null,
        description:
          editUser.profile.member.type === 'honored' ? editUser.profile.member.description : null,
      },
    },
  };
  if (editUser.unlinked) {
    uUser.email = editUser.email;
  }

  try {
    let updateCustomer = Promise.resolve();
    if (editUser.customerID) {
      updateCustomer = api(`/payments/church/${id}/customer/${editUser.customerID}`, 'put', {
        name: `${editUser.profile.firstName} ${editUser.profile.lastName}`,
        phone: editUser.contacts.cellPhone,
      });
    }

    await Promise.all([updateCustomer, api(`/user/${userID}`, 'put', uUser)]);

    if (userID === user.id) await dispatch(userUpdateSuccess(editUser));
    dispatch({
      type: UPDATE_USER,
      payload: { id: userID, user: editUser },
    });
  } catch (error) {
    logger.warn('updateUserRequest action', error);
    throw error;
  }
};

export const updateUserAvatarRequest = (userID, image) => async (dispatch, getState) => {
  try {
    const {
      auth: { user },
    } = getState();
    const data = image.replace(BASE64_IMAGE_REGEXP, '');
    let type = image.match(BASE64_IMAGE_REGEXP);
    type = type[1].toLowerCase();
    const { uploadURL, fullpath } = await api(`/user/${userID}/avatar`, 'put', {
      type,
      encoding: 'base64',
    });
    await axios.put(uploadURL, Buffer.from(data, 'base64'), {
      headers: {
        'Content-Type': `image/${type}`,
      },
    });
    if (user.id === userID) {
      dispatch(
        userUpdateSuccess({
          ...user,
          avatar: fullpath,
        }),
      );
    }
    dispatch({
      type: UPDATE_USER_IMG,
      payload: {
        userID,
        avatar: fullpath,
      },
    });
  } catch (error) {
    logger.warn('updateUserAvatarRequest action', error);
    throw error;
  }
};

export const deleteUserAvatarRequest = userID => async (dispatch, getState) => {
  try {
    const {
      auth: { user },
    } = getState();
    await api(`/user/${userID}/avatar`, 'delete');
    if (user.id === userID) {
      dispatch(
        userUpdateSuccess({
          ...user,
          avatar: null,
        }),
      );
    }
    dispatch({
      type: UPDATE_USER_IMG,
      payload: {
        userID,
        avatar: null,
      },
    });
  } catch (error) {
    logger.warn('deleteUserAvatarRequest action', error);
    throw error;
  }
};

export const createUserBatchRequest = (churchID, users) => async dispatch => {
  logger.log('=========== user subscribe ...');
  if (users.length === 0) return { errors: [], results: [] };
  try {
    const { results: resResults } = await api(`/church/${churchID}/subscribers/upload`, 'put', { users });
    if (!Array.isArray(resResults)) throw new Error('Bad response');
    const errors = resResults.filter(res => res.error).map(res => res.error);
    const results = resResults.filter(res => res.userID).map(({ index, userID }) => ({ index, userID }));
    dispatch(fetchChurchRequest(churchID));
    dispatch(fetchUsersRequest(churchID));
    return { errors, results };
  } catch (error) {
    logger.warn('deleteUserAvatarRequest action', error);
    throw error;
  }
};
