import axios from 'axios';
import { BASE64_IMAGE_REGEXP } from 'config/regexrs';
import { api, logger } from 'service';
import { sendNotfication } from '../../utils';

export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const RECEIVE_GROUP = 'RECEIVE_GROUP';
export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const CREATE_GROUP_MEMBER = 'CREATE_GROUP_MEMBER';
export const UPDATE_GROUP_MEMBER = 'UPDATE_GROUP_MEMBER';
export const REMOVE_GROUP_MEMBER = 'REMOVE_GROUP_MEMBER';
export const UPDATE_GROUP_IMG = 'UPDATE_GROUP_IMG';

export const fetchGroupsRequest = churchID => async (dispatch, getState) => {
  try {
    const {
      auth: { user, as },
    } = getState();
    dispatch({ type: REQUEST_GROUPS });
    const { groups } = await api(`/church/${churchID}/groups`);
    const sortedGroups = groups
      .filter(
        group =>
          as === 'admin' ||
          group.members.some(m => m.type === 'leader' && m.status === 1 && user && user.id === m.userID),
      )
      .map(group => ({ ...group, memberCount: group.members.filter(m => m.status === 1).length }))
      .sort((a, b) => b.created - a.created);
    dispatch({
      type: RECEIVE_GROUPS,
      payload: { groups: sortedGroups },
    });
    return sortedGroups;
  } catch (error) {
    dispatch({ type: RECEIVE_GROUPS, payload: { groups: [] } });
    logger.warn('fetchGroupsRequest action', error);
    return [];
  }
};

export const createGroupRequest = (group, churchID) => async dispatch => {
  try {
    const { group: data } = await api(`/group`, 'post', {
      churchID,
      ...group,
    });
    await sendNotfication('group_admin', { churchID: churchID, groupID: data.id, userID: group.leader });
    dispatch({ type: CREATE_GROUP, payload: { group: data } });
    return { id: data.id };
  } catch (error) {
    logger.warn('createGroupRequest action', error);
    throw error;
  }
};

export const updateGroupRequest = (groupID, group) => async dispatch => {
  try {
    const data = {};
    if (group.title !== undefined) data.title = group.title;
    if (group.desc !== undefined) data.desc = group.desc;
    if (group.isPrivate !== undefined) data.isPrivate = group.isPrivate;

    await api(`/group/${groupID}`, 'put', data);
    dispatch({ type: UPDATE_GROUP, payload: { id: groupID, data } });
  } catch (error) {
    logger.warn('updateGroupRequest action', error);
    throw error;
  }
};

export const deleteGroupRequest = groupID => dispatch =>
  api(`/group/${groupID}`, 'delete')
    .then(() => dispatch({ type: REMOVE_GROUP, payload: { id: groupID } }))
    .catch(error => {
      logger.warn('deleteGroupRequest action', error);
    });

export const fetchGroupRequest = groupID => async dispatch => {
  try {
    const { group } = await api(`/group/${groupID}`);
    dispatch({ type: RECEIVE_GROUP, payload: { id: groupID, data: group } });
    return group;
  } catch (error) {
    logger.warn('fetchGroupRequest action', error);
    throw error;
  }
};

export const updateGroupAvatarRequest = (groupID, image) => async dispatch => {
  const data = image.replace(BASE64_IMAGE_REGEXP, '');
  let type = image.match(BASE64_IMAGE_REGEXP);
  type = type[1].toLowerCase();
  const { uploadURL, fullpath } = await api(`/group/${groupID}/avatar`, 'put', {
    type,
    encoding: 'base64',
  });
  await axios.put(uploadURL, Buffer.from(data, 'base64'), {
    headers: {
      'Content-Type': `image/${type}`,
    },
  });
  dispatch({
    type: UPDATE_GROUP_IMG,
    payload: {
      id: groupID,
      avatar: fullpath,
    },
  });
};

export const deleteGroupAvatarRequest = groupID => async dispatch => {
  await api(`/group/${groupID}/avatar`, 'delete');
  dispatch({
    type: UPDATE_GROUP_IMG,
    payload: {
      id: groupID,
      avatar: null,
    },
  });
};

export const fetchGroupMembersRequest = async groupID => {
  try {
    const { members } = await api(`/group/${groupID}/members`);
    return members;
  } catch (error) {
    logger.warn('fetchGroupMembersRequest action', error);
    return [];
  }
};

export const createGroupMemberRequest = (groupID, userID) => async dispatch => {
  try {
    await api(`/group/${groupID}/members/${userID}`, 'put');
    dispatch({ type: CREATE_GROUP_MEMBER, payload: { id: groupID, userID } });
    return true;
  } catch (error) {
    logger.warn('createGroupMemberRequest action', error);
    return false;
  }
};

export const updateGroupMemberRequest = (churchID, groupID, userID, member) => async dispatch => {
  try {
    const data = {};
    if (member.status !== undefined) data.status = member.status;
    if (member.type !== undefined) data.type = member.type;

    await api(`/group/${groupID}/members/${userID}/status`, 'put', data);
    if (member.type === 'leader') {
      await sendNotfication('group_admin', { churchID: churchID, groupID: groupID, userID: userID });
    }
    dispatch({ type: UPDATE_GROUP_MEMBER, payload: { id: groupID, userID, data } });
    return true;
  } catch (error) {
    logger.warn('updateGroupMemberRequest action', error);
    return false;
  }
};

export const deleteGroupMemberRequest = (groupID, userID) => async dispatch => {
  try {
    await api(`/group/${groupID}/members/${userID}`, 'delete');
    dispatch({ type: REMOVE_GROUP_MEMBER, payload: { id: groupID, userID } });
    return true;
  } catch (error) {
    logger.warn('deleteGroupMemberRequest action', error);
    return false;
  }
};

export const fetchGroupEventsRequest = async groupID => {
  try {
    const { events } = await api(`/group/${groupID}/events`);
    return events;
  } catch (error) {
    logger.warn('fetchGroupEventsRequest action', error);
    return [];
  }
};
