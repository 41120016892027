import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'components';

const GalleryImage = ({ item, showAsThumbnail }) => {
  const [loaded, setLoaded] = useState(undefined); // undefined: loading, true: loaded, false: error

  useEffect(() => {
    setLoaded(undefined);
  }, []);

  const handleImageError = () => {
    setLoaded(false);
  };

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  if (loaded === false) {
    if (showAsThumbnail) {
      return <div className="image-gallery-thumbnail-inner" />;
    }
    return <div className="image-gallery-image-inner" />;
  }

  return (
    <>
      {loaded === undefined && <Loading size={showAsThumbnail ? 12 : 32} style={{ background: 'white' }} />}
      {showAsThumbnail ? (
        <div className="image-gallery-thumbnail-inner">
          <img
            className="image-gallery-thumbnail-image"
            src={item.thumbnail}
            alt={item.thumbnailAlt}
            title={item.thumbnailTitle}
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
          {item.thumbnailLabel && (
            <div className="image-gallery-thumbnail-label">{item.thumbnailLabel}</div>
          )}
        </div>
      ) : (
        <div className="image-gallery-image-inner">
          <img
            className="image-gallery-image"
            src={item.original}
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
            title={item.originalTitle}
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
        </div>
      )}
    </>
  );
};

GalleryImage.defaultProps = {
  showAsThumbnail: false,
};

GalleryImage.propTypes = {
  item: PropTypes.object.isRequired,
  showAsThumbnail: PropTypes.bool,
};

export default GalleryImage;
