import {
  EMAIL_REGEXP,
  FACEBOOK_REGEXP,
  PHONE_REGEXP,
  TWITTER_REGEXP,
  ZIP_CODE_REGEXP,
  WEBSITE_LINK_REGEXP,
} from 'config/regexrs';

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title is required';
  }

  if (values.contacts.email) {
    if (!EMAIL_REGEXP.test(values.contacts.email)) {
      errors.email = 'Email is invalid';
    }
  }

  if (values.contacts.website && !WEBSITE_LINK_REGEXP.test(values.contacts.website)) {
    errors.website = 'Website link is requiring a www. format';
  }

  if (!values.contacts.phoneNumber) {
    errors.phoneNumber = 'Phone number is required';
  } else if (!PHONE_REGEXP.test(values.contacts.phoneNumber)) {
    errors.phoneNumber = 'Phone number is invalid';
  }

  if (!values.pastor) {
    errors.pastor = 'Pastor is required';
  }

  if (!values.contacts.address.line1) {
    errors.line1 = 'Address line 1 is required';
  }

  if (!values.contacts.address.city) {
    errors.city = 'City is required';
  }

  if (!values.contacts.address.state) {
    errors.state = 'State is required';
  }

  if (!values.contacts.address.country) {
    errors.country = 'Country is required';
  }

  if (!values.contacts.address.zip) {
    errors.zip = 'Zip is required';
  } else if (!ZIP_CODE_REGEXP.test(values.contacts.address.zip)) {
    errors.zip = 'Zip is invalid';
  }

  if (values.socialMedia.twitter && !TWITTER_REGEXP.test(values.socialMedia.twitter)) {
    errors.twitter = 'Twitter link is invalid';
  }

  if (values.socialMedia.facebook && !FACEBOOK_REGEXP.test(values.socialMedia.facebook)) {
    errors.facebook = 'Facebook link is invalid';
  }

  return errors;
};

export default validate;
