export const COGNITO_CONFIG = {
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
};

// export const IOT_CONFIG = {
//   aws_pubsub_region: process.env.REACT_APP_REGION,
//   aws_pubsub_endpoint: process.env.REACT_APP_PUB_SUB_ENDPOINT,
// };

export const AMPLIFY_CONFIG = {
  signUpConfig: {
    hiddenDefaults: ['phone_number'],
  },
  usernameAttributes: 'email',
};
