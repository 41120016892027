import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Loading } from 'components';
import { SWIPER_CONFIG } from 'config/swiper';
import PrayerReqIcon from 'assets/images/PrayerReqIcon.png';

import PrayerListItem from './PrayerListItem';

const PrayerList = React.memo(({ prayers, users, loading }) => {
  const chunks = [];
  const count = prayers ? prayers.length || 0 : 0;

  if (prayers && prayers.length) {
    prayers.slice(0, 10).forEach(item => {
      if (!chunks.length || chunks[chunks.length - 1].length === 2) {
        chunks.push([]);
      }
      chunks[chunks.length - 1].push(item);
    });
  }

  return (
    <div className="prayer-requ">
      <div className="card">
        <div className="card-header">
          <div className="title">
            <img src={PrayerReqIcon} alt="Prayer ReqIcon" />
            {count ? (
              <p>{`Prayer Requests (${count > 10 ? '10+' : count})`}</p>
            ) : (
              <p>No New Prayer Requests</p>
            )}
          </div>
        </div>
        <div className="card-block">
          {users && users.length ? (
            <Swiper {...SWIPER_CONFIG}>
              {chunks.map((chunk, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={index}>
                  <div className="row">
                    {chunk
                      .map(p => ({ ...p, user: users.find(u => u.id === p.userID) }))
                      .filter(p => p.user)
                      .map(prayer => (
                        <PrayerListItem prayer={prayer} user={prayer.user} key={prayer.id} />
                      ))}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : loading ? (
            <div
              style={{
                position: 'relative',
                minHeight: '120px',
                minWidth: '120px',
              }}
            >
              <Loading />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
});

PrayerList.propTypes = {
  prayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userID: PropTypes.string.isRequired,
    }),
  ).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PrayerList;
