import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { utils } from 'service';
import eventBlankImg from 'assets/images/EventImgBlankIcon.png';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import EventIcon from '@material-ui/icons/Event';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { titleCase } from '../../utils';
import logger from '../../service/logger';

const EventsListItem = ({ event, group, location }) => {
  const [parsedLink, setParsedLink] = React.useState({});
  React.useEffect(() => {
    if (event.link && typeof event.link === 'string') {
      try {
        setParsedLink(JSON.parse(event.link));
      } catch (e) {
        logger.error(e);
      }
    } else setParsedLink(event.link);
  }, [event.link]);
  const renderRemainDays = () => {
    let days = utils.daysLeft(new Date(event.start));
    if (days < 0 && event.end) {
      const endDays = utils.daysLeft(new Date(event.end));
      days = endDays >= 0 ? 0 : endDays;
    }

    if (days < 0)
      return (
        <div className="event-days-left">
          <h1 className="text-uppercase text-center font-weight-bold">
            {days === -1 ? 'Yesterday' : `${-days} Days`}
          </h1>
          {days !== -1 && <p className="text-uppercase text-center font-weight-bold">Ago</p>}
        </div>
      );
    if (days === 0)
      return (
        <div className="event-days-left">
          <h1 className="text-uppercase text-center font-weight-bold">Today</h1>
        </div>
      );
    return (
      <div className="event-days-left">
        <h1 className="text-uppercase text-center font-weight-bold">
          {days} {days === 1 ? 'Day' : 'Days'}
        </h1>
        <p className="text-uppercase text-center font-weight-bold">Left Until Event</p>
      </div>
    );
  };

  return (
    <div className="card-block p-3">
      <Link
        to={{
          pathname: `/dashboard/event/${event.id}`,
          state: { from: location.pathname },
        }}
      >
        <div className="event-item event-item-hover">
          <div className="card event-card">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                {event.avatar ? (
                  <div className="event-img-round">
                    <img alt="Event" src={event.avatar} />
                  </div>
                ) : (
                  <div className="event-img-round blank">
                    <img alt="Event" src={eventBlankImg} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3>
                      <b className="event-title">{event.title}</b>
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={group ? 3 : 4}>
                    <EventIcon style={{ color: '#fe9168' }} />
                    &nbsp;
                    {utils.formatValue(event.start, 'date')}
                  </Grid>
                  <Grid item xs={12} md={6} lg={group ? 3 : 4}>
                    <AccessAlarmIcon style={{ color: '#fe9168' }} /> &nbsp;
                    {utils.formatValue(event.start, 'time')}
                  </Grid>
                  {!!event.venue && (
                    <Grid item xs={12} md={6} lg={group ? 3 : 4}>
                      <HomeIcon style={{ color: '#fe9168' }} /> &nbsp;
                      {event.venue}
                    </Grid>
                  )}
                  {!!group && (
                    <Grid item xs={12} md={6} lg={3}>
                      <PeopleAltIcon style={{ color: '#fe9168' }} /> &nbsp;
                      {group.title}
                    </Grid>
                  )}
                </Grid>
                {(!!event.coordinator || (parsedLink && Object.keys(parsedLink).length > 0)) && (
                  <Grid container spacing={2}>
                    {!!event.coordinator && (
                      <Grid item xs={6}>
                        <b>Coordinator:</b> {event.coordinator}
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <b>{parsedLink && titleCase(Object.keys(parsedLink)[0])}</b>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={2}
                alignItems="center"
                className={event.groupID !== 'churchwide' ? 'group-event' : 'church-event'}
              >
                {renderRemainDays()}
              </Grid>
            </Grid>
          </div>
        </div>
      </Link>
    </div>
  );
};

EventsListItem.defaultProps = {
  group: undefined,
};

EventsListItem.propTypes = {
  location: PropTypes.object.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    groupID: PropTypes.string.isRequired,
    venue: PropTypes.string,
    address: PropTypes.string,
    title: PropTypes.string.isRequired,
    coordinator: PropTypes.string,
    price: PropTypes.number,
    avatar: PropTypes.string,
    onlineTickets: PropTypes.bool.isRequired,
    start: PropTypes.any.isRequired,
    end: PropTypes.any,
    link: PropTypes.any,
  }).isRequired,
  group: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};

export default withRouter(EventsListItem);
