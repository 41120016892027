export default function validate(values) {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (!values.start) {
    errors.start = 'Start time is required';
  }
  if (values.end && values.start >= values.end) {
    errors.end = 'End time must be after the Start time';
  }
  return errors;
}
