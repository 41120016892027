import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Loading } from 'components';
import { TextField, PlaceSearchField, DateTimePicker, CheckBoxField } from 'components/MDFormControls';
import { normalizeAmountValue } from 'service/utils';
import validate from './validate';

const EventCreateForm = ({ isSubmitting, handleSubmit, handleCancel }) => (
  <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field name="title" label="Title" props={{ maxLength: 75 }} component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field name="venue" label="Venue" component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field name="address" label="Address" component={PlaceSearchField} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="price" normalize={normalizeAmountValue} label="Price" component={TextField} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="coordinator" label="Coordinator" component={TextField} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="start" label="Start" component={DateTimePicker} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="end" label="End" component={DateTimePicker} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="onlineTickets"
          label="Online Tickets"
          props={{ fullWidth: true }}
          component={CheckBoxField}
        />
      </Grid>
    </Grid>
    {isSubmitting ? (
      <div style={{ position: 'relative', height: 50 }}>
        <Loading />
      </div>
    ) : (
      <>
        <button
          type="button"
          className="button border--red neutral small action"
          onClick={handleCancel}
          style={{ width: 132, margin: '8px 30px' }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="button border--red neutral small action"
          style={{ width: 132, margin: '8px 30px' }}
        >
          Create
        </button>
      </>
    )}
  </form>
);

EventCreateForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'EventCreateForm',
  validate,
})(EventCreateForm);
