import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import Dropzone from 'react-dropzone';
import { ROLES } from 'config/roles';
import { logger, utils } from 'service';
import { Loading } from 'components';
import { createChurchGImgRequest, deleteChurchGImgRequest } from 'redux/actions/ChurchActions';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import plus from 'assets/images/add.png';
import './Gallery.css';
import GalleryImage from './GalleryImage';

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
    this.ImageGalleryRef = createRef();
  }

  onDrop = files => {
    const { churchID, dispatch } = this.props;

    this.setState({ isSubmitting: true }, async () => {
      const promises = files.map(async item => {
        try {
          const content = await utils.toBase64(item);
          await dispatch(createChurchGImgRequest(churchID, content));
        } catch (error) {
          logger.error(error);
        }
      });
      await Promise.all(promises);
      this.setState({ isSubmitting: false });
    });
  };

  onDelete = async () => {
    const { churchID, images, dispatch } = this.props;
    this.setState({ isSubmitting: true }, async () => {
      const index = this.ImageGalleryRef.current.getCurrentIndex();
      await dispatch(deleteChurchGImgRequest(churchID, images[index]));
      this.setState({ isSubmitting: false });
    });
  };

  render() {
    const { isSubmitting } = this.state;
    const { images, authUserPermissions } = this.props;

    const isGalleryEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GALLERY_EDITOR.value);

    let currentIndex = this.ImageGalleryRef.current ? this.ImageGalleryRef.current.getCurrentIndex() : 0;
    if (currentIndex >= images.length) currentIndex = images.length - 1;
    if (currentIndex < 0) currentIndex = 0;

    return (
      <div className="gallery-wrapper">
        <div className="gallery">
          <ImageGallery
            ref={this.ImageGalleryRef}
            lazyLoad
            startIndex={currentIndex}
            items={images.map(item => ({
              original: item,
              thumbnail: item,
            }))}
            showPlayButton={false}
            showFullscreenButton={false}
            renderCustomControls={() =>
              isGalleryEditor && (
                <button
                  type="button"
                  className="button lightblue icon-button remove-btn"
                  onClick={this.onDelete}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Loading size={20} /> : <TrashIcon />}
                </button>
              )
            }
            renderItem={item => <GalleryImage item={item} />}
            renderThumbInner={item => <GalleryImage item={item} showAsThumbnail />}
            showIndex
          />
          {isGalleryEditor && (
            <Dropzone onDrop={this.onDrop} accept={['.jpg', '.png', '.jpeg']} noDrag>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <button type="button" className="button gradient gallery-add-btn" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <>
                        <img src={plus} alt="Plus" />
                        <CameraAltIcon />
                      </>
                    )}
                  </button>
                </div>
              )}
            </Dropzone>
          )}
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  dispatch: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  churchID: PropTypes.string.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, auth: { user } }) => ({
  images: church.gallery || [],
  churchID: church.id,
  authUserPermissions: church.admins[user.id],
});

export default connect(mapStateToProps)(Gallery);
