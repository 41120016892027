import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker as TPicker, MuiPickersUtilsProvider as MUIUProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const TimePicker = React.memo(({ input, meta: { touched, error }, placeholder, ...rest }) => (
  <MUIUProvider libInstance={moment} utils={MomentUtils}>
    <TPicker
      value={input.value}
      {...rest}
      disablePast
      showTodayButton
      todayLabel="now"
      clearable
      fullWidth
      InputProps={{
        disableUnderline: true,
        placeholder,
      }}
      error={!!error && touched}
      helperText={!!error && touched ? error : ''}
    />
  </MUIUProvider>
));

TimePicker.defaultProps = {
  meta: {
    touched: false,
    error: '',
  },
  input: {},
  placeholder: '',
  label: '',
};

TimePicker.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default TimePicker;
