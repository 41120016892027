import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Avatar, SwitchCheckBox, Tooltip, RoundedTable, Loading, UserAvatar, InputBox } from 'components';
import StyledModal from 'components/StyledModal';
import { utils } from 'service';
import { ReactComponent as StarIcon } from 'assets/images/star.svg';
import { ReactComponent as StarBlankIcon } from 'assets/images/star-outline.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import validate from './validate';

class GroupEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDeleteGroupModal: false,
      openDeleteModal: false,
      openNotiModal: false,
      avatar: null,
      membersEditStatus: [],
      group: {},
      errors: {},
    };

    this.selectedMemberForRemove = null;
    this.notiMsg = 'There must be at least one group leader active for this group.';
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    const { group } = this.props;
    if (JSON.stringify(prevProps.group) !== JSON.stringify(group)) {
      this.reset();
    }
  }

  reset = () => {
    const { group } = this.props;
    this.setState({ group, membersEditStatus: [], avatar: group.avatar, errors: {} });
  };

  handleChange = (name, value) => {
    const { group } = this.state;
    this.setState({ group: { ...group, [name]: value } });
  };

  handleChangeImage = image => {
    if (image) {
      this.setState(() => ({ avatar: image }));
    } else if (image === null) {
      this.setState(() => ({ avatar: null }));
    }
  };

  getExpectedRemainCnt = userID => {
    const { membersEditStatus } = this.state;
    const { members } = this.props;

    const remainCnt = members
      .map(user => ({
        ...user.member,
        ...(membersEditStatus.find(m => m.userID === user.id) || {}),
        ...(user.id === userID ? { deleted: true } : {}),
      }))
      .filter(user => (user.changedType || user.type) === 'leader' && !user.deleted).length;

    return remainCnt;
  };

  handleOpenRemoveMember = userID => {
    this.selectedMemberForRemove = userID;
    if (!this.getExpectedRemainCnt(userID)) {
      this.notiMsg = 'There must be at least one group leader active for this group.';
      this.setState({ openNotiModal: true });
      return;
    }
    this.setState({ openDeleteModal: true });
  };

  handleCloseRemoveMember = () => {
    this.selectedMemberForRemove = null;
    this.setState({ openDeleteModal: false });
  };

  handleRemoveMember = () => {
    const { membersEditStatus } = this.state;

    if (!this.selectedMemberForRemove) return;
    const mEditStatusIndex = membersEditStatus.findIndex(m => m.userID === this.selectedMemberForRemove);
    if (mEditStatusIndex !== -1) {
      this.setState({
        membersEditStatus: membersEditStatus.map((m, idx) =>
          idx === mEditStatusIndex ? { ...m, deleted: true } : m,
        ),
        openDeleteModal: false,
      });
    } else {
      this.setState({
        membersEditStatus: [...membersEditStatus, { userID: this.selectedMemberForRemove, deleted: true }],
        openDeleteModal: false,
      });
    }
  };

  isThisMemberCanBeLeader = userID => {
    const { members } = this.props;

    const member = members.find(m => m.id === userID);
    if (!member || member.unlinked) {
      throw new Error('Unlinked member can not be a leader of this group.');
    }

    return true;
  };

  handleLeaderClick = (userID, currentType) => {
    const { membersEditStatus } = this.state;

    if (!this.getExpectedRemainCnt(userID)) {
      this.notiMsg = 'There must be at least one group leader active for this group.';
      this.setState({ openNotiModal: true });
      return;
    }
    try {
      this.isThisMemberCanBeLeader(userID);
    } catch (error) {
      this.notiMsg = error.message;
      this.setState({ openNotiModal: true });
      return;
    }
    const mEditStatusIndex = membersEditStatus.findIndex(m => m.userID === userID);
    if (mEditStatusIndex !== -1) {
      this.setState({
        membersEditStatus: membersEditStatus.map((m, idx) => {
          if (idx !== mEditStatusIndex) return m;
          if (!m.changedType) return { ...m, changedType: currentType === 'member' ? 'leader' : 'member' };
          return { ...m, changedType: undefined };
        }),
      });
    } else {
      this.setState({
        membersEditStatus: [
          ...membersEditStatus,
          { userID, changedType: currentType === 'member' ? 'leader' : 'member' },
        ],
      });
    }
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { group, avatar, membersEditStatus } = this.state;

    const errors = validate(group, false);
    const errorCnt = Object.keys(errors).length;
    if (errorCnt) {
      this.setState({ errors });
      return;
    }

    onSave({ title: group.title, desc: group.desc, isPrivate: group.isPrivate }, avatar, membersEditStatus);
  };

  render() {
    const {
      openDeleteModal,
      openDeleteGroupModal,
      openNotiModal,
      avatar,
      membersEditStatus,
      group,
      errors,
    } = this.state;
    const { members, isLoadingMembers, onRemove, deletable } = this.props;

    const renderLeader = member => {
      const isLeader = (member.changedType || member.type) === 'leader';
      return (
        <div
          role="button"
          onClick={() => this.handleLeaderClick(member.userID, member.type)}
          style={{ cursor: 'pointer' }}
          tabIndex={0}
        >
          <StarBlankIcon hidden={isLeader} />
          <StarIcon hidden={!isLeader} style={{ color: '#f7ac02' }} />
        </div>
      );
    };

    return (
      <div className="group-edit">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <Avatar
              type="group"
              onChange={this.handleChangeImage}
              width={160}
              height={160}
              style={{ margin: '15px auto' }}
              editable
              badge="photo"
              modalSize="large"
              src={avatar}
            />
          </div>
          <div className="col-lg-9 col-md-12">
            <div className="form-group row">
              <label htmlFor="group-title" className="col-md-3 col-sm-12 form-label">
                Group Name:
              </label>
              <InputBox
                className="col-md-6 col-sm-12"
                maxLength={38}
                type="text"
                placeholder="Type group name"
                id="group-title"
                onChange={e => this.handleChange('title', e.target.value)}
                value={group.title || ''}
                error={errors.title}
              />
            </div>
            <div className="form-group row">
              <label htmlFor="group-description" className="col-md-3 col-sm-12 form-label">
                Group Description:
              </label>
              <div className="col-md-9 col-sm-12">
                <textarea
                  className="form-control"
                  id="group-description"
                  placeholder="Type group description"
                  rows="3"
                  onChange={e => this.handleChange('desc', e.target.value)}
                  value={group.desc || ''}
                  style={{ minHeight: 120 }}
                />
                <span className="helper-text error">{errors.desc}</span>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="group-private" className="col-md-3 col-sm-12 form-label">
                Private Group:
                <Tooltip title="Making a group ‘private’ will hide it from the view of any non-admin. Members of the group will have to be manually added by an admin.">
                  <IconButton centerRipple color="primary" aria-label="What is Private" component="div">
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </label>
              <div className="col-md-9 col-sm-12">
                <SwitchCheckBox
                  labelNo="No"
                  labelYes="Yes"
                  checked={!!group.isPrivate}
                  onChange={e => this.handleChange('isPrivate', e.target.checked)}
                />
                <span className="helper-text error">{errors.isPrivate}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card transparent-card">
          <div className="card-header">
            <div className="title">
              <p>Group Members</p>
            </div>
          </div>
          <div className="card-block">
            {isLoadingMembers ? (
              <div
                style={{
                  position: 'relative',
                  minHeight: '60px',
                  minWidth: '60px',
                }}
              >
                <Loading />
              </div>
            ) : (
              <RoundedTable
                // showPagenate
                minWidth={720}
                data={members
                  .filter(user => !!user.member)
                  .map(user => ({
                    // userName: `${user.profile.firstName} ${user.profile.lastName}`,
                    // ...user.member,
                    ...user,
                    member: {
                      ...user.member,
                      ...(membersEditStatus.find(m => m.userID === user.id) || {}),
                    },
                  }))
                  .filter(user => user.member.status === 1 && !user.member.deleted)
                  .sort((a, b) => b.member.joinDate - a.member.joinDate)
                  .map(user => [
                    <div>{renderLeader(user.member)}</div>,
                    <UserAvatar user={user} className="align-left" />,
                    <div>{utils.formatValue(user.member.joinDate, 'date')}</div>,
                    <div>
                      <button
                        type="button"
                        className="button gradient icon-button"
                        onClick={() => this.handleOpenRemoveMember(user.member.userID)}
                      >
                        <TrashIcon />
                      </button>
                    </div>,
                  ])}
                headings={[
                  { component: <div>Leader</div>, percentWidth: 15 },
                  { component: <div className="align-left">Name</div>, percentWidth: 45 },
                  {
                    component: <div>Joining Date</div>,
                    percentWidth: 20,
                  },
                  { component: <div>Status</div>, percentWidth: 20 },
                ]}
              />
            )}
          </div>
        </div>
        {deletable && (
          <div>
            <button
              type="button"
              className="link"
              onClick={() => this.setState({ openDeleteGroupModal: true })}
            >
              Delete Group
            </button>
          </div>
        )}
        <StyledModal
          open={openNotiModal}
          onClose={() => this.setState({ openNotiModal: false })}
          closeBtnLabel="Close"
        >
          {this.notiMsg}
        </StyledModal>
        <StyledModal
          open={openDeleteModal}
          onClose={this.handleCloseRemoveMember}
          onSubmit={this.handleRemoveMember}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          Are you sure you want to delete permanently?
        </StyledModal>
        <StyledModal
          open={openDeleteGroupModal}
          onClose={() => this.setState({ openDeleteGroupModal: false })}
          onSubmit={onRemove}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          Are you sure you want to delete permanently?
        </StyledModal>
      </div>
    );
  }
}

GroupEdit.propTypes = {
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  deletable: PropTypes.bool.isRequired,
  isLoadingMembers: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profile: PropTypes.object.isRequired,
      member: PropTypes.shape({
        type: PropTypes.string.isRequired,
        joinDate: PropTypes.number.isRequired,
        userID: PropTypes.string.isRequired,
        status: PropTypes.number,
      }).isRequired,
    }),
  ).isRequired,
  group: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    members: PropTypes.array.isRequired,
    churchID: PropTypes.string.isRequired,
  }).isRequired,
};

export default GroupEdit;
