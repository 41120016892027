import React, { useState } from 'react';
import './style.css';
import { Link, withRouter } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

export default function TrademarkFooter() {
  return (
    <>
      <div className="FaithmoAuth__bottom">
        <grid-item>
          <span className="FaithmoAuth__copyright-full">Ⓒ 2023 Faithmo</span>
        </grid-item>
        <grid-item>
          <div className="links">
            <Link className="FaithmoAuth__terms" to="/privacy-policy">
              Privacy Policy
            </Link>
            <span>|</span>
            <Link className="FaithmoAuth__terms" to="/terms">
              Terms and Conditions
            </Link>
            <span>|</span>
            <a className="FaithmoAuth__terms" href="mailto:info@faithmo.com">
              Contact Us
            </a>
          </div>
        </grid-item>
        <grid-item>
          <div className="socials">
            <a
              href="https://www.facebook.com/people/Faithmo-CRM/61550018264376/"
              className="social-icon"
              target="_blank"
            >
              <FacebookIcon style={{ fontSize: 15, color: '#0d2123' }} />
            </a>
            <a href="https://www.instagram.com/faithmoapp/" className="social-icon" target="_blank">
              <InstagramIcon style={{ fontSize: 15, color: '#0d2123' }} />
            </a>
            <a href="https://twitter.com/faithmocrm" className="social-icon" target="_blank">
              <TwitterIcon style={{ fontSize: 15, color: '#0d2123' }} />
            </a>
            <a href="https://www.youtube.com/@FaithmoCRM" className="social-icon" target="_blank">
              <YouTubeIcon style={{ fontSize: 15, color: '#0d2123' }} />
            </a>
          </div>
        </grid-item>
      </div>
    </>
  );
}
