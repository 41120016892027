import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { UserPopup, Avatar } from 'components';

class UserListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenPrayerModal: false,
      isShowPosition: false,
    };
  }

  handleStarClick = event => {
    event.stopPropagation();

    this.setState({
      isShowPosition: true,
      isOpenPrayerModal: true,
    });
  };

  handlePositionBack = () => {
    this.setState({
      isShowPosition: false,
    });
  };

  onOpenPrayerModal = () => {
    this.setState({ isOpenPrayerModal: true, isShowPosition: false });
  };

  onClosePrayerModal = () => {
    this.setState({ isOpenPrayerModal: false });
  };

  onClickViewMore = () => {
    const { history, location } = this.props;
    history.push('/dashboard/users', { from: location.pathname });
  };

  render() {
    const { user, viewMore } = this.props;
    const { isOpenPrayerModal, isShowPosition } = this.state;

    if (viewMore || !user) {
      return (
        <div role="button" className="wrap--user" onClick={this.onClickViewMore} tabIndex={0}>
          <div className="user--img view-more">
            View
            <br />
            More
          </div>
        </div>
      );
    }

    return (
      <>
        <div role="button" className="wrap--user" onClick={this.onOpenPrayerModal} tabIndex={0}>
          <div className="user--img">
            <Avatar
              size="large"
              src={user.avatar}
              badge={user.profile.member.type === 'honored'}
              onBadgeClick={this.handleStarClick}
            />
          </div>
          <h6 className="name">{`${user.profile.firstName} ${user.profile.lastName}`}</h6>
        </div>

        <UserPopup
          open={isOpenPrayerModal}
          onClose={this.onClosePrayerModal}
          handleStarClick={this.handleStarClick}
          isShowPosition={isShowPosition}
          user={user}
          handlePositionBack={this.handlePositionBack}
        />
      </>
    );
  }
}

UserListItem.defaultProps = {
  viewMore: false,
  user: undefined,
};

UserListItem.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    avatar: PropTypes.string,
  }),
  viewMore: PropTypes.bool,
};

export default withRouter(UserListItem);
