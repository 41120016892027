import React from 'react';
import PropTypes from 'prop-types';
import { Loading, RoundedTable, UserAvatar } from 'components';

const AdminPanel = ({ onEditClick, admins, users, editable }) => (
  <div className="col-md-12 church-admin-panel">
    <div className="card">
      <div className="card-header">
        <div className="title">Admin Panel</div>
        {editable && (
          <div className="title-buttons">
            <button type="button" className="button gradient medium" onClick={onEditClick}>
              Edit
            </button>
          </div>
        )}
      </div>
      <div className="card-block">
        {users.isLoading ? (
          <div
            style={{
              position: 'relative',
              minHeight: '60px',
              minWidth: '60px',
            }}
          >
            <Loading />
          </div>
        ) : (
          <RoundedTable
            minWidth={620}
            minHeight={240}
            maxHeight={360}
            data={Object.keys(admins)
              .map(adminKey =>
                users.data
                  ? {
                      data: users.data.find(user => user.id === adminKey),
                      key: adminKey,
                    }
                  : null,
              )
              .filter(data => !!data)
              .map(admin => [
                <UserAvatar
                  user={
                    admin?.data || {
                      profile: {
                        firstName: 'first',
                        lastName: 'last',
                        member: {
                          type: 'honored',
                        },
                      },
                      avatar: 'test',
                    }
                  }
                  className="align-left"
                />,
                <div style={{ fontSize: '18px', lineHeight: '38px' }}>{admin?.data?.email}</div>,
                <div>{admins[admin.key].isSuperUser ? <span>Super Admin</span> : <span>Admin</span>}</div>,
              ])}
            headings={[
              { component: <div className="align-left">Administrator Name</div>, percentWidth: 45 },
              { component: <div>Email</div>, percentWidth: 40 },
              { component: <div>Role</div>, percentWidth: 15 },
            ]}
          />
        )}
      </div>
    </div>
  </div>
);

AdminPanel.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  admins: PropTypes.objectOf(
    PropTypes.shape({
      email: PropTypes.string,
      isSuperUser: PropTypes.bool.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string,
        profile: PropTypes.object.isRequired,
        avatar: PropTypes.string,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

export default AdminPanel;
