import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROLES } from 'config/roles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Profile from './Profile';
import AdminPanelDetail from './Profile/AdminPanelDetail';
import ServiceTimes from './ServiceTimes';
import Gallery from './Gallery';
import GiftSettings from './GiftSettings';
import Membership from './Membership';
import MembershipEdit from './Membership/MembershipEdit';

import './style.css';

const settingTabs = [
  {
    tabName: 'Profile',
    url: 'profile',
  },
  {
    tabName: 'Service Times',
    url: 'servicetimes',
  },
  {
    tabName: 'Gallery',
    url: 'gallery',
  },
  {
    tabName: 'Gift Settings',
    url: 'gift_settings',
  },
  {
    tabName: 'Membership',
    url: 'membership',
  },
];

class Settings extends Component {
  componentDidMount() {
    const { match, history, location, authUserPermissions } = this.props;
    const urlname = match.params.name;

    const isGiftEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GIFT_EDITOR.value);
    const isMembershipEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.MEMBERSHIP_EDITOR.value);

    if (!authUserPermissions.isSuperUser && urlname === 'adminpanel_edit')
      history.replace('/settings/profile', { from: location.state ? location.state.from : undefined });
    if (!isMembershipEditor && urlname === 'membership_edit')
      history.replace('/settings/membership', { from: location.state ? location.state.from : undefined });
    if (!isGiftEditor && urlname === 'gift_settings')
      history.replace('/settings/profile', { from: location.state ? location.state.from : undefined });
  }

  goBack = () => {
    const { history, location, match } = this.props;
    const urlname = match.params.name;
    if (urlname === 'adminpanel_edit') {
      history.push('/dashboard/settings/profile', { from: location.pathname });
    } else if (urlname === 'membership_edit') {
      history.push('/dashboard/settings/membership', { from: location.pathname });
    } else {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }
  };

  render() {
    const { match, authUserPermissions } = this.props;
    const urlname = match.params.name;

    const isGiftEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GIFT_EDITOR.value);

    const settingsChildren = url => {
      switch (url) {
        case 'profile':
          return <Profile />;
        case 'servicetimes':
          return <ServiceTimes />;
        case 'gallery':
          return <Gallery />;
        case 'gift_settings':
          return <GiftSettings />;
        case 'membership':
          return <Membership />;
        default:
          return <Profile />;
      }
    };

    const renderSettings = url => {
      switch (url) {
        case 'membership_edit':
          return <MembershipEdit />;
        case 'adminpanel_edit':
          return <AdminPanelDetail />;
        default:
          return (
            <div className="card card-tabs">
              <ul className="nav nav-tabs">
                {settingTabs.map(settingTab => {
                  if (!isGiftEditor && settingTab.url === 'gift_settings')
                    return <div key={settingTab.url} />;
                  return (
                    <SettingsTab
                      key={settingTab.url}
                      tabName={settingTab.tabName}
                      url={settingTab.url}
                      isSelected={settingTab.url === urlname}
                    />
                  );
                })}
              </ul>
              <div className="card-block tab-content">{settingsChildren(urlname)}</div>
            </div>
          );
      }
    };

    return (
      <>
        <Helmet title="Settings" />
        <div className="settings card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          {renderSettings(urlname)}
        </div>
      </>
    );
  }
}

const SettingsTab = React.memo(
  withRouter(({ tabName, url, isSelected, location }) => (
    <li className={`nav-item ${isSelected ? 'active' : ''}`}>
      <Link
        className={`${url} nav-link`}
        to={{
          pathname: `/dashboard/settings/${url}`,
          state: { from: location.pathname },
        }}
      >
        <span>{tabName}</span>
      </Link>
    </li>
  )),
);

SettingsTab.propTypes = {
  tabName: PropTypes.string,
  url: PropTypes.string,
  isSelected: PropTypes.bool,
};

Settings.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, auth: { user } }) => ({
  authUserPermissions: church.admins[user.id],
});

export default withRouter(connect(mapStateToProps)(Settings));
