import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { utils } from 'service';
import { PlaceAutoComplete } from 'components';
import StyledModal from 'components/StyledModal';

import validate from 'pages/UserUpload/validate';

class AddNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editUser: {},
      errors: {},
      isOpenAddNewUserDlg: false,
    };
  }

  handleChangeAddress = async (fullAddr, addrObj) => {
    if (addrObj === null) return;
    const { editUser, errors } = this.state;

    this.setState({
      editUser: {
        ...editUser,
        'contacts.address.address': `${addrObj.streetNumber ? `${addrObj.streetNumber} ` : ''}${
          addrObj.address
        }`,
        'contacts.address.city': addrObj.city,
        'contacts.address.state': addrObj.state.sn,
        'contacts.address.country': addrObj.country.sn,
        'contacts.address.zip': addrObj.zip,
      },
      errors: {
        ...errors,
        'contacts.address.address': undefined,
        'contacts.address.city': undefined,
        'contacts.address.state': undefined,
        'contacts.address.country': undefined,
        'contacts.address.zip': undefined,
      },
    });
  };

  handleOpenUserAddDlg = () => {
    this.setState({ isOpenAddNewUserDlg: true, editUser: {}, errors: {} });
  };

  handleUserAdd = () => {
    const { editUser } = this.state;
    const { onAdd, users } = this.props;

    const errors = validate(
      editUser,
      users.filter(
        user => user.email && editUser.email && user.email.toLowerCase() === editUser.email.toLowerCase(),
      ).length
        ? 'duplicated_p'
        : null,
    );
    const errorCnt = Object.keys(errors).length;
    if (errorCnt) {
      this.setState({ errors });
      return;
    }

    /* eslint-disable dot-notation */
    const user = utils.getObject({
      'profile.firstName': editUser['profile.firstName'],
      'profile.lastName': editUser['profile.lastName'],
      email: editUser['email'],
      'profile.ID': editUser['profile.ID'],
      'profile.occupation': null,
      'profile.member.type': 'unknown',
      'profile.member.joinedDate': null,
      'profile.member.position': null,
      'profile.member.description': null,
      'profile.notes': null,
      'contacts.cellPhone': editUser['contacts.cellPhone'],
      'contacts.homePhone': editUser['contacts.homePhone'],
      'contacts.address.address': editUser['contacts.address.address'],
      'contacts.address.city': editUser['contacts.address.city']
        ? editUser['contacts.address.city'].toCapitalize(true)
        : editUser['contacts.address.city'],
      'contacts.address.state': editUser['contacts.address.state']
        ? editUser['contacts.address.state'].toUpperCase()
        : editUser['contacts.address.state'],
      'contacts.address.zip': editUser['contacts.address.zip'],
      'contacts.address.country':
        editUser['contacts.address.address'] ||
        editUser['contacts.address.city'] ||
        editUser['contacts.address.state'] ||
        editUser['contacts.address.zip']
          ? 'US'
          : null,
    });
    /* eslint-enable dot-notation */

    this.setState({ isOpenAddNewUserDlg: false }, () => {
      onAdd({ user, index: `undefined_${utils.makeStringID(24)}` });
    });
  };

  handleInputChange(name, value) {
    const { editUser } = this.state;
    this.setState({ editUser: { ...editUser, [name]: value } });
  }

  render() {
    const { isOpenAddNewUserDlg, errors, editUser } = this.state;

    return (
      <>
        <span role="button" className="link" onClick={this.handleOpenUserAddDlg} tabIndex={0}>
          Add New User
        </span>
        <StyledModal
          padding={0}
          open={isOpenAddNewUserDlg}
          onClose={() => this.setState({ isOpenAddNewUserDlg: false })}
        >
          <div className="card">
            <div className="card-header">
              <div className="title">
                <p>User Quick Add</p>
              </div>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="col-lg-6">
                  <h5 className="text-left">Contact Info</h5>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name *"
                      value={editUser['profile.firstName'] || ''}
                      onChange={e => this.handleInputChange('profile.firstName', e.target.value)}
                    />
                    {errors['profile.firstName'] && (
                      <span className="helper-text error">{errors['profile.firstName']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name *"
                      value={editUser['profile.lastName'] || ''}
                      onChange={e => this.handleInputChange('profile.lastName', e.target.value)}
                    />
                    {errors['profile.lastName'] && (
                      <span className="helper-text error">{errors['profile.lastName']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="ID#"
                      value={editUser['profile.ID'] || ''}
                      onChange={e => this.handleInputChange('profile.ID', e.target.value)}
                    />
                    {errors['profile.ID'] && (
                      <span className="helper-text error">{errors['profile.ID']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email Address"
                      value={editUser.email || ''}
                      onChange={e => this.handleInputChange('email', e.target.value)}
                    />
                    {errors.email && <span className="helper-text error">{errors.email}</span>}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Home Phone Number"
                      value={editUser['contacts.homePhone'] || ''}
                      onChange={e => this.handleInputChange('contacts.homePhone', e.target.value)}
                    />
                    {errors['contacts.homePhone'] && (
                      <span className="helper-text error">{errors['contacts.homePhone']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Cell Phone Number"
                      value={editUser['contacts.cellPhone'] || ''}
                      onChange={e => this.handleInputChange('contacts.cellPhone', e.target.value)}
                    />
                    {errors['contacts.cellPhone'] && (
                      <span className="helper-text error">{errors['contacts.cellPhone']}</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <h5 className="text-left">Address</h5>
                  <div className="form-group">
                    <PlaceAutoComplete
                      value={editUser['contacts.address.address'] || ''}
                      onChange={v => this.handleInputChange('contacts.address.address', v)}
                      onSelect={this.handleChangeAddress}
                      inputProps={{
                        className: 'form-control',
                        placeholder: 'Street Address',
                      }}
                    />
                    {errors['contacts.address.address'] && (
                      <span className="helper-text error">{errors['contacts.address.address']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      value={editUser['contacts.address.city'] || ''}
                      onChange={e => this.handleInputChange('contacts.address.city', e.target.value)}
                    />
                    {errors['contacts.address.city'] && (
                      <span className="helper-text error">{errors['contacts.address.city']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      value={editUser['contacts.address.state'] || ''}
                      onChange={e => this.handleInputChange('contacts.address.state', e.target.value)}
                    />
                    {errors['contacts.address.state'] && (
                      <span className="helper-text error">{errors['contacts.address.state']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      value={editUser['contacts.address.country'] || ''}
                      onChange={e => this.handleInputChange('contacts.address.country', e.target.value)}
                    />
                    {errors['contacts.address.country'] && (
                      <span className="helper-text error">{errors['contacts.address.country']}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Zip"
                      value={editUser['contacts.address.zip'] || ''}
                      onChange={e => this.handleInputChange('contacts.address.zip', e.target.value)}
                    />
                    {errors['contacts.address.zip'] && (
                      <span className="helper-text error">{errors['contacts.address.zip']}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-right">
              <button type="button" className="button gradient small" onClick={this.handleUserAdd}>
                Add
              </button>
            </div>
          </div>
        </StyledModal>
      </>
    );
  }
}

AddNewUser.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      profile: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddNewUser;
