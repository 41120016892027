import {
  REQUEST_PRAYERS,
  RECEIVE_PRAYER,
  RECEIVE_PRAYERS,
  REMOVE_PRAYER,
  UPDATE_PRAYER,
} from 'redux/actions/PrayerActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';

const initialState = { data: [], indexes: [], isLoading: false };

const PrayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PRAYERS:
      return {
        ...state,
        isLoading: true,
      };

    case RECEIVE_PRAYERS:
      return {
        ...state,
        data: action.payload.data,
        indexes: action.payload.data.filter(item => item.id && !item.archived).map(item => item.id),
        isLoading: false,
      };

    case REMOVE_PRAYER:
      if (state.isLoading) return state;
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload.id),
        indexes: state.indexes.filter(item => item !== action.payload.id),
      };

    case RECEIVE_PRAYER:
    case UPDATE_PRAYER:
      if (state.isLoading) return state;
      if (action.payload.data && action.payload.data.archived) {
        return {
          ...state,
          data: state.data.map(item =>
            item.id === action.payload.id ? { ...item, ...action.payload.data } : item,
          ),
          indexes: state.indexes.filter(item => item !== action.payload.id),
        };
      }
      return {
        ...state,
        data: state.data.map(item =>
          item.id === action.payload.id ? { ...item, ...action.payload.data } : item,
        ),
      };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export const getPrayerRequests = state => state.prayers.data;

export default PrayerReducer;
