import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ROLES } from 'config/roles';
import { RoundedTable, Avatar, Loading, Chip } from 'components';
import { fetchGroupsRequest } from 'redux/actions/GroupActions';
import { fetchEventsRequest } from 'redux/actions/EventActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import './style.css';

import groupImage from 'assets/images/Usermanagement.png';
import { utils } from 'service';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch, churchID } = this.props;
    dispatch(fetchGroupsRequest(churchID));
    dispatch(fetchEventsRequest(churchID));
  }

  handleCreateGroup = () => {
    const { history, location } = this.props;
    history.push('/dashboard/group/new', { edit: true, from: location.pathname });
  };

  handleDetailGroup = id => {
    const { history, location } = this.props;
    history.push(`/dashboard/group/${id}`, { from: location.pathname });
  };

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }
  };

  render() {
    const { users, groups, events, isAdmin, authUserPermissions, authUserID } = this.props;

    const isGroupManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GROUP_ADMIN.value);

    const getUpcomingEvent = groupID => {
      const groupEvents = events.data.filter(
        event => event.groupID === groupID && utils.daysLeft(event.start) > 0,
      );
      if (groupEvents.length > 0) {
        return (
          <div className="w-100 text-center">
            <h5 className="d-block color-lightblue font-weight-bold">
              {utils.formatValue(groupEvents[0].start, 'date')}
            </h5>
            <span className="d-block text-truncate">{groupEvents[0].title}</span>
          </div>
        );
      }
      return 'No upcoming event';
    };

    return (
      <>
        <Helmet title="Groups" />
        <div className="groups card-wrapper customWidth">
          {isAdmin && (
            <div className="back">
              <ChevronLeftIcon />
              <button type="button" onClick={this.goBack}>
                Back
              </button>
            </div>
          )}
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={groupImage} alt="Group" />
                <p>Church Groups</p>
              </div>
              {isGroupManager && (
                <div className="title-buttons">
                  <button className="button gradient medium" type="button" onClick={this.handleCreateGroup}>
                    Create Group
                  </button>
                </div>
              )}
            </div>
            <div className="card-block">
              {groups.isLoading || users.isLoading ? (
                <div
                  style={{
                    position: 'relative',
                    minHeight: '60px',
                    minWidth: '60px',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <RoundedTable
                  showPagenate
                  minWidth={1040}
                  data={groups.data
                    // If two group have same members count then sort with created
                    .sort((a, b) => b.memberCount - a.memberCount || b.created - a.created)
                    .map(item => [
                      <div
                        role="button"
                        className="align-left group-name"
                        onClick={() => this.handleDetailGroup(item.id)}
                        tabIndex={0}
                        style={{ cursor: 'pointer' }}
                      >
                        <Avatar type="group" size="small" src={item.avatar} />
                        <span>{item.title}</span>
                      </div>,
                      <TableLeadersCell group={item} users={users.data} authUserID={authUserID} />,
                      <div>{item.memberCount}</div>,
                      <div>{getUpcomingEvent(item.id)}</div>,
                    ])}
                  headings={[
                    { component: <div className="align-left">Group Name</div>, percentWidth: 30 },
                    { component: <div>Group Leader</div>, percentWidth: 30 },
                    {
                      component: <div>Number of Members</div>,
                      percentWidth: 20,
                    },
                    { component: <div>Next Event</div>, percentWidth: 20 },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const TableLeadersCell = React.memo(({ group, users, authUserID }) => {
  const leaders = group.members
    .filter(item => item.type === 'leader' && item.status === 1)
    .sort((a, b) => {
      if (a.userID === authUserID) return -1;
      if (b.userID === authUserID) return 1;
      return a.joinDate - b.joinDate;
    })
    .map(item => {
      const user = users.find(u => u.id === item.userID);
      if (user) return `${user.profile.firstName} ${user.profile.lastName}`;
      return null;
    });

  return <Chip data={leaders} />;
});

TableLeadersCell.propTypes = {
  group: PropTypes.shape({
    members: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        userID: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profile: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  authUserID: PropTypes.string.isRequired,
};

Groups.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  events: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        start: PropTypes.number.isRequired,
        groupID: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        avatar: PropTypes.string,
        title: PropTypes.string.isRequired,
        members: PropTypes.array.isRequired,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  churchID: PropTypes.string.isRequired,
  authUserID: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

// Retrieve data from store as props
const mapStateToProps = ({ auth: { as, user }, groups, users, events, church }) => ({
  authUserPermissions: church.admins[user.id],
  users,
  groups,
  events,
  churchID: church.id,
  isAdmin: as === 'admin',
  authUserID: user.id,
});

export default withRouter(connect(mapStateToProps)(Groups));
