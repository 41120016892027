export const ASSETS_CDN = process.env.REACT_APP_ASSETS_CDN;
export const ASSETS_PREFIX_REGEXP = /^asset-image:/g;

export const preload = items => {
  const images = [];
  items.forEach((item, i) => {
    images[i] = new Image();
    images[i].src = item;
  });
};

preload([
  `${ASSETS_CDN}/availablemobile.png`,
  `${ASSETS_CDN}/featuremobile.png`,
  `${ASSETS_CDN}/background.jpg`,
  `${ASSETS_CDN}/signup_phone1.png`,
  `${ASSETS_CDN}/signup_phone2.png`,
  `${ASSETS_CDN}/signup_phone3.png`,
]);
