import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar } from 'components';
import { utils } from 'service';

const PrayerListItem = ({ history, location, prayer, user }) => {
  const userName = user ? `${user.profile.firstName} ${user.profile.lastName}` : 'Unknown';
  let note = '';
  if (user) {
    note =
      user.profile.notes && user.profile.notes.length > 140
        ? `${user.profile.notes.substring(0, 140)}...`
        : user.profile.notes || '';
  }
  const desc =
    prayer.desc && prayer.desc.length > 140 ? `${prayer.desc.substring(0, 140)}...` : prayer.desc || '';

  const handlePrayerClick = () => {
    history.push(`/dashboard/prayer/${prayer.id}`, { from: location.pathname });
  };

  return (
    <div role="button" className="col-md-6 content--slide" onClick={handlePrayerClick} tabIndex={0}>
      <div className="slide-userImg">
        <Avatar
          size="large"
          src={user ? user.avatar : null}
          badge={user ? user.profile.member.type === 'honored' : false}
          onBadgeClick={handlePrayerClick}
        />
      </div>
      <div className="wrap--user-info">
        <div className="wrap--user--top">
          <h6 className="name">{userName}</h6>
          <p className="date">{utils.formatValue(prayer.created, 'date')}</p>
        </div>
        <p className="text">{desc || note}</p>
      </div>
    </div>
  );
};

PrayerListItem.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  prayer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userID: PropTypes.string.isRequired,
    desc: PropTypes.string,
    created: PropTypes.number.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      notes: PropTypes.string,
      member: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }),
    avatar: PropTypes.string,
  }).isRequired,
};

export default withRouter(PrayerListItem);
