import { Home, Signup, Login, StripeCallback } from './pages';
import DashboardRoutes from './DashboardRoutes';
import Terms from 'pages/Home/Terms';
import Privacy from 'pages/Home/Privacy';
import Pricing from 'pages/Home/Pricing';

export const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/login',
    exact: false,
    component: Login,
  },
  {
    path: '/signup',
    exact: false,
    component: Signup,
  },
  {
    path: '/terms',
    exact: false,
    component: Terms,
  },
  {
    path: '/privacy-policy',
    exact: false,
    component: Privacy,
  },
  {
    path: '/pricing',
    exact: false,
    component: Pricing,
  },
];

export const privateRoutes = [
  { path: '/dashboard', exact: false, component: DashboardRoutes },
  {
    path: '/stripe-callback',
    exact: false,
    component: StripeCallback,
  },
];
