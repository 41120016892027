import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components';

const ChurchInfo = ({ church, onChangeImage, onChangeInput, isEdit, editable, errors }) => (
  <div className="church-info">
    <Avatar
      type="church"
      onChange={onChangeImage}
      width={160}
      height={160}
      editable={editable}
      modalSize="large"
      src={church.avatar}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
    <h4 className="section-heading">Church Information</h4>
    <div className="container-fluid church-info-detail">
      <div className="form-group row church-info-email">
        <label htmlFor="church-email" className="col-3 form-label">
          Church Email:
        </label>
        <div className="col">
          {isEdit ? (
            <input
              className="form-control"
              type="email"
              placeholder="Type church email"
              id="church-email"
              disabled={!editable}
              onChange={e => onChangeInput('email', e.target.value)}
              value={church.contacts.email || ''}
            />
          ) : (
            <span className={`form-text ${church.contacts.email ? '' : 'text-empty'}`}>
              {church.contacts.email || <span style={{ paddingLeft: 24 }}>None</span>}
            </span>
          )}
          <span className="helper-text error">{errors.email}</span>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="church-ministry" className="col-3 form-label">
          Ministry:
        </label>
        <div className="col">
          {isEdit ? (
            <input
              className="form-control"
              type="text"
              placeholder="Type church name"
              id="church-ministry"
              disabled={!editable}
              value={church.title || ''}
              onChange={e => onChangeInput('title', e.target.value)}
            />
          ) : (
            <span className={`form-text ${church.title ? '' : 'text-empty'}`}>
              {church.title || <span style={{ paddingLeft: 24 }}>None</span>}
            </span>
          )}
          <span className="helper-text error">{errors.title}</span>
        </div>
      </div>
      <div className="form-group row church-info-website">
        <label htmlFor="church-website" className="col-3 form-label">
          Church Website:
        </label>
        <div className="col">
          {isEdit ? (
            <input
              className="form-control"
              type="text"
              placeholder="Type church's website URL"
              id="church-website"
              disabled={!editable}
              value={church.contacts.website || ''}
              onChange={e => onChangeInput('website', e.target.value)}
            />
          ) : (
            <span className={`form-text ${church.contacts ? '' : 'text-empty'}`}>
              {church.contacts.website || <span style={{ paddingLeft: 24 }}>None</span>}
            </span>
          )}
          <span className="helper-text error">{errors.website}</span>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="church-pastor" className="col-3 form-label">
          Pastor:
        </label>
        <div className="col">
          {isEdit ? (
            <input
              className="form-control"
              type="text"
              placeholder="Type pastor name"
              id="church-pastor"
              disabled={!editable}
              value={church.pastor || ''}
              onChange={e => onChangeInput('pastor', e.target.value)}
            />
          ) : (
            <span className={`form-text ${church.pastor ? '' : 'text-empty'}`}>
              {church.pastor || <span style={{ paddingLeft: 24 }}>None</span>}
            </span>
          )}
          <span className="helper-text error">{errors.pastor}</span>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="church-description" className="col-3 form-label">
          Description:
        </label>
        <div className="col">
          {isEdit ? (
            <textarea
              className="form-control"
              id="church-description"
              placeholder="Type some more information about your church"
              rows="3"
              disabled={!editable}
              value={church.desc || ''}
              onChange={e => onChangeInput('desc', e.target.value)}
              style={{ minHeight: 120 }}
            />
          ) : (
            <span className={`form-text ${church.desc ? '' : 'text-empty'}`}>
              {church.desc || <span style={{ paddingLeft: 24 }}>None</span>}
            </span>
          )}
          <span className="helper-text error">{errors.desc}</span>
        </div>
      </div>
    </div>
  </div>
);

ChurchInfo.propTypes = {
  onChangeImage: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  church: PropTypes.shape({
    avatar: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    pastor: PropTypes.string,
    contacts: PropTypes.shape({
      website: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ChurchInfo;
