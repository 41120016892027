import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Loading';
import TextField from 'components/MDFormControls/TextField';
import LoginImg from 'assets/images/LoginIcon.png';
import CloseImg from 'assets/images/close-x.png';

import './style.css';

class FaithmoSignIn extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      email: undefined,
      password: undefined,
    };

    this.signIn.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    super.componentDidMount();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { authState } = this.props;
    if (prevProps.authState !== authState && this._isMounted) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ email: undefined, password: undefined });
    }
  }

  async signIn() {
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ email: email || '', password: password || '' });
      return;
    }

    try {
      this.setState({ isSubmitting: true });
      await super.signIn();
    } catch (error) {
      // continue regardless of error
    }

    setTimeout(() => {
      if (this._isMounted) this.setState({ isSubmitting: false });
    }, 500);
  }

  // eslint-disable-next-line no-unused-vars
  showComponent(theme) {
    const { history, location } = this.props;
    const { isSubmitting, email, password } = this.state;

    return (
      <div className="Form__formContainer">
        <div className="close-button-container">
          {!isSubmitting && (
            <div
              role="button"
              className="close-button"
              onClick={() => history.push('/', { from: location.pathname })}
              tabIndex={0}
            >
              <img src={CloseImg} alt="Back" style={{ width: '100%', height: '100%' }} />
            </div>
          )}
        </div>
        <form className="Form__formSection">
          <Grid container spacing={2}>
            <Grid item xs={12} className="Section__sectionHeader">
              <img src={LoginImg} alt="Login Icon" />
              <h3 className="Section__sectionHeaderContent">Login</h3>
            </Grid>
            <Grid item xs={12} className="Section__sectionBody">
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Email"
                  required
                  value={email}
                  onChange={event => {
                    // eslint-disable-next-line no-param-reassign
                    event.target.value = event.target.value.trim();
                    this.setState({ email: event.target.value });
                    this.handleInputChange(event);
                  }}
                  onBlur={() => {
                    if (email === undefined) this.setState({ email: '' });
                  }}
                  input={{
                    id: 'email',
                    key: 'email',
                    name: 'email',
                  }}
                  meta={{
                    error: !email ? 'Email is Required' : '',
                    touched: email !== undefined,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Password"
                  required
                  value={password}
                  onChange={event => {
                    this.setState({ password: event.target.value });
                    this.handleInputChange(event);
                  }}
                  onBlur={() => {
                    if (password === undefined) this.setState({ password: '' });
                  }}
                  input={{
                    id: 'password',
                    key: 'password',
                    name: 'password',
                  }}
                  meta={{
                    error: !password ? 'Password is Required' : '',
                    touched: password !== undefined,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                Forgot your password?&nbsp;
                <span
                  role="button"
                  className="Anchor__a"
                  onClick={() => super.changeState('forgotPassword')}
                  tabIndex={0}
                >
                  Reset Password
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12} className="Section__sectionFooter_">
              <span className="Section__sectionFooterPrimaryContent">
                {isSubmitting ? (
                  <div style={{ position: 'relative', height: 56, width: '100%' }}>
                    <Loading />
                  </div>
                ) : (
                  <button className="signIn-button" type="button" onClick={this.signIn}>
                    Login
                  </button>
                )}
              </span>
              <span className="Section__sectionFooterSecondaryContent">
                {!isSubmitting && (
                  <>
                    Don&apos;t have an account?&nbsp;
                    <Link to="/signup" className="Anchor__a">
                      Sign up
                    </Link>
                  </>
                )}
              </span>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withRouter(FaithmoSignIn);
