import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { utils } from 'service';
import Avatar from 'components/Avatar';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';

class PrayerDetails extends Component {
  toProfile = () => {
    const { history, location, user } = this.props;
    history.push(`/dashboard/user/${user.id}`, { from: location.pathname });
  };

  render() {
    const { handleStarClick, user } = this.props;
    const { cellPhone, homePhone } = user.contacts;
    const userFullName = `${user.profile.firstName} ${user.profile.lastName}`;

    const renderMemberStatus = (type, joinedDate) => {
      let statusDetail = <span />;
      if (type === 'non-member') {
        statusDetail = <span>Non-Member</span>;
      } else if (type !== 'member' && type !== 'honored') {
        statusDetail = <span>Member Status Unknown</span>;
      } else {
        statusDetail = (
          <span>
            Member
            {joinedDate ? ` Since ${utils.formatValue(joinedDate, 'date')}` : ''}
          </span>
        );
      }

      return statusDetail;
    };

    return (
      <div className="prayer-modal">
        <div className="prayer-img">
          <Avatar
            size="large"
            src={user.avatar}
            badge={user.profile.member.type === 'honored'}
            onBadgeClick={handleStarClick}
          />
        </div>
        <div className="prayer-detail">
          <div className="prayer-detail-top">
            <div className="info">
              <h5 className="name">{userFullName}</h5>
              <span className="date">
                {renderMemberStatus(user.profile.member.type, user.profile.member.joinedDate)}
              </span>
            </div>
            <div className="contact">
              {cellPhone && (
                <span>
                  <PhoneIphoneIcon fontSize="small" />
                  {cellPhone}
                </span>
              )}
              {homePhone && (
                <span>
                  <CallIcon fontSize="small" />
                  {homePhone}
                </span>
              )}
              {user.email && (
                <span>
                  <EmailIcon fontSize="small" />
                  {user.email}
                </span>
              )}
            </div>
          </div>
          <div className="prayer-detail-bottom">
            <div className="learn-button">
              <button type="button" className="button neutral border--red" onClick={this.toProfile}>
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrayerDetails.propTypes = {
  handleStarClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    profile: PropTypes.object.isRequired,
    contacts: PropTypes.object.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
};

export default withRouter(PrayerDetails);
