import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { COUNTRIES, STATES } from 'config/supported_address';
import { logger, utils } from 'service';
import { Loading, BootstrapSelect } from 'components';
import {
  // fetchCustomerStateRequest,
  updateMembershipRequest,
  createMembershipRequest,
  // fetchMembershipPlansRequest,
  createCustomerRequest,
} from 'redux/actions/ChurchActions';
import Cards from 'assets/images/cards.png';
import validate from './validate';

// prettier-ignore
const expYearMask = [/\d/, /\d/];
const expMonthMask = [/\d/, /\d/];
const cvcMask = [/\d/, /\d/, /\d/];
const zipcodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

class MembershipEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isSubmitting: false,
      subscription: {},
      plan: null,
      prices: [],
      cards: [],
      initialCard: null,
      selectedCard: null,
      newCard: {
        // brand: '',
        number: '',
        exp_month: '',
        exp_year: '',
        cvc: '',
        name: '',
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_zip: '',
      },
      metadata: {
        first_name: '',
        last_name: '',
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData = async () => {
    const {
      church: { customer: customerData, prices },
    } = this.props;

    const initialStates = {};
    try {
      initialStates.prices = prices;
      // const customerData = await dispatch(fetchcustomerDataStateRequest(church.customerDataID));
      if (customerData && !customerData.deleted) {
        initialStates.cards = customerData.sources ? customerData.sources : [];
        if (customerData.subscription && customerData.subscription.default_source)
          initialStates.selectedCard = customerData.subscription.default_source;
        else initialStates.selectedCard = customerData.default_source;
        initialStates.initialCard = initialStates.selectedCard;
        initialStates.subscription = customerData.subscription;
        initialStates.plan = customerData.subscription ? customerData.subscription.plan.id : null;
      }
    } catch (error) {
      // continue regardless of error
    }
    this.setState({ isLoaded: true, ...initialStates, errors: {} });
  };

  goBack = () => {
    const { history, location } = this.props;
    this.setState({ errors: {} }, () => {
      if (location.state && location.state.from) {
        history.goBack();
      } else {
        history.replace('/dashboard/settings/membership', {
          from: location.state ? location.state.from : undefined,
        });
      }
    });
  };

  handleSave = async () => {
    const { newCard, metadata, selectedCard, plan, subscription } = this.state;
    const { church, dispatch, history } = this.props;

    const errors = validate({
      ...newCard,
      metadata,
    });
    const errorsCnt = Object.keys(errors).length;
    if (!selectedCard && errorsCnt) {
      this.setState({ errors });
    } else if (!plan) {
      this.setState({ errors: { plan: 'Plan is required' } });
    } else {
      this.setState({ isSubmitting: true });
      const card = {
        ...newCard,
        exp_month: newCard.exp_month ? parseInt(newCard.exp_month, 10) : undefined,
        exp_year: newCard.exp_year ? parseInt(newCard.exp_year, 10) : undefined,
        cvc: newCard.cvc || undefined,
        address_line2: newCard.address_line2 || undefined,
        metadata,
      };
      try {
        let cID = church.customer && !church.customer.deleted ? church.customerID : null;
        if (!cID) {
          const { customerID } = await dispatch(
            createCustomerRequest(
              {
                email: church.contacts.email,
                phone: church.contacts.phoneNumber,
                name: church.title,
              },
              church.id,
            ),
          );
          cID = customerID;
        }
        if (subscription && subscription.id) {
          await updateMembershipRequest(
            cID,
            subscription.id,
            selectedCard ? null : card, // new card obj
            selectedCard, // old card id
            plan,
          );
        } else {
          await createMembershipRequest(cID, selectedCard ? null : card, selectedCard, plan);
        }
      } catch (error) {
        logger.warn('handleSave on MembershipEdit', error);
      }
      this.setState({ isSubmitting: false }, () => {
        history.push('/dashboard/settings/membership');
      });
    }
  };

  handleCardInput = (name, value) => {
    const { newCard, errors, metadata } = this.state;
    if (name === 'first_name' || name === 'last_name') {
      this.setState({
        metadata: { ...metadata, [name]: value },
        errors: { ...errors, [name]: undefined },
      });
    } else {
      let inputValue;
      if (name === 'address_zip' || name === 'exp_month' || name === 'exp_year' || name === 'cvc') {
        inputValue = value.replace(/[^0-9]/g, '');
      } else if (name === 'number') {
        inputValue = value.replace(/[^0-9 ]/g, '');
      } else {
        inputValue = value;
      }
      this.setState({
        newCard: { ...newCard, [name]: inputValue },
        errors: { ...errors, [name]: undefined },
      });
    }
  };

  render() {
    const {
      plan,
      prices,
      cards,
      selectedCard,
      initialCard,
      newCard,
      metadata,
      isLoaded,
      isSubmitting,
      errors,
    } = this.state;

    return (
      <div className="membership-edit card">
        <div className="card-header">
          <div className="title">
            <p>Membership Payment</p>
          </div>
        </div>
        <div className="card-body">
          {isLoaded ? (
            <>
              <div className="membership-block">
                <div className="membership-select-header">Membership Package</div>
                <div className="form-group">
                  <BootstrapSelect
                    items={(prices || []).map(item => ({
                      label: `${item.nickname} Package - ${utils.formatValue(
                        item.amount / 100,
                        'currency',
                      )} per/${item.interval}`,
                      value: item.id,
                    }))}
                    value={plan}
                    onChange={v => this.setState({ plan: v })}
                    placeHolder="Please choose a subscription"
                    style={{ width: 'auto' }}
                  />
                  <span className="helper-text error">{errors.plan}</span>
                </div>
              </div>
              <div className="card-block">
                <div className="card-choose">
                  <ul>
                    {cards &&
                      cards
                        .sort((a, b) => {
                          if (a.id === initialCard) return -1;
                          if (b.id === initialCard) return 1;
                          return b.joinDate - a.joinDate;
                        })
                        .map(card => (
                          <li key={card.id}>
                            <div className="currentCard-opt">
                              <input
                                type="radio"
                                id={`currentCard - ${card.id}`}
                                name="radio-group"
                                onChange={() =>
                                  this.setState({
                                    selectedCard: card.id,
                                  })
                                }
                                checked={selectedCard === card.id}
                              />
                              {initialCard === card.id ? (
                                <label htmlFor={`currentCard - ${card.id}`}>Current Card</label>
                              ) : (
                                <label htmlFor={`currentCard - ${card.id}`}>Saved Card</label>
                              )}
                            </div>
                            <p>{`${card.name || ''} (${card.brand.toUpperCase()} Ending ${card.last4})`}</p>
                          </li>
                        ))}
                    <li>
                      <input
                        type="radio"
                        id="newCard"
                        name="radio-group"
                        onChange={() =>
                          this.setState({
                            selectedCard: null,
                          })
                        }
                        checked={selectedCard === null}
                      />
                      <label htmlFor="newCard">Use A New Card</label>
                    </li>
                  </ul>
                </div>
                <div className="row card-detail" hidden={selectedCard}>
                  <div className="col-lg-7 bankCardInfo">
                    <p className="title">Bank Card Information</p>
                    <div className="form-group">
                      <label htmlFor="first_name">Cardholder name:</label>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={metadata.first_name || ''}
                            onChange={e => this.handleCardInput('first_name', e.target.value)}
                          />
                          <span className="helper-text error">{errors.first_name}</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={metadata.last_name || ''}
                            onChange={e => this.handleCardInput('last_name', e.target.value)}
                          />
                          <span className="helper-text error">{errors.last_name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="InputNicknames">Card Nickname:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type a nickname for the new card"
                        value={newCard.name || ''}
                        onChange={e => this.handleCardInput('name', e.target.value)}
                      />
                      <span className="helper-text error">{errors.name}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="InputCardNumber">Card Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type the card number"
                        value={newCard.number || ''}
                        onChange={e => this.handleCardInput('number', e.target.value)}
                      />
                      <span className="helper-text error">{errors.number}</span>
                    </div>
                    <img src={Cards} alt="Cards" />
                    <div className="form-group">
                      <label htmlFor="selectMonth">Expiration Date:</label>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <MaskedInput
                              type="text"
                              className="form-control"
                              placeholder="MM"
                              value={newCard.exp_month || ''}
                              onChange={e => this.handleCardInput('exp_month', e.target.value)}
                              placeholderChar={'\u2000'}
                              mask={expMonthMask}
                            />
                            <span className="helper-text error">{errors.exp_month}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <MaskedInput
                              type="text"
                              className="form-control"
                              placeholder="YY"
                              value={newCard.exp_year || ''}
                              onChange={e => this.handleCardInput('exp_year', e.target.value)}
                              placeholderChar={'\u2000'}
                              mask={expYearMask}
                            />
                            <span className="helper-text error">{errors.exp_year}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="securityCode">Security Code:</label>
                      <div className="row">
                        <div className="col-md-6">
                          <MaskedInput
                            type="text"
                            className="form-control"
                            placeholder="CVC"
                            value={newCard.cvc || ''}
                            onChange={e => this.handleCardInput('cvc', e.target.value)}
                            placeholderChar={'\u2000'}
                            mask={cvcMask}
                          />
                        </div>
                        <div className="col-md-6">
                          <a
                            className="securityCode"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.cvvnumber.com/cvv.html"
                          >
                            What is this?
                          </a>
                        </div>
                      </div>
                      <span className="helper-text error">{errors.cvc}</span>
                    </div>
                  </div>
                  <div className="col-lg-5 billingCardInfo">
                    <p className="title">Billing Information</p>
                    <div className="form-group">
                      <label htmlFor="selectCountry">Address:</label>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Street Address 01"
                          value={newCard.address_line1 || ''}
                          onChange={e => this.handleCardInput('address_line1', e.target.value)}
                        />
                        <span className="helper-text error">{errors.address_line1}</span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Street Address 02"
                          value={newCard.address_line2 || ''}
                          onChange={e => this.handleCardInput('address_line2', e.target.value)}
                        />
                        <span className="helper-text error">{errors.address_line2}</span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={newCard.address_city || ''}
                          onChange={e => this.handleCardInput('address_city', e.target.value)}
                        />
                        <span className="helper-text error">{errors.address_city}</span>
                      </div>
                      <div className="form-group">
                        <BootstrapSelect
                          items={(STATES || []).map(item => ({
                            label: item.name,
                            value: item.abbreviation,
                          }))}
                          value={newCard.address_state || null}
                          onChange={v => this.handleCardInput('address_state', v)}
                          placeHolder="State"
                        />
                        <span className="helper-text error">{errors.address_state}</span>
                      </div>
                      <div className="form-group">
                        <BootstrapSelect
                          items={(COUNTRIES || []).map(item => ({
                            label: item.country,
                            value: item.code,
                          }))}
                          value={newCard.address_country || null}
                          onChange={v => this.handleCardInput('address_country', v)}
                          placeHolder="Country"
                        />
                        <span className="helper-text error">{errors.address_country}</span>
                      </div>
                      <div className="form-group">
                        <MaskedInput
                          type="text"
                          className="form-control"
                          placeholder="Zip Code"
                          value={newCard.address_zip || ''}
                          onChange={e => this.handleCardInput('address_zip', e.target.value)}
                          placeholderChar={'\u2000'}
                          mask={zipcodeMask}
                        />
                        <span className="helper-text error">{errors.address_zip}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="action-group membership-action">
                  {isSubmitting ? (
                    <div style={{ position: 'relative', height: '60px' }}>
                      <Loading />
                    </div>
                  ) : (
                    <div style={{ position: 'relative' }}>
                      <button type="button" className="btn-save" onClick={this.handleSave}>
                        Save
                      </button>
                      <button type="button" className="btn-cancel" onClick={this.goBack}>
                        Cancel
                      </button>
                    </div>
                  )}
                  <p>
                    <span>Terms &amp; Conditions</span>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

MembershipEdit.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  church: PropTypes.shape({
    id: PropTypes.string.isRequired,
    stripe: PropTypes.object.isRequired,
    customerID: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        interval: PropTypes.string.isRequired,
        intervalCount: PropTypes.number.isRequired,
        nickname: PropTypes.string.isRequired,
      }),
    ),
    customer: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    contacts: PropTypes.shape({
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ church, auth: { user } }) => ({
  church: { ...church, customer: church.customer.data },
  authUser: user,
});

export default withRouter(connect(mapStateToProps)(MembershipEdit));
