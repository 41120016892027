import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROLES } from 'config/roles';
import { logger } from 'service';
import { Loading } from 'components';
import {
  updateChurchRequest,
  updateChurchAvatarRequest,
  deleteChurchAvatarRequest,
} from 'redux/actions/ChurchActions';
import ChurchInfo from './ChurchInfo';
import ContactInfo from './ContactInfo';
import SocialMedia from './SocialMedia';
import AdminPanel from './AdminPanel';
import validate from './validate';

import './Profile.css';
import ErrorBoundary from '../../../ErrorBoundary';
import { sendNotfication } from '../../../utils';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      editChurch: null,
      churchData: null,
      errors: {},
    };
  }

  componentDidMount() {
    this.loadChurchData();
  }

  loadChurchData = async () => {
    // const { dispatch, church } = this.props;
    // dispatch(fetchChurchRequest(church.id))
    //   .then(({ church: churchData }) => this.setState({ churchData }))
    //   .catch(() => {});
    const { church } = this.props;

    this.setState({
      errors: {},
      churchData: church,
      editChurch: null,
      isSubmitting: false,
    });
  };

  handleChangeImage = image => {
    const { dispatch, church } = this.props;
    const { editChurch, churchData } = this.state;
    if (image) {
      dispatch(updateChurchAvatarRequest(church.id, image))
        .then(({ avatar }) => {
          this.setState(() => ({
            editChurch: editChurch ? { ...editChurch, avatar } : null,
            churchData: { ...churchData, avatar },
          }));
        })
        .catch(error => {
          logger.warn('handleChangeImage on Church Profile', error);
        });
    } else if (image === null) {
      dispatch(deleteChurchAvatarRequest(church.id))
        .then(() => {
          this.setState(() => ({
            editChurch: editChurch ? { ...editChurch, avatar: null } : null,
            churchData: { ...churchData, avatar: null },
          }));
        })
        .catch(error => {
          logger.warn('handleChangeImage on Church Profile', error);
        });
    }
  };

  handleChangeInput = (name, value) => {
    const { editChurch, errors } = this.state;
    const { socialMedia, contacts } = editChurch;

    switch (name) {
      case 'facebook':
      case 'twitter': {
        const updatedSocialMedia = { ...socialMedia, [name]: value };
        this.setState({
          editChurch: { ...editChurch, socialMedia: updatedSocialMedia },
          errors: { ...errors, [name]: undefined },
        });
        break;
      }
      case 'phoneNumber':
      case 'website':
      case 'email': {
        const updatedContacts = { ...contacts, [name]: value };
        this.setState({
          editChurch: { ...editChurch, contacts: updatedContacts },
          errors: { ...errors, [name]: undefined },
        });
        break;
      }
      case 'line1':
      case 'line2':
      case 'city':
      case 'state':
      case 'country':
      case 'zip': {
        const { address } = contacts;
        const updatedAddress = { ...address, [name]: value };
        const updatedContacts1 = { ...contacts, address: updatedAddress };
        this.setState({
          editChurch: { ...editChurch, contacts: updatedContacts1 },
          errors: { ...errors, [name]: undefined },
        });
        break;
      }
      default:
        this.setState({
          editChurch: { ...editChurch, [name]: value },
          errors: { ...errors, [name]: undefined },
        });
    }
  };

  handleEditClick = () => {
    const { churchData } = this.state;
    this.setState({ editChurch: churchData, errors: {} });
  };

  handleCancel = () => {
    this.setState({ editChurch: null, errors: {} });
  };

  handleSave = async () => {
    const { editChurch } = this.state;
    const { dispatch } = this.props;
    try {
      const errors = validate(editChurch);
      const errorCnt = Object.keys(errors).length;
      if (errorCnt) {
        this.setState({ errors });
        return;
      }

      this.setState({ isSubmitting: true });

      await dispatch(updateChurchRequest(editChurch.id, editChurch));
      await sendNotfication('church_information_updated', { churchId: editChurch.id });
      this.loadChurchData();
    } catch (error) {
      logger.warn('handleSave in Church Profile', error);
      this.setState({ isSubmitting: false });
    }
  };

  handleAdminEditClick = () => {
    const { history, location } = this.props;
    history.push('/dashboard/settings/profile/adminpanel_edit', { from: location.pathname });
  };

  render() {
    const { users, authUserPermissions } = this.props;
    const { churchData, editChurch, errors, isSubmitting } = this.state;

    const isProfileEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.PROFILE_EDITOR.value);

    if (!churchData) {
      return (
        <div style={{ position: 'relative', height: 160 }}>
          <Loading />
        </div>
      );
    }

    return (
      <div className="church-profile">
        <ChurchInfo
          church={editChurch || churchData}
          isEdit={!!editChurch}
          errors={errors}
          editable={isProfileEditor}
          onChangeImage={this.handleChangeImage}
          onChangeInput={this.handleChangeInput}
        />
        <ContactInfo
          contacts={editChurch ? editChurch.contacts : churchData.contacts}
          isEdit={!!editChurch}
          errors={errors}
          editable={isProfileEditor}
          onChangeInput={this.handleChangeInput}
        />
        <SocialMedia
          socialMedia={editChurch ? editChurch.socialMedia : churchData.socialMedia}
          isEdit={!!editChurch}
          errors={errors}
          editable={isProfileEditor}
          onChangeInput={this.handleChangeInput}
        />
        <div className="col-md-12">
          <div className="action-group profile-action">
            {isProfileEditor &&
              (editChurch ? (
                <>
                  {isSubmitting ? (
                    <div style={{ position: 'relative', height: 48 }}>
                      <Loading size={32} />
                    </div>
                  ) : (
                    <>
                      <button type="button" className="btn-save" onClick={this.handleSave}>
                        Update
                      </button>
                      <button type="button" className="btn-cancel" onClick={this.handleCancel}>
                        Cancel
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button type="button" className="button gradient btn-edit" onClick={this.handleEditClick}>
                  Edit
                </button>
              ))}
            <p>
              Add your social links to allow your Subscribers to follow you though their Activities Feed
            </p>
          </div>
        </div>
        <ErrorBoundary>
          <AdminPanel
            admins={editChurch ? editChurch.admins : churchData.admins}
            users={users}
            editable={authUserPermissions.isSuperUser}
            onEditClick={this.handleAdminEditClick}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  church: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contacts: PropTypes.shape({
      phoneNumber: PropTypes.string,
    }).isRequired,
  }).isRequired,
  users: PropTypes.object.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, users, auth: { user } }) => ({
  church,
  users,
  authUserPermissions: church.admins[user.id],
});

export default withRouter(connect(mapStateToProps)(Profile));
