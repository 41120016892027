import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './style.css';
import Menubar from 'components/Menubar';

export default function Terms() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
    <div className="home">
        <div className="header">
        <Menubar />
        <div className='terms-container'>
            <article>
                <h5 style={{ textAlign: 'center' }}>TERMS OF USE</h5>
                <div>
                    <br />
                    <br />
                    PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY USING THE SERVICE (AS DEFINED BELOW), YOU
                    AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN (“TERMS OF SERVICE” OR THE
                    “AGREEMENT”). IF YOU ARE ENTERING THIS AGREEMENT ON BEHALF OF AN ORGANIZATION, YOU CONFIRM
                    THAT YOU HAVE THE AUTHORITY TO BIND THE ORGANIZATION AND THAT THE ORGANIZATION WILL BE BOUND
                    BY THESE TERMS. THE TERM “YOU” OR “YOUR” SHALL REFER TO YOU AND SUCH ORGANIZATION (IF ANY).
                    <br />
                    <br />
                    1. OWNERSHIP
                    <br />
                    <br />
                    This Service is owned and operated by ChurchButton, LLC. (hereinafter “ChurchButton”, “we” or
                    “us).
                    <br />
                    <br />
                    2. THE SERVICE
                    <br />
                    <br />
                    These Terms of Service describe the terms and conditions related to your use of the following
                    (collectively referred to herein as the “Service”): (a) media applications published by
                    ChurchButton (each a “Faithmo” mobile application), ChurchButton’s application program
                    interface (“ChurchButton API”), and any documentation related thereto, all provided to you and
                    for you by ChurchButton; (b) the configuration tools, configuration services, and Faithmo
                    mobile applicationpublication and maintenance services provided to you by ChurchButton; (c)
                    the websites provided by ChurchButton, (d) the integrated giving platform service and
                    functionality provided to you by Faithmo. Section 6 of these Terms of Service applies only to
                    Faithmo Giving. End users of the Site or any ChurchButton productmust also agree to
                    ChurchButton’s Terms of Use.
                    <br />
                    <br />
                    3. PRIVACY POLICY AND TERMS OF USE
                    <br />
                    <br />
                    Please refer to our Privacy Policy for information on how ChurchButton collects, uses and
                    discloses personally identifiable information from its users. These Terms of Service
                    incorporate by reference the ChurchButton Terms of Use.
                    <br />
                    <br />
                    4. TERM AND TERMINATION
                    <br />
                    <br />
                    Unless you have agreed to a longer term in a separate written agreement, the initial term of
                    this agreement is one year. Thereafter, this agreement will renew automatically from year to
                    year unless you notify ChurchButton of your intention to terminate at least thirty (30) days
                    prior to the end of the then-current contract term, in which case your agreement shall
                    terminate on the last day of then-current contract period.
                    <br />
                    <br />
                    ChurchButton may terminate this Agreement without cause upon written notice to you. If
                    ChurchButton terminates in such a manner, it shall refund you a portion of the subscription
                    fees you paid prior to such termination, pro-rated based on the subscription services already
                    provided. In the event this Agreement is terminated, upon your request, ChurchButton will make
                    available to you a file of your Customer Materials for download for a period of thirty (30)
                    days after termination. You agree and acknowledge that ChurchButton has no obligation to
                    retain your Customer Materials or other data, and may delete same, thirty (30) days after
                    termination. Closing your account shall not relieve you of the obligations and/or restrictions
                    stated herein.
                    <br />
                    <br /> This Section supersedes Section 19 of the Terms of Use.
                    <br />
                    <br />
                    5. ACCOUNT
                    <br />
                    <br />
                    You may open an account by completing the information on the registration pages of the Faithmo
                    website. You are responsible for maintaining the confidentiality of your user name and
                    password. Your username and/or password may not be assigned or transferred to any other person
                    or entity. You are required to provide your email address in order to open an account. Your
                    email address will help us to verify your identity on future visits. In the event that you
                    register using a non-existent email address or an email address that belongs to someone else,
                    we may terminate your account without notice. You will be responsible for any damage caused by
                    unauthorized access resulting from your failure to keep your password secure.
                    <br />
                    <br />
                    6. FAITHMO GIVING
                    <br />
                    <br />
                    <div className="privacy-p">
                        6.1 To use Faithmo Giving, you must register for that service and provide your bank or other
                        financial institution account information. By registering for Faithmo Giving, you:
                        <br />
                        <br />
                        <div className="privacy-p">
                        a. authorize Faithmo to act as your agent to collect donations in your name and donor
                        information on your behalf;
                        <br />
                        <br />
                        b. represent and warrant that all account information that you provide to Faithmo shall be
                        true and correct;
                        <br />
                        <br />
                        c. represent and warrant to Faithmo that at all times during the term of this Agreement,
                        (i) for United States entities, you are and will remain recognized by the Internal Revenue
                        Service as exempt from federal income tax under IRC Section 501(c)(3), and have public
                        charity status under IRC Section 509, and will utilize all donations received through
                        Faithmo in accordance with such tax-exempt status, and your organization shall not violate
                        any restrictions imposed by applicable law on IRC Section 501(c)(3) entities, including
                        but not limited to those proscribing political activity or proscribing the use of your
                        income or assets for a private purpose, or, for Canadian entities, you are and will
                        remain, registered as a charity under the Income Tax Act of Canada with the Canada Revenue
                        Agency (“CRA”); (ii) you understand you may have obligations to register under, and
                        covenant that you shall fully comply and bear the costs associated with, any and all
                        applicable federal, state, provincial and local statutes governing the solicitation of
                        charitable solicitations and donations, including but not limited to fulfilling any
                        registration requirements thereof; (iii) you have completed and submitted the Electronic
                        Funds Transfer Authorization Agreement (via "Stripe"), (iv) you will promptly notify
                        ChurchButtonof any change in your tax-exempt status, and any inquiry by the IRS, CRA or
                        any other federal, state, provincial or local government regarding the matters described
                        in (i) and (ii) above; (v) the donor of any online donation will not receive, nor be
                        promised to receive, any valuable goods or services as a result of such donation; (vi) you
                        agree to indemnify and hold harmless ChurchButton and Faithmo from and against any loss or
                        liability of any kind, including but not limited to fees and costs incurred in defense
                        thereof, resulting from or arising in any manner from, the material breach or inaccuracy
                        of any covenant, duty, representation or warranty set forth herein. Your obligation to
                        indemnify ChurchButton and Faithmo shall survive termination of this Agreement. <br />
                        <br /> d. agree to provide ChurchButtonwith proof of tax-exempt status and with
                        information for an acknowledgement letter and receipt that will be emailed to the donor
                        automatically and will allow the donor to seek and have evidence for tax deduction
                        purposes. <br />
                        <br /> e. (Unless you choose Monthly Billing) agree that ChurchButton may deduct its
                        service fees from the donation amounts. <br />
                        <br /> f. authorize ChurchButton and/or third parties associated with the processing of
                        payments to debit your depository account in order to collect fees as well as for the
                        purposes of funding refund requests by the donor and/or chargebacks imposed by the
                        applicable credit card company used by the donor. <br />
                        <br /> g. agree that payment to the designated organization will be made approximately a
                        week after receipt of funds from donor and will be sent to the designated organization via
                        electronic funds transfer.
                        <br />
                        <br />
                        h. warrant and represent that your representatives have legal and financial authority to
                        make legally binding financial decisions on behalf of your organization;
                        <br />
                        <br />
                        i. authorize us to provide your business name and limited information to financial
                        services providers.
                        </div>
                        <br />
                        <br />
                        6.2 Account applications with ChurchButtonmay be declined based on the
                        background/creditworthiness of the applicant.
                        <br />
                        <br /> 6.3 Donors who register for recurring gifts consent to be billed on a recurring basis
                        in compliance with applicable legal requirements and card network payment rules (e.g. rules
                        governing use of card networks such as Visa, MasterCard, Discover or American Express).
                        <br />
                        <br /> 6.4 In the event of a default under this Agreement or a misuse of applicable funds,
                        ChurchButtonand financial service providers facilitating card transactions on your behalf
                        may also report your business name and the name of your principals to the MATCHTM listing
                        maintained by MasterCard and accessed and updated by Visa and American Express or to the
                        Consortium Merchant Negative File maintained by Discover, if applicable, pursuant to
                        requirements of the card network rules. You specifically consent to the fulfillment of the
                        obligations related to this listing by ChurchButton and our payment processor and to the
                        listing itself and you waive and hold harmless ChurchButton and Faithmo from all claims and
                        liabilities resulting from such reporting.
                        <br />
                        <br /> 6.5 You may not utilize Faithmo Giving to process cash advances. <br />
                        <br />
                        6.6 You may not act as a payment intermediary or aggregator or otherwise resell our
                        services.
                        <br />
                        <br /> 6.7 Faithmo Giving utilizes Stripe. By registering for Faithmo, you agree to be bound
                        by the Stripe Connected Account Agreement (https://stripe.com/us/connect-account/legal), the
                        Stripe Services Agreement (https://stripe.com/us/legal), and the Wells Fargo Services
                        Addendum (https://stripe.com/wells-fargo/legal).
                        <br />
                        <br /> 6.8 You agree that, from the time you begin processing payment with Stripe until you
                        terminate your account with us, we may identify you as a customer of Stripe. Neither you nor
                        we will imply any untrue sponsorship, endorsement or affiliation between you and Stripe.
                        <br />
                        <br /> 6.9 Credit card companies permit donors to dispute charges that appear on their bill.
                        A chargeback may result in the reversal of a transaction where you are immediately liable
                        for the amount of the transaction. When a chargeback is issued, you are immediately liable
                        to Faithmo and/or Stripe for the full amount of the transaction related to the chargeback
                        plus any associated fees, fines, expenses or penalties. You agree that, among other
                        remedies, ChurchButton Wallet and/or Stripe may recover these amounts by deducting the
                        amount of the chargeback from any amounts owed to you by us. If we are unable to recover
                        funds related to a chargeback for which you are liable, you will pay us the full amount of
                        the chargeback immediately upon demand. Where such amounts are not immediately paid to us,
                        you agree to pay all costs and expenses, including without limitation attorneys’ fees and
                        other legal expenses, incurred by or on behalf of us in connection with the collection of
                        any unpaid chargebacks. Chargeback reserves may be established by Faithmo or Stripe, or by
                        Wells Fargo based upon risk parameters associated with your processing activity. As a
                        result, donated funds could be held either in whole or in part for an indeterminate time
                        period.
                        <br />
                        <br /> 6.10 ChurchButton is a PCI-DSS compliant service provider. ChurchButton will comply
                        with all applicable PCI DSS requirements to the extent that ChurchButton possesses or
                        otherwise stores, processes, or transmits cardholder data on behalf of its customers, and to
                        the extent that ChurchButton could impact the security of its customers’ cardholder data
                        environment.
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    7. CONFIDENTIALITY
                    <br />
                    <br />
                    <div className="privacy-p">
                        7.1 You acknowledge that your use of the Service may result in ChurchButton disclosing
                        certain of its Confidential Information to you. “Confidential Information” refers to certain
                        information that ChurchButton reasonably regards as proprietary or confidential relating to
                        its business, customers, products, proposed products, plans, inventions, processes and
                        techniques, including without limitation: (i) information, software, designs, text,
                        graphics, pictures, reviews, and sound files implemented or used by ChurchButton in its
                        products or to support its business operations; (ii) trade secrets, business plans,
                        strategies, methods and/or practices; (iii) computer systems architecture and
                        configurations; (iv) information which is governed by any now-existing or future
                        non-disclosure agreement between you and ChurchButton; (v) any other information relating to
                        ChurchButton that is not generally known to the public, including information about
                        government investigations and actions (where disclosure is permitted) personnel, products,
                        customers, financial information, marketing and pricing strategies, services or future
                        business plans; and (vi) any and all analyses, compilations, or notes prepared which contain
                        or are based on any of the above information.
                        <br />
                        <br /> 7.2 You agree to hold in confidence and not use or disclose any Confidential
                        Information except in accordance with this Agreement. You may disclose Confidential
                        Information, on a need-to-know basis, to your personnel who have agreed in writing to
                        non-disclosure terms at least as protective as the provisions of this Section, for purposes
                        permitted in this Agreement, subject to the condition that you shall be liable for their
                        breach of this Section.
                        <br />
                        <br /> 7.3 The obligations set forth in this Section do not apply to Confidential
                        Information that (i) is in or enters the public domain without breach of this Agreement,
                        (ii) the receiving party lawfully receives from a third party without restriction on
                        disclosure and without breach of a nondisclosure obligation, (iii) the receiving party knew
                        prior to receiving such information from the disclosing party or develops independently
                        without access or reference to the Confidential Information, (iv) is disclosed with the
                        written approval of the disclosing party. Notwithstanding the Confidentiality Obligations
                        set forth in this Section, each party may disclose Confidential Information of the other
                        party as permitted by law (i) to the extent required by a court of competent jurisdiction or
                        other governmental authority or otherwise as required by law but only after alerting the
                        other party of such disclosure requirement and, prior to any such disclosure, allowing
                        (where practicable to do so) the other party a reasonable period of time within which to
                        seek a protective order against the proposed disclosure, or (ii) on a “need-to-know” basis
                        under an obligation of confidentiality substantially similar in all material respects to
                        those confidentiality obligations in this Section to its legal counsel, accountants,
                        contractors, consultants, banks and other financing sources.
                        <br />
                        <br /> 7.4 You agree that any unauthorized disclosure of Confidential Information may cause
                        immediate and irreparable injury to ChurchButton and that, in the event of such breach,
                        ChurchButton will be entitled, in addition to any other available remedies, to immediate
                        injunctive and other equitable relief, without bond and without the necessity of showing
                        actual monetary damages.
                    </div>
                    <br />
                    <br />
                    8. INTELLECTUAL PROPERTY
                    <br />
                    <br />
                    <div className="privacy-p">
                        {' '}
                        8.1 Service Materials. Unless otherwise indicated, the Service and all content (other than
                        Customer Materials), including, without limitation, the ChurchButton trademarks, Faithmo
                        mobile applicationlogos, and all designs, text, graphics, images, information, data,
                        software, documentation, sound files, other files and the selection and arrangement thereof
                        (collectively, the “Service Materials”) are the property of ChurchButton and are protected
                        by U.S., Canadian, and international copyright, trade secret and other intellectual property
                        laws. The following actions are specifically prohibited: (a) any resale, lease or rental of
                        the Site or the Service Materials therein; (b) the distribution, public performance or
                        public display of any Service Materials; (c) modifying or otherwise making any derivative
                        uses of the Site, the Service Materials, or any portion thereof; (d) use of automated means,
                        including spiders, robots, crawlers, offline readers, data mining tools, or the like to
                        download data from the Site or Service or to cause an overload of ChurchButton servers ; (e)
                        downloading (other than page caching) or copying any portion of the Site, the Service
                        Materials or any information contained therein, except as expressly permitted; (f) any
                        attempt to gain unauthorized access to ChurchButton’s computer systems or engage in any
                        activity that disrupts, diminishes the quality of, interferes with the performance of, or
                        impairs the functionality of, the Service; (g) collection or harvesting of any personally
                        identifiable information, including, but not limited to, company names, domain names or
                        account names, from the Service nor to use the communication systems provided by the Service
                        for any commercial solicitation purposes; (h) use of any portion of the Service as a
                        destination linked from any unsolicited bulk messages or unsolicited commercial messages;
                        (i) any use of the Service or the Service Materials other than for their intended purpose.
                        Any use of the Service or the Service Materials other than as specifically authorized
                        herein, without the prior written permission of ChurchButton, is strictly prohibited and
                        will terminate the license granted herein. Such unauthorized use may also violate applicable
                        laws including without limitation copyright and trademark laws and applicable communications
                        regulations and statutes. Unless explicitly stated herein, nothing in these Terms of Service
                        shall be construed as conferring any license to intellectual property rights, whether by
                        estoppel, implication or otherwise.
                        <br />
                        <br /> 8.2 Faithmo Mobile Application. You shall retain ownership of any and all logos, and
                        all designs, text, graphics, images, information, data, software, sound files, other files
                        and the selection and arrangement thereof provided by you for incorporation into, or
                        provided by you through data feeds to, the Service, including but not limited to the Faithmo
                        Mobile Application(“Customer Materials”). Inasmuch as the Faithmo Mobile Application is
                        owned by ChurchButton, it may not be lawfully used outside of the Service. Only ChurchButton
                        may post the Faithmo Mobile Application to the Apple iTunes App Store, Google Play, Windows
                        Phone App Store, Amazon App Store, the web, or any other platform. The Faithmo Mobile
                        Application shall not be transferred to or submitted on third party developer accounts
                        except at ChurchButton’s discretion, though ChurchButton reserves the right to require the
                        Faithmo Mobile Applicationbe transferred to or submitted by a third party developer account
                        in your name. Any violation of the foregoing limitations may result in (without limitation)
                        termination of the Service and/or termination of this Agreement.
                    </div>
                    <br />
                    <br />
                    9. COPYRIGHT POLICY
                    <br />
                    <br />
                    ChurchButton respects the intellectual property rights of others and expects users of the
                    Services to do the same. We will respond to notices of alleged copyright infringement that
                    comply with applicable law and are properly provided to us. If you believe that your
                    copyrighted materials have been copied in a way that constitutes copyright infringement,
                    please provide us with the following information: (i) a physical or electronic signature of
                    the copyright owner or a person authorized to act on their behalf; (ii) identification of the
                    copyrighted work claimed to have been infringed; (iii) identification of the material that is
                    claimed to be infringing or to be the subject of infringing activity and that is to be removed
                    or access to which is to be disabled, and information reasonably sufficient to permit us to
                    locate the material; (iv) your contact information, including your address, telephone number,
                    and an email address; (v) a statement by you that you have a good faith belief that use of the
                    material in the manner complained of is not authorized by the copyright owner, its agent, or
                    the law; and (vi) a statement that the information in the notification is accurate, and, under
                    penalty of perjury, that you are authorized to act on behalf of the copyright owner.
                    <br />
                    <br /> We reserve the right to remove Customer Materials alleged to be infringing without
                    prior notice and at our sole discretion. In appropriate circumstances, ChurchButton will also
                    terminate a user’s account if the user is determined to be a repeat infringer.
                    <br />
                    <br />
                    10. LIMITATIONS ON USE OF THE SERVICE
                    <br />
                    <br />
                    Any use of the Service that violates this Agreement is strictly prohibited and may result in
                    your exclusion from the Service, and the removal of the Faithmo Mobile Application from any
                    network, store, or server, and any other platform on which the Faithmo Mobile Application has
                    been published. Unauthorized use may also violate applicable laws including without limitation
                    copyright and trademark laws and applicable communications regulations and statutes. You agree
                    to use the Service and any information obtained from the Service and your use thereof solely
                    for the purposes authorized by these Terms of Service. You will not circumvent ChurchButton’s
                    intended limitations for any feature of the Service as reflected in the ChurchButton
                    documentation from time to time. You will not encourage or promote the use of the Service in
                    any manner or for any purpose that is not permitted under these Terms of Service. You will not
                    use or attempt to use the Service in any manner that is unfair, deceptive, or otherwise
                    unlawful or harmful to ChurchButton, any ChurchButton clients, or any other third party.
                    Unless explicitly stated herein, nothing in these Terms of Service shall be construed as
                    conferring any license to intellectual property rights, whether by estoppel, implication or
                    otherwise. You agree that your use of the Services shall be further governed by these Terms of
                    Service, the Terms of Use, and any other relevant content and conduct rules of the platforms
                    on which the Faithmo Mobile Applicationis published.
                    <br />
                    <br />
                    11. USE ONLY BY YOUR ORGANIZATION AND ONWARD TRANSFERS
                    <br />
                    <br />
                    You may not transfer or assign your rights under this Agreement to any third party and any
                    attempt to do so shall be void.
                    <br />
                    <br /> The Service, including without limitation any Faithmo Mobile Application, shall only be
                    distributed to end users by ChurchButton through the app networks, app stores, servers, and
                    platforms selected by ChurchButton, utilizing in every case a form of license acceptable to
                    ChurchButton. Violation of the provisions of this Section may result in deactivation of the
                    Service, or any part thereof, without refund.
                    <br />
                    <br /> You may not make the Faithmo Mobile Application available to another entity for
                    inclusion of its materials and you may not rent, lease, or sell the Service to third parties
                    or otherwise provide other entities with the ability to distribute their materials through the
                    Faithmo Mobile Applicationunless it is specifically identified as your materials. You agree
                    not to resell, republish, duplicate, reproduce or exploit any part of the Service without
                    ChurchButton’s prior written permission.
                    <br />
                    <br />
                    If ChurchButton gathers personal information from EU data subjects on your behalf, you agree
                    that such data may only be processed for limited and specified purposes consistent with
                    consent provided for such data by the data subject and that you will provide the same level of
                    protection as is required by the EU-U.S. Privacy Shield Principles.
                    <br />
                    <br />
                    12. DATA FEEDS; RESTRICTIONS ON CUSTOMER MATERIALS POSTED
                    <br />
                    <br />
                    You shall be solely responsible for establishing, and maintaining a connection to the
                    Internet, and providing the required data feeds to support proper function of the Faithmo
                    Mobile Application. By providing ChurchButton with Customer Materials, you grant ChurchButton
                    an irrevocable, perpetual, royalty-free, fully sub-licensable, fully paid up, worldwide
                    license to use, copy, transmit, publicly perform, digitally perform, publicly display, and
                    distribute the Customer Materials through the Faithmo Mobile Application, the Site, and any
                    platform, network, store, server, in the Internet. This license is non-exclusive, except you
                    agree that ChurchButton shall have the exclusive right to combine Customer Materials with a
                    Faithmo Mobile Application. You represent and warrant that you possess all rights needed to
                    authorize ChurchButton to use, copy, transmit, publicly perform, digitally perform, publicly
                    display and distribute the Customer Materials as provided herein.
                    <br />
                    <br /> You may not use the Service in a prohibited manner or include in the data feed any
                    Customer Materials that is prohibited. ChurchButton reserves the right to investigate and take
                    appropriate legal action in its sole discretion against anyone who violates this provision,
                    including without limitation, removing the offending communication from the Customer Materials
                    served through the Faithmo Mobile Application and terminating the violator’s Service without
                    refund. We value integrity, truth, and respect for others, and we strive to avoid offending
                    users with content, apps, or websites that are objectionable or inappropriate. For this
                    reason, we don’t allow the publication or promotion of hatred; violence; racism; blasphemy; or
                    sexual, religious, or political content or activity that violates or disparages traditional
                    Christian scripture and values, or organizations with such views.
                    <br />
                    <br /> The following is prohibited: Harassment or perceived harassment of another person;
                    Unsolicited mass mailings or “spam;” Customer Materials determined by ChurchButton to be
                    illegal, or to violate any local, state, provincial, or federal law or regulation or the
                    rights of any person or entity, or violate the rules or requirements of one or more mobile app
                    platforms or mobile app stores; Harmful, offensive and abusive language, including but not
                    limited to hate speech, expletives, harassment, obscenities, vulgarities, sexually explicit
                    language, images, video or other materials that risk offending community standards (e.g.
                    nudity, bestiality, pornography); Customer Materials that are inappropriate based on the
                    subject matter; Customer Materials that are encrypted or that contains viruses or any other
                    computer programming routines that are intended to damage, interfere with, intercept or
                    appropriate any system or information; and Customer Materials that violate the intellectual
                    property rights of any person.
                    <br />
                    <br /> You agree that you will only submit information that you believe to be true and you
                    will not purposefully provide false or misleading information.
                    <br />
                    <br /> You further agree that you will not: Modify, adapt, translate, or reverse engineer any
                    portion of the Service; Take any action that creates an unreasonably large load on our IT
                    infrastructure; Use the Service to violate the security of any computer network, crack
                    passwords or security encryption codes; transfer or store illegal material including that are
                    deemed threatening or obscene; Create user accounts by automated means or under false or
                    fraudulent pretenses; or Collect information about other users for any improper purpose.
                    <br />
                    <br />
                    13. RIGHT TO UPGRADE SERVICE
                    <br />
                    <br />
                    ChurchButton reserves the right to upgrade and/or change the Service at any time without
                    notice.
                    <br />
                    <br />
                    14. PAYMENT FOR SERVICES
                    <br />
                    <br />
                    The service fee for any configuration services, media app publication services, maintenance
                    services, or any other services provided to you by ChurchButton shall be the fees so described
                    when you order these services through the Site. When you authorize ChurchButton to charge your
                    credit card for the monthly service fees associated with the service plan selected by you, the
                    Faithmo Mobile Application will be able to accept donations from your donors. ChurchButton
                    shall charge your credit card in the amount of the agreed Monthly, Quarterly, or Annual fee
                    each Month, Quarter, or Year in advance. You represent and warrant that all credit card,
                    Automated Clearing House (ACH), and or other payment and related information that you provide
                    to ChurchButton for such purposes shall be true and correct. You must promptly inform
                    ChurchButton of any changes in your credit card information. You may make changes to your
                    credit card information on your user profile webpage. All payments shall be made in U.S.
                    dollars. The charges included here do not include taxes. If ChurchButton is required to pay
                    any federal, state, provincial or local sales, use, property or value added taxes including,
                    without limitation, GST and/or provincial sales tax based on the Service provided under this
                    Agreement, the taxes shall be separately billed to you, unless you are able to provide proof
                    of appropriate, qualifying tax exempt status. ChurchButton shall not pay any interest or
                    penalties incurred due to late payment or nonpayment of such taxes by you. Upon your failure
                    to pay monthly fees, you authorize ChurchButton to remove access to the Faithmo Mobile
                    Application. Prepaid fees are non-refundable.
                    <br />
                    <br />
                    15. NON-SOLICITATION AND NON-COMPETITION
                    <br />
                    <br />
                    During the term of this Agreement and for a period of two years from the date your account
                    expires or is terminated, you will not solicit those vendors or customers of ChurchButton that
                    you learned of or with whom you developed relationships as a result of your relationship with
                    ChurchButton nor will you divert or attempt to divert from ChurchButton any business
                    ChurchButton enjoyed or solicited from such customers and to the extent that you gained
                    Confidential Information from ChurchButton, you shall not enter into competition with
                    ChurchButton in the United States or Canada.
                    <br />
                    <br />
                    16. NONINTERFERENCE WITH CHURCHBUTTON EMPLOYEES
                    <br />
                    <br />
                    During the time that your account is active and for two (2) years following, you agree that
                    you will not:
                    <br />
                    <br />
                    <div className="privacy-p">
                        a) induce, or attempt to induce, any ChurchButton employee to quit ChurchButton’s employ,
                        <br />
                        <br /> b) recruit or hire away any ChurchButton employee, or
                        <br />
                        <br /> c) hire or engage any ChurchButton employee or former employee whose employment with
                        ChurchButton ended less than one year before the date of such hiring or engagement.
                    </div>
                    <br />
                    <br />
                    17. WARRANTY
                    <br />
                    <br />
                    ChurchButton does not guarantee acceptance of any Faithmo Mobile Application or data by Apple,
                    Inc. or gatekeepers of other platforms, networks, stores or servers. If the Faithmo Mobile
                    Application is rejected by them for code defects, ChurchButton will correct them and resubmit
                    them as soon as reasonably possible. This warranty to you shall be null and void if you are in
                    default under this Agreement.
                    <br />
                    <br />
                    18. PATENTS
                    <br />
                    <br />
                    One or more patents owned by, or patent applications submitted, by ChurchButton may apply to
                    this Service and to the features or services accessible via the Service.
                    <br />
                    <br />
                    19. ACCREDITATION AND PROMOTION
                    <br />
                    <br />
                    The launch screen of the Faithmo Mobile Application shall bear a copyright notice and the
                    branding of ChurchButton in the form, size and location chosen by ChurchButton in its
                    discretion. The Faithmo Mobile Application’s graphical user interface shall display copyright
                    information and the credit “Designed + Developed by ChurchButton” or similar credit in the
                    form, size and location chosen by ChurchButton in its discretion. ChurchButton retains the
                    right to reproduce, publish and display static screen shots, motion demos, and other
                    representations of the Faithmo Mobile Applicationwith your name and logo in ChurchButton-owned
                    and affiliated portfolios and websites, and in galleries, design periodicals and other media
                    or exhibits for purposes of publicity. In the app stores and marketplaces, keywords
                    “ChurchButton” and/or “Faithmo” must be included and credit to ChurchButton must be included
                    in the app description. In all other places where the Faithmo Mobile Applicationis marketed or
                    distributed, you shall add the following statement “Designed + Developed by ChurchButton.
                    Copyright © 2021-2022ChurchButton” or similar credit in the form, size and location chosen by
                    ChurchButton in its discretion, and include a link to the Site if the statement is made in
                    electronic format.
                    <br />
                    <br />
                    20. ENTIRE AGREEMENT
                    <br />
                    <br />
                    This Agreement, together with the Terms of Use and any Statements of Work between you and
                    ChurchButton or an affiliate thereof, constitutes the complete and exclusive statement of the
                    agreement between the parties with respect to the use of the Services and any acts or
                    omissions of ChurchButton and supersedes any and all prior or contemporaneous communications,
                    representations, statements and understandings, whether oral or written, between the parties.
                    In the event of a conflict between or among the terms of the Terms of Use, Terms of Service,
                    and Statements of Work, the order of precedence shall be, from first to last: the Statements
                    of Work, the Terms of Service, and lastly the Terms of Use. If you have entered into a
                    separate agreement (other than subsequent revisions of the Terms of Use) with ChurchButton,
                    and there is conflict between that agreement and these Terms of Service or the Terms of Use,
                    the terms of that agreement shall control to the extent such agreement is still in effect.
                    </div>
                </article>
            </div>
            </div>
        <Footer />
    </div>
  );
}
