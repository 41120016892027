import { RECEIVE_CUSTOMERS, REQUEST_CUSTOMERS } from 'redux/actions/CustomersActions';

const initialState = { data: [], isLoading: false };

const CustomersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
      };

    case RECEIVE_CUSTOMERS: {
      const { customers } = action.payload;
      return {
        ...state,
        data: customers || state.data,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default CustomersReducer;
