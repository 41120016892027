import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withAuthenticator } from 'aws-amplify-react';
import amplifyConfigParams from 'amplify_auth_params';
import { ROLES } from 'config/roles';
import { DHeader, DSubHeader } from 'components';
import {
  Dashboard,
  UserProfile,
  Users,
  GivingHistory,
  Events,
  Settings,
  EventDetail,
  GivingHistoryDetail,
  Prayers,
  PrayerDetail,
  Groups,
  Group,
  UserUpload,
  GivingManualUpload,
} from './pages';

const DashboardRoutes = ({ authUserPermissions }) => {
  const isGivingManager =
    authUserPermissions.isSuperUser ||
    !!authUserPermissions.permissions.find(pm => pm === ROLES.GIVING_MANAGER.value);
  const isPrayerRequestManager =
    authUserPermissions.isSuperUser ||
    !!authUserPermissions.permissions.find(pm => pm === ROLES.REQUEST_MANAGER.value);
  const isMembershipEditor =
    authUserPermissions.isSuperUser ||
    !!authUserPermissions.permissions.find(pm => pm === ROLES.MEMBERSHIP_EDITOR.value);
  const isUserManager =
    authUserPermissions.isSuperUser ||
    !!authUserPermissions.permissions.find(pm => pm === ROLES.USER_MANAGER.value);

  return (
    <>
      <DHeader />
      <DSubHeader />
      <div className="app-header-spacer" />
      <div className="app-content">
        <Switch>
          {!authUserPermissions.isLeader && <Route exact path="/dashboard" component={Dashboard} />}
          {isGivingManager && <Route exact path="/dashboard/givings" component={GivingHistory} />}
          {isGivingManager && (
            <Route exact path="/dashboard/givings/upload" component={GivingManualUpload} />
          )}
          {isGivingManager && (
            <Route exact path="/dashboard/givings/upload/:userId" component={GivingManualUpload} />
          )}
          {isGivingManager && (
            <Route exact path="/dashboard/giving/:userId" component={GivingHistoryDetail} />
          )}
          <Route exact path="/dashboard/groups" component={Groups} />
          <Route exact path="/dashboard/group/:groupId" component={Group} />
          <Route exact path="/dashboard/events" component={Events} />
          <Route exact path="/dashboard/event/:eventId" component={EventDetail} />
          {isPrayerRequestManager && <Route exact path="/dashboard/prayers" component={Prayers} />}
          {isPrayerRequestManager && (
            <Route exact path="/dashboard/prayer/:prayerId" component={PrayerDetail} />
          )}
          {!authUserPermissions.isLeader && <Route exact path="/dashboard/users" component={Users} />}
          {isUserManager && <Route exact path="/dashboard/users/upload" component={UserUpload} />}
          {!authUserPermissions.isLeader && (
            <Route exact path="/dashboard/user/:userId" component={UserProfile} />
          )}
          {!authUserPermissions.isLeader && (
            <Route exact path="/dashboard/settings/:name" component={Settings} />
          )}
          {authUserPermissions.isSuperUser && (
            <Route exact path="/dashboard/settings/profile/:name" component={Settings} />
          )}
          {isMembershipEditor && (
            <Route exact path="/dashboard/settings/membership/:name" component={Settings} />
          )}
          {authUserPermissions.isLeader ? (
            <Redirect to="/dashboard/groups" />
          ) : (
            <Redirect to="/dashboard" />
          )}
        </Switch>
      </div>
    </>
  );
};

DashboardRoutes.propTypes = {
  authUserPermissions: PropTypes.shape({
    isSuperUser: PropTypes.bool,
    isLeader: PropTypes.bool,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default withAuthenticator(DashboardRoutes, amplifyConfigParams);
