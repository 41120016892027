import React from 'react';
import PropTypes from 'prop-types';
import { SWIPER_CONFIG } from 'config/swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import EventUserListItem from './EventUserListItem';

const EventUserList = React.memo(({ users }) => {
  const chunks = [];
  users.forEach(item => {
    if (!chunks.length || chunks[chunks.length - 1].length === 4) {
      chunks.push([]);
    }
    chunks[chunks.length - 1].push(item);
  });

  return (
    <Swiper {...SWIPER_CONFIG}>
      {chunks.map((chunk, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>
          <div className="event-wrap-users">
            {chunk.map(user => (
              <EventUserListItem user={user} key={user.id} />
            ))}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

EventUserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default EventUserList;
