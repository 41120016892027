import { api, logger } from 'service';

export const RECEIVE_PRAYERS = 'RECEIVE_PRAYERS';
export const RECEIVE_PRAYER = 'RECEIVE_PRAYER';
export const REQUEST_PRAYERS = 'REQUEST_PRAYERS';
export const REMOVE_PRAYER = 'REMOVE_PRAYER';
export const UPDATE_PRAYER = 'UPDATE_PRAYER';

export const fetchPrayersRequest = churchID => async dispatch => {
  dispatch({ type: REQUEST_PRAYERS });
  try {
    const { requests } = await api(`/church/${churchID}/prayer-requests`);
    const prayerRequests = requests.sort((a, b) => b.created - a.created);
    dispatch({
      type: RECEIVE_PRAYERS,
      payload: { data: prayerRequests },
    });
    return { data: requests };
  } catch (error) {
    logger.warn('fetchPrayersRequest action', error);
    dispatch({
      type: RECEIVE_PRAYERS,
      payload: { data: [] },
    });
    return [];
  }
};

export const fetchPrayerRequest = prayerID => async dispatch => {
  try {
    const { request } = await api(`/prayer-request/${prayerID}`);
    dispatch({
      type: RECEIVE_PRAYER,
      payload: { id: prayerID, data: request },
    });
    return request;
  } catch (error) {
    logger.warn('fetchPrayerRequest action', error);
  }
  return {};
};

export const archivePrayerRequest = prayerID => async dispatch => {
  try {
    await api(`/prayer-request/${prayerID}`, 'put', {
      archived: 1,
    });
    dispatch({
      type: UPDATE_PRAYER,
      payload: { id: prayerID, data: { archived: 1 } },
    });
  } catch (error) {
    logger.warn('archivePrayerRequest action', error);
  }
};

export const deletePrayerRequest = prayerID => async dispatch => {
  try {
    await api(`/prayer-request/${prayerID}`, 'delete');
    dispatch({
      type: REMOVE_PRAYER,
      payload: { id: prayerID },
    });
  } catch (error) {
    logger.warn('deletePrayerRequest action', error);
  }
};
