import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import moment from 'moment';
import { MANUAL_PAYMENT_METHODS } from 'config/payment-type';
import { ROLES } from 'config/roles';
import { INITIAL_DATE_RANGE } from 'config/giving-history';
import { logger, utils } from 'service';
import {
  DatePicker,
  DropDownMenu,
  ExcelDownLoad,
  Loading,
  RoundedTable,
  Scrollbar,
  UserAvatar,
} from 'components';
import { fetchGivingHistoryRequest } from 'redux/actions/GiftActions';
import { fetchUsersRequest } from 'redux/actions/UsersActions';
import GivingHistoryImage from 'assets/images/givinghistory.png';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './style.css';

class GivingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProductID: 'all',
      selectedPaymentMethod: 'all',
      giftSummary: {
        history: [],
        productTotalMap: {},
        total: 0,
        isLoading: false,
      },
    };
  }

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const { from: prevFrom, to: prevTo, gifts: prevGifts, users: prevUsers } = prevProps;
    const { from, to, gifts, users } = this.props;
    if (
      prevFrom !== from ||
      prevTo !== to ||
      JSON.stringify(gifts) !== JSON.stringify(prevGifts) ||
      JSON.stringify(users) !== JSON.stringify(prevUsers)
    ) {
      this.loadData(false);
    }
  }

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }
  };

  loadData = async isRefresh => {
    const { selectedProductID, selectedPaymentMethod, giftSummary } = this.state;
    const { gifts, users, dispatch, churchID, from, to } = this.props;
    this.setState({ giftSummary: { ...giftSummary, isLoading: true } }, async () => {
      if (users.isLoading || gifts.isLoading) return;
      try {
        let history = [];
        const productTotalMap = {};
        if (isRefresh || gifts.from > from || gifts.to < to) {
          const [hist] = await Promise.all([
            dispatch(fetchGivingHistoryRequest(churchID, from, to)),
            dispatch(fetchUsersRequest(churchID)),
          ]);
          history = hist;
        } else {
          history = [...gifts.history];
        }
        if (history && history.length) {
          history.forEach(item => {
            productTotalMap[item.productID || 'null'] =
              (productTotalMap[item.productID || 'null'] || 0) + item.total / 100;
          });
        }
        history = history.filter(
          item =>
            item.date >= from &&
            item.date <= to &&
            (selectedProductID === 'all' || item.productID === selectedProductID) &&
            (selectedPaymentMethod === 'all' ||
              (selectedPaymentMethod === 'manual' && item.giftID) ||
              selectedPaymentMethod === item.methodType),
        );
        let total = 0;
        history.forEach(item => {
          total += item.total / 100;
        });
        this.setState({
          giftSummary: {
            history,
            productTotalMap,
            total,
            isLoading: false,
          },
        });
        return;
      } catch (error) {
        logger.warn('loadData in GivingHistory', error);
      }
      this.setState({
        giftSummary: {
          history: [],
          productTotalMap: {},
          total: 0,
          isLoading: false,
        },
      });
    });
  };

  handleChangeProduct = event => {
    this.setState({ selectedProductID: event.target.value }, () => {
      this.loadData(false);
    });
  };

  handleChangeFilter = (name, value) => {
    const { history, location, from, to } = this.props;
    if (name === 'from' || name === 'to') {
      const dateFrom = name === 'from' ? moment(value.getTime()).startOf('day').valueOf() : from;
      const dateTo = name === 'to' ? moment(value.getTime()).endOf('day').valueOf() : to;
      history.replace(`/dashboard/givings?from=${dateFrom}&to=${dateTo}`, {
        from: location.state ? location.state.from : undefined,
      });
    } else {
      this.setState({ [name]: value }, () => {
        this.loadData(false);
      });
    }
  };

  handleEditGift = () => {
    const { history, location } = this.props;
    history.push('/dashboard/settings/gift_settings', {
      from: location.pathname,
    });
  };

  handleManualEntryUpload = () => {
    const { history, location } = this.props;
    history.push('/dashboard/givings/upload', {
      from: location.pathname,
    });
  };

  render() {
    const { selectedProductID, giftSummary, selectedPaymentMethod } = this.state;
    const { users, gifts, authUserPermissions, location, from, to } = this.props;
    const isGiftEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GIFT_EDITOR.value);
    const renderGiftHistoryByProduct = gHistory => {
      if (!gHistory || !gHistory.length) return <div />;
      const reducer = (accumulator, currentValue) => ({
        total: accumulator.total + currentValue.total,
      });
      const historyObj = utils.groupByProductID(giftSummary.history);
      return Object.keys(historyObj)
        .map(key => {
          const gift = gifts.data.find(g => g.productID === key) || { title: 'No Title' };
          const { total } = (historyObj[key] || []).reduce(reducer, { total: 0 });
          return {
            gift,
            history: historyObj[key] || [],
            total,
          };
        })
        .sort((a, b) => {
          if (a.gift.title === 'No Title') return 1;
          if (b.gift.title === 'No Title') return -1;
          return b.total - a.total;
        })
        .map((obj, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <div className="g-showgiving-detail">
              <p>{`${obj.gift.title}`}</p>
              <span className={obj.total < 0 ? 'color-danger' : ''}>
                {utils.formatValue(obj.total / 100, 'currency')}
              </span>
            </div>
          </li>
        ));
    };
    return (
      <>
        <Helmet title="Giving history" />
        <div className="giving-history card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={GivingHistoryImage} alt="Giving History" />
                <p>Giving history</p>
              </div>
              <div className="title-buttons">
                <button
                  type="button"
                  className="button neutral border--red bg-white medium"
                  onClick={this.handleManualEntryUpload}
                >
                  Manual Entry
                </button>
                {isGiftEditor && (
                  <button type="button" className="button gradient medium" onClick={this.handleEditGift}>
                    Edit Gift Options
                  </button>
                )}
                <ExcelDownLoad
                  table="ghistory-table-to-xls"
                  filename="Giving History"
                  sheet={`${utils.formatValue(from, 'date')} - ${utils.formatValue(to, 'date')}`}
                  buttonText="Export To Excel"
                  className="button gradient medium"
                  disabled={giftSummary.isLoading || users.isLoading || !users.data}
                />
                {!giftSummary.isLoading && users.data && (
                  <table hidden id="ghistory-table-to-xls">
                    <thead>
                      <tr>
                        <th>FullName</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.data
                        .map(user => {
                          const reduce = (accumulator, currentValue) => ({
                            total: accumulator.total + currentValue.total,
                            hasManual: accumulator.hasManual || !!currentValue.giftID,
                          });
                          const { total, hasManual } = giftSummary.history
                            .filter(hist => hist.userID === user.id)
                            .reduce(reduce, { total: 0, hasManual: false });
                          return {
                            ...user,
                            gHistory: {
                              total,
                              hasManual,
                            },
                          };
                        })
                        .filter(user => user.gHistory.total)
                        .map(user => (
                          <tr key={user.id}>
                            <td>{`${user.profile.firstName} ${user.profile.lastName}`}</td>
                            <td>{utils.formatValue(user.gHistory.total / 100, 'currency')}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="card-block g-main-history">
              <div className="row mb-3">
                <div className="col-md-10 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="g-from g-picker-container">
                            <span className="label">From: </span>
                            <DatePicker
                              clearIcon={null}
                              onChange={value => this.handleChangeFilter('from', value)}
                              value={new Date(from)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="g-to g-picker-container">
                            <span className="label">To: </span>
                            <DatePicker
                              clearIcon={null}
                              onChange={value => this.handleChangeFilter('to', value)}
                              value={new Date(to)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="g-picker-container">
                            <span className="label">Gift Options: </span>
                            <DropDownMenu
                              value={selectedProductID}
                              onChange={e => this.handleChangeFilter('selectedProductID', e.target.value)}
                              menuArray={[{ title: 'All', productID: 'all' }, ...(gifts.data || [])]
                                .filter(
                                  gift =>
                                    gift.productID === 'all' || giftSummary.productTotalMap[gift.productID],
                                )
                                .map(item => ({
                                  label: item.title,
                                  value: item.productID,
                                }))
                                .sort((a, b) => {
                                  if (a.value === 'all') return -1;
                                  if (b.value === 'all') return 1;
                                  return a.label.localeCompare(b.label);
                                })}
                              style={{ margin: 0 }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="g-picker-container">
                            <span className="label">Payment Method: </span>
                            <DropDownMenu
                              value={selectedPaymentMethod}
                              onChange={e =>
                                this.handleChangeFilter('selectedPaymentMethod', e.target.value)
                              }
                              menuArray={[
                                { label: 'All', value: 'all' },
                                { label: 'Faithmo', value: 'faithmo' },
                                { label: 'Manual', value: 'manual' },
                                ...MANUAL_PAYMENT_METHODS,
                              ]}
                              style={{ margin: 0 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-2 col-sm-12"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    paddingTop: 12,
                  }}
                >
                  <button
                    type="button"
                    className="button neutral border--red small"
                    onClick={() => this.loadData(true)}
                  >
                    Reload
                  </button>
                </div>
              </div>
              {giftSummary.isLoading || users.isLoading ? (
                <div
                  style={{
                    position: 'relative',
                    minHeight: '60px',
                    minWidth: '60px',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-7 col-md-12 g-main-history-table">
                    <RoundedTable
                      // showPagenate
                      showCount
                      minWidth={640}
                      data={users.data
                        .map(user => {
                          const reduce = (accumulator, currentValue) => ({
                            total: accumulator.total + currentValue.total,
                            hasManual: accumulator.hasManual || !!currentValue.giftID,
                          });
                          const { total, hasManual } = giftSummary.history
                            .filter(hist => hist.userID === user.id)
                            .reduce(reduce, { total: 0, hasManual: false });
                          return {
                            ...user,
                            gHistory: {
                              total,
                              hasManual,
                            },
                          };
                        })
                        .filter(user => user.gHistory.total)
                        .map(user => [
                          <UserAvatar user={user} className="align-left" />,
                          <div className={user.gHistory.total < 0 ? 'amount color-danger' : 'amount'}>
                            {utils.formatValue(user.gHistory.total / 100, 'currency')}
                          </div>,
                          <div>
                            <Link
                              to={{
                                pathname: `/dashboard/giving/${user.id}`,
                                search: `?from=${from}&to=${to}`,
                                state: { from: location.pathname },
                              }}
                              style={{
                                textDecoration: 'underline',
                                color: '#31c5c3',
                              }}
                            >
                              View all Givings
                            </Link>
                          </div>,
                        ])}
                      headings={[
                        { component: <div className="align-left">FullName</div>, percentWidth: 40 },
                        { component: <div>Total Amount</div>, percentWidth: 30 },
                        {
                          component: <div>Action</div>,
                          percentWidth: 30,
                        },
                      ]}
                    />
                  </div>
                  <div className="col-lg-5 col-md-12 g-total-by">
                    <div className="g-total-by-date">
                      <div className="g-total-by-date-top">
                        <p>
                          <span>Showing Givings From</span>
                          &nbsp;
                          <span>{utils.formatValue(from, 'date')}</span>
                          &nbsp;-&nbsp;
                          <span>{utils.formatValue(to, 'date')}</span>
                        </p>
                      </div>
                      <div className="g-total-by-date-main">
                        <Scrollbar autoHeight autoHeightMin={420} autoHeightMax={660}>
                          <ul>{renderGiftHistoryByProduct(giftSummary.history)}</ul>
                        </Scrollbar>
                      </div>
                      <div className="g-total-by-date-bottom">
                        <span>Grand Total:</span>
                        <span className={giftSummary.total < 0 ? 'color-danger' : ''}>
                          {utils.formatValue(giftSummary.total, 'currency')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

GivingHistory.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  churchID: PropTypes.string.isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        profile: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  gifts: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        churchID: PropTypes.string.isRequired,
        created: PropTypes.number.isRequired,
        productID: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
    history: PropTypes.arrayOf(
      PropTypes.shape({
        methodType: PropTypes.string.isRequired,
        churchID: PropTypes.string.isRequired,
        customerID: PropTypes.string,
        userID: PropTypes.string.isRequired,
        productID: PropTypes.string.isRequired,
        date: PropTypes.number.isRequired,
        created: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        desc: PropTypes.string,
        giftID: PropTypes.string,
      }),
    ),
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    isLoading: PropTypes.number.isRequired,
  }).isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  from: PropTypes.number,
  to: PropTypes.number,
};
GivingHistory.defaultProps = {
  from: INITIAL_DATE_RANGE.from,
  to: INITIAL_DATE_RANGE.to,
};
// Retrieve data from store as props
const mapStateToProps = ({ auth: { user }, users, gifts, church }, props) => {
  let from;
  let to;
  if (props.location.search && props.location.search.length > 1) {
    try {
      const queryObj = qs.parse(props.location.search.substr(1), '&', '=');
      from = parseInt(queryObj.from, 10);
      to = parseInt(queryObj.to, 10);
    } catch (error) {
      // continue regardless of error
    }
  }
  return {
    users,
    gifts,
    churchID: church.id,
    authUserPermissions: church.admins[user.id],
    from,
    to,
  };
};

export default withRouter(connect(mapStateToProps)(GivingHistory));
