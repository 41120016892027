import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { configureStore } from 'redux/store';

import 'swiper/swiper-bundle.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-day-picker/lib/style.css';

// preloading
import 'config/assets';
import './preload-utils';

import App from './App';
import theme from './MuiTheme';
import './index.css';

// Initialize store
let initialState = {};
if (process.env.NODE_ENV === 'development') {
  const data = localStorage.getItem('unverified_faithmo_signup_data');
  if (data) {
    initialState = {
      form: { RegisterChurchForm: { values: JSON.parse(data) } },
    };
  }
}
const store = configureStore(initialState);

SwiperCore.use([Navigation, Pagination]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
