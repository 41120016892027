import { EMAIL_REGEXP, PHONE_REGEXP, WEBSITE_LINK_REGEXP, ZIP_CODE_REGEXP } from 'config/regexrs';

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Church/Ministry Name is required';
  }

  if (!values.pastor) {
    errors.pastor = 'Pastor/Priest Name is required';
  }

  if (!values.churchEmail) {
    errors.churchEmail = 'Church Email Address is required';
  } else if (!EMAIL_REGEXP.test(values.churchEmail)) {
    errors.churchEmail = 'Church Email Address is invalid';
  }

  if (values.website && !WEBSITE_LINK_REGEXP.test(values.website)) {
    errors.website = 'Website link is requiring a www. format';
  }

  if (!values.phone) {
    errors.phone = 'Church Phone Number is required';
  } else if (!PHONE_REGEXP.test(values.phone)) {
    errors.phone = 'Church Phone Number has invalid format';
  }

  if (!values.line1) {
    errors.line1 = 'Address is required';
  }

  if (!values.city) {
    errors.city = 'City is required';
  }

  if (!values.state) {
    errors.state = 'State is required';
  }

  if (!values.zip) {
    errors.zip = 'Zipcode is required';
  } else if (!ZIP_CODE_REGEXP.test(values.zip)) {
    errors.zip = 'Zipcode has invalid format';
  }

  if (!values.country) {
    errors.country = 'Country is required';
  }

  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }

  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }

  if (!values.personalEmail) {
    errors.personalEmail = 'Personal Email Address is required';
  } else if (!EMAIL_REGEXP.test(values.personalEmail)) {
    errors.personalEmail = 'Personal Email Address is invalid';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must have at least 8 characters';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords must match';
  }

  return errors;
};

export default validate;
