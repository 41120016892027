import { api, logger } from 'service';
import { sendNotfication } from '../../utils';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const fetchNotifications = data => async dispatch => {
  dispatch({
    type: FETCH_NOTIFICATIONS,
    payload: { notifications: data },
  });
};

export const newNotificationReceived = data => async dispatch => {
  dispatch({ type: NEW_NOTIFICATION, payload: { notification: data } });
};

export const markNotificationAsRead = id => async dispatch => {
  await sendNotfication('read_notification', { NFTID: id });
  dispatch({ type: READ_NOTIFICATION, payload: { id } });
};

export const removeNotification = id => async dispatch => {
  await sendNotfication('remove_notification', { NFTID: id });
  dispatch({ type: REMOVE_NOTIFICATION, payload: { id } });
};

export const getNotification = userId => async (dispatch, getState) => {
  const {
    gifts: { isLoading: giftsLoading },
  } = getState();
  if (giftsLoading) return;
  try {
    // userId = "cbc18f1f956ef484a9619d5cbe452d870"
    const notifications = await api(`/notification/${userId}`);
    dispatch({
      type: FETCH_NOTIFICATIONS,
      payload: { notifications: notifications.notifications },
    });
  } catch (error) {
    logger.warn('getNotification action', error);
    // throw error;
  }
};

export const churchExpNotification = church => async () => {
  const { customerID } = church;
  try {
    if (customerID) {
      const { customer } = await api(`/payments/merchant/customer/${customerID}`);
      const currentDate = new Date();
      const expDate = new Date(customer.subscription.current_period_end * 1000);
      const leftDate =
        (expDate.getMonth() - currentDate.getMonth()) * 30 + (expDate.getDate() - currentDate.getDate());
      // todo: only show expired notification if:
      //  -there is no recurring membership
      //  -ending soon without renewing
      if (
        (customer?.subscription?.cancel_at_period_end && leftDate > 0 && leftDate < 31) ||
        customer?.subscription?.status !== 'active'
      ) {
        await sendNotfication('subscription_expired', { churchID: church.id, leftDate });
      }
    }
  } catch (error) {
    logger.warn('expiration notification action', error);
    // return null;
  }
};
