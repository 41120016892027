import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, MenuItem, Input, Select, makeStyles, Button, Typography } from '@material-ui/core';
import VolunteerIcon from 'assets/images/volunteer.svg';
import GroupsIcon from 'assets/images/groups.svg';
import ForumIcon from 'assets/images/forum.svg';
import EventIcon from 'assets/images/event.svg';
import Event2Icon from 'assets/images/praying-hands-solid-svgrepo-com.svg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '17px',
      height: '100%',
      flexDirection: 'column',
    },
  },
  content: {
    fontSize: '35px',
    color: '#fff',
    fontWeight: 700,
    marginBottom: '40px',
    width: '80%',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      fontSize: '5vw',
      marginBottom: '20px',
      width: '100%',
    },
  },
  contentTitle: {
    fontSize: '6vw',
    color: '#fff',
    fontWeight: 700,
    display: 'none',
    width: '80%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: '10px',
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 120px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  title: {
    fontSize: '55px',
    lineHeight: '55px',
    color: '#fff',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '43px',
      lineHeight: '45px',
    },
  },
  secondTitle: {
    fontSize: '70px',
    lineHeight: '70px',
    color: '#000',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '43px',
      lineHeight: '45px',
    },
  },
  buttonContainer: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  button: {
    width: '100%',
    height: '130px',
    background: '#fff',
    borderRadius: '30px',
    padding: '20px 30px !important',
    textTransform: 'none',
    textAlign: 'start',
    margin: '5px 0',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      height: 'unset',
      margin: '10px',
      padding: '10px 15px !important',
      borderRadius: '25px',
    },
  },
  btnText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  right: {
    paddingTop: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
  rightBox: {
    borderRadius: '20px',
    background: 'rgba(255, 254, 248, 0.13)',
    display: 'flex',
    padding: '30px 30px 0 30px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'rgba(0, 0, 0, 0.13) 0px 1px 4px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '3vw 3vw 0 3vw',
      justifyContent: 'start',
    },
  },
  buttonTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  buttonText: {
    fontSize: '20px',
  },
}));

const HomeSlide = ({ data, onBtnClick }) => {
  const buttons = [
    {
      title: 'Giving Mangement',
      text: 'Simplify Giving. Foster Generosity.',
      icon: VolunteerIcon,
    },
    {
      title: 'Group Mangement',
      text: 'Unify Groups. Strengthen Connections.',
      icon: GroupsIcon,
    },
    {
      title: 'Messaging/Notifications',
      text: 'Instant Outreach. Seamless Communication.',
      icon: ForumIcon,
    },
    {
      title: 'Event Management',
      text: 'Effortless Events. Perfectly Coordinated.',
      icon: EventIcon,
    },
    {
      title: 'Prayer Requests',
      text: 'Share Prayers. Find Solace.',
      icon: Event2Icon,
    },
  ];
  const [contentData, setContentData] = useState(data);
  const classes = useStyles({ contentData });
  return (
    <Grid container classes={{ root: classes.root }} style={{ background: data.background }}>
      <Grid item sm={12} md={5} classes={{ root: classes.titleBox }}>
        <Typography classes={{ root: classes.title }}>
          Simple, <em>yet</em>
        </Typography>
        <Typography classes={{ root: classes.secondTitle }}>Powerful</Typography>
        <Typography classes={{ root: classes.title }} style={{ marginBottom: '15px' }}>
          Features
        </Typography>
        <Grid container classes={{ root: classes.buttonContainer }}>
          {buttons.map((button, index) => (
            <Grid item key={index}>
              <Button
                onClick={() => onBtnClick(index)}
                classes={{ root: classes.button }}
                style={{ background: data.index - 1 == index ? 'rgb(13, 33, 35)' : '#fff' }}
              >
                <Grid sm={12} md={3}>
                  <img
                    src={button.icon}
                    alt={button.text}
                    style={{ filter: data.index - 1 == index ? 'invert(1)' : 'none' }}
                  />
                </Grid>
                <Grid sm={9} classes={{ root: classes.btnText }}>
                  <Typography
                    classes={{ root: classes.buttonTitle }}
                    style={{ color: data.index - 1 == index ? '#fff' : '#000' }}
                  >
                    {button?.title}
                  </Typography>
                  <Typography
                    classes={{ root: classes.buttonText }}
                    style={{ color: data.index - 1 == index ? '#fff' : '#000' }}
                  >
                    {button?.text}
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item sm={12} md={7} classes={{ root: classes.right }}>
        <Box classes={{ root: classes.rightBox }}>
          <Typography classes={{ root: classes.contentTitle }}>{data?.title}</Typography>
          <Typography classes={{ root: classes.content }}>{data?.content}</Typography>
          <img src={data?.image} alt={data?.index} className="slideImg" />
        </Box>
      </Grid>
    </Grid>
  );
};
export default HomeSlide;
