import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fe9168',
    },
    secondary: {
      main: '#00bcd4',
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid #00bcd4',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #00bcd4',
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: 'white',
      },
      toolbarTxt: {
        color: '#00000069',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        color: 'white',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: 'white',
      },
    },
    MuiButton: {
      label: {
        fontWeight: 'bold',
      },
    },
  },
});

export default theme;
