import {
  FETCH_NOTIFICATIONS,
  NEW_NOTIFICATION,
  READ_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from 'redux/actions/NotificationActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';

const initialState = {
  data: [],
  isLoading: false,
};

const NotificationReducer = (state = initialState, action) => {
  const { data } = state;
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        data: action.payload.notifications.sort((a, b) => b.created - a.created),
        isLoading: true,
      };

    case NEW_NOTIFICATION:
      return {
        ...state,
        data: [action.payload.notification, ...data].sort((a, b) => b.created - a.created),
        isLoading: false,
      };

    case READ_NOTIFICATION:
      return {
        ...state,
        data: data.map(noti => (noti.id === action.payload.id ? { ...noti, isRead: true } : noti)),
      };

    case REMOVE_NOTIFICATION:
      return { ...state, data: data.filter(noti => noti.id !== action.payload.id) };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default NotificationReducer;
