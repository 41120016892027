export const SWIPER_CONFIG = {
  slidesPerView: 1,
  centeredSlides: true,
  pagination: {
    clickable: true,
  },
  navigation: true,
};

export default { SWIPER_CONFIG };
