import React from 'react';
import PropTypes from 'prop-types';
import userStarBig from 'assets/images/groupstar_big.png';
import leftArrow from 'assets/images/arrows.svg';

const PrayerDetailsPosition = React.memo(({ handlePositionBack, user }) => (
  <div className="position-content">
    <div role="button" className="back-prayer" onClick={handlePositionBack} tabIndex={0}>
      <img src={leftArrow} alt="left arrow" />
    </div>
    <div className="shield-icon">
      <img src={userStarBig} alt="UserStar Big" />
    </div>
    <div className="info">
      <h5 className="name">Position: {user.profile.member.position}</h5>
      <p className="text">{user.profile.member.description}</p>
    </div>
  </div>
));

PrayerDetailsPosition.propTypes = {
  handlePositionBack: PropTypes.func.isRequired,
  user: PropTypes.shape({
    profile: PropTypes.object.isRequired,
  }).isRequired,
};

export default PrayerDetailsPosition;
