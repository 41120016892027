import ChurchIconBlack from 'assets/images/Churchico_black.png';
import GroupsIcon from 'assets/images/group.png';
import PrayerReqIcon from 'assets/images/PrayerReqIcon.png';
import ChatBubbleIcon from 'assets/images/chat.png';
import AlertIcon from 'assets/images/exclamation-mark-in-a-circle.png';

// info, error, warning, success
export const NOTIFICATION_TYPES = {
  welcome: {
    label: 'Faithmo',
    icon: ChurchIconBlack,
    link: '/dashboard/settings/profile',
    color: 'info',
  },
  prayer_request: {
    label: 'Prayer Request',
    icon: PrayerReqIcon,
    link: '/dashboard/prayers',
    color: 'info',
  },
  new_subscribers: {
    label: 'New Subscribers',
    icon: GroupsIcon,
    link: '/dashboard/users',
    color: 'info',
  },
  subscription_expired: {
    label: 'Expired Faithmo Subscription',
    icon: AlertIcon,
    link: '/dashboard/settings/membership/membership_edit',
    color: 'danger',
  },
  group: {
    label: '',
    icon: ChatBubbleIcon,
    link: null,
    color: 'info',
  },
};

export default { NOTIFICATION_TYPES };
