import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Loading';
import { InputComponent } from './formikFields';

const StepThree = ({ isSubmitting }) => (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputComponent name="firstName" label="First Name" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputComponent name="lastName" label="Last Name" />
      </Grid>
      <Grid item xs={12}>
        <InputComponent name="personalEmail" label="Personal Email Address" />
      </Grid>
      <Grid item xs={12}>
        <InputComponent name="password" type="password" label="Password" />
      </Grid>
      <Grid item xs={12}>
        <InputComponent name="confirmPassword" type="password" label="Re-enter Password" />
      </Grid>
    </Grid>
    <button type="submit" className="button medium gradient button--signup-step" disabled={isSubmitting}>
      {isSubmitting ? <Loading size={24} loadingIconStyle={{ color: '#fff' }} /> : 'Submit'}
    </button>
  </>
);

StepThree.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default StepThree;
