import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { CSVReader, jsonToCSV } from 'react-papaparse';
import { RoundedTable, SwitchCheckBox, Loading, Tooltip, ProgressRing } from 'components';
import { logger, utils } from 'service';
import { CSV_OPTIONS, CSV_HEADERS } from 'config/user-upload';
import { createUserBatchRequest } from 'redux/actions/UsersActions';
import StyledModal from 'components/StyledModal';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import UserManagementImage from 'assets/images/Usermanagement.png';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';

import './style.css';
import validate from './validate';
import { sendNotfication } from '../../utils';
import { ASSETS_CDN } from 'config/assets';
import {
  PHONE_REGEXP,
} from 'config/regexrs';

const phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
// eslint-disable-next-line react/prop-types
const PhoneInput = React.forwardRef(({ ...props }, ref) => (
  <MaskedInput {...props} ref={ref} mask={phoneMask} placeholderChar={'\u2000'} />
));

class UserUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Usersimported: [],
      Usersinputted: [],
      errors: [],
      isOpenClearConfrimDlg: false,
      isOpenSubmitConfrimDlg: false,
      isOpenLeaveConfirmDlg: false,
      isOpenEmptyNotiDlg: false,
      isLoading: false,
      isShowOnlyErrors: false,
      currentStep: 'edit', // edit or submit
    };

    this.next = {};
    this.userArray = [];

    const headers = {
      Status: { key: 'status', length: 2 },
      ...CSV_HEADERS,
    };
    this.headerSettings = {};
    this.headerSettings.submit = utils.generageHeaderSettings(headers);
    this.headerSettings.edit = utils.generageHeaderSettings(CSV_HEADERS);
    this.selectedRow = null;
    this.notiText = 'No data has been entered to upload.';

    this.clearRowButtonRef = React.createRef();
    this.tableRef = React.createRef();
    this.buttonRef = React.createRef(); // For open file selection dialog
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  handleLeavePage = (location, action) => {
    this.setState({ isOpenLeaveConfirmDlg: true });
    this.next = { location, action };
    return false;
  };

  handleGo = () => {
    const { history } = this.props;
    if (this.next.location) {
      this.setState({ isOpenLeaveConfirmDlg: false, Usersimported: [], Usersinputted: [] }, () => {
        if (this.next.action === 'POP') {
          history.goBack();
        } else if (this.next.action === 'REPLACE') {
          history.replace(
            this.next.location.pathname + this.next.location.search + this.next.location.hash,
            this.next.location.state,
          );
        } else if (this.next.action === 'PUSH') {
          history.push(
            this.next.location.pathname + this.next.location.search + this.next.location.hash,
            this.next.location.state,
          );
        }
        this.next = {};
      });
    }
  };

  handleOpenDialog = e => {
    // Note that the ref is set async, so it might be null at some point
    if (this.buttonRef.current) {
      this.buttonRef.current.open(e);
    }
  };

  handleCSVFileReadSuccess = data => {
    const validData = utils
      .generateObjectArrayFromTable(data)
      .filter(row => Object.keys(row).filter(name => row[name]).length)
      .map(row => {
        let validRow = {...row, index: utils.makeStringID(24)}
        if(row['contacts.cellPhone'] && !PHONE_REGEXP.test(row['contacts.cellPhone']))
        {
          validRow = { ...validRow, 'contacts.cellPhone': utils.addDashes(row['contacts.cellPhone'])}
        }
        if(row['contacts.homePhone'] && !PHONE_REGEXP.test(row['contacts.homePhone']))
        {
          validRow = { ...validRow, 'contacts.homePhone': utils.addDashes(row['contacts.homePhone'])}
        }
        return validRow
      })
      this.setState({ Usersimported: validData }, () => {
        this.handleValidate();
      });
  };

  handleCSVFileReadError = (...args) => {
    logger.error(args);
  };

  handleClearAllClick = () => {
    const { Usersimported, Usersinputted } = this.state;

    if (!Usersimported.length && !Usersinputted.length) {
      this.notiText = 'No data has been entered to clear.';
      this.setState({ isOpenEmptyNotiDlg: true });
      return;
    }

    this.selectedRow = null;
    this.setState({ isOpenClearConfrimDlg: true });
  };

  handleClearRowClick = () => {
    this.setState({
      isOpenClearConfrimDlg: true,
    });
  };

  handleClear = () => {
    const { Usersimported, Usersinputted, errors } = this.state;
    if (this.selectedRow) {
      this.setState({
        isOpenClearConfrimDlg: false,
        Usersimported: Usersimported.filter(r => r.index !== this.selectedRow),
        Usersinputted: Usersinputted.filter(r => r.index !== this.selectedRow),
        errors: errors.filter(r => r.index !== this.selectedRow),
      });
      this.selectedRow = null;
    } else {
      this.setState({ isOpenClearConfrimDlg: false, Usersimported: [], Usersinputted: [], errors: [] });
    }
  };

  handleValidate = () => {
    const { Usersimported, Usersinputted, currentStep } = this.state;
    const { users } = this.props;

    if (!Usersimported.length && !Usersinputted.length) {
      this.notiText = 'No data has been entered to validate.';
      this.setState({ isOpenEmptyNotiDlg: true });
      return;
    }

    const rows = [...Usersinputted, ...Usersimported];
    const errors = rows
      .map(row => {
        const rowErrors = validate(
          row,
          (rows.filter(
            user => user.email && row.email && user.email.toLowerCase() === row.email.toLowerCase(),
          ).length > 1
            ? 'duplicated_f'
            : null) ||
            (users.data.filter(
              user => user.email && row.email && user.email.toLowerCase() === row.email.toLowerCase(),
            ).length
              ? 'duplicated_p'
              : null),
        );

        return {
          validator: rowErrors,
          index: row.index,
          errorCnt: Object.keys(rowErrors).length,
        };
      })
      .filter(error => error.errorCnt);

    if (currentStep !== 'submit') {
      this.tableRef.current.scrollToTop();
      this.tableRef.current.scrollToLeft();
    }
    this.setState({ errors, currentStep: 'submit' });
  };

  handleSumitButtonClick = () => {
    const { Usersimported, Usersinputted } = this.state;
    const rows = [...Usersinputted, ...Usersimported];

    const userArray = rows.map(row => {
      /* eslint-disable dot-notation */
      const user = {
        'profile.firstName': row['profile.firstName'],
        'profile.lastName': row['profile.lastName'],
        email: row['email'],
        'profile.ID': row['profile.ID'],
        'profile.occupation': row['profile.occupation'],
        'contacts.cellPhone': row['contacts.cellPhone'],
        'contacts.homePhone': row['contacts.homePhone'],
        'profile.notes': row['profile.notes'],
        'profile.member.joinedDate': row['profile.member.joinedDate'],
        'profile.member.position': row['profile.member.position'],
        'profile.member.description': row['profile.member.description'],
        'contacts.address.address': row['contacts.address.address'],
        'contacts.address.city': row['contacts.address.city'],
        'contacts.address.state': row['contacts.address.state'],
        'contacts.address.zip': row['contacts.address.zip'],
        'contacts.address.country':
          row['contacts.address.address'] ||
          row['contacts.address.city'] ||
          row['contacts.address.state'] ||
          row['contacts.address.zip']
            ? 'US'
            : null,
      };
      /* eslint-enable dot-notation */
      if (user['contacts.address.city']) {
        user['contacts.address.city'] = user['contacts.address.city'].toCapitalize(true);
      }
      if (user['contacts.address.state']) {
        user['contacts.address.state'] = user['contacts.address.state'].toUpperCase();
      }
      if (user['profile.member.joinedDate']) {
        user['profile.member.joinedDate'] = moment(
          user['profile.member.joinedDate'],
          'MM/DD/YYYY',
        ).valueOf();
      }
      if (row.church_member && row.church_member.toUpperCase() === 'N') {
        user['profile.member.type'] = 'non-member';
      } else if (row.church_member && row.church_member.toUpperCase() === 'Y') {
        if (row.honored_member && row.honored_member.toUpperCase() === 'Y') {
          user['profile.member.type'] = 'honored';
        } else {
          user['profile.member.type'] = 'member';
        }
      } else {
        user['profile.member.type'] = 'unknown';
      }

      const ret = utils.getObject(user);
      return { user: ret, index: row.index };
    });

    if (!userArray || !userArray.length) {
      this.notiText = 'No data has been entered to upload.';
      this.setState({ isOpenEmptyNotiDlg: true });
    } else {
      this.userArray = userArray;
      this.setState({ isOpenSubmitConfrimDlg: true });
    }
  };

  handleSubmit = async () => {
    const { dispatch, churchID, history } = this.props;

    this.setState({ isLoading: true, isOpenSubmitConfrimDlg: false });
    try {
      const { errors } = await dispatch(createUserBatchRequest(churchID, this.userArray));
      if (errors.length) {
        this.setState(() => ({ errors, isLoading: false }));
      } else {
        await sendNotfication('church_honored_member', { churchId: churchID, users: this.userArray });
        this.setState(
          () => ({ errors: [], isLoading: false, Usersimported: [], Usersinputted: [] }),
          () => {
            history.goBack();
          },
        );
      }
    } catch (error) {
      this.setState(() => ({
        errors: this.userArray.map(() => ({ message: 'Request is not permitted' })),
        isLoading: false,
      }));
    }
  };

  handleSave = () => {
    const { Usersimported, Usersinputted } = this.state;

    if (!Usersimported.length && !Usersinputted.length) {
      this.notiText = 'No data has been entered to save.';
      this.setState({ isOpenEmptyNotiDlg: true });
      return;
    }

    const rows = [...Usersinputted, ...Usersimported];
    const csv = jsonToCSV(
      rows.map(row => {
        const obj = {};
        Object.keys(row).forEach(key => {
          const columnNames = Object.keys(CSV_HEADERS);
          const columnName = columnNames.find(name => CSV_HEADERS[name].key === key);
          if (!columnName) return;
          obj[columnName] = row[key];
        });

        return obj;
      }),
    );
    const csvURL = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv;charset=utf-8;' }));
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = 'users.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleMouseEnterRow = (e, index) => {
    const { currentStep } = this.state;
    if (currentStep === 'submit') {
      this.selectedRow = index;
      const rect = e.target.getBoundingClientRect();
      this.clearRowButtonRef.current.classList.add('visible');
      this.clearRowButtonRef.current.style.top = `${rect.y}px`;
    }
  };

  handleMouseLeaveRow = () => {
    this.clearRowButtonRef.current.classList.remove('visible');
  };

  handleChangeTemplateInput(index, event) {
    const { Usersimported, Usersinputted, currentStep } = this.state;

    const promise = new Promise(resolve => {
      const rowIndex1 = Usersimported.findIndex(row => row.index === index);
      const rowIndex2 = Usersinputted.findIndex(row => row.index === index);
      const isImportedRow = rowIndex1 >= 0;
      const rowIndex = isImportedRow ? rowIndex1 : rowIndex2;
      let rows = [];
      if (index && isImportedRow) {
        rows = [...Usersimported];
      } else {
        rows = [...Usersinputted];
      }

      if (index && rowIndex >= 0) {
        rows.splice(rowIndex, 1, { ...rows[rowIndex], [event.target.name]: event.target.value });
        const names = Object.keys(rows[rowIndex]);
        const nonEmptyCount = names.filter(name => rows[rowIndex][name]).length;
        if (!nonEmptyCount) {
          rows.splice(rowIndex, 1);
        }
        if (isImportedRow) {
          this.setState({ Usersimported: rows }, () => {
            resolve();
          });
        } else {
          this.setState({ Usersinputted: rows }, () => {
            resolve();
          });
        }
      } else {
        rows.push({ [event.target.name]: event.target.value, index: utils.makeStringID(24) });
        this.setState({ Usersinputted: rows }, () => {
          resolve();
        });
      }
    });

    promise.then(() => {
      if (currentStep === 'submit') {
        this.handleValidate();
      }
    });
  }

  goBack = () => {
    const { history, location } = this.props;
    const { currentStep } = this.state;
    if (currentStep === 'submit') {
      this.tableRef.current.scrollToTop();
      this.tableRef.current.scrollToLeft();
      this.setState({ currentStep: 'edit' });
      return;
    }
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard/users', { from: location.state ? location.state.from : undefined });
    }
  };

  onUnload = e => {
    // the method that will be used for both add and remove event
    const { Usersimported, Usersinputted } = this.state;
    if (Usersimported.length || Usersinputted.length) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  render() {
    const {
      Usersimported,
      Usersinputted,
      currentStep,
      errors,
      isLoading,
      isShowOnlyErrors,
      isOpenSubmitConfrimDlg,
      isOpenLeaveConfirmDlg,
      isOpenClearConfrimDlg,
      isOpenEmptyNotiDlg,
    } = this.state;
    const { users } = this.props;

    let rows = [];
    if (currentStep === 'submit') {
      rows = [...Usersinputted, ...Usersimported];
    } else {
      rows = [...Usersinputted, {}];
    }

    const renderTableRow = row => {
      const error = errors.find(e => e.index === row.index);
      return {
        props: {
          onMouseEnter: e => this.handleMouseEnterRow(e, row.index),
          onMouseLeave: e => this.handleMouseLeaveRow(e, row.index),
        },
        childs: this.headerSettings[currentStep].headers.map(hs => {
          if (hs.key === 'status') {
            return (
              <div>
                {error ? (
                  <CancelIcon style={{ color: 'red' }} />
                ) : (
                  <CheckCircleIcon style={{ color: 'green' }} />
                )}
              </div>
            );
          }
          let errorMsg = '';
          if (error && error.validator && error.validator[hs.key]) {
            errorMsg = error.validator[hs.key];
          }

          return (
            <div className={errorMsg ? 'has-error' : ''} key={hs.key}>
              <Tooltip padding={2} color="#000" title={errorMsg}>
                {hs.key.endsWith('Phone') ? (
                  <PhoneInput
                    name={hs.key}
                    onChange={e => this.handleChangeTemplateInput(row.index, e)}
                    value={row[hs.key] || ''}
                  />
                ) : (
                  <input
                    name={hs.key}
                    onChange={e => this.handleChangeTemplateInput(row.index, e)}
                    value={row[hs.key] || ''}
                  />
                )}
              </Tooltip>
            </div>
          );
        }),
      };
    };

    return (
      <>
        <Helmet title="User management - Upload Users" />
        <Prompt when={!!(Usersimported.length || Usersinputted.length)} message={this.handleLeavePage} />
        <div className="user-upload card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={UserManagementImage} alt="Import User" />
                <p>Import Users</p>
              </div>
              {currentStep === 'submit' && (
                <div className="title-buttons">
                  <button type="button" className="button gradient medium" onClick={this.handleSave}>
                    Save to Computer
                  </button>
                </div>
              )}
            </div>
            <div className="card-block">
              {isLoading || users.isLoading ? (
                <div
                  style={{
                    position: 'relative',
                    minHeight: '60px',
                    minWidth: '60px',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  {currentStep === 'submit' ? (
                    <div className="submit-wrapper">
                      <div className="row">
                        <div className="col-md-5">
                          <h2>Import {rows.length} Rows</h2>
                          <div className="switch-check-box">
                            <SwitchCheckBox
                              size="small"
                              labelNo="OFF"
                              labelYes="ON"
                              checked={!!isShowOnlyErrors}
                              onChange={e => this.setState({ isShowOnlyErrors: e.target.checked })}
                            />
                            <span style={{ fontSize: 18, marginLeft: 18 }}>Only show rows with errors</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="errors-wrapper">
                            <span style={{ color: '#878787' }}>Errors Remaining</span>
                            <div className="error-cnt">
                              {errors && errors.length
                                ? errors.reduce(
                                    (accumulator, currentValue) => ({
                                      errorCnt: accumulator.errorCnt + currentValue.errorCnt,
                                    }),
                                    { errorCnt: 0 },
                                  ).errorCnt
                                : 0}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                          <button
                            type="button"
                            disabled={!!(errors && errors.length)}
                            className="button neutral border--red medium"
                            onClick={this.handleSumitButtonClick}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="upload-wrapper">
                        <div className="row">
                          <div className="col-md-4">
                            <CSVReader
                              ref={this.buttonRef}
                              config={CSV_OPTIONS}
                              onFileLoad={this.handleCSVFileReadSuccess}
                              onError={this.handleCSVFileReadError}
                              noDrag
                              noProgressBar
                            >
                              {({ progressBar }) => (
                                <div className="upload-button-container">
                                  <button
                                    type="button"
                                    className="button red medium"
                                    onClick={this.handleOpenDialog}
                                  >
                                    Upload Data From File
                                  </button>
                                  <ProgressRing radius={18} stroke={4} progress={progressBar} />
                                </div>
                              )}
                            </CSVReader>
                          </div>
                          <div className="col-md-8">
                            <p className="description-text">
                              You can upload a .csv file by utilizing the template available via the
                              download link below. The template contains instructions for each available
                              field on the user profile. Enter one user per row on the template and once you
                              are complete, save the file in the .csv format. Click the ‘Upload Data from
                              File’ button to upload your user data. Any validation errors will be
                              highlighted in the next page.
                            </p>
                            <a className="link" href={`${ASSETS_CDN}/template.xlsx`} download>
                              Download template
                            </a>
                          </div>
                        </div>
                      </div>
                      <h5 style={{ margin: 24 }}>...or Just manually enter your data here:</h5>
                    </>
                  )}
                  <div className="template-table-wrapper">
                    <RoundedTable
                      ref={this.tableRef}
                      minHeight={160}
                      maxHeight={540}
                      minWidth={`${this.headerSettings[currentStep].total}em`}
                      data={rows
                        .filter(
                          row =>
                            !isShowOnlyErrors ||
                            (isShowOnlyErrors && errors.find(e => e.index === row.index)),
                        )
                        .map(row => renderTableRow(row))}
                      headings={this.headerSettings[currentStep].headers.map(hs => ({
                        component: <div key={hs.key}>{hs.name}</div>,
                        percentWidth: hs.percentWidth,
                      }))}
                    />
                    <button
                      ref={this.clearRowButtonRef}
                      type="button"
                      className="button red icon-button clear-row"
                      onClick={this.handleClearRowClick}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                  {currentStep === 'edit' && (
                    <div className="action-buttons">
                      <button
                        type="button"
                        className="button neutral bg-white border--gray medium mr-auto"
                        onClick={this.handleClearAllClick}
                      >
                        Clear Data
                      </button>
                      <button
                        type="button"
                        className="button gradient medium  ml-auto"
                        onClick={this.handleValidate}
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <StyledModal
          open={isOpenLeaveConfirmDlg}
          onClose={() => this.setState({ isOpenLeaveConfirmDlg: false })}
          onSubmit={this.handleGo}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          <h4 style={{ marginBottom: '1rem' }}>Leave this page?</h4>
          <p style={{ margin: 0 }}>Changes you made not be saved.</p>
        </StyledModal>
        <StyledModal
          open={isOpenClearConfrimDlg}
          onClose={() => this.setState({ isOpenClearConfrimDlg: false })}
          onSubmit={this.handleClear}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          {this.selectedRow ? 'Clear selected user data?' : 'Clear all user data?'}
        </StyledModal>
        <StyledModal
          open={isOpenEmptyNotiDlg}
          onClose={() => this.setState({ isOpenEmptyNotiDlg: false })}
          closeBtnLabel="OK"
        >
          {this.notiText}
        </StyledModal>
        <StyledModal
          open={isOpenSubmitConfrimDlg}
          onClose={() => this.setState({ isOpenSubmitConfrimDlg: false })}
          onSubmit={this.handleSubmit}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          Do you want to upload all these users?
        </StyledModal>
      </>
    );
  }
}

UserUpload.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  churchID: PropTypes.string.isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

// Retrieve data from store as props
const mapStateToProps = ({ church, users }) => ({
  churchID: church.id,
  users,
});

export default withRouter(connect(mapStateToProps)(UserUpload));
