import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { CheckBoxField } from 'components/MDFormControls';
import { SubscriberSerchBox } from 'components';
import { ROLES } from 'config/roles';

const CheckBox = styled(CheckBoxField)({
  margin: '0px',
  display: 'block',
  '& > .MuiFormControlLabel-root': {
    margin: '0px',
  },
});

class AdminPanelDetailEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      adminData: {
        isSuperUser: false,
        permissions: {},
      },
    };
  }

  componentDidMount() {
    const { admins, users, selectedKey } = this.props;
    const adminData = admins[selectedKey] || {
      isSuperUser: false,
      permissions: [],
    };
    const permissions = {};
    if (adminData.isSuperUser) {
      Object.keys(ROLES).forEach(pmk => {
        if ((ROLES[pmk].type === 'page' || ROLES[pmk].type === 'settings') && ROLES[pmk].label)
          permissions[ROLES[pmk].value] = true;
      });
    } else {
      adminData.permissions.forEach(item => {
        permissions[item] = true;
      });
    }
    const userData = users.data.find(item => item.id === selectedKey) || null;
    this.setState({
      adminData: { isSuperUser: adminData.isSuperUser, permissions: permissions },
      userData,
    });
  }

  handleSave = () => {
    const { adminData, userData } = this.state;
    const { onSave } = this.props;
    const permissions = [];
    Object.keys(adminData.permissions).forEach(p => {
      if (adminData.permissions[p]) {
        permissions.push(p);
      }
    });
    onSave(userData.id, {
      isSuperUser: adminData.isSuperUser,
      permissions,
    });
  };

  handleChangeValue = (name, value) => {
    const { adminData } = this.state;
    if (name === 'super_user') {
      const permissions = {};
      Object.keys(ROLES).forEach(pmk => {
        if ((ROLES[pmk].type === 'page' || ROLES[pmk].type === 'settings') && ROLES[pmk].label)
          permissions[ROLES[pmk].value] = value;
      });
      this.setState({
        adminData: {
          ...adminData,
          isSuperUser: value,
          permissions: {
            ...adminData.permissions,
            ...permissions,
          },
        },
      });
    } else {
      let isSuperUser = true;
      Object.keys(ROLES).forEach(pmk => {
        if (
          (ROLES[pmk].type === 'page' || ROLES[pmk].type === 'settings') &&
          ROLES[pmk].label &&
          ROLES[pmk].value !== name &&
          !adminData.permissions[ROLES[pmk].value]
        )
          isSuperUser = false;
      });
      this.setState({
        adminData: {
          ...adminData,
          isSuperUser: isSuperUser && value,
          permissions: {
            ...adminData.permissions,
            [name]: value,
          },
        },
      });
    }
  };

  handleSelectUser = suggestion => {
    this.setState({ userData: suggestion });
  };

  render() {
    const {
      adminData: { isSuperUser, permissions },
      userData,
    } = this.state;
    const { status, editable, onCancel, users, admins } = this.props;

    if (status === 'edit' && !userData) {
      return <div />;
    }

    return (
      <div className="admin-panel-detail">
        {status === 'add' && !userData && (
          <SubscriberSerchBox
            onSelect={this.handleSelectUser}
            data={users.data.filter(user => user.email)}
            exceptionList={Object.keys(admins)}
            desc="A person must signup as a user before they can be added as an admin."
          />
        )}
        {!!userData && (
          <div className="row">
            <div className="col-md-12 col-lg-5">
              <div className="apa-selected-user">
                <p className="apas_name">
                  <span>
                    {userData.profile.firstName} {userData.profile.lastName}
                  </span>
                </p>
                <p className="apas_email">{userData.email}</p>
                {/* <div className="apas_inputs row" style={{ display: 'none' }}>
                  <span>Phone Number:</span>
                  <input
                    type="text"
                    className="form-control col-md-4 apas-areacode"
                    placeholder="(Area Code)"
                  />
                  <input
                    type="text"
                    className="form-control col-md-8 apas-pnumber"
                    placeholder="Phone number"
                  />
                </div> */}
              </div>
            </div>
            <div className="col-md-12 col-lg-7 apa-permission">
              <p>Admin Permissions</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="m-3">
                    <div className="section-title">Page Functions</div>
                    {Object.keys(ROLES)
                      .filter(pmk => ROLES[pmk].type === 'page' && ROLES[pmk].label)
                      .map(pmk => (
                        <CheckBox
                          key={pmk}
                          disabled={!editable}
                          input={{
                            value: permissions[ROLES[pmk].value],
                            onChange: e => this.handleChangeValue(ROLES[pmk].value, e.target.checked),
                          }}
                          label={ROLES[pmk].label}
                        />
                      ))}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="m-3">
                    <div className="section-title">Settings Functions</div>
                    {Object.keys(ROLES)
                      .filter(pmk => ROLES[pmk].type === 'settings' && ROLES[pmk].label)
                      .map(pmk => (
                        <CheckBox
                          key={pmk}
                          disabled={!editable}
                          input={{
                            value: permissions[ROLES[pmk].value],
                            onChange: e => this.handleChangeValue(ROLES[pmk].value, e.target.checked),
                          }}
                          label={ROLES[pmk].label}
                        />
                      ))}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="m-3 text-center">
                    <CheckBox
                      disabled={!editable}
                      input={{
                        value: isSuperUser,
                        onChange: e => this.handleChangeValue('super_user', e.target.checked),
                      }}
                      label="Select all (Super Admin)"
                      style={{ display: 'inline-block', margin: 'auto' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="action-group admin-edit-action">
          {!!userData && (
            <button type="button" className="btn-save" onClick={this.handleSave}>
              Save
            </button>
          )}
          <button type="button" className="btn-cancel" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

AdminPanelDetailEdit.propTypes = {
  status: PropTypes.oneOf(['add', 'edit']).isRequired,
  editable: PropTypes.bool.isRequired,
  selectedKey: PropTypes.string.isRequired,
  admins: PropTypes.objectOf(
    PropTypes.shape({
      email: PropTypes.string,
      isSuperUser: PropTypes.bool.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string,
        profile: PropTypes.object.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AdminPanelDetailEdit;
