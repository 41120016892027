import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { ROLES } from 'config/roles';
import { utils } from 'service';
import { DropDownMenu, Loading } from 'components';
import { fetchEventsRequest } from 'redux/actions/EventActions';
import { fetchGroupsRequest } from 'redux/actions/GroupActions';
import eventBlankImg from 'assets/images/EventIcon.png';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import EventsList from './EventsList';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.css';

const localizer = momentLocalizer(moment);

const CustomToolbar = toolbar => {
  const goToBack = () => {
    const mDate = toolbar.date;
    const newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
    toolbar.onNavigate('prev', newDate);
  };
  const goToNext = () => {
    const mDate = toolbar.date;
    const newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
    toolbar.onNavigate('next', newDate);
  };

  const showMonth = () => {
    const date = moment(toolbar.date);
    return (
      <span className="rbc-month-label">
        {date.format('MMMM')} {date.format('YYYY')}
      </span>
    );
  };

  return (
    <div className="toolbar-container">
      <div className="rbc-back-next">
        <span role="button" className="rbc-back-next-button" onClick={goToBack} tabIndex={0}>
          <ChevronLeftIcon />
        </span>
        {showMonth()}
        <span role="button" className="rbc-back-next-button" onClick={goToNext} tabIndex={0}>
          <ChevronRightIcon />
        </span>
      </div>
    </div>
  );
};

class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: 'none',
    };
  }

  componentDidMount() {
    const { dispatch, churchID } = this.props;
    dispatch(fetchEventsRequest(churchID));
    dispatch(fetchGroupsRequest(churchID));
  }

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }
  };

  handleGroupSelect = id => {
    this.setState({ selectedGroup: id });
  };

  handleCreateNew = () => {
    const { history, location } = this.props;
    history.push('/dashboard/event/new', { edit: true, from: location.pathname });
  };

  handleViewDetails = eventItem => {
    const { history, location } = this.props;
    history.push(`/dashboard/event/${eventItem.id}`, { from: location.pathname });
  };

  render() {
    const { selectedGroup } = this.state;
    const { authUserPermissions, isAdmin, authUserID, events, groups } = this.props;

    const isEventManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.EVENT_EDITOR.value);

    let menuArray = [
      {
        label: 'All Events',
        value: 'none',
      },
      {
        label: 'Church Events',
        value: 'churchwide',
        color: '#fe9168',
      },
    ];
    if (!groups.isLoading) {
      if (isAdmin) {
        menuArray = [
          ...menuArray,
          {
            label: 'All Groups',
            value: 'all_groups',
            color: '#31c5c3',
          },
        ];
      }
      menuArray = [
        ...menuArray,

        ...groups.data
          .map(group => ({
            label: group.title,
            value: group.id,
            color: '#31c5c3',
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ];
    }

    return (
      <>
        <Helmet title="Events" />
        <div className="events card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={eventBlankImg} alt="Events" />
                <p>Events</p>
              </div>
              {(isEventManager || !isAdmin) && (
                <div className="title-buttons">
                  <button type="button" className="button gradient medium" onClick={this.handleCreateNew}>
                    Add Event
                  </button>
                </div>
              )}
            </div>
            <div className="card-block">
              {events.isLoading || groups.isLoading ? (
                <div style={{ position: 'relative', height: 150 }}>
                  <Loading />
                </div>
              ) : (
                <>
                  <DropDownMenu
                    className="event-page"
                    value={selectedGroup}
                    onChange={e => this.handleGroupSelect(e.target.value)}
                    menuArray={menuArray}
                    style={{ position: 'absolute', right: '20px', top: '8px' }}
                    menuPosition="right"
                  />
                  <div className="rbc-container">
                    <Calendar
                      popup
                      selectable={false}
                      views={['month']}
                      localizer={localizer}
                      dayLayoutAlgorithm="no-overlap"
                      onSelectEvent={this.handleViewDetails}
                      events={events.data
                        .filter(event => {
                          if (isAdmin || event.groupID === 'churchwide') return true;
                          const group = groups.data.find(g => g.id === event.groupID);
                          if (!group) return false;
                          if (
                            group.members.some(
                              m => m.type === 'leader' && m.status === 1 && authUserID === m.userID,
                            )
                          )
                            return true;
                          return false;
                        })
                        .filter(
                          e =>
                            selectedGroup === 'none' ||
                            (selectedGroup === 'all_groups' && e.groupID !== 'churchwide') ||
                            e.groupID === selectedGroup,
                        )
                        .map(({ id, start, end, groupID, title }) => {
                          let color = 'gray';
                          if (utils.daysLeft(start) >= 0 || (end && utils.daysLeft(end) >= 0)) {
                            if (groupID === 'churchwide') color = '#fe9168';
                            else color = '#31c5c3';
                          } else if (groupID === 'churchwide') color = '#515151';
                          else color = '#a8a8a8';

                          return {
                            id,
                            title,
                            start,
                            end: end || start,
                            color,
                          };
                        })}
                      eventPropGetter={event => ({
                        style: {
                          backgroundColor: event.color,
                          opacity: 0.8,
                        },
                      })}
                      startAccessor="start"
                      endAccessor="end"
                      components={{
                        toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                  <EventsList
                    events={events.data
                      .filter(event => {
                        if (isAdmin || event.groupID === 'churchwide') return true;
                        const group = groups.data.find(g => g.id === event.groupID);
                        if (!group) return false;
                        if (
                          group.members.some(
                            m => m.type === 'leader' && m.status === 1 && authUserID === m.userID,
                          )
                        )
                          return true;
                        return false;
                      })
                      .filter(
                        event =>
                          (utils.daysLeft(event.start) >= 0 ||
                            (event.end && utils.daysLeft(event.end) >= 0)) &&
                          (selectedGroup === 'none' ||
                            (selectedGroup === 'all_groups' && event.groupID !== 'churchwide') ||
                            event.groupID === selectedGroup),
                      )}
                    groups={groups.data}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Events.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  churchID: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  authUserID: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  events: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        start: PropTypes.number.isRequired,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
};

// Retrieve data from store as props
const mapStateToProps = ({ events, groups, church, auth: { user, as } }) => ({
  authUserPermissions: church.admins[user.id],
  isAdmin: as === 'admin',
  authUserID: user.id,
  churchID: church.id,
  events,
  groups,
});

export default withRouter(connect(mapStateToProps)(Events));
