import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { COUNTRIES, STATES } from 'config/supported_address';
import { useFormikContext } from 'formik';
import { InputComponent, SelectComponent } from './formikFields';

const StepTwo = ({ handleSubmit }) => {
  const { errors, touched, setFieldTouched, validateForm, values } = useFormikContext();
  const onSubmit = async () => {
    const fields = ['phone', 'line1', 'city', 'state', 'zip'];
    await validateForm(values);
    fields.forEach(field => setFieldTouched(field, errors[field]));
    if (fields.every(field => !errors[field] && touched[field])) handleSubmit();
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputComponent name="phone" label="Church Phone Number" />
        </Grid>
        <Grid item xs={12}>
          <InputComponent name="line1" label="Address" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent name="city" label="City" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectComponent
            name="state"
            label="State"
            menuArray={STATES.map(item => ({
              label: item.name,
              value: item.abbreviation,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputComponent name="zip" label="Zipcode" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectComponent
            name="country"
            label="Country"
            disabled
            menuArray={COUNTRIES.map(item => ({
              label: item.country,
              value: item.code,
            }))}
          />
        </Grid>
      </Grid>
      <button type="button" onClick={onSubmit} className="button medium gradient button--signup-step">
        Next
      </button>
    </>
  );
};

StepTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default StepTwo;
