import {
  RECEIVE_GIFT_HISTORY,
  REQUEST_GIFTS,
  REQUEST_GIFT_HISTORY,
  RECEIVE_GIFTS,
  CREATE_GIFT,
  UPDATE_GIFT,
  REMOVE_GIFT,
  DATE_RANGE_CHANGED,
} from 'redux/actions/GiftActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';
import { INITIAL_DATE_RANGE } from 'config/giving-history';

const initialState = {
  data: [],
  history: [],
  from: new Date().getTime(),
  to: new Date().getTime(),
  dateRange: INITIAL_DATE_RANGE.dateRange,
  isLoading: 0,
};

const GiftReducer = (state = initialState, action) => {
  const { data } = state;
  switch (action.type) {
    case REQUEST_GIFTS:
    case REQUEST_GIFT_HISTORY:
      return { ...state, isLoading: state.isLoading + 1 };

    case RECEIVE_GIFTS:
      return {
        ...state,
        data: state.isLoading > 0 ? action.payload.data : [],
        isLoading: state.isLoading > 0 ? state.isLoading - 1 : 0,
      };

    case RECEIVE_GIFT_HISTORY:
      if (state.isLoading > 0) {
        return {
          ...state,
          history: action.payload.history,
          from: action.payload.from,
          to: action.payload.to,
          isLoading: state.isLoading - 1,
        };
      }
      return {
        ...state,
        history: [],
        from: new Date().getTime(),
        to: new Date().getTime(),
        isLoading: 0,
      };

    case CREATE_GIFT: {
      const addedData = [action.payload.gift, ...data];
      return { ...state, data: addedData };
    }

    case UPDATE_GIFT: {
      const updatedData = data.map(v =>
        v.id === action.payload.id ? { ...v, status: action.payload.status === true ? 1 : 0 } : v,
      );
      return { ...state, data: updatedData };
    }

    case REMOVE_GIFT: {
      const removedData = data.filter(v => v.id !== action.payload.id);
      return { ...state, data: removedData };
    }

    case USER_LOGOUT_SUCCESS:
      return initialState;

    case DATE_RANGE_CHANGED:
      return { ...state, dateRange: action.payload.dateRange };
    default:
      return state;
  }
};

export default GiftReducer;
