const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title is required';
  }

  if (values.price !== null && values.price !== undefined && values.price !== '') {
    if (parseFloat(values.price) < 0.5) {
      errors.price = 'Price cannot be $0. For free events, please leave field blank.';
    }
  }

  if (!values.start) {
    errors.start = 'Start is required';
  }

  if (values.end) {
    if (values.start >= values.end) {
      errors.end = 'End date must be after the Start date';
    }
  }

  if (!values.groupID) {
    errors.groupID = 'Please select a group';
  }
  return errors;
};

export default validate;
