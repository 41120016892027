import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, UserPopup } from 'components';

class EventUserListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenPrayerModal: false,
      isShowPosition: false,
    };
  }

  handleStarClick = event => {
    event.stopPropagation();

    this.setState({
      isShowPosition: true,
      isOpenPrayerModal: true,
    });
  };

  handlePositionBack = () => {
    this.setState({
      isShowPosition: false,
    });
  };

  onOpenPrayerModal = () => {
    this.setState({ isOpenPrayerModal: true, isShowPosition: false });
  };

  onClosePrayerModal = () => {
    this.setState({ isOpenPrayerModal: false });
  };

  render() {
    const { user } = this.props;
    const { isOpenPrayerModal, isShowPosition } = this.state;

    return (
      <>
        <div role="button" className="event-wrap-user" onClick={this.onOpenPrayerModal} tabIndex={0}>
          <Avatar
            size="medium"
            src={user.avatar}
            style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto' }}
            badge={user.profile.member.type === 'honored'}
            onBadgeClick={this.handleStarClick}
          />
          <h6 className="name">{`${user.profile.firstName} ${user.profile.lastName}`}</h6>
        </div>
        <UserPopup
          open={isOpenPrayerModal}
          onClose={this.onClosePrayerModal}
          handleStarClick={this.handleStarClick}
          isShowPosition={isShowPosition}
          user={user}
          handlePositionBack={this.handlePositionBack}
        />
      </>
    );
  }
}

EventUserListItem.propTypes = {
  user: PropTypes.shape({
    profile: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      member: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }).isRequired,
    }),
    avatar: PropTypes.string,
  }).isRequired,
};

export default EventUserListItem;
