import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { Chip, Loading, RoundedTable, SubscriberSerchBox, UserAvatar } from 'components';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: [],
      newMembersList: [],
    };
  }

  componentDidMount() {
    const { users } = this.props;
    this.setState({ searchResult: users });
  }

  handleGetSearchResult = result => {
    this.setState({ searchResult: result });
  };

  handleCheckBoxClick = (event, userID) => {
    const { newMembersList } = this.state;
    if (event.target.checked === true) {
      this.setState({ newMembersList: [...newMembersList, userID] });
    } else {
      this.setState({ newMembersList: newMembersList.filter(item => item !== userID) });
    }
  };

  render() {
    const { searchResult, newMembersList } = this.state;
    const { users, members, groups, isLoading, onAdd, onCancel } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          <div className="title">
            <p>Add Group Member</p>
          </div>
        </div>
        <div className="card-block">
          {isLoading ? (
            <div
              style={{
                position: 'relative',
                minHeight: '60px',
                minWidth: '60px',
              }}
            >
              <Loading />
            </div>
          ) : (
            <>
              <div className="row mb-3">
                <div className="col-sm-12 col-md-5 col-lg-4">
                  <SubscriberSerchBox
                    onSearchClick={this.handleGetSearchResult}
                    onSelect={user => this.handleGetSearchResult([user])}
                    onChange={this.handleGetSearchResult}
                    data={users}
                    className="mb-4"
                  />
                </div>
              </div>
              <RoundedTable
                showPagenate
                minWidth={1040}
                data={searchResult.map(item => [
                  <div>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon style={{ color: '#fe9168' }} />}
                      checkedIcon={<RadioButtonCheckedIcon style={{ color: '#fe9168' }} />}
                      checked={
                        members.some(member => member.member.status && member.id === item.id) ||
                        newMembersList.some(memberID => memberID === item.id)
                      }
                      onChange={e => this.handleCheckBoxClick(e, item.id)}
                      color="primary"
                    />
                  </div>,
                  <UserAvatar user={item} className="align-left" />,
                  <div className="align-left">{item.email}</div>,
                  <Chip
                    data={item.groups
                      .map(ug => groups.find(g => g.id === ug))
                      .filter(g => !!g)
                      .map(g => g.title)}
                  />,
                  <div className="table-member">
                    {item.profile.member.type === 'honored' ? 'Yes' : 'Unknown'}
                  </div>,
                ])}
                headings={[
                  { component: <div />, percentWidth: 5 },
                  { component: <div className="align-left">Name</div>, percentWidth: 30 },
                  { component: <div className="align-left">Email</div>, percentWidth: 27 },
                  {
                    component: <div>Groups</div>,
                    percentWidth: 28,
                  },
                  { component: <div>Member</div>, percentWidth: 10 },
                ]}
              />
            </>
          )}
          <div className="action-group admin-edit-action">
            {!isLoading && (
              <button type="button" className="btn-save" onClick={() => onAdd(newMembersList)}>
                Add User(s)
              </button>
            )}
            <button type="button" className="btn-cancel" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AddMember.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string,
      profile: PropTypes.object.isRequired,
    }),
  ).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string,
      profile: PropTypes.object.isRequired,
      member: PropTypes.object.isRequired,
    }),
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddMember;
