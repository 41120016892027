import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Amplify, { Hub } from 'aws-amplify';
// import { v4 as uuidv4 } from 'uuid';
import { COGNITO_CONFIG } from 'config/amplify';
import Container from '@material-ui/core/Container';
import { logger } from 'service';
import { Loading } from 'components';
import {
  currentAuthenticatedUser,
  logout,
  registerChurchRequest,
  registerUserRequest,
} from 'redux/actions/AuthActions';
import { initializeAdminData, initializeLeaderData } from 'redux/actions/GeneralActions';
import { churchExpNotification, getNotification } from 'redux/actions/NotificationActions';

// import { NOTIFICATION_DUMMY_DATA } from './dummyData';
import { privateRoutes, publicRoutes } from './routes';
import TrademarkFooter from './components/TrademarkFooter';
// import { NOTIFICATION_DUMMY_DATA } from './dummyData';

if (process.env.NODE_ENV === 'production') {
  Amplify.Logger.LOG_LEVEL = 'ERROR';
} else {
  Amplify.Logger.LOG_LEVEL = 'INFO';
}

Amplify.configure({
  Auth: COGNITO_CONFIG,
});

class App extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      authUserPermissions: {
        permissions: [],
        isSuperUser: false,
      },
    };
    // this.subscriptions = null;
    // this.topics = ['prayer_request_created', 'new_subscriber_created', 'faithmo_membership_expire'];
  }

  componentDidMount() {
    const { location } = this.props;

    Hub.listen('faithmo_auth', this.faithmoAuthListener);

    const isPrivate = privateRoutes.some(routeProps => {
      if (routeProps.exact) return location.pathname === routeProps.path;
      return location.pathname.startsWith(routeProps.path);
    });

    if (isPrivate) {
      Hub.dispatch('faithmo_auth', { event: 'signin' });
    } else {
      this.setState({ isLoaded: true });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    Hub.remove('faithmo_auth', this.faithmoAuthListener);
  }

  _signout = async () => {
    clearInterval(this.interval);
    const { dispatch, history, location } = this.props;
    this.setState(() => ({ isLoaded: false }));
    try {
      await dispatch(logout());
    } catch (error) {
      logger.warn(error);
    }
    history.push('/login', {
      from: location.pathname,
    });
    this.setState(() => ({ isLoaded: true }));
  };

  _signin = async ({ from, to }) => {
    const { history, dispatch } = this.props;
    try {
      this.setState(() => ({ isLoaded: false }));
      const churchData = await dispatch(currentAuthenticatedUser());
      if (churchData.as === 'admin') {
        this.setState({
          authUserPermissions: churchData.church.admins[churchData.user.id],
        });
        await dispatch(initializeAdminData(churchData.church, churchData.church.customerID));
      } else {
        this.setState({
          authUserPermissions: {
            permissions: [],
            isSuperUser: false,
            isLeader: true,
          },
        });
        await dispatch(initializeLeaderData(churchData.church, churchData.user.id));
      }
      await dispatch(churchExpNotification(churchData.church));

      await dispatch(getNotification(churchData.user.id));
      this.interval = setInterval(async () => {
        await dispatch(getNotification(churchData.user.id));
      }, 20000);

      this.setState(() => ({ isLoaded: true }));
      if (to) history.replace(to, { from });
    } catch (error) {
      Hub.dispatch('faithmo_auth', {
        event: 'signout',
        data: { error },
      });
    }
  };

  _signup = async ({ email, dataString }) => {
    if (!email || !dataString) {
      Hub.dispatch('faithmo_auth', {
        event: 'signout',
        data: { error: 'invalid upload. email or dataString is empty', email, dataString },
      });
      return;
    }
    try {
      this.setState(() => ({ isLoaded: false }));
      const signUpData = JSON.parse(dataString);
      if (
        signUpData &&
        signUpData.title &&
        signUpData.pastor &&
        signUpData.churchEmail &&
        signUpData.phone &&
        signUpData.line1 &&
        signUpData.city &&
        signUpData.state &&
        signUpData.zip &&
        signUpData.country &&
        signUpData.personalEmail &&
        signUpData.firstName &&
        signUpData.lastName
      ) {
        await registerChurchRequest(signUpData);
        localStorage.removeItem(`unverified_faithmo_signup_data[${email}]`);
      } else if (signUpData && signUpData.firstName && signUpData.lastName && signUpData.personalEmail) {
        await registerUserRequest(signUpData);
        localStorage.removeItem(`unverified_faithmo_signup_data[${email}]`);
      } else if (signUpData) {
        localStorage.removeItem(`unverified_faithmo_signup_data[${email}]`);
      }
      Hub.dispatch('faithmo_auth', {
        event: 'signin',
        data: { from: '/signup', to: '/dashboard' },
      });
    } catch (error) {
      if (error.response && error.response.data) {
        const errBody = error.response.data;
        if (errBody.code && errBody.code === 'invalid') {
          localStorage.removeItem(`unverified_faithmo_signup_data[${email}]`);
        }
      }
      Hub.dispatch('faithmo_auth', {
        event: 'signout',
        data: { error },
      });
    }
  };

  faithmoAuthListener = ({ channel, payload: { event, data } }) => {
    const payloadData = data || {};

    if (payloadData.error) logger.warn(channel, event, payloadData);
    else logger.info(channel, event, payloadData);

    switch (event) {
      case 'signin':
        this._signin(payloadData);
        break;
      case 'signup':
        this._signup(payloadData);
        break;
      case 'signout':
        this._signout(payloadData);
        break;
      default:
        break;
    }
  };

  render() {
    const { isLoaded, authUserPermissions } = this.state;
    const reload = () => window.location.reload();

    return (
      <Container disableGutters maxWidth={false}>
        {!isLoaded ? (
          <div className="app-loading-container">
            <Loading size={48} thickness={2} />
          </div>
        ) : (
          <>
            <Helmet
              title="Pray, give and stay up to date!"
              titleTemplate="%s - FAITHMO"
              meta={[
                { charset: 'utf-8' },
                {
                  'http-equiv': 'X-UA-Compatible',
                  content: 'IE=edge',
                },
                {
                  name: 'viewport',
                  content: 'width=device-width, initial-scale=1',
                },
              ]}
            />
            <Switch>
              {publicRoutes.map(routeProps => (
                <Route key={`${routeProps.path}-${routeProps.exact ? 1 : 0}`} {...routeProps} />
              ))}

              <Route path="/.well-known/apple-developer-merchantid-domain-association" onEnter={reload} />
              {privateRoutes.map(({ component: PageComponent, ...rest }) => (
                <Route key={`${rest.path}-${rest.exact ? 1 : 0}`} {...rest}>
                  <PageComponent authUserPermissions={authUserPermissions} />
                  <TrademarkFooter />
                </Route>
              ))}
              <Redirect to="/" />
            </Switch>
          </>
        )}
      </Container>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default withRouter(connect(mapStateToProps)(App));
