import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ROLES } from 'config/roles';
import { BASE64_IMAGE_REGEXP } from 'config/regexrs';
import { Loading } from 'components';
import StyledModal from 'components/StyledModal';
import {
  updateEventRequest,
  createEventRequest,
  deleteEventRequest,
  updateEventAvatarRequest,
  fetchEventRequest,
  fetchEventAttendeesRequest,
  deleteEventAvatarRequest,
} from 'redux/actions/EventActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import eventsIcon from 'assets/images/EventIcon.png';
import EventDetailEdit from './EventDetailEdit';
import EventDetail from './EventDetail';

import 'pages/Events/style.css';
import './style.css';

class EventDetailIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      attendees: null,
      isLoadedAttendees: null,
      isEdit: false,
      isLoaded: false,
      isDeleteModalShow: false,
    };
  }

  componentDidMount() {
    this.initialLoad();
  }

  componentDidUpdate(prevProps) {
    const { eventID } = this.props;
    if (prevProps.eventID !== eventID) {
      this.initialLoad();
    }
  }

  isEditable = () => {
    const { authUserPermissions, authUserID, location, groups, eventID } = this.props;
    const { event } = this.state;

    const isEventManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.EVENT_EDITOR.value);

    if (isEventManager || (eventID === 'new' && location.state && location.state.edit)) return true;
    if (!event || event.groupID === 'churchwide') return false;
    const group = groups.data.find(g => g.id === event.groupID);
    if (!group) return false;
    if (group.members.some(m => m.type === 'leader' && m.status === 1 && authUserID === m.userID))
      return true;
    return false;
  };

  initialLoad = () => {
    const { eventID, history, location, isAdmin, dispatch } = this.props;

    if ((eventID === 'new' || (location.state && location.state.edit)) && !this.isEditable()) {
      history.replace('/dashboard/events', { from: location.state ? location.state.from : undefined });
      return;
    }

    if (eventID === 'new' && location.state && location.state.edit) {
      const event = {
        title: null,
        price: null,
        coordinator: null,
        address: null,
        venue: null,
        group: null,
        onlineTickets: false,
        start: null,
        end: null,
        desc: null,
        link: {},
        groupID: isAdmin ? location.state.groupID || 'churchwide' : location.state.groupID || null,
      };
      this.setState({
        event,
        isLoaded: true,
        isEdit: true,
        attendees: null,
        isLoadedAttendees: false,
      });
    } else {
      this.setState(
        {
          isLoaded: false,
          isEdit: !!location.state && location.state.edit,
          attendees: null,
          isLoadedAttendees: false,
        },
        () =>
          dispatch(fetchEventRequest(eventID))
            .then(event =>
              this.setState(
                {
                  event,
                  isLoaded: true,
                },
                () => {
                  fetchEventAttendeesRequest(eventID).then(attendees => {
                    this.setState({
                      attendees,
                      isLoadedAttendees: true,
                    });
                  });
                },
              ),
            )
            .catch(() => {
              history.replace('/dashboard/events', {
                from: location.state ? location.state.from : undefined,
              });
            }),
      );
    }
  };

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard/events', { from: location.state ? location.state.from : undefined });
    }
  };

  handleSaveEvent = (updatedEvent, imgStatus, avatarImg) => {
    const { dispatch, history, location, eventID, churchID } = this.props;
    const { event } = this.state;

    this.setState({ isLoaded: false }, async () => {
      if (eventID === 'new' && !event.id) {
        const { id } = await dispatch(createEventRequest(updatedEvent, churchID));
        if (imgStatus === 'changed' && BASE64_IMAGE_REGEXP.test(avatarImg)) {
          await dispatch(updateEventAvatarRequest(id, avatarImg));
        }
        history.replace(`/dashboard/event/${id}`, {
          from: location.state ? location.state.from : undefined,
        });
      } else if (event && event.id) {
        await dispatch(updateEventRequest(event.id, updatedEvent));
        if (imgStatus === 'removed') {
          await dispatch(deleteEventAvatarRequest(event.id));
        } else if (imgStatus === 'changed' && BASE64_IMAGE_REGEXP.test(avatarImg)) {
          await dispatch(updateEventAvatarRequest(event.id, avatarImg));
        }
        history.replace(`/dashboard/event/${event.id}`, {
          from: location.state ? location.state.from : undefined,
        });
        this.initialLoad();
      }
    });
  };

  handleCancelClick = () => {
    const { eventID, history, location } = this.props;
    this.setState({ isEdit: false }, () => {
      if (eventID === 'new')
        history.replace('/dashboard/events', { from: location.state ? location.state.from : undefined });
    });
  };

  handleEditClick = () => {
    this.setState({ isEdit: true });
  };

  handleShowDeleteModal = () => {
    this.setState({ isDeleteModalShow: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ isDeleteModalShow: false });
  };

  handleDeleteEvent = async () => {
    const { dispatch, history, location } = this.props;
    const { event } = this.state;
    await dispatch(deleteEventRequest(event.id));
    history.replace('/dashboard/events', { from: location.state ? location.state.from : undefined });
  };

  render() {
    const { event, isEdit, isLoadedAttendees, attendees, isLoaded, isDeleteModalShow } = this.state;
    const { eventID, isAdmin, groups } = this.props;

    const renderTitle = () => {
      if (!isEdit) return event ? event.title || '' : '';
      if (eventID === 'new') return 'Add Event';
      return 'Edit Event';
    };

    return (
      <>
        <Helmet title="Event details" />
        <div className="events card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header lg-column">
              <div className="title">
                <img src={eventsIcon} alt="Events" />
                <p>{renderTitle()}</p>
              </div>
              {this.isEditable() && !isEdit && (
                <div className="title-buttons">
                  <button type="button" className="button gradient medium" onClick={this.handleEditClick}>
                    Edit
                  </button>
                  <button
                    type="button"
                    className="button gradient medium"
                    onClick={this.handleShowDeleteModal}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            <div className="card-block">
              {!isLoaded || groups.isLoading ? (
                <div style={{ position: 'relative', height: 150 }}>
                  <Loading />
                </div>
              ) : (
                <>
                  {this.isEditable() && isEdit ? (
                    <EventDetailEdit
                      data={event}
                      isAdmin={isAdmin}
                      groups={groups.data}
                      onSave={this.handleSaveEvent}
                      onCancel={this.handleCancelClick}
                    />
                  ) : (
                    <EventDetail
                      data={event}
                      groups={groups.data}
                      attendees={attendees}
                      isLoadedAttendees={isLoadedAttendees}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <StyledModal
          open={isDeleteModalShow}
          onClose={this.handleCloseDeleteModal}
          onSubmit={this.handleDeleteEvent}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          Are you sure you want to delete this event?
        </StyledModal>
      </>
    );
  }
}

EventDetailIndex.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  eventID: PropTypes.string.isRequired,
  churchID: PropTypes.string.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  authUserID: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ church, groups, auth: { user, as } }, props) => ({
  authUserPermissions: church.admins[user.id],
  authUserID: user.id,
  isAdmin: as === 'admin',
  eventID: props.match.params.eventId,
  churchID: church.id,
  groups,
});

export default withRouter(connect(mapStateToProps)(EventDetailIndex));
