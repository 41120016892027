import React from 'react';
import PropTypes from 'prop-types';
import { BootstrapSelect, DatePicker, Loading, PlaceAutoComplete } from 'components';
import GStar from 'assets/images/groupstar.png';
import { COUNTRIES, STATES } from 'config/supported_address';
import PhoneInput from '../../components/PhoneInput';

const UserProfileEdit = ({ user, onSave, onChange, onChangeFullAddress, errors, isSubmitting }) => (
  <div className="u-profile-edit">
    <div className="row upe-prop">
      <div className="col-lg-6 upe-prop-left">
        <div className="form-group row">
          <label htmlFor="upe-firstname" className="col-md-5 form-label">
            Firstname:
          </label>
          <div className="col-md-7">
            <input
              className="form-control"
              type="text"
              value={user.profile.firstName || ''}
              onChange={e => onChange('firstName', e.target.value)}
              id="upe-firstname"
            />
            {errors.firstName && <span className="helper-text error">{errors.firstName}</span>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="upe-lastname" className="col-md-5 form-label">
            Lastname:
          </label>
          <div className="col-md-7">
            <input
              className="form-control"
              type="text"
              value={user.profile.lastName || ''}
              onChange={e => onChange('lastName', e.target.value)}
              id="upe-lastname"
            />
            {errors.lastName && <span className="helper-text error">{errors.lastName}</span>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="upe-id" className="col-md-5 form-label">
            ID#:
          </label>
          <div className="col-md-7">
            <input
              className="form-control"
              type="text"
              value={user.profile.ID || ''}
              onChange={e => onChange('ID', e.target.value)}
              id="upe-id"
            />
            {errors.ID && <span className="helper-text error">{errors.ID}</span>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="upe-occupation" className="col-md-5 form-label">
            Occupation:
          </label>
          <div className="col-md-7">
            <input
              className="form-control"
              type="text"
              value={user.profile.occupation || ''}
              onChange={e => onChange('occupation', e.target.value)}
              id="upe-occupation"
            />
            {errors.occupation && <span className="helper-text error">{errors.occupation}</span>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="upe-email" className="col-md-5 form-label">
            Email Address:
          </label>
          <div className="col-md-7">
            <input
              className="form-control"
              type="email"
              value={user.email || ''}
              onChange={e => onChange('email', e.target.value)}
              id="upe-email"
              readOnly={!user.unlinked}
            />
            {errors.email && <span className="helper-text error">{errors.email}</span>}
          </div>
        </div>
        <div className="form-group row upe-homePhone-Wrapper">
          <label htmlFor="upe-homePhone" className="col-md-5 form-label">
            Home Phone Number:
          </label>
          <div className="col-md-7">
            <PhoneInput
              className="form-control"
              type="text"
              value={user.contacts.homePhone || ''}
              onChange={e => onChange('homePhone', e.target.value)}
              id="upe-homePhone"
            />
            {errors.homePhone && <span className="helper-text error">{errors.homePhone}</span>}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="upe-cellPhone" className="col-md-5 form-label">
            Cell Phone Number:
          </label>
          <div className="col-md-7">
            <PhoneInput
              className="form-control"
              type="text"
              value={user.contacts.cellPhone || ''}
              onChange={e => onChange('cellPhone', e.target.value)}
              id="upe-cellPhone"
            />
            {errors.cellPhone && <span className="helper-text error">{errors.cellPhone}</span>}
          </div>
        </div>
      </div>
      <div className="col-lg-6 upe-prop-right">
        <label htmlFor="Address1">Address:</label>
        <div className="form-group">
          <PlaceAutoComplete
            value={user.contacts.address.address || ''}
            onChange={v => onChange('address', v)}
            onSelect={onChangeFullAddress}
            inputProps={{
              id: 'Address1',
              className: 'form-control address-control',
              placeholder: 'Street Address',
            }}
          />
          {errors.address && <span className="helper-text error">{errors.address}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control address-control"
            id="city"
            placeholder="City"
            value={user.contacts.address.city || ''}
            onChange={e => onChange('city', e.target.value)}
          />
          {errors.city && <span className="helper-text error">{errors.city}</span>}
        </div>
        <div className="form-group">
          <BootstrapSelect
            className="form-control address-control"
            items={(STATES || []).map(item => ({
              label: item.name,
              value: item.abbreviation,
            }))}
            value={user.contacts.address.state || ''}
            onChange={v => onChange('state', v)}
            placeHolder="State"
          />
          {errors.state && <span className="helper-text error">{errors.state}</span>}
        </div>
        <div className="form-group">
          <BootstrapSelect
            className="form-control address-control"
            items={(COUNTRIES || []).map(item => ({
              label: item.country,
              value: item.code,
            }))}
            value={user.contacts.address.country}
            onChange={v => onChange('country', v)}
            placeHolder="Country"
          />
          {errors.country && <span className="helper-text error">{errors.country}</span>}
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control address-control"
            placeholder="Zip"
            value={user.contacts.address.zip || ''}
            onChange={e => onChange('zip', e.target.value)}
          />
          {errors.zip && <span className="helper-text error">{errors.zip}</span>}
        </div>
      </div>
    </div>
    <div className="upe-prop-bottom">
      <div className="upe-b-member">
        <div className="upe-b-ques">
          <p>Member of the church?</p>
        </div>
        <div className="form-group upe-b-radio-group">
          <div className="upe-b-radio-btn">
            <input
              id="radio-member-yes"
              type="radio"
              name="radio-group-member"
              checked={user.profile.member.type === 'member' || user.profile.member.type === 'honored'}
              onChange={() => onChange('type', 'member')}
            />
            <label htmlFor="radio-member-yes">Yes</label>
          </div>
          <div className="upe-b-radio-btn">
            <input
              id="radio-member-no"
              type="radio"
              name="radio-group-member"
              checked={user.profile.member.type === 'non-member'}
              onChange={() => onChange('type', 'non-member')}
            />
            <label htmlFor="radio-member-no">No</label>
          </div>
          <div className="upe-b-radio-btn">
            <input
              id="radio-member-unknown"
              type="radio"
              name="radio-group-member"
              checked={user.profile.member.type === 'unknown'}
              onChange={() => onChange('type', 'unknown')}
            />
            <label htmlFor="radio-member-unknown">Unknown</label>
          </div>
          {errors.type && <span className="helper-text error">{errors.type}</span>}
        </div>
        {(user.profile.member.type === 'member' || user.profile.member.type === 'honored') && (
          <div className="form-group upe-b-joined-date">
            <p className="pl-2 mt-1 mb-0 font-weight-bold">Joined Date?</p>
            <DatePicker
              onChange={value => {
                if (value && typeof value.getTime === 'function') {
                  onChange('joinedDate', value.getTime());
                } else {
                  onChange('joinedDate', value);
                }
              }}
              value={user.profile.member.joinedDate ? new Date(user.profile.member.joinedDate) : null}
            />
            {errors.joinedDate && <span className="helper-text error">{errors.joinedDate}</span>}
          </div>
        )}
      </div>
      {(user.profile.member.type === 'member' || user.profile.member.type === 'honored') && (
        <>
          <div className="upe-b-member">
            <div className="upe-b-ques">
              <p>
                Is This Member a Honored Member? <img src={GStar} alt="GroupStar" />
              </p>
            </div>
            <div className="upe-b-radio-group">
              <div className="upe-b-radio-btn">
                <input
                  id="radio-honor-yes"
                  type="radio"
                  name="radio-group-honor"
                  checked={user.profile.member.type === 'honored'}
                  onChange={() => onChange('type', 'honored')}
                />
                <label htmlFor="radio-honor-yes">Yes</label>
              </div>
              <div className="upe-b-radio-btn">
                <input
                  id="radio-honor-no"
                  type="radio"
                  name="radio-group-honor"
                  checked={user.profile.member.type === 'member'}
                  onChange={() => onChange('type', 'member')}
                />
                <label htmlFor="radio-honor-no">No</label>
              </div>
            </div>
          </div>
          {user.profile.member.type === 'honored' && (
            <div className="upe-b-member">
              <div className="form-group row">
                <label htmlFor="upe-position" className="col-md-2 form-label">
                  Position:
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={user.profile.member.type === 'honored' ? user.profile.member.position || '' : ''}
                    onChange={e => onChange('position', e.target.value)}
                    disabled={user.profile.member.type !== 'honored'}
                  />
                  {errors.position && <span className="helper-text error">{errors.position}</span>}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="upe-desc" className="col-md-2 form-label">
                  Description:
                </label>
                <div className="col-md-10">
                  <textarea
                    className="form-control"
                    id="upe-position"
                    rows="3"
                    value={
                      user.profile.member.type === 'honored' ? user.profile.member.description || '' : ''
                    }
                    onChange={e => onChange('description', e.target.value)}
                    disabled={user.profile.member.type !== 'honored'}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="form-group row upe-b-notes">
        <label htmlFor="upe-notes" className="col-md-2 form-label">
          Notes:
        </label>
        <textarea
          className="form-control"
          id="upe-notes"
          rows="3"
          value={user.profile.notes || ''}
          onChange={e => onChange('notes', e.target.value)}
        />
      </div>
    </div>
    <div className="upe-b-savebtn">
      {isSubmitting ? (
        <div style={{ position: 'relative', height: 50 }}>
          <Loading />
        </div>
      ) : (
        <button type="button" className="button neutral border--red medium" onClick={onSave}>
          Save Changes
        </button>
      )}
    </div>
  </div>
);

UserProfileEdit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    profile: PropTypes.object.isRequired,
    contacts: PropTypes.object.isRequired,
    unlinked: PropTypes.bool,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeFullAddress: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default UserProfileEdit;
