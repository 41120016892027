const validate = values => {
  const errors = {};

  if (!values.total) {
    errors.total = 'Amount is required';
  }
  if (!values.user) {
    errors.user = 'User is required';
  }
  if (!values.date) {
    errors.date = 'Date is required';
  }
  if (!values.giftID) {
    errors.giftID = 'Gift is required';
  }
  if (!values.methodType) {
    errors.methodType = 'Payment method is required';
  }

  return errors;
};

export default validate;
