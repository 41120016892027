import {
  REQUEST_EVENTS,
  RECEIVE_EVENTS,
  RECEIVE_EVENT,
  REMOVE_EVENT,
  UPDATE_EVENT,
  CREATE_EVENT,
  UPDATE_EVENT_IMG,
} from 'redux/actions/EventActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';

const initialState = { data: [], isLoading: false };

const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EVENTS:
      return { ...state, isLoading: true };

    case RECEIVE_EVENTS: {
      const { events } = action.payload;
      return { ...state, data: events, isLoading: false };
    }

    case REMOVE_EVENT:
      return { ...state, data: state.data.filter(e => e.id !== action.payload.id) };

    case RECEIVE_EVENT:
    case UPDATE_EVENT:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload.id ? { ...e, ...action.payload.event } : e)),
      };

    case CREATE_EVENT:
      return { ...state, data: [action.payload.event, ...state.data] };

    case UPDATE_EVENT_IMG:
      return {
        ...state,
        data: state.data.map(e =>
          e.id === action.payload.id
            ? {
                ...e,
                avatar: action.payload.avatar,
              }
            : e,
        ),
      };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default EventReducer;
