import { logger } from 'service';
import { fetchUsersRequest } from './UsersActions';
import { fetchGiftsRequest, fetchGivingHistoryRequest } from './GiftActions';
import {
  fetchChurchStateSuccess,
  fetchCustomerStateRequest,
  fetchMembershipPlansRequest,
} from './ChurchActions';
import { fetchPrayersRequest } from './PrayerActions';
import { fetchEventsRequest } from './EventActions';
import { fetchGroupsRequest } from './GroupActions';
import { fetchCustomersRequest } from './CustomersActions';

export const initializeAdminData =
  (church, customerID = null) =>
  async dispatch => {
    await dispatch(fetchChurchStateSuccess(church));
    Promise.all([
      dispatch(fetchCustomerStateRequest(customerID)),
      dispatch(fetchUsersRequest(church.id)),
      dispatch(fetchCustomersRequest(church.id)),
      dispatch(fetchGroupsRequest(church.id)),
      dispatch(fetchGiftsRequest(church.id)),
      dispatch(fetchGivingHistoryRequest(church.id)),
      dispatch(fetchPrayersRequest(church.id)),
      dispatch(fetchEventsRequest(church.id)),
      dispatch(fetchMembershipPlansRequest()),
    ]).catch(errors => {
      logger.warn('initializeAdminData action', errors);
    });
  };

export const initializeLeaderData = (church, userID) => async dispatch => {
  await dispatch(
    fetchChurchStateSuccess({
      ...church,
      admins: { [userID]: { permissions: [], isSuperUser: false, isLeader: true } },
    }),
  );
  Promise.all([
    dispatch(fetchUsersRequest(church.id)),
    dispatch(fetchGroupsRequest(church.id)),
    dispatch(fetchEventsRequest(church.id)),
  ]).catch(errors => {
    logger.warn('initializeLeaderData action', errors);
  });
};
