import { INITIAL_DATE_RANGE } from 'config/giving-history';
import { api, logger } from 'service';
import moment from 'moment';

export const REQUEST_GIFTS = 'REQUEST_GIFTS';
export const RECEIVE_GIFTS = 'RECEIVE_GIFTS';
export const REQUEST_GIFT_HISTORY = 'REQUEST_GIFT_HISTORY';
export const RECEIVE_GIFT_HISTORY = 'RECEIVE_GIFT_HISTORY';
export const CREATE_GIFT = 'CREATE_GIFT';
export const UPDATE_GIFT = 'UPDATE_GIFT';
export const REMOVE_GIFT = 'REMOVE_GIFT';
export const DATE_RANGE_CHANGED = 'DATE_RANGE_CHANGED';

export const changeDateRange = range => ({
  type: DATE_RANGE_CHANGED,
  payload: {
    dateRange: range,
  },
});

export const fetchGiftsRequest = churchID => async dispatch => {
  try {
    dispatch({ type: REQUEST_GIFTS });
    const { gifts } = await api(`/church/${churchID}/gift`);
    dispatch({
      type: RECEIVE_GIFTS,
      payload: {
        data: gifts,
      },
    });
  } catch (error) {
    logger.warn('fetchGiftsRequest action', error);
    dispatch({
      type: RECEIVE_GIFTS,
      payload: {
        data: [],
      },
    });
  }
};

export const createGiftRequest = (title, status) => async (dispatch, getState) => {
  try {
    const {
      church: { id },
    } = getState();
    const { gift } = await api(`/gift`, 'post', {
      churchID: id,
      title,
      status: status ? 1 : 0,
    });
    dispatch({
      type: CREATE_GIFT,
      payload: {
        gift,
      },
    });
  } catch (error) {
    logger.warn('createGiftRequest action', error);
  }
};

// eslint-disable-next-line no-unused-vars
export const updateGiftRequest =
  (giftID, status, title = null) =>
  async dispatch => {
    try {
      await api(`/gift/${giftID}`, 'put', {
        status: status ? 1 : 0,
      });
      dispatch({
        type: UPDATE_GIFT,
        payload: {
          id: giftID,
          status,
        },
      });
    } catch (error) {
      logger.warn('updateGiftRequest action', error);
    }
  };

export const removeGiftRequest = giftID => async dispatch => {
  try {
    await api(`/gift/${giftID}`, 'delete');
    dispatch({
      type: REMOVE_GIFT,
      payload: {
        id: giftID,
      },
    });
  } catch (error) {
    logger.warn('removeGiftRequest action', error);
  }
};

export const fetchGivingHistoryRequest =
  (churchID, from = INITIAL_DATE_RANGE.from, to = INITIAL_DATE_RANGE.to) =>
  async (dispatch, getState) => {
    /*
     * Fetching giving history returns 504 error when
     * it takes more than 30 secs to get the report
     * so retry with adjusted range in each round reduce
     * the range spread by 7 days.
     *
     * */
    let range = 28;
    dispatch({ type: REQUEST_GIFT_HISTORY });
    const { gifts } = getState();
    // eslint-disable-next-line no-constant-condition
    let promises = [];
    promises.push(api(`/giving-history/church/${churchID}/from/${from}/to/${to}`));

    let gHistory = gifts.history.filter(item => item.date < from || item.date > to);

    const i = 1;
    while (i > 0) {
      try {
        if (!promises.length) {
          let currFrom = from;
          let currTo = parseInt(moment(currFrom).add(`${range}`, 'days').format('x'), 10);
          while (currTo <= to) {
            logger.log('creating promises');
            promises.push(api(`/giving-history/church/${churchID}/from/${currFrom}/to/${currTo}`));
            if (currTo === to) break;
            currFrom = parseInt(moment(currTo).add('1', 'days').format('x'), 10);
            currTo = parseInt(moment(currFrom).add(`${range}`, 'days').format('x'), 10);
            if (currTo > to) {
              const momTo = moment(to);
              const momCurr = moment(currTo);
              logger.log(momTo.month(), momTo.date(), momCurr.month(), momCurr.date());
              if (momTo.month() === momCurr.month() && momTo.date() === momCurr.date()) break;
              currTo = to;
            }
          }
        }
        // eslint-disable-next-line no-await-in-loop
        const batchHistory = await Promise.all(promises);
        // eslint-disable-next-line no-loop-func
        batchHistory.forEach(({ history }) => {
          gHistory = gHistory.concat(history);
        });

        gHistory = gHistory.filter(his => his.userID).sort((a, b) => b.date - a.date);
        dispatch({
          type: RECEIVE_GIFT_HISTORY,
          payload: {
            history: gHistory,
            from: Math.min(from, gifts.from),
            to: Math.max(to, gifts.to),
          },
        });
        return gHistory;
      } catch (error) {
        range -= 7;
        logger.log('fetchGivingHistoryRequest action', error, range);
        if (range < 7) {
          logger.warn('Endpoint Failed');
          return gifts.history;
        }
        promises = [];
      }
    }

    return null;
  };

export const createGivingHistoryBatchRequest = (churchID, data) => async (dispatch, getState) => {
  const { gifts } = getState();
  try {
    dispatch({ type: REQUEST_GIFT_HISTORY });
    const { history } = await api(`/giving-history/church/${churchID}`, 'post', {
      history: data,
    });
    let gHistory = history.filter(item => item.date > gifts.from && item.date < gifts.to);
    gHistory = gifts.history.concat(gHistory).sort((a, b) => b.date - a.date);
    dispatch({
      type: RECEIVE_GIFT_HISTORY,
      payload: {
        history: gHistory,
        from: gifts.from,
        to: gifts.to,
      },
    });
    return history;
  } catch (error) {
    logger.warn('createGivingHistoryBatchRequest action', error);
    dispatch({
      type: RECEIVE_GIFT_HISTORY,
      payload: {
        history: [],
        from: gifts.from,
        to: gifts.to,
      },
    });
    return [];
  }
};
