// eslint-disable-next-line react/prop-types
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaskedInput from 'react-text-mask';

const phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const PhoneInput = ({ inputRef, ...props }) => (
  <MaskedInput
    {...props}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={phoneMask}
    placeholderChar={'\u2000'}
  />
);
const zipcodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
// eslint-disable-next-line react/prop-types
const ZipInput = ({ inputRef, ...props }) => (
  <MaskedInput
    {...props}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={zipcodeMask}
    placeholderChar={'\u2000'}
  />
);
export const InputComponent = ({ label, startAdornment, ...props }) => {
  const [field, { error, touched }] = useField(props);
  const getInputComponent = () => {
    switch (props.name) {
      case 'phone':
        return (
          <Input
            startAdornment={startAdornment && <InputAdornment position="start">$</InputAdornment>}
            // autoFocus
            {...props}
            {...field}
            inputComponent={PhoneInput}
          />
        );
      case 'zip':
        return (
          <Input
            startAdornment={startAdornment && <InputAdornment position="start">$</InputAdornment>}
            // autoFocus
            {...props}
            {...field}
            inputComponent={ZipInput}
          />
        );
      default:
        return (
          <Input
            startAdornment={startAdornment && <InputAdornment position="start">$</InputAdornment>}
            // autoFocus
            {...props}
            {...field}
          />
        );
    }
  };
  return (
    <FormControl {...props} error={!!error && touched} fullWidth color="primary">
      <InputLabel shrink={!!field.value} htmlFor={field.name}>
        {label}
      </InputLabel>
      {getInputComponent()}
      <ErrorMessage name={field.name} component={FormHelperText} />
    </FormControl>
  );
};
InputComponent.defaultProps = {
  startAdornment: false,
};
InputComponent.propTypes = {
  startAdornment: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
}));
export const SelectComponent = ({ label, menuArray, ...props }) => {
  const classes = useStyles();
  const [input, { error, touched }] = useField(props);
  return (
    <FormControl error={!!error && touched} className={clsx(classes.formControl)}>
      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        {label}
      </InputLabel>
      <Select {...props} {...input} MenuProps={MenuProps}>
        {menuArray &&
          menuArray.map(v => (
            <MenuItem key={v.value} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
      </Select>
      <ErrorMessage name={input.name} component={FormHelperText} />
    </FormControl>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.string.isRequired,
  menuArray: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
};
