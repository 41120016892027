import React from 'react';
import PropTypes from 'prop-types';
import StyledModal from 'components/StyledModal';
import PrayerDetails from './PrayerDetails';
import PrayerDetailsPosition from './PrayerDetailsPosition';

import './style.css';

const UserPopupModal = React.memo(
  ({ open, isShowPosition, user, onClose, handlePositionBack, handleStarClick }) => (
    <StyledModal
      className="prayer-modal-wrapper"
      open={open}
      onClose={onClose}
      fullWidth
      showCloseIcon
      padding={0}
      style={{}}
    >
      {isShowPosition === false ? (
        <PrayerDetails handleStarClick={handleStarClick} user={user} />
      ) : (
        <PrayerDetailsPosition handlePositionBack={handlePositionBack} user={user} />
      )}
    </StyledModal>
  ),
);

UserPopupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isShowPosition: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleStarClick: PropTypes.func.isRequired,
  handlePositionBack: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserPopupModal;
