import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Tooltip, IconButton } from '@material-ui/core';
import { NOTIFICATION_TYPES } from 'config/notification';
import { ROLES } from 'config/roles';
import StyledModal from 'components/StyledModal';
import Avatar from 'components/Avatar';
import ChurchIconBlack from 'assets/images/Churchico_black.png';
import GivingIcon from 'assets/images/giving.png';
import EventsIcon from 'assets/images/event.png';
import GroupsIcon from 'assets/images/group.png';
import PrayerReqIcon from 'assets/images/PrayerReqIcon.png';
import SettingsIcon from 'assets/images/Settings.png';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import { markNotificationAsRead, removeNotification } from 'redux/actions/NotificationActions';

import NotificationListModal from './NotificationListModal';
import './style.css';

class DSubHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSubmenuModal: false,
      openNotiMenu: false,
    };
  }

  handleOpenSubmenuModal = () => {
    this.setState({ openSubmenuModal: true });
  };

  handleCloseSubmenuModal = () => {
    this.setState({ openSubmenuModal: false });
  };

  handleToggleNotification = () => {
    const { openNotiMenu } = this.state;
    this.setState({ openNotiMenu: !openNotiMenu });
  };

  handleSelectNotification = (event, notification) => {
    const { history, location, dispatch } = this.props;
    if (NOTIFICATION_TYPES[notification.type] && NOTIFICATION_TYPES[notification.type].link) {
      dispatch(markNotificationAsRead(notification.id));
      history.push(NOTIFICATION_TYPES[notification.type].link, { from: location.pathname });
      return;
    }
    this.handleCloseNotification();
  };

  handleRemoveNotification = (event, notification) => {
    const { dispatch } = this.props;
    dispatch(removeNotification(notification.id));
  };

  handleCloseNotification = () => {
    this.setState({ openNotiMenu: false });
  };

  handleCloseMenu = () => {
    this.setState({ openSubmenuModal: false });
  };

  render() {
    const { openSubmenuModal, openNotiMenu } = this.state;
    const {
      churchCustomer,
      churchPayment,
      churchAvatar,
      churchName,
      authUserPermissions,
      isAdmin,
      notifications,
      location,
    } = this.props;

    const isGivingManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GIVING_MANAGER.value);
    const isPrayerRequestManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.REQUEST_MANAGER.value);
    const newNotificationCount =
      notifications && notifications.length
        ? notifications.reduce((cnt, item) => (item.type !== 'group' && !item.isRead ? cnt + 1 : cnt), 0)
        : 0;
    const notificationsCount = notifications && notifications.length ? notifications.filter(item => item.type !== 'group').length : 0

    const renderAlertBanner = () => {
      if (!churchCustomer || churchCustomer.isLoading) {
        return <></>;
      }
      if (
        !churchCustomer.data ||
        !churchCustomer.data.subscription ||
        churchCustomer.data.subscription.status !== 'active'
      ) {
        return (
          <div className="alert-banner-container mui-fixed">
            <span className="step-header">STEP 1: </span>There is no active FaithMo subscription, you can
            activate your subscription by&nbsp;
            <Link
              className="link"
              to={{
                pathname: '/dashboard/settings/membership/membership_edit',
                state: { from: location.pathname },
              }}
            >
              clicking here
            </Link>
            .
          </div>
        );
      }
      if (!churchPayment.id) {
        return (
          <div className="alert-banner-container mui-fixed">
            <span className="step-header">Step 2: </span>There is no connected Church&apos;s payment method.
            You can connect payment method by&nbsp;
            <Link
              className="link"
              to={{
                pathname: '/dashboard/settings/gift_settings',
                state: { from: location.pathname },
              }}
            >
              clicking here
            </Link>
            .
          </div>
        );
      }
      return <></>;
    };

    return (
      <>
        <div className="app-subheader mui-fixed">
          <div className="row">
            <div className="col-8 d-sub-left">
              <Link
                className="wrapper"
                to={{
                  pathname: '/dashboard',
                  state: { from: location.pathname },
                }}
              >
                <Avatar type="church" size="small" src={churchAvatar} />
                <h6 className="name--church">{churchName}</h6>
              </Link>
            </div>
            <div className="col-4 d-sub-right">
              <div className="notification">
                <Tooltip
                  title={`Alerts • ${
                    notificationsCount > 0 ? notificationsCount : 'No'
                  } alerts`}
                >
                  <IconButton onClick={this.handleToggleNotification}>
                    {newNotificationCount ? (
                      <NotificationsActiveIcon style={{ margin: -8, fontSize: '28px', color: 'white' }} />
                    ) : (
                      <NotificationsIcon style={{ margin: -8, fontSize: '28px', color: 'white' }} />
                    )}
                    {!!newNotificationCount && (
                      <span className="noti-count-icon-text">{newNotificationCount}</span>
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div className="sub-menu">
                <Tooltip title="Menu">
                  <IconButton onClick={this.handleOpenSubmenuModal}>
                    <MenuIcon style={{ fontSize: '28px', margin: -8, color: 'white' }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {isAdmin && renderAlertBanner()}
        <StyledModal
          className="menu-modal"
          open={openSubmenuModal}
          onClose={this.handleCloseSubmenuModal}
          showCloseIcon
          padding={0}
          style={{}}
        >
          <nav>
            <ul>
              {isAdmin && (
                <li>
                  <Link
                    to={{
                      pathname: '/dashboard',
                      state: { from: location.pathname },
                    }}
                    onClick={this.handleCloseMenu}
                  >
                    <span className="subp-img">
                      <img src={ChurchIconBlack} alt="Churchico" />
                    </span>
                    <span>My Church</span>
                  </Link>
                </li>
              )}
              {isGivingManager && (
                <li>
                  <Link
                    to={{
                      pathname: '/dashboard/givings',
                      state: { from: location.pathname },
                    }}
                    onClick={this.handleCloseMenu}
                  >
                    <span className="subp-img">
                      <img src={GivingIcon} alt="Giving" />
                    </span>
                    <span>Giving</span>
                  </Link>
                </li>
              )}
              {isAdmin && (
                <li>
                  <Link
                    to={{
                      pathname: '/dashboard/users',
                      state: { from: location.pathname },
                    }}
                    onClick={this.handleCloseMenu}
                  >
                    <span className="subp-img">
                      <img src={GroupsIcon} alt="User Management" />
                    </span>
                    <span>User Management</span>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={{
                    pathname: '/dashboard/events',
                    state: { from: location.pathname },
                  }}
                  onClick={this.handleCloseMenu}
                >
                  <span className="subp-img">
                    <img src={EventsIcon} alt="Events" />
                  </span>
                  <span>Events</span>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/dashboard/groups',
                    state: { from: location.pathname },
                  }}
                  onClick={this.handleCloseMenu}
                >
                  <span className="subp-img">
                    <img src={GroupsIcon} alt="Groups" />
                  </span>
                  <span>Groups</span>
                </Link>
              </li>
              {isPrayerRequestManager && (
                <li>
                  <Link
                    to={{
                      pathname: '/dashboard/prayers',
                      state: { from: location.pathname },
                    }}
                    onClick={this.handleCloseMenu}
                  >
                    <span className="subp-img">
                      <img src={PrayerReqIcon} alt="Prayer Request" />
                    </span>
                    <span>Prayer Request</span>
                  </Link>
                </li>
              )}
              {isAdmin && (
                <li>
                  <Link
                    to={{
                      pathname: '/dashboard/settings/profile',
                      state: { from: location.pathname },
                    }}
                    onClick={this.handleCloseMenu}
                  >
                    <span className="subp-img">
                      <img src={SettingsIcon} alt="Settings" />
                    </span>
                    <span>Settings</span>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </StyledModal>
        <NotificationListModal
          open={openNotiMenu}
          onClose={this.handleCloseNotification}
          notifications={notifications.filter(item => item.type !== 'group')}
          newCnt={newNotificationCount}
          onSelect={this.handleSelectNotification}
          onRemove={this.handleRemoveNotification}
        />
      </>
    );
  }
}

DSubHeader.defaultProps = {
  churchAvatar: null,
  churchName: null,
  churchCustomer: {
    isLoading: true,
    data: null,
  },
  churchPayment: {
    id: null,
  },
};

DSubHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  churchCustomer: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.object,
  }),
  churchPayment: PropTypes.shape({
    id: PropTypes.string,
  }),
  churchAvatar: PropTypes.string,
  churchName: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
    isLeader: PropTypes.bool,
  }).isRequired,
  notifications: PropTypes.array.isRequired,
};

const mapStateToProps = ({ church, auth: { user, as }, notifications }) => ({
  isAdmin: as === 'admin',
  churchCustomer: church.customer,
  churchPayment: church.stripe,
  churchAvatar: church.avatar,
  churchName: church.title,
  authUserPermissions: church.admins[user.id],
  notifications: notifications.data,
});

export default withRouter(connect(mapStateToProps)(DSubHeader));
