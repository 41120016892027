import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TimePicker } from 'components/MDFormControls';
import { InputBox, Loading } from 'components';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';
import { ReactComponent as PencilIcon } from 'assets/images/pencil.svg';
import linkIcon from 'assets/images/outline_live_tv_white_48dp.png';

import { setDate } from '../../../utils';
import validate from './validate';

const ServiceItem = React.memo(
  ({ index, editable, item, onRemove, onSave, openVirtualLinkDialog, dayValue }) => {
    const [data, setData] = useState(
      item || {
        title: '',
        start: null,
        end: null,
        link: {},
      },
    );
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(!item);

    const handleChange = (name, value) => {
      setData({ ...data, [name]: name === 'end' || name === 'start' ? setDate(dayValue, value) : value });
    };

    const handleSave = () => {
      const mErrors = validate(data);
      setErrors(mErrors);
      const errorCnt = Object.keys(mErrors).length;
      if (errorCnt) {
        return;
      }
      if (!item) {
        openVirtualLinkDialog(index, data);
      } else {
        const stData = {
          title: data.title,
          start: data.start,
          end: data.end,
        };
        setIsSubmitting(true);
        onSave(index, stData)
          .then(() => {
            setIsSubmitting(false);
            setIsEditing(false);
          })
          .catch(() => {
            setIsSubmitting(false);
            setIsEditing(false);
          });
      }
    };

    const handleCancelClick = () => {
      setIsEditing(false);
      if (typeof index === 'string' && !item) {
        onRemove(index);
      } else {
        setData(item);
      }
    };

    const handleRemoveClick = () => {
      setIsSubmitting(true);
      onRemove(index)
        .then(() => {
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    };

    const handleEditClick = () => {
      setIsEditing(true);
    };

    return (
      <div className="service-item">
        <div className="st-name">
          {isEditing ? (
            <InputBox
              className={`${errors.title ? 'error' : ''}`}
              maxLength={40}
              type="text"
              placeholder="Service title"
              id="service-title"
              onChange={e => handleChange('title', e.target.value)}
              value={data.title || ''}
              error={errors.title}
            />
          ) : (
            <div className="item-cell">{data.title}</div>
          )}
        </div>
        <div className="st-start-time">
          {isEditing ? (
            <>
              <TimePicker
                autoOk
                placeholder="Start time"
                value={data.start || null}
                onChange={date => {
                  handleChange('start', date ? moment(date).valueOf() : null);
                }}
                className={`time-picker ${errors.start ? 'error' : ''}`}
              />
              <span className={`helper-text ${errors.start ? 'error' : ''}`}>{errors.start}</span>
            </>
          ) : (
            <div className="item-cell">{moment(data.start).format('LT')}</div>
          )}
        </div>
        <div className="st-end-time">
          {isEditing ? (
            <>
              <TimePicker
                autoOk
                placeholder="End time"
                value={data.end || null}
                className={`time-picker ${errors.end ? 'error' : ''}`}
                onChange={date => {
                  handleChange('end', date ? moment(date).valueOf() : null);
                }}
              />
              <span className={`helper-text ${errors.end ? 'error' : ''}`}>{errors.end}</span>
            </>
          ) : (
            <div className="item-cell">{!!data.end && moment(data.end).format('LT')}</div>
          )}
        </div>
        {editable && (
          <div className="st-action">
            {isSubmitting ? (
              <div style={{ position: 'relative', height: 50, width: '100%' }}>
                <Loading size={24} />
              </div>
            ) : (
              <>
                {isEditing ? (
                  <>
                    <button key="save" type="button" className="button gradient small" onClick={handleSave}>
                      Save
                    </button>
                    <button
                      key="cancel"
                      type="button"
                      className="button neutral border--red small"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      key="edit"
                      type="button"
                      className="button red icon-button"
                      onClick={handleEditClick}
                    >
                      <PencilIcon />
                    </button>
                    <button
                      key="remove"
                      type="button"
                      className="button red icon-button"
                      onClick={handleRemoveClick}
                    >
                      <TrashIcon />
                    </button>
                    <button
                      key="Edit Virtual Link"
                      type="button"
                      className={`button ${
                        item.link && Object.keys(item.link).length ? 'lightblue' : 'red'
                      } icon-button`}
                      onClick={() => {
                        openVirtualLinkDialog(index, item);
                        // open up the editing modal
                        // let user edit
                        // save the update data
                      }}
                    >
                      <img src={linkIcon} className="service-time-link-icon" alt="icon" />
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  },
);

ServiceItem.defaultProps = {
  item: null,
};

ServiceItem.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number,
    link: PropTypes.object,
  }),
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  openVirtualLinkDialog: PropTypes.func.isRequired,
  dayValue: PropTypes.string.isRequired,
};

export default ServiceItem;
