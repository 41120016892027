import { api, logger } from '../../service';

export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const fetchCustomersRequest = churchID => async dispatch => {
  try {
    dispatch({ type: REQUEST_CUSTOMERS });
    const { customers } = await api(`payments/merchant/church/${churchID}/customer`);
    dispatch({
      type: RECEIVE_CUSTOMERS,
      payload: { customers: customers.filter(({ email }) => email) },
    });
    return customers;
  } catch (error) {
    dispatch({ type: RECEIVE_CUSTOMERS, payload: { customers: [] } });
    logger.warn('fetchCustomersRequest action', error);
    return [];
  }
};
