import { CREDIT_CARD_CVC_REGEXP, CREDIT_CARD_NUMBER_REGEXP } from 'config/regexrs';

const validate = values => {
  const errors = {};

  if (!values.number) {
    errors.number = 'Number is required';
  } else if (!CREDIT_CARD_NUMBER_REGEXP.test(values.number)) {
    errors.number = 'Invalid card number input';
  }

  if (!values.exp_month) {
    errors.exp_month = 'Expiry month is required';
  } else if (!/^\d\d$/.test(values.exp_month)) {
    errors.exp_month = 'Expiry month is requiring a Two-digit format';
  } else if (parseInt(values.exp_month, 10) > 12) {
    errors.exp_month = 'Expiry month should be less than or equal to 12';
  }

  if (!values.exp_year) {
    errors.exp_year = 'Expiry year is required';
  } else if (!/^\d\d$/.test(values.exp_year)) {
    errors.exp_year = 'Expiry year is requiring a Two-digit format';
  }

  if (!values.cvc) {
    errors.cvc = 'CVC is required';
  } else if (!CREDIT_CARD_CVC_REGEXP.test(values.cvc)) {
    errors.cvc = 'Invalid CVC input';
  }

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.address_line1) {
    errors.address_line1 = 'Street Address is required';
  }

  if (!values.address_city) {
    errors.address_city = 'City is required';
  }

  if (!values.address_state) {
    errors.address_state = 'State is required';
  }

  if (!values.address_zip) {
    errors.address_zip = 'Zip code is required';
  }

  if (!values.address_country) {
    errors.address_country = 'Country is required';
  }

  if (!values.metadata.first_name) {
    errors.first_name = 'First name is required';
  }

  if (!values.metadata.last_name) {
    errors.last_name = 'Last name is required';
  }

  return errors;
};

export default validate;
