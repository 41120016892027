import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import { InputComponent } from './formikFields';

const StepOne = ({ handleSubmit }) => {
  const { errors, touched, setFieldTouched, validateForm, values } = useFormikContext();
  const onSubmit = async () => {
    const fields = ['title', 'pastor', 'churchEmail'];
    await validateForm(values);
    fields.forEach(field => setFieldTouched(field, errors[field]));
    if (fields.every(field => !errors[field] && touched[field]) && !errors.website) handleSubmit();
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputComponent name="title" label="Church/Ministry Name" />
        </Grid>
        <Grid item xs={12}>
          <InputComponent name="pastor" label="Pastor/Priest Name" />
        </Grid>
        <Grid item xs={12}>
          <InputComponent name="churchEmail" label="Church Email Address" />
        </Grid>
        <Grid item xs={12}>
          <InputComponent name="website" label="Church Website (Optional)" />
        </Grid>
      </Grid>
      <button type="button" onClick={onSubmit} className="button medium gradient button--signup-step">
        Next
      </button>
    </>
  );
};

StepOne.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default StepOne;
