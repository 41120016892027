export const ROLES = {
  SUPER_ADMIN: { value: 'super_admin', label: '', type: '' },
  REQUEST_MANAGER: {
    value: 'prayer_request',
    label: 'Prayer Request',
    type: 'page',
  },
  GIVING_MANAGER: {
    value: 'gift_management',
    label: 'Gift Management',
    type: 'page',
  },
  EVENT_EDITOR: {
    value: 'calendar_events_edit',
    label: 'Calendar Events Edit',
    type: 'page',
  },
  GROUP_ADMIN: {
    value: 'group_admin_view',
    label: 'Group Admin View',
    type: 'page',
  },
  USER_MANAGER: {
    value: 'user_management',
    label: 'User Management',
    type: 'page',
  },
  PROFILE_EDITOR: {
    value: 'profile_settings',
    label: 'Profile Page',
    type: 'settings',
  },
  SERVICETIMES_EDITOR: {
    value: 'servicetimes_settings',
    label: 'Service Times',
    type: 'settings',
  },
  GALLERY_EDITOR: {
    value: 'gallery_settings',
    label: 'Gallery',
    type: 'settings',
  },
  GIFT_EDITOR: {
    value: 'gifts_settings',
    label: 'Gift Settings',
    type: 'settings',
  },
  MEMBERSHIP_EDITOR: {
    value: 'memebership_settings',
    label: 'Membership',
    type: 'settings',
  },
  ADMIN: { value: 'admin', label: '', type: '' },
  USER: { value: 'user', label: '', type: '' },
};

export const MEMBER_TYPES = [
  { value: 'unlinked', label: 'Unlinked Subscribers' },
  { value: 'unknown', label: 'Unknown' },
  { value: 'non-member', label: 'Non-Member' },
  { value: 'member', label: 'Member' },
  { value: 'honored', label: 'Honored' },
];
