import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Hub } from 'aws-amplify';
import Avatar from 'components/Avatar';
import StyledModal from 'components/StyledModal';
import FaithmoIcon from 'assets/images/Faithmo.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './style.css';

class DHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openProfileModal: false,
    };
  }

  handleLogout = () => {
    this.setState({ openProfileModal: false }, () => {
      Hub.dispatch('faithmo_auth', { event: 'signout' });
    });
  };

  onOpenProfileModal = () => {
    this.setState({ openProfileModal: true });
  };

  toProfile = () => {
    const { history, location, authUser } = this.props;
    history.push(`/dashboard/user/${authUser.id}`, { edit: true, from: location.pathname });
    this.setState({ openProfileModal: false });
  };

  onCloseProfileModal = () => {
    this.setState({ openProfileModal: false });
  };

  reloadOrNavigate = () => {
    const { history, location } = this.props;
    if (location.pathname === '/dashboard') {
      Hub.dispatch('faithmo_auth', { event: 'signin' });
    } else {
      history.push('/dashboard', { from: location.pathname });
    }
  };

  render() {
    const { openProfileModal } = this.state;
    const { authUser, isAdmin, authUserFullName, authUserEmail } = this.props;

    return (
      <div className="app-header mui-fixed">
        <div className="row">
          <div className="col d-info">
            <p>Give, Pray and stay Connected.</p>
          </div>
          <div role="button" className="col d-logo" onClick={this.reloadOrNavigate} tabIndex={0}>
            <img src={FaithmoIcon} alt="FaithmoIcon" />
          </div>
          <div className="col">
            <div role="button" className="d-usermenu" onClick={this.onOpenProfileModal} tabIndex={0}>
              <Avatar src={authUser.avatar} />
              <p className="name--user">
                <span>{authUserFullName}</span>
                <ExpandMoreIcon style={{ fontSize: '28px', color: '#31c5c3' }} />
              </p>
            </div>
            <StyledModal
              className="profile-modal"
              open={openProfileModal}
              onClose={this.onCloseProfileModal}
              showCloseIcon
              padding={0}
              style={{}}
            >
              <Avatar
                width={120}
                height={120}
                src={authUser.avatar}
                style={{
                  position: 'absolute',
                  top: -42,
                  transform: 'translateX(-50%)',
                  left: '50%',
                }}
              />
              <p className="user--name">{authUserFullName}</p>
              <p className="user--email">{authUserEmail}</p>
              {isAdmin && (
                <button type="button" onClick={this.toProfile} className="button gradient medium">
                  Edit Profile
                </button>
              )}
              <button
                type="button"
                className="button neutral border--red medium"
                onClick={this.handleLogout}
              >
                Logout
              </button>
            </StyledModal>
          </div>
        </div>
      </div>
    );
  }
}
DHeader.defaultProps = {
  authUserEmail: null,
  authUserFullName: null,
};

DHeader.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  authUserEmail: PropTypes.string,
  authUserFullName: PropTypes.string,
  authUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    profile: PropTypes.object.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth: { user, as } }) => ({
  isAdmin: as === 'admin',
  authUser: user,
  authUserEmail: user ? user.email : null,
  authUserFullName: user ? `${user.profile.firstName} ${user.profile.lastName}` : null,
});

export default withRouter(connect(mapStateToProps)(DHeader));
