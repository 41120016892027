import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbar } from 'components';
import { makeStringID } from 'service/utils';

import ServiceItem from './ServiceItem';
import VirtualLinkModal from '../../../components/VirtualLinkModal';
import VirtualLinkField from '../../../components/VirtualLinkField';
import { getLinkFieldErrors } from '../../../utils';

class DayServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newServices: [],
      openVirtualLinkDialog: false,
      savingIndex: null,
      savingData: null,
      link: {},
      linkError: {},
    };
  }

  handleLinkPropertyChange = (key, val) => {
    const { link } = this.state;
    const [linkType, valueObj] = Object.entries(link)[0];
    const newLink = { ...valueObj, [key]: val };
    this.setState({ link: { [linkType]: newLink } });
  };

  handleLinkTypeChange = (type, shape) => {
    let newLink;
    if (type === 'no_link') newLink = {};
    else {
      const newLinkDet = Object.fromEntries([...Object.keys(shape)].map(key => [key, '']));
      newLink = { [type]: newLinkDet };
    }
    this.setState({ link: newLink, linkError: {} });
  };

  handleCreateClick = () => {
    const { newServices } = this.state;
    this.setState({
      newServices: [...newServices, makeStringID(12)],
    });
  };

  handleOpenVirtualLinkDialog = (index, data) => {
    this.setState({
      openVirtualLinkDialog: true,
      savingIndex: index,
      savingData: data,
      link: data.link || {},
      linkError: {},
    });
  };

  handleCloseVirtualLinkDialog = () => {
    this.setState({
      openVirtualLinkDialog: false,
    });
  };

  handleSave = async (index, data) => {
    const errors = await getLinkFieldErrors(data.link);
    if (errors) {
      this.setState({ linkError: errors });
      // throw Error('Virtual Link is Invalid!');
      return false;
    }
    const { onCreated, onEdited, value: dayValue } = this.props;
    const { newServices } = this.state;

    if (typeof index === 'string') {
      await onCreated(dayValue, data);
      this.setState({ newServices: newServices.filter(key => index !== key) });
    } else {
      await onEdited(dayValue, index, data);
    }
    return true;
  };

  handleRemove = async key => {
    const { onRemoved, value: dayValue } = this.props;
    const { newServices } = this.state;
    if (typeof key === 'string') {
      this.setState({ newServices: newServices.filter(index => index !== key) });
    } else {
      await onRemoved(dayValue, key);
    }
  };

  render() {
    const {
      editable,
      visible,
      label,
      services: { serviceTimes },
      value: dayValue,
    } = this.props;
    const { linkError, openVirtualLinkDialog, newServices, savingIndex, savingData, link } = this.state;

    if (!visible) {
      return <div />;
    }

    return (
      <div className="service-block card transparent-card border-card">
        <div className="card-header">
          <h4 className="title section-heading">{label} Services</h4>
          {editable && (
            <div className="title-buttons">
              <button type="button" className="button gradient medium" onClick={this.handleCreateClick}>
                + Add New Service
              </button>
            </div>
          )}
        </div>
        <div className="card-body">
          <Scrollbar
            autoHeight
            autoHeightMin={252}
            autoHeightMax={422}
            style={{
              borderRadius: '30px',
            }}
            verticalBarStyle={{
              height: 'calc(100% - 90px)',
              marginTop: '60px',
            }}
            horizontalBarStyle={{
              width: 'calc(100% - 60px)',
              marginLeft: '30px',
            }}
          >
            <div className="card-header table-header">
              <div className="st-name">
                <span>Name of the service</span>
              </div>
              <div className="st-start-time st-border">
                <span>Start Time</span>
              </div>
              <div className="st-end-time st-border">
                <span>End Time</span>
              </div>
              <div className="st-action st-border">
                <span>Action</span>
              </div>
            </div>
            <div className="card-block table-body">
              {serviceTimes.map((item, index) => (
                <ServiceItem
                  key={item.title}
                  index={index}
                  item={item}
                  editable={editable}
                  dayValue={dayValue}
                  onSave={this.handleSave}
                  onRemove={this.handleRemove}
                  openVirtualLinkDialog={this.handleOpenVirtualLinkDialog}
                />
              ))}
              {!!editable &&
                newServices.map(index => (
                  <ServiceItem
                    key={index}
                    index={index}
                    editable
                    dayValue={dayValue}
                    onSave={this.handleSave}
                    onRemove={this.handleRemove}
                    openVirtualLinkDialog={this.handleOpenVirtualLinkDialog}
                  />
                ))}
            </div>
          </Scrollbar>
          <VirtualLinkModal
            className="virtual-link-modal"
            onClose={() => this.setState({ openVirtualLinkDialog: !openVirtualLinkDialog })}
            open={openVirtualLinkDialog}
            // children={VirtualLinkField}
            onSave={this.handleSave}
            closeDialog={this.handleCloseVirtualLinkDialog}
            index={savingIndex}
            data={savingData}
            link={link}
          >
            <VirtualLinkField
              errors={linkError}
              handleLinkPropertyChange={this.handleLinkPropertyChange}
              link={link}
              handleLinkTypeChange={this.handleLinkTypeChange}
            />
          </VirtualLinkModal>
        </div>
      </div>
    );
  }
}

DayServices.propTypes = {
  visible: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  services: PropTypes.shape({
    serviceTimes: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onRemoved: PropTypes.func.isRequired,
  onEdited: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
};

export default DayServices;
