import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROLES } from 'config/roles';
import { utils } from 'service';
import { Loading } from 'components';
import StyledModal from 'components/StyledModal';
import { cancelMembershipRequest, fetchCustomerStateRequest } from 'redux/actions/ChurchActions';

import './Membership.css';
import { sendNotfication } from '../../../utils';

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      subscription: null,
      cards: [],
      selectedCard: null,
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData = async () => {
    const { church, dispatch } = this.props;
    try {
      const customer = await dispatch(fetchCustomerStateRequest(church.customerID));
      if (!customer) throw new Error('Not valid customer');
      this.setState({
        cards: customer.sources ? customer.sources : [],
        selectedCard: customer.subscription ? customer.subscription.default_source : null,
        subscription: customer.subscription,
      });
    } catch (error) {
      // continue regardless of error
    }
    this.setState({ isLoaded: true });
  };

  handleCancelPlan = async () => {
    const { subscription } = this.state;
    this.setState({ open: false });
    if (subscription) {
      await cancelMembershipRequest(subscription.customer, subscription.id);
      await sendNotfication('church_membership_canceled', {
        subscriptionCustomerId: subscription.customer,
      });
      this.loadInitialData();
    }
  };

  handleConfirm = () => {
    const { subscription } = this.state;
    if (
      subscription.latest_invoice &&
      subscription.latest_invoice.payment_intent &&
      subscription.latest_invoice.payment_intent.next_action
    ) {
      const action = subscription.latest_invoice.payment_intent.next_action;
      if (action.type === 'use_stripe_sdk') {
        // window.open(action.use_stripe_sdk.stripe_js, '_self');
        window.location.href = action.use_stripe_sdk.stripe_js;
      } else if (action.type === 'redirect_to_url') {
        // window.open(action.redirect_to_url.url || action.redirect_to_url.return_url, '_self');
        window.location.href = action.redirect_to_url.url || action.redirect_to_url.return_url;
      } else {
        throw Error('invalid action type');
      }
    }
  };

  handleClickEdit = () => {
    const { history, location } = this.props;
    history.push('/dashboard/settings/membership/membership_edit', { from: location.pathname });
  };

  render() {
    const { open, cards, selectedCard, subscription, isLoaded } = this.state;
    const { authUserPermissions } = this.props;

    const isMembershipEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.MEMBERSHIP_EDITOR.value);

    if (!isLoaded) {
      return <Loading />;
    }

    if (subscription && subscription.status === 'active') {
      return (
        <div className="membership_wrapper">
          <div className="membership_detail">
            <ul>
              <li>
                <p className="subPackage">Subscription Package</p>
                <p>{`${subscription.plan.nickname} Package`}</p>
              </li>
              <li>
                <p>Start Date</p>
                <p>{utils.formatValue(subscription.current_period_start * 1000, 'date_long')}</p>
              </li>
              <li>
                <p className="autoRD">Auto Renewal Date</p>
                <p>
                  {subscription.cancel_at_period_end
                    ? '--- --- -- ----'
                    : utils.formatValue(subscription.current_period_end * 1000, 'date_long')}
                </p>
              </li>
              <li>
                <p className="currentpm">Current Payment Method</p>
                <p>{(cards.find(card => card.id === selectedCard) || { name: '' }).name}</p>
              </li>
            </ul>
          </div>
          <div className="action-group membership-action">
            {isMembershipEditor && (
              <>
                <button type="button" className="btn-save" onClick={this.handleClickEdit}>
                  Edit
                </button>
                <p>
                  {!subscription.cancel_at_period_end && (
                    <span role="button" onClick={() => this.setState({ open: true })} tabIndex={0}>
                      Cancel Plan
                    </span>
                  )}
                </p>
                <StyledModal
                  open={open}
                  onClose={() => this.setState({ open: false })}
                  onSubmit={this.handleCancelPlan}
                  closeBtnLabel="No"
                  submitBtnLabel="Yes"
                >
                  <p style={{ marginBottom: 12 }}>
                    If you are having any issues, trying calling FaithMo at (313) 312-9345
                  </p>
                  <p style={{ marginBottom: 0 }}>Are you sure you want to cancel your membership?</p>
                </StyledModal>
              </>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="membership-wrapper2 row">
        <div className="col-md-6 col-sm-12 membership_note">
          Your Membership is not active. You may still create content for your subscribers to view, but your
          Subscribers will not be able to donate until you activate your membership.
        </div>
        <div className="col-md-1 col-sm-12 mb-4" />
        <div className="col-md-5 col-sm-12">
          {isMembershipEditor && (
            <>
              <div className="pb-4">
                Activate Your Subscription to fully experience all that FaithMo has to offer!
              </div>
              {subscription &&
              subscription.status !== 'active' &&
              subscription.latest_invoice &&
              subscription.latest_invoice.payment_intent &&
              subscription.latest_invoice.payment_intent.status === 'requires_action' ? (
                <>
                  <div className="pb-4 text-danger">
                    confirm your payment method for activate your subscription!
                  </div>
                  <button
                    type="button"
                    className="button btn-activate gradient"
                    onClick={this.handleConfirm}
                  >
                    Confirm
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="button btn-activate gradient"
                  onClick={this.handleClickEdit}
                >
                  Activate
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

Membership.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  church: PropTypes.shape({
    stripe: PropTypes.object.isRequired,
    customerID: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, auth: { user } }) => ({
  authUserPermissions: church.admins[user.id],
  church,
});

export default withRouter(connect(mapStateToProps)(Membership));
