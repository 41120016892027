import {
  RECEIVE_GROUPS,
  RECEIVE_GROUP,
  REQUEST_GROUPS,
  UPDATE_GROUP,
  UPDATE_GROUP_IMG,
  CREATE_GROUP,
} from 'redux/actions/GroupActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';

const initialState = { data: [], isLoading: false };

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GROUPS:
      return {
        ...state,
        isLoading: true,
      };

    case RECEIVE_GROUPS: {
      const { groups } = action.payload;
      return {
        ...state,
        data: groups,
        isLoading: false,
      };
    }

    case CREATE_GROUP: {
      const { group } = action.payload;
      return {
        ...state,
        data: [group, ...state.data],
        isLoading: false,
      };
    }

    case RECEIVE_GROUP:
    case UPDATE_GROUP: {
      const { data, id } = action.payload;
      return {
        ...state,
        data: state.data.map(item => (item.id === id ? { ...item, ...data } : item)),
      };
    }

    case UPDATE_GROUP_IMG: {
      const { id: groupID, avatar } = action.payload;
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === groupID) {
            return { ...item, avatar };
          }
          return item;
        }),
      };
    }

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default UsersReducer;
