import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'querystring';
import PropTypes from 'prop-types';
import { Loading } from 'components';
import { connectStripeAccount } from 'redux/actions/ChurchActions';
import { Hub } from 'aws-amplify';

class StripeCallback extends Component {
  componentDidMount() {
    const { location, history, connectStripe, church } = this.props;

    if (!church || !church.id) {
      Hub.dispatch('faithmo_auth', {
        event: 'signout',
        data: { error: new Error('Not sign in as church admin') },
      });
      return;
    }
    if (location.search && location.search.length > 1) {
      const queryObj = qs.parse(location.search.substr(1), '&', '=');
      if (queryObj.code && queryObj.scope && queryObj.scope === 'read_write') {
        connectStripe(church.id, queryObj.code)
          .then(() => {
            history.push('/dashboard/settings/gift_settings', { from: location.pathname });
          })
          .catch(() => {
            history.push('/dashboard/settings/gift_settings', { from: location.pathname });
          });
      } else {
        history.push('/dashboard/settings/gift_settings', { error: queryObj, from: location.pathname });
      }
    } else {
      history.push('/dashboard/settings/gift_settings', { from: location.pathname });
    }
  }

  render() {
    return (
      <div className="app-loading-container">
        <Loading size={48} thickness={2} />
      </div>
    );
  }
}

StripeCallback.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  church: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  connectStripe: PropTypes.func.isRequired,
};

const mapStateToProps = ({ church }) => ({
  church: church || {},
});

export default withRouter(
  connect(mapStateToProps, {
    connectStripe: connectStripeAccount,
  })(StripeCallback),
);
