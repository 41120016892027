/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Loading } from './index';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    // borderRadius: '30px',
    width: '30rem',
    height: '30rem',
    overflow: 'unset',
  },
  actions: {
    justifyContent: 'center',
  },
  content: ({ padding, style }) => ({
    padding: `0 ${theme.spacing(padding)}px !important`,
    ...style,
  }),
  title: {
    color: 'white',
    backgroundColor: 'coral',
    textAlign: 'center',
  },
  closeButton: {
    height: '60px',
    background: 'linear-gradient( 180deg, #feaf78 0, #fe8563 0, #fc8362 0, #feaf78 0, #f57754 )',
    width: '100%',
    borderRadius: 0,
  },
  actionBtn: {
    width: 132,
    // margin: `${theme.spacing(2)}px !important`,
  },
}));
const VirtualLinkModal = React.memo(
  ({
    className,
    style,
    open,
    onClose,
    maxWidth,
    children,
    padding,
    fullWidth,
    fullScreen,
    Transition,
    disableEscapeKeyDown,
    disableBackdropClick,
    onSave,
    index,
    data,
    closeDialog,
    link,
  }) => {
    const classes = useStyles({ padding, style });
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleSave = () => {
      // const errors = await getLinkFieldErrors(link);
      // if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      // setTimeout(() => {
      //   setIsSubmitting(false);
      //   // closeDialog();
      // }, 4000);
      const stData = {
        title: data.title,
        start: data.start,
        end: data.end,
        link,
      };
      onSave(index, stData)
        .then(success => {
          // success
          if (success) closeDialog();
          setIsSubmitting(false);
        })
        // error
        .catch();
      // }
    };

    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
        transitionDuration={{ enter: 500, exit: 300 }}
        onClose={(_, reason) => {
          if (disableBackdropClick && reason === 'backdropClick') return;
          onClose();
        }}
        PaperProps={{
          className: classes.dialogPaper,
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogTitle className={classes.title}>
          If there is a video or audio conference for this service please add the link here.
        </DialogTitle>
        <DialogContent className={clsx(classes.content, className)}>
          {isSubmitting ? (
            <div style={{ position: 'relative', height: 80, width: '100%' }}>
              <Loading size={40} />
            </div>
          ) : (
            children
          )}
        </DialogContent>
        {!isSubmitting && (
          <DialogActions className={classes.actions}>
            <button key="save" type="button" className="button gradient small" onClick={handleSave}>
              Save
            </button>
          </DialogActions>
        )}
      </Dialog>
    );
  },
);

VirtualLinkModal.defaultProps = {
  maxWidth: 'md',
  children: null,
  padding: 4,
  fullWidth: true,
  fullScreen: false,
  Transition: undefined,
  className: undefined,
  disableEscapeKeyDown: false,
  disableBackdropClick: false,
  index: '',
  data: {},
  style: {
    fontWeight: 'bold',
    color: '#3b3b3b',
    textAlign: 'center',
  },
};

VirtualLinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  index: PropTypes.string,
  data: PropTypes.object,
  padding: PropTypes.number,
  Transition: PropTypes.node,
  fullWidth: PropTypes.bool,
  link: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  style: PropTypes.object,
};

export default VirtualLinkModal;
