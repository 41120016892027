import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { utils } from 'service';
import RoundedTable from 'components/RoundedTable';
import UserAvatar from 'components/UserAvatar';
import Loading from 'components/Loading';
import StyledModal from 'components/StyledModal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';

import './style.css';

const PrayersTable = React.memo(({ history, location, data, removable, onRemove, isLoading }) => {
  const [isAscending, setSort] = useState(false);
  const [deletePrayerID, setDeletePrayerID] = useState(null);

  const toggleSort = () => {
    setSort(isDesc => !isDesc);
  };

  const handleShowDeleteModal = (event, prayerID) => {
    event.stopPropagation();
    setDeletePrayerID(prayerID);
  };

  const handleCloseDeleteModal = () => {
    setDeletePrayerID(null);
  };

  const handleDeletePrayer = () => {
    onRemove(deletePrayerID);
    setDeletePrayerID(null);
  };

  return (
    <div className="prayer-table">
      {isLoading ? (
        <div
          style={{
            position: 'relative',
            minHeight: '60px',
            minWidth: '60px',
          }}
        >
          <Loading />
        </div>
      ) : (
        <RoundedTable
          // showPagenate
          minWidth={760}
          data={data
            .sort((a, b) =>
              isAscending === false
                ? b.prayer.created - a.prayer.created
                : a.prayer.created - b.prayer.created,
            )
            .map(item => [
              <div
                role="button"
                className="title"
                onClick={() => {
                  history.push(`/dashboard/prayer/${item.prayer.id}`, { from: location.pathname });
                }}
                tabIndex={0}
              >
                <span>{item.prayer.title}</span>
                {removable && (
                  <span
                    role="button"
                    className="remove-icon"
                    onClick={e => handleShowDeleteModal(e, item.prayer.id)}
                    tabIndex={0}
                  >
                    <TrashIcon />
                  </span>
                )}
              </div>,
              <div>{utils.formatValue(item.prayer.created, 'date')}</div>,
              <UserAvatar user={item.user} className="align-left" />,
            ])}
          headings={[
            { component: <div className="align-left">Title</div>, percentWidth: 40 },
            {
              component: (
                <div role="button" className="date-sort" onClick={toggleSort} tabIndex={0}>
                  <span>Request Date</span>
                  <ArrowDropDownIcon
                    style={{
                      transform: isAscending
                        ? 'translateY(-50%) scale(1,-1)'
                        : 'translateY(-50%) scale(1,1)',
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      right: 0,
                      marginLeft: 'auto',
                      marginRight: '8px',
                      fontSize: '28px',
                    }}
                  />
                </div>
              ),
              percentWidth: 25,
            },
            {
              component: <div className="align-left">User Requested</div>,
              percentWidth: 35,
            },
          ]}
        />
      )}
      <StyledModal
        open={!!deletePrayerID}
        onClose={handleCloseDeleteModal}
        onSubmit={handleDeletePrayer}
        closeBtnLabel="No"
        submitBtnLabel="Yes"
      >
        Are you sure you want to delete this prayer?
      </StyledModal>
    </div>
  );
});

PrayersTable.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      prayer: PropTypes.shape({
        created: PropTypes.number.isRequired,
      }).isRequired,
      user: PropTypes.object.isRequired,
    }),
  ).isRequired,
  removable: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withRouter(PrayersTable);
