import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'react-day-picker';
import { Helmet } from 'react-helmet';
import { ROLES } from 'config/roles';
import { logger, utils } from 'service';
import { EventCreateForm, EventEditForm } from 'components/EventsForm';
import StyledModal from 'components/StyledModal';
import { DropDownMenu, Loading } from 'components';
import { createEventRequest, updateEventRequest } from 'redux/actions/EventActions';
import { changeDateRange, fetchGivingHistoryRequest } from 'redux/actions/GiftActions';
import { fetchUsersRequest } from 'redux/actions/UsersActions';
import TotalGiftIcon from 'assets/images/TotalGiftIcon.png';
import CalendarIcon from 'assets/images/CalendarIcon.png';
import AddIcon from 'assets/images/add.png';
import { EventList, PrayerList, UserList } from './components';

import './style.css';
import { fetchCustomersRequest } from '../../redux/actions/CustomersActions';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      activedEventIndex: 0,
      showEditModal: false,
      showCreateModal: false,
      isSubmittingEvent: false,
      giftSummary: {
        total: 0,
        count: 0,
        isLoading: false,
      },
    };

    this.datetimes = [
      {
        label: 'Today',
        value: 'day',
      },
      {
        label: 'This Week',
        value: 'week',
      },
      {
        label: 'This Month',
        value: 'month',
      },
      {
        label: 'This Year',
        value: 'year',
      },
    ];
  }

  componentDidMount() {
    this.loadData(false);
  }

  componentDidUpdate(prevProps) {
    const { gifts, users } = this.props;
    if (
      JSON.stringify(prevProps.gifts) !== JSON.stringify(gifts) ||
      JSON.stringify(prevProps.users) !== JSON.stringify(users)
    )
      this.loadData(false);
  }

  loadData = async isRefresh => {
    const { giftSummary } = this.state;
    const {
      gifts: { dateRange },
    } = this.props;
    const {
      dispatch,
      churchID,
      users,
      gifts: { history: gHistory, isLoading, from: currentDateFrom, to: currentDateTo },
    } = this.props;

    this.setState({ giftSummary: { ...giftSummary, isLoading: true } }, async () => {
      if (users.isLoading || isLoading) return;

      const from = Math.floor(moment().startOf(dateRange).valueOf());
      const to = Math.ceil(moment().endOf(dateRange).valueOf());

      try {
        let history = [];
        if (isRefresh || currentDateFrom > from || currentDateTo < to) {
          const [hist] = await Promise.all([
            dispatch(fetchGivingHistoryRequest(churchID, from, to)),
            dispatch(fetchUsersRequest(churchID)),
            dispatch(fetchCustomersRequest(churchID)),
          ]);
          history = hist;
        } else {
          history = [...gHistory];
        }
        history = history.filter(item => item.date >= from && item.date <= to);
        if (history && history.length) {
          const reducer = (accumulator, currentValue) => ({
            total: accumulator.total + currentValue.total,
          });
          const sorter = (a, b) => (a.userID ? a.userID.localeCompare(b.userID) : b.created - a.created);
          const counter = (acc, cur) =>
            acc.userID !== cur.userID
              ? {
                  userID: cur.userID,
                  count: (acc.count || 0) + 1,
                }
              : acc;

          const { total } = history.reduce(reducer, { total: 0 });
          const { count } = history.sort(sorter).reduce(counter, { count: 0 });
          this.setState({
            giftSummary: {
              total: total / 100,
              count,
              isLoading: false,
            },
          });
          return;
        }
      } catch (error) {
        logger.warn('loadData in Dashboard', error);
      }
      this.setState({
        giftSummary: {
          total: 0,
          count: 0,
          isLoading: false,
        },
      });
    });
  };

  handleDateRangeChange = event => {
    const { dispatch } = this.props;

    dispatch(changeDateRange(event.target.value));
  };

  // Event handlers
  handleAdvancedEditClick = id => {
    const { history, location } = this.props;
    history.push(`/dashboard/event/${id}`, { edit: true, from: location.pathname });
  };

  handleShowCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  handleCloseCreateModal = () => {
    this.setState({ showCreateModal: false, isSubmittingEvent: false });
  };

  handleCreateEvent = values => {
    const { churchID, dispatch, history, location } = this.props;
    this.setState({ isSubmittingEvent: true }, async () => {
      try {
        const { id } = await dispatch(createEventRequest(values, churchID));
        history.push(`/dashboard/event/${id}`, { from: location.pathname });
      } catch (error) {
        this.setState({ isSubmittingEvent: false });
      }
    });
  };

  handleShowEditModal = event => {
    // const { dispatch } = this.props;
    // dispatch(fetchEventRequest(event.id));
    this.setState({
      showEditModal: true,
      selectedEvent: event,
    });
  };

  handleCloseEditModal = () => {
    this.setState({ showEditModal: false, isSubmittingEvent: false, selectedEvent: null });
  };

  handleEditEvent = async values => {
    const { dispatch, history, location } = this.props;
    const { selectedEvent } = this.state;
    this.setState({ isSubmittingEvent: true }, async () => {
      try {
        const { id } = await dispatch(updateEventRequest(selectedEvent.id, values));
        this.handleCloseEditModal();
        history.push(`/dashboard/event/${id}`, { from: location.pathname });
      } catch (error) {
        this.setState({ isSubmittingEvent: false });
      }
    });
  };

  handleDayClick = day => {
    const { events } = this.props;

    const index = events.data.findIndex(event => moment(event.start).isSame(day, 'day'));
    if (index !== -1) {
      this.setState({
        activedEventIndex: index,
      });
    }
  };

  handleClickGiving = () => {
    const {
      history,
      location,
      gifts: { dateRange },
    } = this.props;

    const from = Math.floor(moment().startOf(dateRange).valueOf());
    const to = Math.ceil(moment().endOf(dateRange).valueOf());

    history.push(`/dashboard/givings?from=${from}&to=${to}`, { from: location.pathname });
  };

  handleClickUsers = () => {
    const { history, location } = this.props;
    history.push('/dashboard/users', { from: location.pathname });
  };

  render() {
    const {
      showEditModal,
      showCreateModal,
      giftSummary,
      selectedEvent,
      activedEventIndex,
      isSubmittingEvent,
    } = this.state;
    const {
      users,
      prayers,
      eventDays,
      events,
      authUserPermissions,
      gifts: { dateRange },
    } = this.props;

    const isGivingManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GIVING_MANAGER.value);
    const isPrayerRequestManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.REQUEST_MANAGER.value);
    const isEventManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.EVENT_EDITOR.value);

    return (
      <>
        <Helmet title="Dashboard" />
        <div className="d-main">
          <div className="d-main-top">
            <div className="row">
              {(isGivingManager || isPrayerRequestManager) && (
                <div className="col-md-8 left">
                  {isGivingManager && (
                    <div className="total-gift">
                      <div className="card">
                        <div className="card-header">
                          <div className="title">
                            <img src={TotalGiftIcon} alt="TotalGift Icon" />
                            <p>Total Gifts</p>
                          </div>
                          <DropDownMenu
                            value={dateRange}
                            onChange={this.handleDateRangeChange}
                            menuArray={this.datetimes}
                            style={{ margin: '12px', float: 'right' }}
                            menuPosition="right"
                          />
                        </div>
                        <div className="card-block">
                          <div className="wrap--info gifts">
                            {giftSummary.isLoading ? (
                              <div
                                style={{
                                  position: 'relative',
                                  minHeight: '60px',
                                  minWidth: '60px',
                                }}
                              >
                                <Loading />
                              </div>
                            ) : (
                              <>
                                <p className="price">{utils.formatValue(giftSummary.total, 'currency')}</p>
                                <p>{`Gift givers: ${giftSummary.count}`}</p>
                              </>
                            )}
                          </div>
                          <div className="wrap--button">
                            <button
                              type="button"
                              className="button neutral border--red large"
                              onClick={this.handleClickGiving}
                            >
                              View Gift History
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isPrayerRequestManager && (
                    <PrayerList
                      loading={prayers.isLoading || users.isLoading}
                      prayers={prayers.data.filter(item => !item.archived)}
                      users={users.data}
                    />
                  )}
                </div>
              )}
              <div
                className={`col-md-4 right ${
                  !isGivingManager && !isPrayerRequestManager ? 'col-md-12' : ''
                }`}
              >
                <UserList
                  users={users.data.filter(
                    user => Math.abs(moment(user.created).diff(Date.now(), 'day').valueOf()) <= 20,
                  )}
                  isLoading={users.isLoading}
                  onClickTitle={this.handleClickUsers}
                />
              </div>
            </div>
          </div>
          <div className="d-main-bottom">
            <div className="card">
              <div className="card-header">
                <div className="title">
                  <img src={CalendarIcon} alt="CalendarIcon" />
                  <p>Events Calendar</p>
                </div>
                {isEventManager && (
                  <div className="title-buttons">
                    <button
                      type="button"
                      className="button black medium"
                      onClick={this.handleShowCreateModal}
                    >
                      <img className="icon--plus" src={AddIcon} alt="Icon Plus" />
                      Add new event
                    </button>
                  </div>
                )}
              </div>
              <div className="card-block">
                <div className="event-calendar">
                  <Calendar onDayClick={this.handleDayClick} selectedDays={eventDays} />
                </div>
                <div className="event-list-container">
                  <EventList
                    events={events.data}
                    activeSlideIndex={activedEventIndex}
                    editable={isEventManager}
                    onEdit={this.handleShowEditModal}
                  />
                  {isEventManager && (
                    <>
                      <StyledModal
                        open={showCreateModal}
                        onClose={this.handleCloseCreateModal}
                        maxWidth="sm"
                        style={{
                          paddingTop: '60px !important',
                          fontWeight: 'bold',
                          color: '#3b3b3b',
                          textAlign: 'center',
                        }}
                      >
                        <button
                          type="button"
                          className="button gradient medium right-top"
                          onClick={() => this.handleAdvancedEditClick('new')}
                        >
                          Advanced Edit
                        </button>
                        <h3>Create Event</h3>
                        <EventCreateForm
                          isSubmitting={isSubmittingEvent}
                          onSubmit={this.handleCreateEvent}
                          handleCancel={this.handleCloseCreateModal}
                        />
                      </StyledModal>
                      <StyledModal
                        open={showEditModal}
                        onClose={this.handleCloseEditModal}
                        maxWidth="sm"
                        style={{
                          paddingTop: '60px !important',
                          fontWeight: 'bold',
                          color: '#3b3b3b',
                          textAlign: 'center',
                        }}
                      >
                        <button
                          type="button"
                          className="button gradient medium right-top"
                          onClick={() => this.handleAdvancedEditClick(selectedEvent.id)}
                        >
                          Advanced Edit
                        </button>
                        <h3>Edit Event</h3>
                        <EventEditForm
                          isSubmitting={isSubmittingEvent}
                          onSubmit={this.handleEditEvent}
                          handleCancel={this.handleCloseEditModal}
                          initialValues={selectedEvent}
                        />
                      </StyledModal>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  gifts: PropTypes.shape({
    history: PropTypes.arrayOf(
      PropTypes.shape({
        methodType: PropTypes.string.isRequired,
        churchID: PropTypes.string.isRequired,
        customerID: PropTypes.string,
        userID: PropTypes.string,
        productID: PropTypes.string.isRequired,
        date: PropTypes.number.isRequired,
        created: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        desc: PropTypes.string,
        giftID: PropTypes.string,
      }),
    ),
    dateRange: PropTypes.oneOf(['day', 'week', 'month', 'year']),
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
    isLoading: PropTypes.number.isRequired,
  }).isRequired,
  prayers: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userID: PropTypes.string.isRequired,
        archived: PropTypes.number.isRequired,
        created: PropTypes.number.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  events: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  eventDays: PropTypes.array.isRequired,
  churchID: PropTypes.string.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ auth: { user }, users, customers, prayers, events, church, gifts }) => {
  const eventData = events.data ? events.data.filter(event => utils.daysLeft(event.start) > 0) : [];

  return {
    authUserPermissions: church.admins[user.id],
    users,
    gifts,
    customers,
    prayers,
    events: {
      ...events,
      data: eventData,
    },
    eventDays: eventData.map(({ start }) => new Date(start)),
    churchID: church.id,
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
