import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Grid, IconButton } from '@material-ui/core';
import { utils, logger } from 'service';
import { PlaceAutoComplete, SwitchCheckBox, BootstrapSelect, Tooltip, InputBox } from 'components';
import { DateTimePicker } from 'components/MDFormControls';
import validate from 'components/EventsForm/validate';
import eventBlankImg from 'assets/images/EventImgBlankIcon.png';
import eDateIcon from 'assets/images/DateIcon.png';
import eCoordIcon from 'assets/images/CoordinatorIcon.png';
import eGroupIcon from 'assets/images/GroupIcon.png';
import eTicketIcon from 'assets/images/TicketsIcon.png';
import ePriceIcon from 'assets/images/PriceIcon.png';
import eMapIcon from 'assets/images/MapIcon.png';
import pencilImg from 'assets/images/pencil.svg';
import linkIcon from 'assets/images/outline_live_tv_white_48dp.png';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import './style.css';
import VirtualLinkField from '../../components/VirtualLinkField';
import { getLinkFieldErrors } from '../../utils';

class EventDetailEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventEdit: null,
      errors: {},
      avatar: null,
      imgStatus: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({ eventEdit: data, errors: { link: {} }, avatar: data.avatar });
  }

  handleDrop = async files => {
    if (files.length !== 1) return;
    try {
      const content = await utils.toBase64(files[0]);
      this.setState({ avatar: content, imgStatus: 'changed' });
    } catch (error) {
      logger.error('handleDrop on EventDetailEdit', error);
    }
  };

  handleDelete = event => {
    event.stopPropagation();
    this.setState({ avatar: null, imgStatus: 'removed' });
  };

  handleSaveEvent = async () => {
    const { onSave } = this.props;
    const { eventEdit, avatar, imgStatus } = this.state;
    let linkError;
    let linkErrorCnt;

    const errors = validate(eventEdit);
    if (eventEdit.link && Object.keys(eventEdit.link).length) {
      linkError = await getLinkFieldErrors(eventEdit.link);
    }
    const errorCnt = Object.keys(errors).length;
    if (linkError) linkErrorCnt = Object.keys(linkError).length;
    if (linkErrorCnt) errors.link = linkError;
    if (errorCnt > 0 || linkErrorCnt) {
      this.setState({ errors });
      return;
    }
    // eventEdit.link = link;

    onSave(eventEdit, imgStatus, avatar);
  };

  handleCancelClick = () => {
    const { onCancel } = this.props;

    this.setState({ errors: {} }, () => {
      onCancel();
    });
  };

  handleInputChange = (name, value) => {
    const { eventEdit } = this.state;
    this.setState({ eventEdit: { ...eventEdit, [name]: value } });
  };

  handleLinkPropertyChange = (key, val) => {
    const { eventEdit } = this.state;
    const [linkType, valueObj] = Object.entries(eventEdit.link)[0];
    const newLink = { [linkType]: { ...valueObj, [key]: val } };
    this.setState({ eventEdit: { ...eventEdit, link: newLink } });
    // this.setState({ link: { [linkType]: newLink } });
  };

  handleAddressInputChange = fullAddress => {
    const { eventEdit } = this.state;
    this.setState({ eventEdit: { ...eventEdit, address: fullAddress } });
  };

  handleLinkTypeChange = (type, shape) => {
    const { errors, eventEdit } = this.state;
    let newLink;
    if (type === 'no_link') newLink = {};
    else {
      const newLinkDet = Object.fromEntries([...Object.keys(shape)].map(key => [key, '']));
      newLink = { [type]: newLinkDet };
    }
    // this.setState({ link: { [type]: newLink }, errors: { ...errors, link: {} } });
    this.setState({
      eventEdit: { ...eventEdit, link: newLink },
      errors: { ...errors, link: {} },
    });
  };

  render() {
    const { avatar, eventEdit, errors } = this.state;
    const { groups, isAdmin } = this.props;

    if (!eventEdit) return <></>;

    return (
      <>
        <Dropzone onDrop={this.handleDrop} multiple={false} accept={['.jpg', '.png', '.jpeg']} noDrag>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: 'event-img-dropzone' })}>
              <div className="dropzone-overlay">
                <img className="overlay-icon" src={pencilImg} alt="Edit" />
                <span className="overlay-text">Change Image</span>
                <button
                  type="button"
                  className="button red icon-button remove-btn"
                  onClick={this.handleDelete}
                >
                  <TrashIcon />
                </button>
              </div>
              <input {...getInputProps()} />
              {avatar ? (
                <div className="event-img">
                  <img alt="Event" src={avatar} />
                </div>
              ) : (
                <div className="event-img blank">
                  <img alt="Event" src={eventBlankImg} />
                </div>
              )}
            </div>
          )}
        </Dropzone>
        <div className="event-section">
          <div className="item-group edit">
            <div className="item-label">Title:</div>
            <div className="item-value">
              <InputBox
                className="form-group"
                maxLength={75}
                onChange={e => this.handleInputChange('title', e.target.value)}
                value={eventEdit.title || ''}
                error={errors.title}
              />
            </div>
          </div>
        </div>
        <div className="event-section">
          <div className="item-group edit">
            <div className="item-label">Description:</div>
            <div className="item-value">
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows={3}
                  value={eventEdit.desc || ''}
                  onChange={e => this.handleInputChange('desc', e.target.value)}
                />
                <span className="helper-text error">{errors.desc}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="event-section">
          <h4 className="event-details-title">Event Details</h4>
          <Grid container>
            {/* Left Column */}
            <Grid className="item" item xs={12} md={6}>
              <Grid container justifyContent="space-between" style={{ gap: '2rem' }} direction="column">
                <Grid item>
                  <div className="event-attribute">
                    <img src={eDateIcon} alt="Date" className="item-icon" />
                    <div className="item-content">
                      <div className="item-group edit">
                        <div className="item-label">Start:</div>
                        <div className="item-value">
                          <div className="form-group">
                            <DateTimePicker
                              input={{
                                value: eventEdit.start,
                                onChange: v => this.handleInputChange('start', v),
                              }}
                              className="form-control"
                            />
                            <span className="helper-text error">{errors.start}</span>
                          </div>
                        </div>
                      </div>
                      <div className="item-group edit">
                        <div className="item-label">End:</div>
                        <div className="item-value">
                          <div className="form-group">
                            <DateTimePicker
                              input={{
                                value: eventEdit.end,
                                onChange: v => this.handleInputChange('end', v),
                              }}
                              className="form-control"
                            />
                            <span className="helper-text error">{errors.end}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="event-attribute">
                    <img src={eCoordIcon} alt="Coord" className="item-icon" />
                    <div className="item-content">
                      <div className="item-group edit">
                        <div className="item-label">Coordinator:</div>
                        <div className="item-value">
                          <div className="form-group">
                            <input
                              className="form-control"
                              value={eventEdit.coordinator || ''}
                              onChange={e => this.handleInputChange('coordinator', e.target.value)}
                            />
                            <span className="helper-text error">{errors.coordinator}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="event-attribute">
                    <img src={ePriceIcon} alt="Price" className="item-icon" />
                    <div className="item-content">
                      <div className="item-group edit">
                        <div className="item-label">Price:</div>
                        <div className="item-value">
                          <div className="form-group">
                            <input
                              className="form-control"
                              value={eventEdit.price === null ? '' : eventEdit.price}
                              onChange={e => {
                                this.handleInputChange('price', utils.normalizeAmountValue(e.target.value));
                              }}
                            />
                            <span className="helper-text error">{errors.price}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* Left Column */}
            {/* Right Column */}
            <Grid className="item" item xs={12} md={6}>
              <Grid container justifyContent="space-between" style={{ gap: '2rem' }} direction="column">
                <Grid item>
                  <div className="event-attribute">
                    <img src={eGroupIcon} alt="Group" className="item-icon" />
                    <div className="item-content">
                      <div className="item-group edit">
                        <div className="item-label">Group:</div>
                        <div className="item-value">
                          <div className="form-group">
                            <div className="input-group">
                              <BootstrapSelect
                                items={(groups || [])
                                  .map(item => ({
                                    label: item.title,
                                    value: item.id,
                                    color: '#31c5c3',
                                  }))
                                  .sort((a, b) => a.label.localeCompare(b.label))}
                                value={eventEdit.groupID}
                                onChange={v => this.handleInputChange('groupID', v)}
                                placeHolder={isAdmin ? 'Church Event' : undefined}
                                placeHolderStyle={{}}
                                emptyColor={isAdmin ? '#fe9168' : undefined}
                                emptyValue={isAdmin ? 'churchwide' : undefined}
                                style={{ flex: 1 }}
                              />
                              <div className="input-group-append">
                                <Tooltip title="Selecting a group will make this event only viewable to the group members. Select ‘Church Event’ to make event available to everyone.">
                                  <IconButton
                                    centerRipple
                                    color="primary"
                                    aria-label="Selecting a group"
                                    component="div"
                                  >
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                            <span className="helper-text error">{errors.groupID}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="event-attribute">
                    <img src={eTicketIcon} alt="Ticket" className="item-icon" />
                    <div className="item-content">
                      <div className="item-group edit">
                        <div className="item-label">Online Tickets:</div>
                        <div className="item-value">
                          <SwitchCheckBox
                            labelNo="No"
                            labelYes="Yes"
                            checked={eventEdit.onlineTickets}
                            onChange={e => this.handleInputChange('onlineTickets', e.target.checked)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="item-link event-attribute">
                    <img src={linkIcon} className="item-icon link-icon" alt="icon" />
                    <div className="item-content">
                      <VirtualLinkField
                        link={eventEdit.link}
                        errors={errors.link}
                        handleLinkPropertyChange={this.handleLinkPropertyChange}
                        handleLinkTypeChange={this.handleLinkTypeChange}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* Right Column */}
          </Grid>
        </div>
        <div className="event-section">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <div className="event-attribute full-width">
                <img src={eMapIcon} alt="Map" className="item-icon" />
                <div className="item-content">
                  <div className="item-group edit">
                    <div className="item-label">Location:</div>
                    <div className="item-value">
                      <div className="form-group">
                        <PlaceAutoComplete
                          value={eventEdit.address || ''}
                          onChange={v => this.handleInputChange('address', v)}
                          onSelect={this.handleAddressInputChange}
                          inputProps={{
                            className: 'form-control',
                            placeholder: 'Street Address',
                          }}
                        />
                        <span className="helper-text error">{errors.address}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="event-attribute full-width">
                <div className="item-content ml-venue">
                  <div className="item-group edit">
                    <div className="item-label">Venue:</div>
                    <div className="item-value">
                      <div className="form-group">
                        <input
                          className="form-control"
                          value={eventEdit.venue || ''}
                          onChange={e => this.handleInputChange('venue', e.target.value)}
                        />
                        <span className="helper-text error">{errors.venue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="action-group">
          <button type="button" className="btn-save" onClick={this.handleSaveEvent}>
            Save
          </button>
          <button type="button" className="btn-cancel" onClick={this.handleCancelClick}>
            Cancel
          </button>
        </div>
      </>
    );
  }
}

EventDetailEdit.propTypes = {
  data: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default EventDetailEdit;
