import axios from 'axios';
import { BASE64_IMAGE_REGEXP } from 'config/regexrs';
import { api, logger } from 'service';
import { sendNotfication } from '../../utils';

export const REQUEST_CUSTOMER_STATE = 'REQUEST_CUSTOMER_STATE';
export const RECEIVE_CUSTOMER_STATE = 'RECEIVE_CUSTOMER_STATE';
export const RECEIVE_PLANS = 'RECEIVE_PLANS';
export const RECEIVE_CHURCH_STATE = 'RECEIVE_CHURCH_STATE';
export const UPDATE_CHURCH_STATE = 'UPDATE_CHURCH_STATE';
export const CONNECT_CHURCH_MERCHANT = 'CONNECT_CHURCH_MERCHANT';
export const CREATE_CHURCH_CUSTOMER = 'CREATE_CHURCH_CUSTOMER';
export const UPDATE_CHURCH_AVATAR = 'UPDATE_CHURCH_AVATAR';
export const CREATE_CHURCH_GALLERY_IMG = 'CREATE_CHURCH_GALLERY_IMG';
export const REMOVE_CHURCH_GALLERY_IMG = 'REMOVE_CHURCH_GALLERY_IMG';
export const UPDATE_CHURCH_ADMIN = 'UPDATE_CHURCH_ADMIN';
export const REMOVE_CHURCH_ADMIN = 'REMOVE_CHURCH_ADMIN';
export const CREATE_CHURCH_SERVICE_DAY = 'CREATE_CHURCH_SERVICE_DAY';
export const CREATE_CHURCH_SERVICE_TIME = 'CREATE_CHURCH_SERVICE_TIME';
export const UPDATE_CHURCH_SERVICE_TIME = 'UPDATE_CHURCH_SERVICE_TIME';
export const REMOVE_CHURCH_SERVICE_TIME = 'REMOVE_CHURCH_SERVICE_TIME';

export const connectStripeAccount = (churchID, code) => dispatch =>
  new Promise((resolve, reject) => {
    api(`/payments/merchant/church/${churchID}/connect`, 'post', {
      code,
    })
      .then(async ({ stripe }) => {
        dispatch({
          type: CONNECT_CHURCH_MERCHANT,
          payload: { stripe },
        });
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });

export const fetchChurchStateSuccess = church => ({
  type: RECEIVE_CHURCH_STATE,
  payload: { church },
});

// export const fetchChurchStripeLoginLink = async merchantID => {
//   const { link } = await api(`/payments/merchant/${merchantID}/get-link`);
//   return link;
// };

export const fetchChurchRequest = churchID => async dispatch => {
  const { church } = await api(`/church/${churchID}`);
  dispatch(fetchChurchStateSuccess(church));
  return { church };
};

export const updateChurchRequest = (churchID, editChurch) => async dispatch => {
  const uChurch = {
    contacts: editChurch.contacts,
    socialMedia: editChurch.socialMedia,
    title: editChurch.title,
    desc: editChurch.desc,
    pastor: editChurch.pastor,
  };

  let updateCustomer = Promise.resolve();
  if (uChurch.customerID) {
    updateCustomer = api(`/payments/merchant/customer/${uChurch.customerID}`, 'put', {
      name: uChurch.title,
      phone: uChurch.contacts.phoneNumber,
      email: uChurch.contacts.email,
    });
  }

  await Promise.all([updateCustomer, api(`/church/${churchID}`, 'put', uChurch)]);

  dispatch({
    type: UPDATE_CHURCH_STATE,
    payload: {
      church: uChurch,
      customer: {
        name: uChurch.title,
        phone: uChurch.contacts.phoneNumber,
        email: uChurch.contacts.email,
      },
    },
  });
};

export const fetchCustomerStateRequest = customerID => async dispatch => {
  try {
    if (!customerID) {
      dispatch({ type: RECEIVE_CUSTOMER_STATE, payload: { customer: {} } });
      return null;
    }
    dispatch({ type: REQUEST_CUSTOMER_STATE });
    const { customer } = await api(`/payments/merchant/customer/${customerID}`);
    dispatch({ type: RECEIVE_CUSTOMER_STATE, payload: { customer } });
    return customer;
  } catch (error) {
    dispatch({ type: RECEIVE_CUSTOMER_STATE, payload: {} });
    logger.warn('fetchCustomerStateRequest action', error);
    return null;
  }
};

export const fetchMembershipPlansRequest = () => async dispatch => {
  console.log('prices')
  try {
    const { prices } = await api(`/payments/merchant/membership-prices`);    
    dispatch({ type: RECEIVE_PLANS, payload: { prices } });
    return prices;
  } catch (error) {
    return [];
  }
};

export const createCustomerRequest = (profile, churchID) => async dispatch => {
  const { customer } = await api(`/payments/merchant/church/${churchID}/customer`, 'post', profile);
  dispatch({ type: CREATE_CHURCH_CUSTOMER, payload: { customer } });
  return { customerID: customer.id };
};

export const createMembershipRequest = async (customerID, source, sourceId, priceId) => {
  const { subscription } = await api(`/payments/merchant/customer/${customerID}/subscription`, 'post', {
    sourceID: sourceId || undefined,
    priceID: priceId,
    source: source || undefined,
  });
  return subscription;
};

export const updateMembershipRequest = async (customerID, subscriptionId, source, sourceId, priceId) => {
  const { subscription } = await api(
    `/payments/merchant/customer/${customerID}/subscription/${subscriptionId}`,
    'put',
    {
      sourceID: sourceId || undefined,
      priceID: priceId,
      source: source || undefined,
    },
  );
  return subscription;
};

export const cancelMembershipRequest = (customerID, subscriptionId) =>
  api(`/payments/merchant/customer/${customerID}/subscription/${subscriptionId}`, 'delete');

export const updateChurchAvatarRequest = (churchID, image) => async dispatch => {
  const data = image.replace(BASE64_IMAGE_REGEXP, '');
  let type = image.match(BASE64_IMAGE_REGEXP);
  type = type[1].toLowerCase();
  const { uploadURL, fullpath } = await api(`/church/${churchID}/avatar`, 'put', {
    type,
    encoding: 'base64',
  });
  await axios.put(uploadURL, Buffer.from(data, 'base64'), {
    headers: {
      'Content-Type': `image/${type}`,
    },
  });
  dispatch({
    type: UPDATE_CHURCH_AVATAR,
    payload: { avatar: fullpath },
  });
  return { avatar: fullpath };
};

export const deleteChurchAvatarRequest = churchID => async dispatch => {
  await api(`/church/${churchID}/avatar`, 'delete');
  dispatch({
    type: UPDATE_CHURCH_AVATAR,
    payload: { avatar: null },
  });
};

export const createChurchGImgRequest = (churchID, image) => async dispatch => {
  try {
    const data = image.replace(BASE64_IMAGE_REGEXP, '');
    let type = image.match(BASE64_IMAGE_REGEXP);
    type = type[1].toLowerCase();
    const { uploadURL, fullpath } = await api(`/church/${churchID}/gallery`, 'put', {
      type,
      encoding: 'base64',
    });
    await axios.put(uploadURL, Buffer.from(data, 'base64'), {
      headers: {
        'Content-Type': `image/${type}`,
      },
    });
    dispatch({
      type: CREATE_CHURCH_GALLERY_IMG,
      payload: { fullpath },
    });
  } catch (error) {
    // continue regardless of error
  }
};

export const deleteChurchGImgRequest = (churchID, fullpath) => async dispatch => {
  try {
    const filename = fullpath.split('/').pop();
    await api(`/church/${churchID}/gallery/${filename}`, 'delete');
    dispatch({
      type: REMOVE_CHURCH_GALLERY_IMG,
      payload: { fullpath },
    });
  } catch (error) {
    // continue regardless of error
  }
};

export const updateAdminPermissionsRequest = (churchID, userID, data) => async dispatch => {
  try {
    await api(`/church/${churchID}/admins/${userID}`, 'put', data);
    dispatch({
      type: UPDATE_CHURCH_ADMIN,
      payload: { userID, ...data },
    });
  } catch (error) {
    // continue regardless of error
  }
};

export const deleteAdminRequest = (churchID, userID) => async dispatch => {
  try {
    await api(`/church/${churchID}/admins/${userID}`, 'delete');
    dispatch({
      type: REMOVE_CHURCH_ADMIN,
      payload: { userID },
    });
  } catch (error) {
    // continue regardless of error
  }
};

export const createServiceDayRequest = (churchID, day, title, serviceData) => async dispatch => {
  try {
    const { serviceTimes } = await api(`/church/${churchID}/days`, 'put', {
      keyName: day,
      title,
      serviceTimes: serviceData,
    });
    await sendNotfication('church_service_times_updated', { churchId: churchID });
    dispatch({
      type: CREATE_CHURCH_SERVICE_DAY,
      payload: { day, title, serviceTimes },
    });
    return { serviceTimes };
  } catch (error) {
    logger.error(error);
    return { serviceTimes: null };
  }
};

export const createServiceTimeRequest = (churchID, day, serviceData) => async dispatch => {
  try {
    const { serviceID } = await api(`/church/${churchID}/days/${day}/service-time`, 'put', serviceData);
    await sendNotfication('church_service_times_updated', { churchId: churchID });
    dispatch({
      type: CREATE_CHURCH_SERVICE_TIME,
      payload: { day, serviceItem: { ...serviceData, id: serviceID } },
    });
    return { serviceItem: { ...serviceData, id: serviceID } };
  } catch (error) {
    logger.error(error);
    return { serviceItem: null };
  }
};

export const updateServiceTimeRequest = (churchID, day, serviceID, serviceData) => async dispatch => {
  try {
    await api(`/church/${churchID}/days/${day}/service-time/${serviceID}`, 'put', serviceData);
    await sendNotfication('church_service_times_updated', { churchId: churchID });
    dispatch({
      type: UPDATE_CHURCH_SERVICE_TIME,
      payload: { day, serviceID, serviceData },
    });
  } catch (error) {
    logger.error(error);
  }
};

export const deleteServiceTimeRequest = (churchID, day, serviceID) => async dispatch => {
  try {
    await api(`/church/${churchID}/days/${day}/service-time/${serviceID}`, 'delete');
    await sendNotfication('church_service_times_updated', { churchId: churchID });

    dispatch({
      type: REMOVE_CHURCH_SERVICE_TIME,
      payload: { day, serviceID },
    });
  } catch (error) {
    logger.error(error);
  }
};
