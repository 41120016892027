import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbar } from 'components';
import EventsListItem from './EventsListItem';

function EventsList({ events, groups }) {
  return (
    <div className="card-block event-list">
      {!!events && !!events.length ? (
        <Scrollbar autoHeight autoHeightMin={100} autoHeightMax={720}>
          {events.map(event => (
            <EventsListItem
              key={event.id}
              event={event}
              group={groups.find(g => !!g && g.id === event.groupID)}
            />
          ))}
        </Scrollbar>
      ) : (
        <h2 style={{ textAlign: 'center' }}>There are no upcoming events</h2>
      )}
    </div>
  );
}

EventsList.defaultProps = {
  groups: [],
};

EventsList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
};

export default EventsList;
