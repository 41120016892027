import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Hub, Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { Loading } from 'components';
import amplifyConfigParams from 'amplify_auth_params';
import { publicRoutes } from 'routes';

class Login extends Component {
  componentDidMount() {
    const { location } = this.props;
    Auth.currentAuthenticatedUser()
      .then(cognitoUser => {
        // eslint-disable-next-line no-console
        console.log(cognitoUser.signInUserSession.idToken.jwtToken);
        const { email, email_verified: emailVerified } = cognitoUser.attributes;
        if (!email || !emailVerified) {
          Hub.dispatch('faithmo_auth', {
            event: 'signout',
            data: { error: 'Your email invalid or not verified.', email, emailVerified },
          });
          return;
        }
        const dataString = localStorage.getItem(`unverified_faithmo_signup_data[${email}]`);
        if (dataString)
          Hub.dispatch('faithmo_auth', {
            event: 'signup',
            data: { dataString, email },
          });
        else {
          let { from } = location.state || { from: '/dashboard' };
          from = publicRoutes.map(r => r.path).includes(from) ? '/dashboard' : from;
          Hub.dispatch('faithmo_auth', {
            event: 'signin',
            data: { from: '/login', to: from },
          });
        }
      })
      .catch(error => {
        Hub.dispatch('faithmo_auth', {
          event: 'signout',
          data: { error },
        });
      });
  }

  render() {
    return (
      <div className="app-loading-container">
        <Loading size={48} thickness={2} />
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withAuthenticator(withRouter(Login), amplifyConfigParams);
