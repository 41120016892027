import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import {
  DropDownMenu,
  Loading,
  RoundedTable,
  UserAvatar,
  Chip,
  SubscriberSerchBox,
  Tooltip,
  ExcelDownLoad,
} from 'components';
import { MEMBER_TYPES } from 'config/roles';
import { logger } from 'service';
import { fetchUsersRequest } from 'redux/actions/UsersActions';
import { fetchGroupsRequest } from 'redux/actions/GroupActions';
import UserManagementImage from 'assets/images/Usermanagement.png';
import UnlinkImg from 'assets/images/Disconnected_User.png';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import './style.css';

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeValue: 'none',
      groupValue: 'none',
      users: [],
      searchResult: [],
      groups: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch, history, location, isAdmin, churchID } = this.props;

    if (!isAdmin) {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }

    this.setState({ isLoading: true, users: [], groups: [], typeValue: 'none', groupValue: 'none' }, () => {
      Promise.all([dispatch(fetchUsersRequest(churchID)), dispatch(fetchGroupsRequest(churchID))])
        .then(([users, groups]) => {
          this.setState({ searchResult: users, users, groups, isLoading: false });
        })
        .catch(errors => {
          logger.warn('componentDidMount on User Management', errors);
          this.setState({ searchResult: [], users: [], groups: [], isLoading: false });
        });
    });
  }

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }
  };

  handleGetSearchResult = result => {
    this.setState({ searchResult: result });
  };

  handleUploadButtonClick = () => {
    const { history, location } = this.props;

    history.push('/dashboard/users/upload', { from: location.pathname });
  };

  render() {
    const { typeValue, groupValue, searchResult, users, groups, isLoading } = this.state;

    const renderUserMemberType = (memberType, isUnlinkedUser = false) => {
      const mType = MEMBER_TYPES.find(type => type.value === memberType);
      const mStatus = mType ? mType.label : 'Unknown';
      return (
        <>
          {mStatus}
          {isUnlinkedUser && (
            <Tooltip title="No FaithMo account is connected to this profile" placement="bottom-end">
              <IconButton style={{ position: 'absolute', right: 0 }}>
                <img src={UnlinkImg} alt="Disconnected User" width={12} />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    };

    return (
      <>
        <Helmet title="User management" />
        <div className="user-management card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={UserManagementImage} alt="User Management" />
                <p>User Management</p>
              </div>
              <div className="title-buttons">
                <button
                  type="button"
                  className="button neutral border--red bg-white medium"
                  onClick={this.handleUploadButtonClick}
                >
                  Upload Users
                </button>
                <ExcelDownLoad
                  table="user-table-to-xls"
                  filename="subscribers"
                  sheet="subscribers"
                  buttonText="Export To Excel"
                  className="button gradient medium"
                  disabled={isLoading}
                />
                {!isLoading && (
                  <table hidden className="table table-bordered" id="user-table-to-xls">
                    <thead>
                      <tr>
                        <th>Subscriber</th>
                        <th>Email</th>
                        <th>Group</th>
                        <th>Member</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResult
                        .filter(
                          item =>
                            (groupValue === 'none' || item.groups.some(gIndex => groupValue === gIndex)) &&
                            (typeValue === 'none' ||
                              (typeValue === 'unlinked' && item.unlinked) ||
                              typeValue === item.profile.member.type),
                        )
                        .map(userItem => (
                          <tr key={userItem.id}>
                            <td>{`${userItem.profile.firstName} ${userItem.profile.lastName}`}</td>
                            <td>{userItem.email}</td>
                            <td>
                              {userItem.groups
                                .map(ug => groups.find(g => g.id === ug))
                                .filter(g => !!g)
                                .map((g, i) =>
                                  i === userItem.groups.length - 1 ? g.title : `${g.title},\n`,
                                )}
                            </td>
                            <td>{renderUserMemberType(userItem.profile.member.type)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="col-sm-12 col-md-5 col-lg-4">
                  <SubscriberSerchBox
                    onSearchClick={this.handleGetSearchResult}
                    onSelect={user => this.handleGetSearchResult([user])}
                    onChange={this.handleGetSearchResult}
                    data={users}
                  />
                </div>
                <div className="col-sm-12 col-md-7 col-lg-8">
                  <div className="filter-box">
                    <div className="row">
                      <div className="col-0 col-sm-0 col-md-0 col-lg-4" />
                      <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <DropDownMenu
                          value={groupValue}
                          onChange={event => this.setState({ groupValue: event.target.value })}
                          menuArray={[
                            { label: 'Filter by group', value: 'none' },
                            ...groups.map(g => ({ label: g.title, value: g.id })),
                          ]}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <DropDownMenu
                          value={typeValue}
                          onChange={event => this.setState({ typeValue: event.target.value })}
                          menuArray={[{ label: 'Filter by member type', value: 'none' }, ...MEMBER_TYPES]}
                          menuPosition="right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div
                  style={{
                    position: 'relative',
                    minHeight: '60px',
                    minWidth: '60px',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <RoundedTable
                  showPagenate
                  minWidth={1060}
                  data={searchResult
                    .filter(
                      item =>
                        (groupValue === 'none' || item.groups.some(gIndex => groupValue === gIndex)) &&
                        (typeValue === 'none' ||
                          (typeValue === 'unlinked' && item.unlinked) ||
                          typeValue === item.profile.member.type),
                    )
                    .map(item => [
                      <UserAvatar user={item} className="align-left" />,
                      <div>{item.email}</div>,
                      <Chip
                        data={item.groups
                          .map(ug => groups.find(g => g.id === ug))
                          .filter(g => !!g)
                          .map(g => g.title)}
                      />,
                      <div className="table-member">
                        {renderUserMemberType(item.profile.member.type, item.unlinked)}
                      </div>,
                    ])}
                  headings={[
                    { component: <div className="align-left">Subscriber</div>, percentWidth: 30 },
                    { component: <div>Email</div>, percentWidth: 30 },
                    {
                      component: <div>Groups</div>,
                      percentWidth: 25,
                    },
                    { component: <div>Member</div>, percentWidth: 15 },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

UserManagement.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  churchID: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

// Retrieve data from store as props
const mapStateToProps = ({ church, auth: { as } }) => ({
  churchID: church.id,
  isAdmin: as === 'admin',
});

export default withRouter(connect(mapStateToProps)(UserManagement));
