import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { COUNTRIES, STATES } from 'config/supported_address';
import { BootstrapSelect } from 'components';
import PhoneInput from '../../../components/PhoneInput';

const zipcodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

const ContactInfo = ({ contacts, editable, isEdit, onChangeInput, errors }) => (
  <div className="church-contact-info">
    <h4 className="section-heading">Contact Information</h4>
    <div className="church-contact-info-detail">
      <div className="form-group church-contact-info-phone">
        <label htmlFor="church-phone" className="form-label w-fixed-label">
          Phone:
        </label>
        <div className="row">
          {isEdit ? (
            <div className="col-md-12">
              <PhoneInput
                type="text"
                className="form-control"
                placeholder="###-###-####"
                disabled={!editable}
                value={contacts.phoneNumber || ''}
                onChange={e => onChangeInput('phoneNumber', e.target.value)}
                id="church-phone"
              />
              <span className="helper-text error">{errors.phoneNumber}</span>
            </div>
          ) : (
            <div className="col-md-12">
              <span
                className={`form-text ${contacts.phoneNumber ? '' : 'empty-text'} d-inline-block w-auto`}
              >
                {contacts.phoneNumber || <span style={{ paddingLeft: 24 }}>None</span>}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="form-group church-contact-info-address">
        <label htmlFor="church-address" className="form-label w-fixed-label">
          Address:
        </label>
        <div className="row" id="church-address">
          <div className="col-md-12 contact-info-item">
            {isEdit ? (
              <input
                className="form-control streetAddr1"
                type="text"
                placeholder="Street Address Line 01"
                disabled={!editable}
                value={contacts.address.line1 || ''}
                onChange={e => onChangeInput('line1', e.target.value)}
                id="church-address-street1"
              />
            ) : (
              <span className={`form-text ${contacts.address.line1 ? '' : 'empty-text'}`}>
                {contacts.address.line1 || <span style={{ paddingLeft: 24 }}>None</span>}
              </span>
            )}
            <span className="helper-text error">{errors.line1}</span>
          </div>
          {((isEdit && editable) || contacts.address.line2) && (
            <div className="col-md-12 contact-info-item">
              {isEdit ? (
                <input
                  className="form-control streetAddr2"
                  type="text"
                  placeholder="Street Address Line 02"
                  disabled={!editable}
                  value={contacts.address.line2 || ''}
                  onChange={e => onChangeInput('line2', e.target.value)}
                  id="church-address-street2"
                />
              ) : (
                <span className={`form-text ${contacts.address.line2 ? '' : 'empty-text'}`}>
                  {contacts.address.line2 || <span style={{ paddingLeft: 24 }}>None</span>}
                </span>
              )}
              <span className="helper-text error">{errors.line2}</span>
            </div>
          )}
          <div className="col-md-5 contact-info-item">
            {isEdit ? (
              <input
                className="form-control"
                type="text"
                placeholder="City"
                disabled={!editable}
                value={contacts.address.city || ''}
                onChange={e => onChangeInput('city', e.target.value)}
                id="church-address-city"
              />
            ) : (
              <span className={`form-text ${contacts.address.city ? '' : 'empty-text'}`}>
                {contacts.address.city || <span style={{ paddingLeft: 24 }}>None</span>}
              </span>
            )}
            <span className="helper-text error">{errors.city}</span>
          </div>
          <div className="col-md-7 contact-info-item">
            {isEdit ? (
              <BootstrapSelect
                className="contact-info-state"
                disabled={!editable}
                items={(STATES || []).map(item => ({
                  label: item.name,
                  value: item.abbreviation,
                }))}
                value={contacts.address.state || ''}
                onChange={v => onChangeInput('state', v)}
                placeHolder="State"
              />
            ) : (
              <span className={`form-text ${contacts.address.state ? '' : 'empty-text'}`}>
                {
                  (
                    (STATES || []).find(item => item.abbreviation === contacts.address.state) || {
                      name: <span style={{ paddingLeft: 24 }}>None</span>,
                    }
                  ).name
                }
              </span>
            )}
            <span className="helper-text error">{errors.state}</span>
          </div>
          <div className="col-md-5 contact-info-item">
            {isEdit ? (
              <MaskedInput
                className="form-control"
                type="text"
                placeholder="Zip"
                disabled={!editable}
                value={contacts.address.zip || ''}
                onChange={e => onChangeInput('zip', e.target.value)}
                id="church-address-zip"
                placeholderChar={'\u2000'}
                mask={zipcodeMask}
              />
            ) : (
              <span className={`form-text ${contacts.address.zip ? '' : 'empty-text'}`}>
                {contacts.address.zip || <span style={{ paddingLeft: 24 }}>None</span>}
              </span>
            )}
            <span className="helper-text error">{errors.zip}</span>
          </div>
          <div className="col-md-7 contact-info-item">
            {isEdit ? (
              <BootstrapSelect
                className="contact-info-country"
                disabled={!editable}
                items={(COUNTRIES || []).map(item => ({
                  label: item.country,
                  value: item.code,
                }))}
                value={contacts.address.country}
                onChange={v => onChangeInput('country', v)}
                placeHolder="Country"
              />
            ) : (
              <span className={`form-text ${contacts.address.country ? '' : 'empty-text'}`}>
                {
                  (
                    (COUNTRIES || []).find(item => item.code === contacts.address.country) || {
                      country: <span style={{ paddingLeft: 24 }}>None</span>,
                    }
                  ).country
                }
              </span>
            )}
            <span className="helper-text error">{errors.country}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ContactInfo.propTypes = {
  contacts: PropTypes.shape({
    phoneNumber: PropTypes.string,
    phoneSubNumber: PropTypes.string,
    phoneAreaCode: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      zip: PropTypes.string,
    }),
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ContactInfo;
