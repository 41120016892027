const validate = (values, isLeaderRequired = true) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Group name is required';
  } else if (/^[\s\S]{0,3}$/.test(values.title)) {
    errors.title = 'The Number of Group name characters is less than 4.';
  } else if (/^[\s\S]{39,}$/.test(values.title)) {
    errors.title = 'The Number of Group name characters is greater than 38.';
  }

  if (isLeaderRequired) {
    if (!values.leader) {
      errors.leader = 'Group leader is required';
    } else if (!/^u[0-9a-z]{32}$/.test(values.leader)) {
      errors.leader = 'Group leader is invalid';
    }
  }

  return errors;
};

export default validate;
