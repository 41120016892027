import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Avatar, CollapsibleParagraph, Tooltip } from 'components';
import { utils } from 'service';
import { ReactComponent as LockIcon } from 'assets/images/Lock.svg';
import { ReactComponent as UnLockIcon } from 'assets/images/UnLock.svg';
import { UserTable, EventsList, NotificationList } from './components';

class GroupDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'members',
    };
    this.groupDetailTabs = [
      { label: 'Group users', value: 'members' },
      { label: 'Notifications', value: 'notifications' },
      { label: 'Events', value: 'group_events' },
    ];
  }

  handleTabChange = value => {
    const { editable } = this.props;
    let tab = value;
    if (!editable && tab === 'notifications') {
      // Only group admin or leader can view notifications.
      tab = 'members';
    }
    this.setState({ selectedTab: tab });
  };

  render() {
    const { selectedTab } = this.state;
    const {
      group,
      churchName,
      members,
      users,
      isLoadingMembers,
      onClickAddMember,
      onChangeMemberStatus,
      events,
      isLoadingEvents,
      onCreateEvent,
      notifications,
      isLoadingNotifications,
      onReadNotification,
      onNewNotification,
      onRemoveNotification,
      editable,
    } = this.props;

    const newNotificationCount =
      notifications && notifications.length
        ? notifications.reduce((cnt, item) => (!item.isRead ? cnt + 1 : cnt), 0)
        : 0;

    const renderContent = selected => {
      if (selected === 'notifications' && editable) {
        return (
          <NotificationList
            notifications={notifications}
            users={users}
            isLoading={isLoadingNotifications}
            onRead={onReadNotification}
            onNew={onNewNotification}
            onRemove={onRemoveNotification}
            editable={editable}
          />
        );
      }
      if (selected === 'group_events') {
        return (
          <EventsList
            groupID={group.id}
            events={events}
            onCreate={onCreateEvent}
            isLoading={isLoadingEvents}
            editable={editable}
          />
        );
      }
      // if (selected === 'members') or else
      return (
        <UserTable
          onClickAddMember={onClickAddMember}
          onChangeMemberStatus={onChangeMemberStatus}
          members={members}
          isLoading={isLoadingMembers}
          churchName={churchName}
          groupName={group.title}
          editable={editable}
        />
      );
    };

    return (
      <div className="group-detail">
        <div className="group-status">
          {group.isPrivate ? (
            <Tooltip title="Private Group">
              <IconButton style={{ padding: 0 }}>
                <LockIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Public Group">
              <IconButton style={{ padding: 0 }}>
                <UnLockIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Avatar
          type="group"
          width={160}
          height={160}
          src={group.avatar}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
        <div className="group-section">
          <h4 className="group-details-title">{group.title || ''}</h4>
          {!!group.desc && <CollapsibleParagraph lines={4}>{group.desc}</CollapsibleParagraph>}
          <p className="group-details-created">Date Created: {utils.formatValue(group.created, 'date')}</p>
        </div>
        <div className="card-block">
          <div className="card-tabs">
            <ul className="nav nav-tabs">
              {this.groupDetailTabs
                .filter(tab => editable || tab.value !== 'notifications') // Only group admin or leader can view notifications.
                .map(tab => (
                  <GroupDetailTab
                    key={tab.value}
                    tabName={
                      tab.value === 'notifications' ? `${tab.label} (${newNotificationCount})` : tab.label
                    }
                    isSelected={tab.value === selectedTab}
                    onSelected={() => this.handleTabChange(tab.value)}
                  />
                ))}
            </ul>
            <div className="tab-content">{renderContent(selectedTab)}</div>
          </div>
        </div>
      </div>
    );
  }
}

const GroupDetailTab = React.memo(({ onSelected, tabName, isSelected }) => (
  <li className={`nav-item ${isSelected ? 'active' : ''}`}>
    <div role="button" className="nav-link" onClick={onSelected} tabIndex={0}>
      <span>{tabName}</span>
    </div>
  </li>
));

GroupDetailTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

GroupDetails.propTypes = {
  churchName: PropTypes.string.isRequired,
  isLoadingMembers: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingNotifications: PropTypes.bool.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  group: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    members: PropTypes.array.isRequired,
    churchID: PropTypes.string.isRequired,
    created: PropTypes.number.isRequired,
    desc: PropTypes.string,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  onClickAddMember: PropTypes.func.isRequired,
  onChangeMemberStatus: PropTypes.func.isRequired,
  onReadNotification: PropTypes.func.isRequired,
  onNewNotification: PropTypes.func.isRequired,
  onRemoveNotification: PropTypes.func.isRequired,
  onCreateEvent: PropTypes.func.isRequired,
};

export default GroupDetails;
