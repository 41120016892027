import React from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from './DropDownMenu';
import { phoneNumberFormatter } from '../utils';

const linkTypeArray = [
  { label: 'No Link', value: 'no_link', color: '#31c5c3' },

  { label: 'Phone Conference', value: 'phone_conference', color: '#31c5c3' },
  { label: 'Web Link', value: 'web_link', color: '#31c5c3' },
  { label: 'Live Video Link', value: 'live_video_link', color: '#31c5c3' },
  { label: 'Video Conference Link', value: 'video_conference_link', color: '#31c5c3' },
];

const linkObjShapes = {
  no_link: {},
  phone_conference: {
    phone_number: {
      placeholder: 'Phone Number',
      type: 'tel',
      required: true,
    },
    passcode: {
      placeholder: 'Passcode',
      type: 'numeric',
      required: false,
    },
  },
  web_link: {
    link_path: {
      placeholder: 'Link Path',
      type: 'url',
      required: true,
    },
  },
  video_conference_link: {
    link_path: {
      placeholder: 'Link Path',
      type: 'url',
      required: true,
    },
    meeting_id: {
      placeholder: 'Meeting ID',
      type: 'url',
      required: false,
    },
    meeting_password: {
      placeholder: 'Meeting Password',
      type: 'text',
      required: false,
    },
    phone_number: {
      placeholder: 'Phone Number',
      type: 'tel',
      required: false,
    },
    password: {
      placeholder: 'Password',
      type: 'text',
      required: false,
    },
  },
  live_video_link: {
    link_path: {
      placeholder: 'Link Path',
      type: 'url',
      required: true,
    },
  },
};
const classNameGenerator = (type, key) => {
  let className = 'form-group';
  if (type === 'video_conference_link') {
    if (key === 'phone_number' || key === 'password') {
      className = [className, 'link-form-group-video-conf-phone'].join(' ');
      if (key === 'phone_number')
        className = [className, 'link-form-group-video-conf-phone-number'].join(' ');
    } else {
      className = [className, 'link-form-group-video-conf'].join(' ');
    }
  } else {
    className = [className, 'link-form-group'].join(' ');
  }
  return className;
};
const VirtualLinkField = ({ handleLinkPropertyChange, handleLinkTypeChange, errors, link }) => {
  const linkType = link && Object.keys(link).length ? Object.entries(link)[0][0] : 'no_link';
  const [selectedLinkType, setSelectedLinkType] = React.useState(linkType);
  const [linkShape, setLinkShape] = React.useState(linkObjShapes[linkType]);
  // const linkTypeChangeHandler = (value) => {
  //   setSelectedLinkType()
  // };
  const onLinkTypeChange = value => {
    setSelectedLinkType(value);
    setLinkShape(linkObjShapes[value]);
    handleLinkTypeChange(value, linkObjShapes[value]);
  };
  return (
    // <div className="item-content">
    <div className="item-group edit">
      <DropDownMenu
        value={selectedLinkType}
        onChange={e => onLinkTypeChange(e.target.value)}
        menuArray={linkTypeArray}
      />
      <div className="item-value" id="link-value">
        {[...Object.entries(linkShape)].map(([fieldName, fieldMeta], i) => (
          <div className={classNameGenerator(selectedLinkType, fieldName)} key={fieldName}>
            <div className="input-group">
              <input
                className={`form-control ${!fieldMeta.required ? 'appended-input' : ''}`}
                placeholder={fieldMeta.placeholder}
                maxLength={fieldName === 'phone_number' ? 12 : 100}
                type={fieldMeta.type}
                required={fieldMeta.required}
                value={
                  link[selectedLinkType][fieldName] && fieldName === 'phone_number'
                    ? phoneNumberFormatter(link[selectedLinkType][fieldName] || '')
                    : link[selectedLinkType][fieldName] || ''
                }
                onChange={e =>
                  handleLinkPropertyChange(
                    fieldName,
                    fieldName === 'phone_number' ? e.target.value.replace(/-/g, '') : e.target.value,
                  )
                }
              />
              {!fieldMeta.required && (
                <div className="input-group-append">
                  <span className="input-group-text custom-append-text">optional</span>
                </div>
              )}
            </div>
            <span className="helper-text error">{errors && errors[fieldName]}</span>
          </div>
        ))}
      </div>
    </div>
    // </div>
  );
};
VirtualLinkField.propTypes = {
  errors: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  handleLinkPropertyChange: PropTypes.func.isRequired,
  handleLinkTypeChange: PropTypes.func.isRequired,
};

export default VirtualLinkField;
