import {
  RECEIVE_USERS,
  RECEIVE_USER,
  UPDATE_USER,
  REQUEST_USERS,
  UPDATE_USER_IMG,
} from 'redux/actions/UsersActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';

const initialState = { data: [], isLoading: false };

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USERS:
      return {
        ...state,
        isLoading: true,
      };

    case RECEIVE_USERS: {
      const { users } = action.payload;
      return {
        ...state,
        data: users || state.data,
        isLoading: false,
      };
    }

    case RECEIVE_USER:
    case UPDATE_USER: {
      const { id, user } = action.payload;
      return {
        ...state,
        data: state.data.map(item => (item.id === id ? { ...item, ...user } : item)),
      };
    }

    case UPDATE_USER_IMG: {
      const { userID, avatar } = action.payload;
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id === userID) {
            return { ...item, avatar };
          }
          return item;
        }),
      };
    }

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default UsersReducer;
