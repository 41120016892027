import {
  DATE_REGEXP,
  EMAIL_REGEXP,
  PHONE_REGEXP,
  STREET_ADDRESS_REGEXP,
  ZIP_CODE_REGEXP,
} from 'config/regexrs';
import { STATES } from 'config/supported_address';

const validate = (values, emailDuplicateStatus) => {
  const errors = {};

  if (!values['profile.firstName']) {
    errors['profile.firstName'] = 'Missing';
  }
  if (!values['profile.lastName']) {
    errors['profile.lastName'] = 'Missing';
  }
  if (values.email) {
    if (!EMAIL_REGEXP.test(values.email)) {
      errors.email = 'Incorrect Format';
    } else if (emailDuplicateStatus === 'duplicated_f') {
      errors.email = 'Duplicate Email - email already exists on your upload form';
    } else if (emailDuplicateStatus) {
      // emailStatus === 'duplicated_p'
      errors.email = 'Duplicate Email - email is already assigned to an existing user';
    }
  }
  if (values['contacts.cellPhone'] && !PHONE_REGEXP.test(values['contacts.cellPhone'])) {
    errors['contacts.cellPhone'] = 'Phone number structure should be ###-###-####';
  }
  if (values['contacts.homePhone'] && !PHONE_REGEXP.test(values['contacts.homePhone'])) {
    errors['contacts.homePhone'] = 'Phone number structure should be ###-###-####';
  }
  if (
    values.church_member &&
    values.church_member.toUpperCase() !== 'N' &&
    values.church_member.toUpperCase() !== 'Y'
  ) {
    errors.church_member = "Invalid character (Must be 'Y' or 'N')";
  }
  if (
    values.honored_member &&
    values.honored_member.toUpperCase() !== 'N' &&
    values.honored_member.toUpperCase() !== 'Y'
  ) {
    errors.honored_member = "Invalid character (Must be 'Y' or 'N')";
  }
  if (
    values.church_member &&
    values.honored_member &&
    values.church_member.toUpperCase() === 'Y' &&
    values.honored_member.toUpperCase() === 'Y' &&
    !values['profile.member.position']
  ) {
    errors['profile.member.position'] = "Missing (this is required when Honored member is set to 'Y')";
  }
  if (values['profile.member.joinedDate']) {
    if (DATE_REGEXP.test(values['profile.member.joinedDate'])) {
      const [month, date, year] = values['profile.member.joinedDate'].split('/');
      const temp = new Date(year, parseInt(month, 10) - 1, date);
      if (
        temp.getDate().toString().padStart(2, '0') !== date ||
        (temp.getMonth() + 1).toString().padStart(2, '0') !== month ||
        temp.getFullYear().toString().padStart(4, '0') !== year
      ) {
        errors['profile.member.joinedDate'] = 'Invalid Date Format. Date format should be MM/DD/YYYY';
      }
    } else {
      errors['profile.member.joinedDate'] = 'Invalid Date Format. Date format should be MM/DD/YYYY';
    }
  }
  if (
    values['contacts.address.address'] &&
    !STREET_ADDRESS_REGEXP.test(values['contacts.address.address'])
  ) {
    errors['contacts.address.address'] = 'Bad street address structure';
  }
  if (
    values['contacts.address.state'] &&
    !STATES.find(s => s.abbreviation === values['contacts.address.state'].toUpperCase())
  ) {
    errors['contacts.address.state'] = 'Not valid 2 digit state code';
  }
  if (values['contacts.address.zip'] && !ZIP_CODE_REGEXP.test(values['contacts.address.zip'])) {
    errors['contacts.address.zip'] = 'Invalid zip code structure';
  }
  return errors;
};

export default validate;
