import React from 'react';
import {
  Greetings,
  ConfirmSignIn,
  RequireNewPassword,
  ConfirmSignUp,
  VerifyContact,
  ForgotPassword,
  TOTPSetup,
} from 'aws-amplify-react';
import { FaithmoSignIn, FaithmoAuthLoading } from 'components';
import { AMPLIFY_CONFIG } from 'config/amplify';

export default {
  includeGreetings: false,
  authenticatorComponents: [
    <Greetings />,
    <FaithmoSignIn />,
    <ConfirmSignIn />,
    <RequireNewPassword />,
    <ConfirmSignUp />,
    <VerifyContact />,
    <ForgotPassword />,
    <TOTPSetup />,
    <FaithmoAuthLoading />,
  ],
  federated: null,
  theme: {
    container: {
      backgroundImage: 'linear-gradient(180deg, #feb37d, #feb37d 0, #ff8867 51%, #feb37d)',
      height: '100%',
      width: '100%',
      position: 'fixed',
      overflow: 'auto',
    },
  },
  ...AMPLIFY_CONFIG,
};
