import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const InputBox = React.memo(({ className, maxLength, onChange, error, ...rest }) => {
  const [length, setLength] = useState(0);
  const [helperText, sethelperText] = useState({ text: '', error: false });

  useEffect(() => {
    if (maxLength > 0) {
      const text = `Characters Left: ${maxLength - length}`;
      sethelperText({ text, error: false });
    }
  }, [length, maxLength]);

  useEffect(() => {
    sethelperText({ text: error, error: true });
  }, [error]);

  const handleChange = event => {
    const len = event.target.value ? event.target.value.length : 0;
    setLength(len);
    if (maxLength > 0 && len >= maxLength) return;
    if (onChange && typeof onChange === 'function') onChange(event);
  };

  return (
    <div className={clsx('input-box', className)}>
      <input className="form-control" onChange={handleChange} {...rest} />
      <span className={`helper-text ${helperText.error ? 'error' : ''}`}>{helperText.text}</span>
    </div>
  );
});

InputBox.defaultProps = {
  maxLength: -1,
  error: '',
  className: undefined,
  onChange: undefined,
};

InputBox.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

export default InputBox;
