import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserPopup from 'components/UserPopup';
import Avatar from 'components/Avatar';

import './style.css';

const UserAvatar = React.memo(({ displayName, user, className, ...props }) => {
  const [isOpenPrayerModal, setOpenPrayer] = useState(false);
  const [isShowPosition, setPositionVisible] = useState(false);

  return (
    <>
      <div
        {...props}
        role="button"
        className={className ? `${className} user-avatar-container` : 'user-avatar-container'}
        onClick={e => {
          e.stopPropagation();
          setOpenPrayer(true);
          setPositionVisible(false);
        }}
        tabIndex={0}
      >
        <Avatar
          size="small"
          src={user.avatar}
          badge={user.profile.member.type === 'honored'}
          onBadgeClick={() => {
            setOpenPrayer(true);
            setPositionVisible(true);
          }}
        />
        {displayName && <span>{`${user.profile.firstName} ${user.profile.lastName}`}</span>}
      </div>
      <UserPopup
        open={isOpenPrayerModal}
        onClose={() => {
          setOpenPrayer(false);
        }}
        handleStarClick={() => {
          setPositionVisible(true);
        }}
        isShowPosition={isShowPosition}
        user={user}
        handlePositionBack={() => {
          setPositionVisible(false);
        }}
      />
    </>
  );
});

UserAvatar.defaultProps = {
  user: {
    profile: {
      firstName: 'first',
      lastName: 'last',
      member: {
        type: 'honored',
      },
    },
    avatar: 'test',
  },
  displayName: true,
  className: undefined,
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    profile: PropTypes.object.isRequired,
    avatar: PropTypes.string,
  }),
  displayName: PropTypes.bool,
  className: PropTypes.string,
};

export default UserAvatar;
