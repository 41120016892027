import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { Link, withRouter } from 'react-router-dom';
import StyledModal from '../../components/StyledModal';
import './style.css';
import Menubar from 'components/Menubar';
import rightImage from 'assets/images/wepik-export-202305240358222afB.png';
import CheckIcon from 'assets/images/check.svg';
import RoundCheckIcon from 'assets/images/roundCheck.svg';
import { ASSETS_CDN } from '../../config/assets';

export default function Pricing({ location }) {
  const [isAnnual, setIsAnnual] = useState(true);
  const [openChurch, setOpenChurch] = useState(false);
  const onClickOption = annual => {
    setIsAnnual(annual);
  };
  return (
    <div className="home">
      <div className="pricing">
        <div className="pricing-background" />
        <div className="header">
          <Menubar isLight={true} />
        </div>
        <div className="pricing-container">
          <div className="pricing-container-header">
            <h1>Choose the Plan</h1>
            <div className="pricing-option">
              <button
                onClick={() => onClickOption(true)}
                style={{ background: isAnnual ? '#fff' : 'transparent' }}
              >
                {isAnnual && <img src={RoundCheckIcon} alt="RoundCheckIcon" />}
                <span style={{ color: isAnnual ? '#000' : '#fff' }}>Annual</span>
              </button>
              <button
                onClick={() => onClickOption(false)}
                style={{ background: !isAnnual ? '#fff' : 'transparent' }}
              >
                {!isAnnual && <img src={RoundCheckIcon} alt="RoundCheckIcon" />}
                <span style={{ color: !isAnnual ? '#000' : '#fff' }}>Monthly</span>
              </button>
            </div>
          </div>
          <div className="pricing-content">
            <div className="pricing-content-left">
              <div className="price-header">
                <div className="price-title">
                  <span className="dollar">$</span>
                  <span className="number">{isAnnual ? '299' : '30'}</span>
                  <span className="option">{isAnnual ? 'Annually ($60 savings!)' : 'Monthly'}</span>
                </div>
                <span>{isAnnual ? '2.65% + 30¢ per transaction *' : '2.65% + 30¢ per transaction *'}</span>
                <button onClick={() => setOpenChurch(true)}>Get started</button>
              </div>
              <div className="price-features">
                <div className="features-row">
                  <img src={CheckIcon} alt="CheckIcon" />
                  <span>Giving management</span>
                </div>
                <div className="features-row">
                  <img src={CheckIcon} alt="CheckIcon" />
                  <span>Event/Services Management</span>
                </div>
                <div className="features-row">
                  <img src={CheckIcon} alt="CheckIcon" />
                  <span>Group Management</span>
                </div>
                <div className="features-row">
                  <img src={CheckIcon} alt="CheckIcon" />
                  <span>Messaging/Notifications</span>
                </div>
                <div className="features-row">
                  <img src={CheckIcon} alt="CheckIcon" />
                  <span>Prayer Requests Management</span>
                </div>
                <div className="features-row">
                  <img src={CheckIcon} alt="CheckIcon" />
                  <span>And much more...</span>
                </div>
              </div>
              <span className="bottom-label">
                <em>
                  *Verification of 501c3 status required to obtain the 2.65% rate. Otherwise, rate is 2.9%
                </em>
              </span>
            </div>
            <div className="pricing-content-right">
              <img src={rightImage} alt="rightImage" />
            </div>
          </div>
        </div>
      </div>
      <StyledModal
        open={openChurch}
        onClose={() => setOpenChurch(false)}
        maxWidth="md"
        fullWidth
        className="header-signup-modal"
        style={{
          minHeight: 372,
        }}
        padding={0}
      >
        <div className="layer--dark" />
        <h6 className="header-title" style={{ zIndex: 1 }}>
          Let&apos;s sign you up! Please complete all 3 steps.
        </h6>
        <Link
          className="button white-font"
          style={{ zIndex: 1, color: 'white !important' }}
          to={{
            pathname: '/signup',
            state: {
              isUserForm: false,
              from: location.pathname,
            },
          }}
        >
          Get started
        </Link>
      </StyledModal>
      <Footer />
    </div>
  );
}
