import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { Scrollbar, SwitchCheckBox } from 'components';

const GiftOption = React.memo(({ data, onItemClick, onChangeActive, itemsLoadingStatus }) => (
  <div className="gift-option">
    <div className="card transparent-card border-card">
      <div className="card-header">
        <div className="title">
          <p>Gift Option</p>
        </div>
        <div className="right-title">
          <p>Active Status</p>
        </div>
      </div>
      <div className="card-block">
        <Scrollbar
          autoHeight
          autoHeightMin={252}
          autoHeightMax={422}
          verticalBarStyle={{
            height: 'calc(100% - 30px)',
            marginBottom: '30px',
          }}
          horizontalBarStyle={{
            width: 'calc(100% - 60px)',
            marginLeft: '30px',
            marginRight: '30px',
          }}
        >
          <div className="go_active_wrapper">
            <div className="go-detail">
              <ul>
                {data.map(item => (
                  <li key={item.id}>
                    <div
                      role="button"
                      onClick={() => onItemClick(item.id)}
                      tabIndex={0}
                      style={{ width: '100%', height: '100%', userSelect: 'none' }}
                    >
                      <span>{item.title}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="go-as">
              <ul>
                {data.map(item => (
                  <li key={item.id}>
                    {itemsLoadingStatus[item.id] ? (
                      <CircularProgress size="sm" />
                    ) : (
                      <SwitchCheckBox
                        type="ios"
                        checked={!!item.status}
                        onChange={event => onChangeActive(item.id, event.target.checked)}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  </div>
));

GiftOption.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      churchID: PropTypes.string.isRequired,
      created: PropTypes.number.isRequired,
      productID: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  itemsLoadingStatus: PropTypes.object.isRequired,
};

export default GiftOption;
