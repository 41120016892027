export const CSV_HEADERS = {
  'First Name': { key: 'profile.firstName', length: 10 },
  'Last Name': { key: 'profile.lastName', length: 10 },
  'Email Address': { key: 'email', length: 15 },
  ID: { key: 'profile.ID', length: 10 },
  Occupation: { key: 'profile.occupation', length: 10 },
  'Cell Phone Number': { key: 'contacts.cellPhone', length: 15 },
  'Home Phone Number': { key: 'contacts.homePhone', length: 15 },
  'Street Address': { key: 'contacts.address.address', length: 20 },
  City: { key: 'contacts.address.city', length: 10 },
  'State (2 Digit Code)': { key: 'contacts.address.state', length: 15 },
  Zip: { key: 'contacts.address.zip', length: 10 },
  'Church Member (Y or N)': { key: 'church_member', length: 20 },
  'Member Joined Date': { key: 'profile.member.joinedDate', length: 20 },
  'Honored Member (Y or N)': { key: 'honored_member', length: 20 },
  'Honored Member Position': { key: 'profile.member.position', length: 25 },
  'Honored Member Position Description': { key: 'profile.member.description', length: 30 },
  'Notes (Admin Visible Only)': { key: 'profile.notes', length: 25 },
};

export const CSV_OPTIONS = {
  skipEmptyLines: true,
};

export const transformUserTableHeader = header => {
  const th = header
    .toLowerCase()
    .replace(/ \([\w\W]+\)/g, '')
    .replace(/\W/g, '_');
  return CSV_HEADERS[header] ? CSV_HEADERS[header].key : { key: th, length: header.length };
};
