import React, { Component, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Logo from 'assets/images/faithmo_blue_logo.png';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Drawer, makeStyles } from '@material-ui/core';
import burgerIcon from 'assets/images/burger.svg';
import CloseIcon from '@material-ui/icons/Close';
import StyledModal from 'components/StyledModal';
import { ASSETS_CDN } from 'config/assets';
const useStyles = makeStyles({
  paper: {
    width: '60vw',
    background: 'rgb(3,129,179)',
    color: 'white',
    padding: '20px 10px',
  },
});
export default function Menubar({ isLight }) {
  const location = useLocation();
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [openChurch, setOpenChurch] = useState(false);
  const classes = useStyles();
  return (
    <div className="header-content-top">
      <img className="logo" src={Logo} alt="Logo" />
      <div className="header-content-top-right">
        <a
          id="link"
          href="/#about_target"
          className={isLight ? 'medium menu-button-light' : 'medium menu-button'}
        >
          About
        </a>
        <a
          id="link"
          href="/#feature_target"
          className={isLight ? 'medium menu-button-light' : 'medium menu-button'}
        >
          Features
        </a>
        <Link
          className={isLight ? 'medium menu-button-light' : 'medium menu-button'}
          to={{
            pathname: '/pricing',
            state: { from: location.pathname },
          }}
          style={{ color: isLight ? '#fff !important' : 'rgb(3, 129, 179)' }}
        >
          Pricing
        </Link>
        <a
          className={isLight ? 'medium menu-button-light' : 'medium menu-button'}
          href="mailto:info@faithmo.com"
        >
          Contact Us
        </a>
        <Link
          className="button medium login-btn"
          to={{
            pathname: '/login',
            state: { from: location.pathname },
          }}
        >
          Login
        </Link>
      </div>
      <div
        className="header-burger-menu"
        onClick={() => {
          console.log('click');
          setIsBurgerOpen(true);
        }}
      >
        <img src={burgerIcon} alt="burgerIcon" width={'38px'} height={'38px'} />
      </div>
      <Drawer
        anchor={'right'}
        open={isBurgerOpen}
        onClose={() => setIsBurgerOpen(false)}
        classes={{ paper: classes.paper }}
        transitionDuration={0}
      >
        <div className="drawer-menu-closeBtn">
          <div onClick={() => setIsBurgerOpen(false)}>
            <CloseIcon style={{ fill: 'white' }} />
          </div>
        </div>
        <div className="drawer-menu-items-container">
          <div className="drawer-menu-items-top">
            <Link
              className="drawer-menu-item drawer-menu-item-login"
              to={{
                pathname: '/login',
                state: { from: location.pathname },
              }}
            >
              Login
            </Link>
            <a
              id="link"
              href={isLight ? '/#about_target' : '#about_target'}
              className="drawer-menu-item"
              onClick={() => setIsBurgerOpen(false)}
            >
              About
            </a>
            <a
              id="link"
              href={isLight ? '/#feature_target' : '#feature_target'}
              className="drawer-menu-item"
              onClick={() => setIsBurgerOpen(false)}
            >
              Features
            </a>
            <Link
              className="drawer-menu-item"
              to={{
                pathname: '/pricing',
                state: { from: location.pathname },
              }}
            >
              Pricing
            </Link>
            <a className="drawer-menu-item" href="mailto:info@faithmo.com">
              Contact Us
            </a>
          </div>
          <div className="drawer-menu-get-started" onClick={() => setOpenChurch(true)}>
            Get Started
          </div>
        </div>
      </Drawer>
      <StyledModal
        open={openChurch}
        onClose={() => setOpenChurch(false)}
        maxWidth="md"
        fullWidth
        className="header-signup-modal"
        style={{
          minHeight: 372,
        }}
        padding={0}
      >
        <div className="layer--dark" />
        <h6 className="header-title" style={{ zIndex: 1 }}>
          Let&apos;s sign you up! Please complete all 3 steps.
        </h6>
        <Link
          className="button white-font"
          style={{ zIndex: 1 }}
          to={{
            pathname: '/signup',
            state: {
              isUserForm: false,
              from: location.pathname,
            },
          }}
        >
          Get started
        </Link>
      </StyledModal>
    </div>
  );
}
