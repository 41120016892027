import Image1 from 'assets/images/wepik-export-20230616003121mpHF.png';
import Image2 from 'assets/images/wepik-export-20230616013843bc0T.png';
import Image3 from 'assets/images/texticon.png';
import Image4 from 'assets/images/wepik-export-20230616024946Wg83.png';
import Image5 from 'assets/images/slideImg5.png';

// info, error, warning, success
export const SLIDE_CONTENTS = [
  {
    index: 1,
    content:
      'Gather donations conveniently using our mobile app or website. Our platform incorporates top-tier secure payment collection methods to guarantee swift and secure transactions for every donation.',
    image: Image1,
    title: 'Giving Mangement',
    background: 'linear-gradient(90deg, #31C5C3 54.42708730697632%, #2987C3 100%)',
  },
  {
    index: 2,
    content:
      'Whether your community uses small-groups or ministries, our group management functionality will keep your members involved and informed. ',
    image: Image2,
    title: 'Group Mangement',
    background: 'linear-gradient(90deg, #2987C1 66.14583730697632%, #2C6FC0 100%)',
  },
  {
    index: 3,
    title: 'Messaging / Notifications',
    content: `Deliver instant push notifications to your members' mobile devices within seconds. Utilizing the mobile app enables a seamless and ongoing communication channel, whether it's for brief announcements or an entire thread.`,
    image: Image3,
    background: 'linear-gradient(90deg, #2C6FC0 62.6495361328125%, #AFABC0 100%)',
  },
  {
    index: 4,
    content: `Centralize all your community events in a single location. Whether it's a group gathering or a churchwide occasion, gain a comprehensive overview of upcoming events, ranging from the next day to years ahead.`,
    image: Image4,
    title: 'Event Management',
    background: 'linear-gradient(90deg, #AFABC0 62.7593994140625%, #EFC8BD 100%)',
  },
  {
    index: 5,
    content:
      'To accommodate members seeking to submit prayer requests during their times of need, our mobile app offers a secure and convenient way to do so.',
    image: Image5,
    title: 'Payer Requests',
    background: 'linear-gradient(90deg, #EFC8BD 25.40130615234375%, #FF9576 58.612060546875%)',
  },
];

export default { SLIDE_CONTENTS };
