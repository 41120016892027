import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loading, Scrollbar } from 'components';
import StyledModal from 'components/StyledModal';
import { EventCreateForm } from 'components/EventsForm';
import EventsListItem from 'pages/Events/EventsListItem';
import { utils } from 'service';

const EventsList = React.memo(({ groupID, events, editable, onCreate, isLoading, history, location }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleCreateNew = () => {
    history.push('/dashboard/event/new', { edit: true, groupID, from: location.pathname });
  };

  if (isLoading)
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '60px',
          minWidth: '60px',
        }}
      >
        <Loading />
      </div>
    );

  return (
    <div className="events-content events">
      {editable && (
        <div className="d-flex align-items-center justify-content-center m-3">
          <button type="button" className="button gradient medium" onClick={() => setShowCreateModal(true)}>
            Create Event
          </button>
        </div>
      )}
      <div className="events-content-body">
        <Scrollbar autoHeight autoHeightMin={120} autoHeightMax={540}>
          {events
            .filter(
              event => utils.daysLeft(event.start) >= 0 || (event.end && utils.daysLeft(event.end) >= 0),
            )
            .map(item => (
              <EventsListItem key={item.id} event={item} />
            ))}
        </Scrollbar>
      </div>
      <StyledModal
        open={editable && showCreateModal}
        onClose={() => setShowCreateModal(false)}
        maxWidth="sm"
        style={{
          paddingTop: '60px !important',
          fontWeight: 'bold',
          color: '#3b3b3b',
          textAlign: 'center',
        }}
      >
        <button type="button" className="button gradient medium right-top" onClick={handleCreateNew}>
          Advanced Edit
        </button>
        <h3>Create Event</h3>
        <EventCreateForm
          onSubmit={values => onCreate({ ...values, groupID })}
          handleCancel={() => setShowCreateModal(false)}
        />
      </StyledModal>
    </div>
  );
});

EventsList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      start: PropTypes.number.isRequired,
    }),
  ).isRequired,
  editable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  groupID: PropTypes.string.isRequired,
};

export default withRouter(EventsList);
