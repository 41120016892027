import React from 'react';
import PropTypes from 'prop-types';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  left: '0',
  top: '0',
  height: '100%',
  width: '100%',
  position: 'absolute',
};

const GoogleMap = React.memo(({ google, center, markers }) => (
  <Map google={google} zoom={12} style={mapStyles} initialCenter={center}>
    {/* eslint-disable-next-line react/no-array-index-key */}
    {markers && markers.length && markers.map((item, index) => <Marker key={index} position={item} />)}
  </Map>
));

GoogleMap.propTypes = {
  google: PropTypes.object.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(GoogleMap);
