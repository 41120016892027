import React, { useEffect, useState } from 'react';
import AppStoreBtn from 'assets/images/appstore.png';
import GoogleStoreBtn from 'assets/images/googleplay.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Typography } from '@material-ui/core';
import TrademarkFooter from '../../components/TrademarkFooter';
import { EMAIL_REGEXP } from 'config/regexrs';
import { SES } from 'service/aws';

const Footer = () => {
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const onChange = e => {
    setEmail(e.target.value);
  };
  const onBlur = () => {
    if (email === '' || !EMAIL_REGEXP.test(email)) {
      setIsValid(true);
      setStatus('Please submit a valid email.');
    } else {
      setIsValid(false);
      setStatus('');
    }
  };

  const onSubmit = () => {
    setStatus('');
    if (email === '' || !EMAIL_REGEXP.test(email)) {
      setIsValid(true);
      setStatus('Please submit a valid email.');
      return;
    }
    var params = {
      Destination: {
        ToAddresses: [
          'support@faithmo.com',
          /* more items */
        ],
      },
      Message: {
        /* required */
        Body: {
          /* required */
          Text: {
            Charset: 'UTF-8',
            Data: `Please respond to ${email} to schedule the demo.`,
          },
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'New Demo Requested',
        },
      },
      Source: `noreply@faithmo.com` /* required */,
    };
    SES.sendEmail(params)
      .promise()
      .then(() => {
        setEmail('');
        setStatus('Thanks! One of our team members will be reaching out to you soon!');
      })
      .catch(e => {
        setEmail('');
        setIsValid(true);
        setStatus('There was an error with your submission. Please validate the email and try again.');
        console.log('fail', e.stack);
      });
  };

  return (
    <div className="footer">
      <div className="row flex-lg-row footer-top">
        <div className="row flex-column-reverse flex-lg-row divider">
          <span className="divider-label">Get a Demo</span>
        </div>
        <div className="col d-flex flex-column flex-lg-row align-items-center justify-content-between footer-bottom">
          <div className="social-media d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <div className="FaithmoAuth__badges">
              <a
                href="https://apps.apple.com/lk/app/faithmo/id1247722295"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppStoreBtn} alt="Google Play" className="FaithmoAuth__badge" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.churchbutton.faithmo&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GoogleStoreBtn} alt="Apple App Store" className="FaithmoAuth__badge" />
              </a>
            </div>
          </div>
          <ul>
            <li>
              <a id="link" href="/#about_target">
                About
              </a>
            </li>
            <li>
              <a id="link" href="/#feature_target">
                Features
              </a>
            </li>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
            <li>
              <a href="mailto:info@faithmo.com">Contact Us</a>
            </li>
          </ul>
          <div className="demo-content-container">
            <div
              className="demo-content"
              style={{
                border: isValid ? '1px solid rgb(255,64,64)' : 'none',
                background: isValid ? 'rgba(255,64,64, 0.07)' : 'rgba(255,255,255, 0.6)',
              }}
            >
              <input
                placeholder="Best email to reach you at"
                onChange={onChange}
                onBlur={onBlur}
                value={email}
              />
              <button onClick={onSubmit}>Submit</button>
              {/* <a href="mailto:info@faithmo.com?subject=New%20Demo%20Requested&body=Please%20respond%20to%20info@faithmo.com%20to%20schedule%20the%20demo.">Submit</a> */}
            </div>
            <span
              className="status-label"
              style={{
                visibility: status !== '' ? 'visible' : 'hidden',
                color: isValid ? 'rgb(255,64,64)' : '#3271c0',
              }}
            >
              {status}
            </span>
          </div>
        </div>
      </div>
      <TrademarkFooter />
    </div>
  );
};

export default Footer;
