import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BASE64_IMAGE_REGEXP } from 'config/regexrs';
import { ROLES } from 'config/roles';
import { DropDownMenu, Loading } from 'components';
import {
  createGroupMemberRequest,
  createGroupRequest,
  deleteGroupAvatarRequest,
  deleteGroupMemberRequest,
  deleteGroupRequest,
  fetchGroupEventsRequest,
  fetchGroupMembersRequest,
  fetchGroupRequest,
  updateGroupAvatarRequest,
  updateGroupMemberRequest,
  updateGroupRequest,
} from 'redux/actions/GroupActions';
import { createEventRequest } from 'redux/actions/EventActions';
import {
  markNotificationAsRead,
  newNotificationReceived,
  removeNotification,
} from 'redux/actions/NotificationActions';
import groupImage from 'assets/images/Usermanagement.png';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GroupEdit from './GroupEdit';
import GroupCreate from './GroupCreate';
import GroupDetails from './GroupDetails';
import AddMember from './AddMember';

import './style.css';
import { createNotificationObj, sendNotfication } from '../../utils';
class GroupDetailIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      groupMenuArray: [],
      isEdit: null,
      isLoaded: false,
      members: [],
      isLoadedMembers: false,
      isAddMemberOpen: false,
      events: [],
      isLoadedEvents: false,
      editable: false,
    };

    this.groupEditRef = React.createRef();
  }

  componentDidMount() {
    this.initialLoad();
    this.loadGroupEvents();
    this.updateMenu();
  }

  componentDidUpdate(prevProps) {
    const { groupID, groups } = this.props;
    if (prevProps.groupID !== groupID) {
      this.initialLoad();
      this.loadGroupEvents();
    }
    if (JSON.stringify(prevProps.groups) !== JSON.stringify(groups)) this.updateMenu();
  }

  initialLoad = () => {
    const { groupID, history, location, authUserPermissions, isAdmin, authUserID, dispatch } = this.props;

    const isGroupManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GROUP_ADMIN.value);

    if ((groupID === 'new' || (location.state && location.state.edit)) && !isGroupManager) {
      history.replace('/dashboard/groups', { from: location.state ? location.state.from : undefined });
      return;
    }

    if (groupID === 'new' && location.state && location.state.edit) {
      const group = {
        title: null,
        leader: null,
        desc: null,
        isPrivate: false,
      };
      this.setState({
        group,
        isLoaded: true,
        editable: true,
        isEdit: true,
      });
    } else {
      this.setState(
        {
          isLoaded: false,
          isEdit: !!location.state && location.state.edit,
          members: [],
          editable: false,
          isLoadedMembers: false,
        },
        () =>
          dispatch(fetchGroupRequest(groupID))
            .then(group => {
              let editable = isGroupManager;
              if (
                group.members.some(m => m.type === 'leader' && m.status === 1 && authUserID === m.userID)
              ) {
                editable = true;
              } else if (!isAdmin) {
                throw new Error('Not permitted');
              }

              this.setState(
                {
                  group,
                  editable,
                  isLoaded: true,
                },
                () => {
                  this.loadGroupMembers();
                },
              );
            })
            .catch(() => {
              history.replace('/dashboard/groups', {
                from: location.state ? location.state.from : undefined,
              });
            }),
      );
    }
  };

  loadGroupMembers = (refreshAll = true) => {
    const { groupID } = this.props;

    this.setState(
      refreshAll
        ? {
            members: [],
            isLoadedMembers: false,
          }
        : {},
      () => {
        fetchGroupMembersRequest(groupID).then(members => {
          this.setState({
            members,
            isLoadedMembers: true,
          });
        });
      },
    );
  };

  loadGroupEvents = (refreshAll = true) => {
    const { groupID } = this.props;

    if (groupID !== 'new')
      this.setState(
        refreshAll
          ? {
              events: [],
              isLoadedEvents: false,
            }
          : {},
        () => {
          fetchGroupEventsRequest(groupID).then(events => {
            this.setState({
              events,
              isLoadedEvents: true,
            });
          });
        },
      );
  };

  updateMenu = () => {
    const { groups } = this.props;
    if (groups.isLoading) return;

    this.setState({
      groupMenuArray: groups.data.map(item => ({ value: item.id, label: item.title })),
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard/groups', { from: location.state ? location.state.from : undefined });
    }
  };

  handleSaveClick = () => {
    if (this.groupEditRef.current) {
      this.groupEditRef.current.handleSave();
    }
  };

  handleSaveGroup = (updatedGroup, avatarImg, members) => {
    const { dispatch, history, location, groupID, churchID } = this.props;
    const { group } = this.state;

    this.setState({ isLoaded: false }, async () => {
      if (groupID === 'new' && !group.id) {
        const { id } = await dispatch(createGroupRequest(updatedGroup, churchID));
        if (BASE64_IMAGE_REGEXP.test(avatarImg)) {
          await dispatch(updateGroupAvatarRequest(id, avatarImg));
        }
        history.replace(`/dashboard/group/${id}`, {
          from: location.state ? location.state.from : undefined,
        });
      } else if (group && group.id) {
        await dispatch(updateGroupRequest(group.id, updatedGroup));
        if (group.avatar !== avatarImg) {
          if (avatarImg === null) {
            await dispatch(deleteGroupAvatarRequest(group.id, avatarImg));
          } else if (BASE64_IMAGE_REGEXP.test(avatarImg)) {
            await dispatch(updateGroupAvatarRequest(group.id, avatarImg));
          }
        }
        if (members && members.length) {
          await Promise.all(
            members.map(async item => {
              if (item.deleted) {
                await dispatch(deleteGroupMemberRequest(group.id, item.userID));
              } else if (item.changedType) {
                await dispatch(
                  updateGroupMemberRequest(churchID, group.id, item.userID, { type: item.changedType }),
                );
              }
            }),
          );
        }
        this.initialLoad();
      }
    });
  };

  handleRemoveGroup = () => {
    const { dispatch, history, location, groupID } = this.props;
    const { group } = this.state;

    this.setState({ isLoaded: false }, async () => {
      if (groupID !== 'new' && group && group.id) {
        await dispatch(deleteGroupRequest(group.id));
      }
      history.replace('/dashboard/groups', { from: location.state ? location.state.from : undefined });
    });
  };

  handleEditCancelClick = () => {
    const { groupID, history, location } = this.props;
    this.setState({ isEdit: false }, () => {
      if (groupID === 'new')
        history.replace('/dashboard/groups', { from: location.state ? location.state.from : undefined });
    });
  };

  handleEditClick = () => {
    this.setState({ isEdit: true });
  };

  handleArchiveGroup = async isPrivate => {
    const { dispatch } = this.props;
    const { group } = this.state;
    dispatch(updateGroupRequest(group.id, { isPrivate })).then(() => {
      this.setState({ group: { ...group, isPrivate } });
    });
  };

  handleGroupSelect = groupID => {
    const { history, location } = this.props;
    const { isEdit } = this.state;
    history.replace(`/dashboard/group/${groupID}`, {
      edit: isEdit,
      from: location.state ? location.state.from : undefined,
    });
  };

  handleAddMembers = userIDs => {
    const { dispatch, groupID, churchID } = this.props;
    this.setState({ isAddMemberOpen: false, isLoadedMembers: false }, () => {
      const promises = userIDs.map(async userID => {
        await dispatch(createGroupMemberRequest(groupID, userID));
        await dispatch(updateGroupMemberRequest(churchID, groupID, userID, { status: 1 }));
      });
      Promise.all(promises).then(() => {
        this.loadGroupMembers();
      });
    });
  };

  handleUpdateMemberStatus = (userID, status) => {
    const { dispatch, groupID, churchID } = this.props;
    this.setState({ isAddMemberOpen: false }, () => {
      dispatch(updateGroupMemberRequest(churchID, groupID, userID, { status })).then(() => {
        this.loadGroupMembers(false);
      });
    });
  };

  handleReadNotification = notification => {
    const { dispatch } = this.props;
    dispatch(markNotificationAsRead(notification.id));
  };

  handleNewNotification = async notification => {
    const { churchID, authUserID, groupID, dispatch } = this.props;
    const nft = createNotificationObj(authUserID, notification, groupID);
    await sendNotfication('user_group_notification', {
      id: nft.id,
      groupID: nft.to,
      userID: nft.from,
      subject: nft.subject,
      message: nft.message,
      churchID: churchID,
    });
    await dispatch(newNotificationReceived(nft));
  };

  handleRemoveNotification = notification => {
    const { dispatch } = this.props;
    dispatch(removeNotification(notification.id));
  };

  handleCreateEvent = async values => {
    const { churchID, dispatch, history, location } = this.props;
    const { id } = await dispatch(createEventRequest(values, churchID));
    history.push(`/dashboard/event/${id}`, { from: location.pathname });
  };

  render() {
    const {
      group,
      isEdit,
      isLoaded,
      members,
      groupMenuArray,
      isLoadedMembers,
      isAddMemberOpen,
      events,
      isLoadedEvents,
      editable,
    } = this.state;
    const { groupID, users, groups, churchName, authUserPermissions, notifications } = this.props;

    const isGroupManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.GROUP_ADMIN.value);

    const renderContent = () => {
      if (editable && isEdit && groupID === 'new')
        return <GroupCreate onSave={this.handleSaveGroup} users={users} />;
      if (editable && isEdit)
        return (
          <GroupEdit
            ref={this.groupEditRef}
            group={group}
            members={members}
            isLoadingMembers={!isLoadedMembers}
            onRemove={this.handleRemoveGroup}
            onSave={this.handleSaveGroup}
            deletable={isGroupManager}
          />
        );
      return (
        <GroupDetails
          churchName={churchName}
          group={group}
          editable={editable}
          // onChangeStatus={this.handleArchiveGroup}
          members={members}
          isLoadingMembers={!isLoadedMembers}
          onClickAddMember={() => this.setState({ isAddMemberOpen: true })}
          onChangeMemberStatus={this.handleUpdateMemberStatus}
          events={events}
          users={users.data}
          isLoadingEvents={!isLoadedEvents}
          onCreateEvent={this.handleCreateEvent}
          notifications={notifications.data.filter(
            // || item.to === 'groupID' should remove but just ignore now for the test
            // item => item.type === 'group' && (item.to === groupID || item.to === 'groupID'),
            item => item.type === 'group' && item.groupID === group.id,
          )}
          isLoadingNotifications={notifications.isLoading || users.isLoading}
          onReadNotification={this.handleReadNotification}
          onNewNotification={this.handleNewNotification}
          onRemoveNotification={this.handleRemoveNotification}
        />
      );
    };

    return (
      <>
        <Helmet title="Group details" />
        <div className="group card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          {isAddMemberOpen && editable ? (
            <AddMember
              groups={groups.data}
              members={members}
              users={users.data}
              onCancel={() => this.setState({ isAddMemberOpen: false })}
              onAdd={this.handleAddMembers}
              isLoading={users.isLoading || groups.isLoading || !isLoaded || !isLoadedMembers}
            />
          ) : (
            <div className="card">
              <div className="card-header">
                <div className="title">
                  <img src={groupImage} alt="Group" />
                  {isEdit && groupID === 'new' && <p>Create New Group</p>}
                  {isLoaded && groupID !== 'new' && (
                    <DropDownMenu
                      value={group.id}
                      onChange={e => this.handleGroupSelect(e.target.value)}
                      menuArray={groupMenuArray}
                      style={{ margin: '-2px', marginLeft: '12px' }}
                    />
                  )}
                </div>
                {editable && !isEdit && (
                  <div className="title-buttons">
                    <button type="button" className="button gradient medium" onClick={this.handleEditClick}>
                      Edit Group
                    </button>
                  </div>
                )}
                {editable && isEdit && groupID !== 'new' && (
                  <div className="title-buttons">
                    <button
                      type="button"
                      className="button neutral border--red bg-white medium"
                      onClick={this.handleEditCancelClick}
                    >
                      Cancel
                    </button>
                    <button type="button" className="button gradient medium" onClick={this.handleSaveClick}>
                      Update
                    </button>
                  </div>
                )}
              </div>
              <div className="card-block">
                {isLoaded ? (
                  renderContent()
                ) : (
                  <div style={{ position: 'relative', height: 150 }}>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

GroupDetailIndex.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  groupID: PropTypes.string.isRequired,
  churchID: PropTypes.string.isRequired,
  authUserID: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  churchName: PropTypes.string.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  groups: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        avatar: PropTypes.string,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  notifications: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object.isRequired),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, auth: { user, as }, groups, users, notifications }, props) => ({
  authUserPermissions: church.admins[user.id],
  isAdmin: as === 'admin',
  authUserID: user.id,
  groupID: props.match.params.groupId,
  churchID: church.id,
  churchName: church.title,
  notifications,
  groups,
  users,
});

export default withRouter(connect(mapStateToProps)(GroupDetailIndex));
