/* eslint-disable import/order */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROLES } from 'config/roles';
import { DropDownMenu } from 'components';
import {
  createServiceDayRequest,
  createServiceTimeRequest,
  deleteServiceTimeRequest,
  updateServiceTimeRequest,
} from 'redux/actions/ChurchActions';
import DayServices from './DayServices';

import './ServiceTimes.css';

class ServiceTimes extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      day: 'all',
      daysData: {},
    };
    this.days = [
      { value: 'all', label: 'All Days' },
      { value: 'sunday', label: 'Sunday' },
      { value: 'monday', label: 'Monday' },
      { value: 'tuesday', label: 'Tuesday' },
      { value: 'wednesday', label: 'Wednesday' },
      { value: 'thursday', label: 'Thursday' },
      { value: 'friday', label: 'Friday' },
      { value: 'saturday', label: 'Saturday' },
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    const { daysData } = this.props;
    if (this._isMounted) this.setState({ daysData });
  }

  handleChangeDay = event => this.setState({ day: event.target.value });

  handleCreated = async (day, data) => {
    const { daysData } = this.state;
    const { churchID, dispatch } = this.props;

    if (!daysData[day]) {
      const { serviceTimes } = await dispatch(createServiceDayRequest(churchID, day, day, [data]));
      if (!serviceTimes) return;
      this.setState({
        daysData: {
          ...daysData,
          [day]: {
            title: day,
            serviceTimes,
          },
        },
      });
    } else {
      const { serviceItem } = await dispatch(createServiceTimeRequest(churchID, day, data));
      if (!serviceItem) return;
      this.setState({
        daysData: {
          ...daysData,
          [day]: {
            ...daysData[day],
            serviceTimes: [...daysData[day].serviceTimes, serviceItem],
          },
        },
      });
    }
  };

  handleEdited = async (day, index, data) => {
    const { daysData } = this.state;
    const { churchID, dispatch } = this.props;

    await dispatch(updateServiceTimeRequest(churchID, day, daysData[day].serviceTimes[index].id, data));
    const update = { ...daysData[day] };
    update.serviceTimes.splice(index, 1, data);
    this.setState({
      daysData: {
        ...daysData,
        [day]: update,
      },
    });
  };

  handleRemoved = async (day, index) => {
    const { daysData } = this.state;
    const { churchID, dispatch } = this.props;

    await dispatch(deleteServiceTimeRequest(churchID, day, daysData[day].serviceTimes[index].id));
    const update = { ...daysData[day] };
    update.serviceTimes.splice(index, 1);
    this.setState({
      daysData: {
        ...daysData,
        [day]: update,
      },
    });
  };

  render() {
    const { authUserPermissions } = this.props;
    const { daysData, day } = this.state;
    const initialValue = { title: 'sample', serviceTimes: [] };

    const isServicetimeEditor =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.SERVICETIMES_EDITOR.value);

    return (
      <div className="service-times-wrapper">
        <div className="st-dropdown">
          <span className="st-show">Show:</span>
          <DropDownMenu value={day} onChange={this.handleChangeDay} menuArray={this.days} />
        </div>
        {this.days
          .slice(1)
          // .filter(d => d.value !== 'all')
          .map(d => (
            <DayServices
              key={d.value}
              visible={d.value === day || day === 'all'}
              value={d.value}
              label={d.label}
              editable={isServicetimeEditor}
              onCreated={this.handleCreated}
              onEdited={this.handleEdited}
              onRemoved={this.handleRemoved}
              services={daysData[d.value] || initialValue}
            />
          ))}
      </div>
    );
  }
}

ServiceTimes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  daysData: PropTypes.object.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  churchID: PropTypes.string.isRequired,
};

const mapStateToProps = ({ church, auth: { user } }) => ({
  daysData: church.days,
  authUserPermissions: church.admins[user.id],
  churchID: church.id,
});

export default connect(mapStateToProps)(ServiceTimes);
