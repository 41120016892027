import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Loading, RoundedTable, ButtonGroupSelect, UserAvatar, ExcelDownLoad } from 'components';
import { utils } from 'service';
import { ReactComponent as StarIcon } from 'assets/images/star.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const UserTable = React.memo(
  ({ members, editable, isLoading, groupName, churchName, onClickAddMember, onChangeMemberStatus }) => {
    const [filterValue, setFilter] = useState('all_members');

    if (isLoading)
      return (
        <div
          style={{
            position: 'relative',
            minHeight: '60px',
            minWidth: '60px',
          }}
        >
          <Loading />
        </div>
      );

    const renderStatus = user => {
      if (user.member.status === 1) return 'Active';
      if (user.member.status === 0) return 'Inactive';
      return (
        <>
          <IconButton
            onClick={() => onChangeMemberStatus(user.id, 0)}
            color="primary"
            style={{ padding: 0, margin: 12 }}
          >
            <CancelIcon style={{ color: '#fe9168' }} />
          </IconButton>
          <IconButton
            onClick={() => onChangeMemberStatus(user.id, 1)}
            color="primary"
            style={{ padding: 0, margin: 12 }}
          >
            <CheckCircleIcon style={{ color: '#fe9168' }} />
          </IconButton>
        </>
      );
    };

    const pendingCnt = members.filter(u => u.member && u.member.status === null).length;
    const tabs = [
      { label: 'All members', value: 'all_members' },
      { label: `Pending (${pendingCnt})`, value: null },
    ];

    return (
      <div className="members-content">
        {editable && (
          <ButtonGroupSelect
            data={tabs}
            onSelect={setFilter}
            className="d-flex align-items-center justify-content-center"
          />
        )}
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mt-3">
          {editable && (
            <button type="button" className="button gradient medium mb-3" onClick={onClickAddMember}>
              Add member
            </button>
          )}
          <ExcelDownLoad
            table="members-table-to-xls"
            filename={`${groupName} - Members`}
            sheet="members"
            buttonText="Export To Excel"
            className="button gradient medium mb-3"
            disabled={isLoading}
          />
          {!isLoading && (
            <table hidden className="table table-bordered" id="members-table-to-xls">
              <thead>
                <tr>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th colSpan="4" />
                  <th colSpan="4">{churchName}</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th colSpan="4" />
                </tr>
                <tr>
                  <th colSpan="3">{groupName}</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Join Date</th>
                  <th>Leader</th>
                </tr>
              </thead>
              <tbody>
                {members
                  .filter(u => u.member && u.member.status === 1)
                  .map(userItem => (
                    <tr key={userItem.id}>
                      <td>{`${userItem.profile.firstName} ${userItem.profile.lastName}`}</td>
                      <td>{utils.formatValue(userItem.member.joinDate, 'date')}</td>
                      <td>{userItem.member.type === 'leader' ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <RoundedTable
          showPagenate
          minWidth={720}
          data={members
            .filter(
              u =>
                u.member &&
                ((filterValue === 'all_members' && u.member.status === 1) ||
                  u.member.status === filterValue),
            )
            .sort((a, b) => {
              if (a.member.type === 'leader') return -1;
              if (b.member.type === 'leader') return 1;
              return b.member.joinDate - a.member.joinDate;
            })
            .map(user => [
              <div>
                <StarIcon hidden={user.member.type !== 'leader'} style={{ color: '#f7ac02' }} />
              </div>,
              <UserAvatar user={user} className="align-left" />,
              <div>{utils.formatValue(user.member.joinDate, 'date')}</div>,
              <div>{renderStatus(user)}</div>,
            ])}
          headings={[
            { component: <div>Leader</div>, percentWidth: 15 },
            { component: <div className="align-left">Name</div>, percentWidth: 45 },
            {
              component: <div>Joining Date</div>,
              percentWidth: 20,
            },
            { component: <div>Status</div>, percentWidth: 20 },
          ]}
        />
      </div>
    );
  },
);

UserTable.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profile: PropTypes.object.isRequired,
      member: PropTypes.shape({
        type: PropTypes.string.isRequired,
        joinDate: PropTypes.number.isRequired,
        userID: PropTypes.string.isRequired,
        status: PropTypes.number,
      }).isRequired,
    }),
  ).isRequired,
  editable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  churchName: PropTypes.string.isRequired,
  onClickAddMember: PropTypes.func.isRequired,
  onChangeMemberStatus: PropTypes.func.isRequired,
};

export default UserTable;
