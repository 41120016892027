import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { CollapsibleParagraph, GoogleMap, Loading } from 'components';
import { utils } from 'service';
import eDateIcon from 'assets/images/DateIcon.png';
import eCoordIcon from 'assets/images/CoordinatorIcon.png';
import eGroupIcon from 'assets/images/GroupIcon.png';
import eTicketIcon from 'assets/images/TicketsIcon.png';
import ePriceIcon from 'assets/images/PriceIcon.png';
import eMapIcon from 'assets/images/MapIcon.png';
import { EventUserList as AttendeesList } from './components';

import './style.css';
import linkIcon from '../../assets/images/outline_live_tv_white_48dp.png';
import { phoneNumberFormatter, titleCase } from '../../utils';

const EventDetail = ({ data, groups, attendees, isLoadedAttendees }) => {
  const [linkType, linkValue] =
    data.link && Object.keys(data.link).length ? Object.entries(data.link)[0] : [null, {}];
  return (
    <>
      {!!data.avatar && (
        <div className="event-img">
          <img alt="Event" src={data.avatar} />
        </div>
      )}
      {!!data.desc && (
        <div className="event-section">
          <CollapsibleParagraph>{data.desc}</CollapsibleParagraph>
        </div>
      )}
      <div className="event-section">
        <h4 className="event-details-title">Event Details</h4>
        <Grid container spacing={5} alignItems="flex-start">
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Grid container direction="column" item xs={12} spacing={2}>
              <Grid item className="justify-content-start" xs={12}>
                <div className="event-attribute">
                  <img src={eDateIcon} alt="Date" className="item-icon" />
                  <div className="item-content">
                    {!!data.start && (
                      <div className="item-group">
                        <div className="item-label">Start:</div>
                        <div className="item-value">{utils.formatValue(data.start, 'datetime')}</div>
                      </div>
                    )}
                    {!!data.end && (
                      <div className="item-group">
                        <div className="item-label">End:</div>
                        <div className="item-value">{utils.formatValue(data.end, 'datetime')}</div>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid className="justify-content-start" item xs={12}>
                <div className="event-attribute">
                  <img src={eCoordIcon} alt="Coord" className="item-icon" />
                  <div className="item-content">
                    <div className="item-group">
                      <div className="item-label">Coordinator:</div>
                      <div className="item-value">{data.coordinator || ''}</div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid className="justify-content-start" item xs={12}>
                <div className="event-attribute">
                  <img src={ePriceIcon} alt="Price" className="item-icon" />
                  <div className="item-content">
                    <div className="item-group">
                      <div className="item-label">Price:</div>
                      <div className="item-value">
                        {typeof data.price === 'number' ? utils.formatValue(data.price, 'currency') : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* Left Column */}
          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Grid container direction="column" item xs={12} spacing={2}>
              <Grid item className="justify-content-start" xs={12}>
                <div className="event-attribute">
                  <img src={eGroupIcon} alt="Group" className="item-icon" />
                  <div className="item-content">
                    <div className="item-group">
                      <div className="item-label">Group:</div>
                      <div className="item-value">
                        {(groups.find(g => g.id === data.groupID) || { title: 'Churchwide' }).title}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item className="justify-content-start" xs={12}>
                <div className="event-attribute">
                  <img src={eTicketIcon} alt="Ticket" className="item-icon" />
                  <div className="item-content">
                    <div className="item-group">
                      <div className="item-label">Online Tickets:</div>
                      <div className="item-value">{data.onlineTickets === true ? 'Yes' : 'No'}</div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="item-link event-attribute">
                  <img src={linkIcon} className="item-icon link-icon" alt="icon" />
                  <div className="item-content">
                    <div className="item-group">
                      <div className="item-value d-flex flex-column ml-0">
                        <span className="mb-1">{(linkType && titleCase(linkType)) || 'No Link'}:</span>

                        {linkValue &&
                          [...Object.entries(linkValue)].map(
                            ([key, val]) =>
                              val && (
                                <div className="d-flex" key={key} style={{ gap: '1rem' }}>
                                  <span>{titleCase(key)}: </span>
                                  <span>
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {key === 'phone_number' ? (
                                      <a className="reg-link" href={`tel:${val}`}>
                                        {phoneNumberFormatter(val)}
                                      </a>
                                    ) : key === 'link_path' ? (
                                      <a
                                        className="reg-link"
                                        href={val.includes('http') ? val : `https://${val}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {val}
                                      </a>
                                    ) : (
                                      val
                                    )}
                                  </span>
                                </div>
                              ),
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* Right Column */}
        </Grid>
      </div>
      <div className="event-section">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className="event-attribute full-width">
              <img src={eMapIcon} alt="Map" className="item-icon" />
              <div className="item-content">
                <div className="item-group">
                  <div className="item-label">Location:</div>
                  <div className="item-value">{data.address || ''}</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="event-attribute full-width">
              <div className="item-content ml-venue">
                <div className="item-group">
                  <div className="item-label">Venue:</div>
                  <div className="item-value">{data.venue || ''}</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            {data.lat && data.lng && (
              <div className="map-responsive">
                <GoogleMap
                  center={{
                    lat: data.lat,
                    lng: data.lng,
                  }}
                  markers={[
                    {
                      lat: data.lat,
                      lng: data.lng,
                    },
                  ]}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="event-section">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={7}>
            <div className="event-attendees">
              <h4 className="event-details-title">Attendees ({data.rsvp ? data.rsvp.length : 0})</h4>
              {!isLoadedAttendees && (
                <div
                  style={{
                    position: 'relative',
                    minHeight: '60px',
                    minWidth: '60px',
                  }}
                >
                  <Loading />
                </div>
              )}
              {isLoadedAttendees &&
                (attendees && attendees.length ? (
                  <AttendeesList users={attendees} />
                ) : (
                  <div>No Attendees</div>
                ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

EventDetail.defaultProps = {
  attendees: [],
};

EventDetail.propTypes = {
  data: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  attendees: PropTypes.array,
  isLoadedAttendees: PropTypes.bool.isRequired,
};

export default EventDetail;
