import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SWIPER_CONFIG } from 'config/swiper';
import EventListItem from './EventListItem';

const EventList = React.memo(({ activeSlideIndex, events, onEdit, editable }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(activeSlideIndex);
    }
  }, [activeSlideIndex]);

  return (
    <Swiper {...SWIPER_CONFIG} ref={swiperRef}>
      {events.map(event => (
        <SwiperSlide key={event.id}>
          <EventListItem event={event} onEdit={() => onEdit(event)} editable={editable} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

EventList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  editable: PropTypes.bool.isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EventList;
