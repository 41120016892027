import {
  CONNECT_CHURCH_MERCHANT,
  CREATE_CHURCH_CUSTOMER,
  CREATE_CHURCH_GALLERY_IMG,
  CREATE_CHURCH_SERVICE_DAY,
  CREATE_CHURCH_SERVICE_TIME,
  RECEIVE_CHURCH_STATE,
  RECEIVE_CUSTOMER_STATE,
  RECEIVE_PLANS,
  REMOVE_CHURCH_ADMIN,
  REMOVE_CHURCH_GALLERY_IMG,
  REQUEST_CUSTOMER_STATE,
  UPDATE_CHURCH_ADMIN,
  UPDATE_CHURCH_AVATAR,
  UPDATE_CHURCH_STATE,
} from 'redux/actions/ChurchActions';
import { USER_LOGOUT_SUCCESS } from 'redux/actions/AuthActions';

const initialState = {
  stripe: {},
  gallery: [],
  customer: { isLoading: false },
  admins: {},
  customerID: null,
  days: {},
};

const ChurchReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHURCH_STATE:
      return {
        ...state,
        ...action.payload.church,
        customer: {
          data: { ...state.customer.data, ...action.payload.customer },
          isLoading: false,
        },
      };

    case CONNECT_CHURCH_MERCHANT: {
      const { stripe } = action.payload;
      return { ...state, stripe };
    }

    case CREATE_CHURCH_CUSTOMER: {
      const { customer } = action.payload;
      return {
        ...state,
        customer: { data: customer, isLoading: false },
        customerID: customer.id,
      };
    }

    case RECEIVE_CHURCH_STATE: {
      const { church } = action.payload;
      return { ...state, ...church };
    }

    case REQUEST_CUSTOMER_STATE:
      return { ...state, customer: { isLoading: true } };

    case RECEIVE_CUSTOMER_STATE: {
      const { customer: customerData } = action.payload;
      return { ...state, customer: { data: customerData, isLoading: false } };
    }

    case RECEIVE_PLANS: {
      const { prices } = action.payload;
      return { ...state, prices };
    }

    case UPDATE_CHURCH_AVATAR: {
      const { avatar } = action.payload;
      return { ...state, avatar };
    }

    case CREATE_CHURCH_GALLERY_IMG:
      return { ...state, gallery: [...state.gallery, action.payload.fullpath] };

    case REMOVE_CHURCH_GALLERY_IMG: {
      const { fullpath } = action.payload;
      const gallery = [...state.gallery];
      const index = gallery.findIndex(val => val === fullpath);
      if (index === -1) return state;
      gallery.splice(index, 1);
      return { ...state, gallery };
    }

    case CREATE_CHURCH_SERVICE_DAY: {
      const { day } = action.payload;
      return {
        ...state,
        days: {
          ...state.days,
          [day]: { ...action.payload },
        },
      };
    }
    case CREATE_CHURCH_SERVICE_TIME: {
      const { day, serviceItem } = action.payload;
      return {
        ...state,
        days: {
          ...state.days,
          [day]: {
            ...state.days[day],
            serviceTimes: [...state.days[day].serviceTimes, serviceItem],
          },
        },
      };
    }

    case UPDATE_CHURCH_ADMIN: {
      const { userID } = action.payload;
      return {
        ...state,
        admins: {
          ...state.admins,
          [userID]: {
            ...(state.admins[userID] ? state.admins[userID] : {}),
            permissions: action.payload.permissions,
            isSuperUser: action.payload.isSuperUser,
          },
        },
      };
    }

    case REMOVE_CHURCH_ADMIN: {
      const { admins } = state;
      delete admins[action.payload.userID];
      return {
        ...state,
        admins: { ...admins },
      };
    }

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default ChurchReducer;
