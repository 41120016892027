import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Grid from '@material-ui/core/Grid';

import { TextField } from 'components/MDFormControls';
import { Loading } from 'components';
import validate from './validate';

const UserSignupForm = ({ handleSubmit, isSubmitting }) => (
  <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field name="firstName" label="First Name" component={TextField} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field name="lastName" label="Last Name" component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field name="personalEmail" label="Personal Email Address" component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field name="password" type="password" label="Password" component={TextField} />
      </Grid>
      <Grid item xs={12}>
        <Field name="confirmPassword" type="password" label="Re-enter Password" component={TextField} />
      </Grid>
    </Grid>
    <button type="submit" className="button medium gradient button--signup-step" disabled={isSubmitting}>
      {isSubmitting ? <Loading size={24} loadingIconStyle={{ color: '#fff' }} /> : 'Submit'}
    </button>
  </form>
);

UserSignupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'RegisterUserForm',
  validate,
})(UserSignupForm);
