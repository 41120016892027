import React from 'react';
import PropTypes from 'prop-types';
import { Hub } from 'aws-amplify';

const Thanks = ({ hidden }) => (
  <div className="thanks" hidden={hidden}>
    <h3>Thanks! We just sent you a confirmation email.</h3>
    <div className="thx-text">
      <p>Just sign in to your account and enter your verification code!</p>
    </div>
    <button
      type="button"
      className="button gradient medium"
      onClick={() => {
        Hub.dispatch('faithmo_auth', {
          event: 'signout',
          data: { source: 'thanks page', error: 'just signed up to cognito. email is not verified' },
        });
      }}
    >
      OK
    </button>
  </div>
);

Thanks.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

export default Thanks;
