import React from 'react';
import PropTypes from 'prop-types';

import Facebook from 'assets/images/fackbook-logo.png';
import Twitter from 'assets/images/twitter-logo.png';

const SocialMedia = ({ editable, socialMedia, isEdit, onChangeInput, errors }) => (
  <div className="church-social-media">
    <h4 className="section-heading">Social Media</h4>
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-prepend form-label">
              <img src={Facebook} alt="Facebook" />
            </span>
            {isEdit ? (
              <input
                type="text"
                className="form-control"
                placeholder="EX: www.facebook.com/FaithMo"
                disabled={!editable}
                value={socialMedia.facebook || ''}
                onChange={e => onChangeInput('facebook', e.target.value)}
              />
            ) : (
              <span className={`form-text ${socialMedia.facebook ? '' : 'text-empty'}`}>
                {socialMedia.facebook || <span style={{ paddingLeft: 24 }}>None</span>}
              </span>
            )}
          </div>
          <span className="helper-text error">{errors.facebook}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-prepend form-label">
              <img src={Twitter} alt="Twitter" />
            </span>
            {isEdit ? (
              <input
                type="text"
                className="form-control"
                placeholder="EX: @FaithMo"
                disabled={!editable}
                value={socialMedia.twitter || ''}
                onChange={e => onChangeInput('twitter', e.target.value)}
              />
            ) : (
              <span className={`form-text ${socialMedia.twitter ? '' : 'text-empty'}`}>
                {socialMedia.twitter || <span style={{ paddingLeft: 24 }}>None</span>}
              </span>
            )}
          </div>
          <span className="helper-text error">{errors.twitter}</span>
        </div>
      </div>
    </div>
  </div>
);

SocialMedia.propTypes = {
  socialMedia: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
  }).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default SocialMedia;
