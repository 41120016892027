import { USER_LOGIN_SUCCESS, USER_LOGOUT_SUCCESS, AUTH_USER_UPDATED } from 'redux/actions/AuthActions';

const initialState = { cognitoUser: null, user: null };

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS: {
      const { cognitoUser, user, as } = action.payload;
      return { ...state, cognitoUser, user, as };
    }

    case AUTH_USER_UPDATED:
      return { ...state, user: action.payload.user };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default AuthReducer;
