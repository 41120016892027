import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RoundedTable, UserAvatar, Loading } from 'components';
import StyledModal from 'components/StyledModal';
import { deleteAdminRequest, updateAdminPermissionsRequest } from 'redux/actions/ChurchActions';
import AdminPanelDetailEdit from './AdminPanelDetailEdit';
import { sendNotfication } from '../../../../utils';

class AdminPanelDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      openDelete: false,
      selectedKey: null,
    };
  }

  handleRemove = async () => {
    const { church, dispatch } = this.props;
    const { selectedKey } = this.state;
    this.handleCloseDeleteModal();
    await dispatch(deleteAdminRequest(church.id, selectedKey));
    await sendNotfication('church_admin_removed', { churchId: church.id, userId: selectedKey });
  };

  handleOpenDeleteModal = key => {
    this.setState({ status: null, openDelete: true, selectedKey: key });
  };

  handleCloseDeleteModal = () => {
    this.setState({ status: null, openDelete: false, selectedKey: null });
  };

  handleSaveEdit = async (key, values) => {
    const { church, dispatch } = this.props;
    await dispatch(updateAdminPermissionsRequest(church.id, key, values));
    if (church.admins) {
      let flag = church.admins.hasOwnProperty(key);
      if (flag === true) {
        await sendNotfication('church_admin_updated', { churchId: church.id, userId: key, extra: values });
      } else {
        // create
        await sendNotfication('church_admin_created', { churchId: church.id, userId: key, extra: values });
      }
    }

    this.setState({ status: null, selectedKey: null });
  };

  handleCancelEdit = () => {
    this.setState({ status: null, selectedKey: null });
  };

  handleToggleAdd = () => {
    this.setState({ status: 'add', selectedKey: null });
  };

  handleToggleEdit = key => {
    this.setState({ status: 'edit', selectedKey: key });
  };

  render() {
    const { status, openDelete, selectedKey } = this.state;
    const {
      users,
      church: { admins },
      authUserID,
    } = this.props;

    const renderContent = () =>
      !status ? (
        <RoundedTable
          minWidth={960}
          data={Object.keys(admins)
            .map(adminKey =>
              users.data ? { data: users.data.find(user => user.id === adminKey), key: adminKey } : null,
            )
            .filter(data => !!data)
            .map(admin => [
              <UserAvatar user={admin.data} className="align-left" />,
              <div style={{ fontSize: '18px', lineHeight: '38px' }}>{admin.data.email}</div>,
              <div>{admin.data.contacts.cellPhone}</div>,
              <div>{admins[admin.key].isSuperUser ? <span>Super Admin</span> : <span>Admin</span>}</div>,
              <div>
                {admin.key !== authUserID && !admins[admin.key].email && (
                  <div>
                    <span
                      role="button"
                      className="table-action"
                      onClick={() => this.handleToggleEdit(admin.key)}
                      tabIndex={0}
                    >
                      Edit
                    </span>
                    <span
                      role="button"
                      className="table-action"
                      onClick={() => this.handleOpenDeleteModal(admin.key)}
                      tabIndex={0}
                    >
                      Delete
                    </span>
                  </div>
                )}
              </div>,
            ])}
          headings={[
            { component: <div className="align-left">Administrator Name</div>, percentWidth: 30 },
            { component: <div>Email</div>, percentWidth: 25 },
            {
              component: <div>Phone Number</div>,
              percentWidth: 20,
            },
            { component: <div>Role</div>, percentWidth: 15 },
            { component: <div>Action</div>, percentWidth: 10 },
          ]}
        />
      ) : (
        <AdminPanelDetailEdit
          status={status}
          users={users}
          admins={admins}
          editable={selectedKey === null || (selectedKey !== authUserID && !admins[selectedKey].email)}
          selectedKey={selectedKey}
          onSave={this.handleSaveEdit}
          onCancel={this.handleCancelEdit}
        />
      );

    return (
      <div className="admin-panel-edit card">
        <div className="card-header">
          <div className="title">
            <p>Admin Panel {status}</p>
          </div>
          {status === null && (
            <div className="title-buttons">
              <button type="button" className="button gradient medium" onClick={this.handleToggleAdd}>
                Add Admin
              </button>
            </div>
          )}
        </div>
        <div className="card-block">
          {users.isLoading ? (
            <div
              style={{
                position: 'relative',
                minHeight: '60px',
                minWidth: '60px',
              }}
            >
              <Loading />
            </div>
          ) : (
            renderContent()
          )}
        </div>
        <StyledModal
          open={openDelete}
          onClose={this.handleCloseDeleteModal}
          onSubmit={this.handleRemove}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          Are you sure you want to delete this user&apos;s administrator permission?
        </StyledModal>
      </div>
    );
  }
}

AdminPanelDetail.propTypes = {
  authUserID: PropTypes.string.isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        avatar: PropTypes.string,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  church: PropTypes.shape({
    id: PropTypes.string.isRequired,
    admins: PropTypes.objectOf(
      PropTypes.shape({
        email: PropTypes.string,
        isSuperUser: PropTypes.bool.isRequired,
        permissions: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ church, users, auth: { user } }) => ({
  church,
  users,
  authUserID: user.id,
});

export default connect(mapStateToProps)(AdminPanelDetail);
