import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, List } from '@material-ui/core';
import { Loading, NotificationItem } from 'components';
import StyledModal from 'components/StyledModal';
import { utils } from 'service';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';

const NotificationList = React.memo(({ notifications, users, onRemove, onNew, onRead, isLoading }) => {
  const [openRead, setOpenRead] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [curItem, setCurItem] = useState({});

  const handleOpenRead = notification => {
    setCurItem(notification);
    setOpenRead(true);
    onRead(notification);
  };

  const handleOpenNew = () => {
    setCurItem({});
    setOpenNew(true);
  };

  const handleCreateNotification = () => {
    setOpenNew(false);
    onNew(curItem);
  };

  const handleOpenRemove = notification => {
    setCurItem(notification);
    setOpenRemove(true);
  };

  const handleRemoveNotification = () => {
    setOpenRead(false);
    setOpenRemove(false);
    onRemove(curItem);
  };

  const handleChange = event => {
    setCurItem({ ...curItem, [event.target.name]: event.target.value });
  };

  return (
    <div className="notifications-content">
      <div className="d-flex align-items-center justify-content-center m-3">
        <button type="button" className="button gradient medium" onClick={handleOpenNew}>
          New Notifications
        </button>
      </div>
      <div className="notifications-content-body">
        {!isLoading ? (
          <div
            style={{
              position: 'relative',
              minHeight: '60px',
              minWidth: '60px',
            }}
          >
            <Loading />
          </div>
        ) : (
          <List style={{ width: '100%', maxWidth: '100%' }}>
            {notifications
              .map(item => ({
                notification: item,
                // || item.from === 'userID' should remove but just ignore now for the test
                // userFrom: users.find(u => u.id === item.from || item.from === 'userID'),
                userFrom: users.find(u => u.id === item.fromID || u.id === item.from),
                // userFrom: users[0],
              }))
              .filter(item => !!item.userFrom)
              .map(item => (
                <NotificationItem
                  key={item.notification.id}
                  isGroupNotification
                  user={item.userFrom}
                  item={item.notification}
                  onRemove={() => handleOpenRemove(item.notification)}
                  onSelect={() => handleOpenRead(item.notification)}
                />
              ))}
          </List>
        )}
      </div>

      <StyledModal
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        onSubmit={handleRemoveNotification}
        closeBtnLabel="No"
        submitBtnLabel="Yes"
      >
        Are you sure you want to delete permanently?
      </StyledModal>
      <StyledModal open={openRead} onClose={() => setOpenRead(false)}>
        <h5 className="font-weight-bold">{curItem.title || ''}</h5>
        <p className="text font-italic">
          {curItem.date ? utils.formatValue(curItem.date, 'datetime') : ''}
        </p>
        <p className="text">{curItem.message || ''}</p>
        <div className="text-right">
          <IconButton color="primary" onClick={() => handleOpenRemove(curItem)}>
            <TrashIcon />
          </IconButton>
        </div>
      </StyledModal>
      <StyledModal
        fullWidth
        maxWidth="sm"
        open={openNew}
        onClose={() => setOpenNew(null)}
        onSubmit={handleCreateNotification}
        closeBtnLabel="Cancel"
        submitBtnLabel="Send"
        style={{
          paddingBottom: '0px !important',
        }}
      >
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="title"
            value={curItem.title || ''}
            onChange={handleChange}
            placeholder="TITLE"
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            rows="5"
            value={curItem.message || ''}
            onChange={handleChange}
            placeholder="MESSAGE"
          />
        </div>
      </StyledModal>
    </div>
  );
});

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      message: PropTypes.string,
      isRead: PropTypes.bool.isRequired,
      created: PropTypes.number.isRequired,
      fromID: PropTypes.string.isRequired,
      toID: PropTypes.string.isRequired,
      groupID: PropTypes.string.isRequired,
    }),
  ).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profile: PropTypes.object.isRequired,
      avatar: PropTypes.string,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
};

export default NotificationList;
