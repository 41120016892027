import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SWIPER_CONFIG } from 'config/swiper';
import { Loading } from 'components';

import UserListItem from './UserListItem';

const UserList = React.memo(({ users, onClickTitle, isLoading }) => {
  const chunks = [];
  let newSubscriberCnt = 0;

  if (users && users.length) {
    newSubscriberCnt = users.length;
    users.slice(0, 19).forEach(item => {
      if (
        !chunks.length ||
        (chunks[chunks.length - 1].length === 2 && chunks[chunks.length - 1][1].length === 2)
      ) {
        chunks.push([[]]);
      }
      if (chunks[chunks.length - 1][0].length < 2) {
        chunks[chunks.length - 1][0].push(item);
      } else {
        if (!chunks[chunks.length - 1][1]) chunks[chunks.length - 1].push([]);
        chunks[chunks.length - 1][1].push(item);
      }
    });
  }

  return (
    <div className="card subscribers">
      <div role="button" className="card-header" onClick={onClickTitle} tabIndex={0}>
        <p>{chunks.length ? `New Subscribers (${newSubscriberCnt})` : 'No New Subscribers'}</p>
      </div>
      <div className="card-block">
        {chunks.length ? (
          <Swiper {...SWIPER_CONFIG}>
            {chunks.map((chunk, chunkIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={chunkIndex}>
                <div className="wrap--users">
                  {chunk.map((user2, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="wrap--user-2">
                      {user2.map((user, idx) => {
                        if (chunkIndex === 4 && index === 1 && idx === 0) {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Fragment key={idx}>
                              <UserListItem user={user} />
                              <UserListItem viewMore />
                            </Fragment>
                          );
                        }
                        // eslint-disable-next-line react/no-array-index-key
                        return <UserListItem user={user} key={idx} />;
                      })}
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : isLoading ? (
          <div
            style={{
              position: 'relative',
              minHeight: '120px',
              minWidth: '120px',
            }}
          >
            <Loading />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
});

UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickTitle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UserList;
