import React from 'react';
import { ASSETS_CDN } from 'config/assets';
import AppStoreBtn from 'assets/images/appstore.png';
import GoogleStoreBtn from 'assets/images/googleplay.png';
import Phone1 from 'assets/images/phone4.png';
import Phone2 from 'assets/images/phone3.png';

const Find = () => (
  <div className="Feature">
    <div className="row feature-container">
      <div className="col-md-4 col-sm-12 feature-main">
        <div className="feature-main-left">
          <h1>Find us on the App Stores</h1>
          <a
            href="https://apps.apple.com/lk/app/faithmo/id1247722295"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AppStoreBtn} alt="AppStoreBtn" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.churchbutton.faithmo&pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={GoogleStoreBtn} alt="GoogleStoreBtn" />
          </a>
        </div>
      </div>
      <div className="col-sm-12 col-md-8 feature-main-right">
        <div className="row">
          <div className="col-sm-12 col-md-6 first-app">
            {/* <div className="over-box" /> */}
            <img
              className="img-fluid find-image"
              src={Phone1}
              alt="FeatureMobile"
              rel="preload"
              as="image"
            />
          </div>
          <div className="col-sm-12 col-md-6 first-app">
            <img
              className="img-fluid find-image"
              src={Phone2}
              alt="FeatureMobile"
              rel="preload"
              as="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Find;
