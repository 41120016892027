import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import { ROLES } from 'config/roles';
import { utils } from 'service';
import { Loading, PrayersTable, Paginate } from 'components';
import { fetchPrayerRequest, deletePrayerRequest, archivePrayerRequest } from 'redux/actions/PrayerActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import './style.css';

import prayerIcon from 'assets/images/PrayerReqIcon.png';

class PrayerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prayers: [],
      prayer: {},
      isLoading: true,
      selectedTab: 'unarchived',
    };

    this.prayerTabs = [
      { label: 'Opened', value: 'unarchived' },
      { label: 'Archived', value: 'archived' },
    ];
  }

  componentDidMount() {
    const { authUserPermissions, history, location } = this.props;

    const isPrayerRequestManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.REQUEST_MANAGER.value);

    if (!isPrayerRequestManager)
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    else this.initialLoad(true);
  }

  componentDidUpdate(prevProps) {
    const { prayerID, prayers, users } = this.props;
    if (prevProps.prayerID !== prayerID) {
      this.initialLoad(true);
    } else if (
      JSON.stringify(prevProps.prayers) !== JSON.stringify(prayers) ||
      JSON.stringify(prevProps.users) !== JSON.stringify(users)
    ) {
      this.initialLoad();
    }
  }

  initialLoad = (isRefech = false) => {
    const { prayerID, prayers, users, dispatch } = this.props;

    this.setState({ isLoading: true }, async () => {
      if (users.isLoading || prayers.isLoading) return;

      const prayerDatas = prayers.data.map(pr => ({
        prayer: pr,
        user: users.data.find(ur => ur.id === pr.userID),
      }));
      let data = {};
      if (isRefech) {
        data = await dispatch(fetchPrayerRequest(prayerID));
      } else {
        data = prayers.data.find(pr => pr.id === prayerID);
      }
      if (data && data.userID) {
        this.setState({
          isLoading: false,
          prayers: prayerDatas,
          prayer: {
            data,
            user: users.data.find(item => item.id === data.userID),
          },
        });
      } else {
        this.handleNavigate({ selected: null });
      }
    });
  };

  handleTabChange = value => {
    this.setState({ selectedTab: value });
  };

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard/prayers', { from: location.pathname });
    }
  };

  handledeletePrayerRequest = id => {
    const { dispatch } = this.props;
    dispatch(deletePrayerRequest(id));
  };

  handleMakeArchive = async () => {
    const { prayerID, dispatch } = this.props;
    await dispatch(archivePrayerRequest(prayerID));
    this.handleNavigate({ selected: null });
  };

  handleNavigate = ({ selected }) => {
    const {
      history,
      location,
      prayers: { indexes },
    } = this.props;
    const { prayer } = this.state;
    const currentIndex = indexes.findIndex(item => prayer && prayer.data && item === prayer.data.id) || -1;
    let pIndex = selected === null ? currentIndex + 1 : selected;
    if (pIndex < 0) pIndex = 0;
    if (indexes.length === 0) {
      history.push('/dashboard/prayers', { from: location.pathname });
    } else {
      if (pIndex > indexes.length - 1) pIndex = indexes.length - 1;
      history.push(`/dashboard/prayer/${indexes[pIndex]}`, { from: location.pathname });
    }
  };

  render() {
    const { prayers, prayer, selectedTab, isLoading } = this.state;
    const {
      prayers: { indexes },
    } = this.props;

    const filterTable = (data, userID, option) => {
      switch (option) {
        case 'archived':
          return data.filter(item => !!item.prayer.archived && item.prayer.userID === userID);
        case 'unarchived':
          return data.filter(item => !item.prayer.archived && item.prayer.userID === userID);
        default:
          return data.filter(item => item.prayer.userID === userID);
      }
    };

    return (
      <>
        <Helmet title="Prayer requests details" />
        <div className="prayer-detail card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            {isLoading ? (
              <div style={{ position: 'relative', height: 150 }}>
                <Loading />
              </div>
            ) : (
              <>
                <div className="card-header">
                  <div className="title">
                    <img src={prayerIcon} alt="PrayerLogo" />
                    <p>Prayer Request Detail</p>
                  </div>
                </div>
                <div className="card-block">
                  <div className="prayer-attrs">
                    <h4 className="prayer-section-header">{prayer.data.title}</h4>
                    <div className="content">
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                          <div className="item-label">User Name:</div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="item-value">
                            {prayer.user
                              ? `${prayer.user.profile.firstName} ${prayer.user.profile.lastName}`
                              : 'Unknown'}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <div className="item-label">Date Requested:</div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="item-value">{utils.formatValue(prayer.data.created, 'date')}</div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <div className="item-label">Description:</div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <div className="item-value">{prayer.data.desc || ''}</div>
                        </Grid>
                      </Grid>
                      <button
                        type="button"
                        className="button gradient medium"
                        disabled={!!prayer.data.archived}
                        onClick={this.handleMakeArchive}
                      >
                        Mark as prayed for
                      </button>
                    </div>
                  </div>
                  <div className="user-prayer-table">
                    <h4 className="prayer-section-header">All Prayer Requests from User</h4>
                    <div className="card-tabs">
                      <ul className="nav nav-tabs">
                        {this.prayerTabs.map(tab => (
                          <PrayersTab
                            key={tab.value}
                            tabName={tab.label}
                            isSelected={tab.value === selectedTab}
                            onSelected={() => this.handleTabChange(tab.value)}
                          />
                        ))}
                      </ul>
                      <div className="tab-content">
                        <PrayersTable
                          data={filterTable(prayers, prayer.data.userID, selectedTab)}
                          removable={selectedTab === 'archived'}
                          onRemove={this.handledeletePrayerRequest}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="navigation-pane">
                    <Paginate
                      pageCount={indexes.length}
                      currentPage={indexes.findIndex(item => item === prayer.data.id)}
                      onPageChange={this.handleNavigate}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const PrayersTab = React.memo(({ onSelected, tabName, isSelected }) => (
  <li className={`nav-item ${isSelected ? 'active' : ''}`}>
    <div role="button" className="nav-link" onClick={onSelected} tabIndex={0}>
      <span>{tabName}</span>
    </div>
  </li>
));

PrayersTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

PrayerDetail.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  prayerID: PropTypes.string.isRequired,
  prayers: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userID: PropTypes.string.isRequired,
        created: PropTypes.number.isRequired,
        archived: PropTypes.number.isRequired,
      }),
    ),
    indexes: PropTypes.arrayOf(PropTypes.string.isRequired),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, auth: { user }, users, prayers }, props) => {
  const prayerID = props.match.params.prayerId;

  return {
    authUserPermissions: church.admins[user.id],
    prayerID,
    prayers,
    users,
  };
};

export default withRouter(connect(mapStateToProps)(PrayerDetail));
