/**
 * Root Reducer
 */
import { combineReducers } from 'redux';

// Import Reducers
import { reducer as formReducer } from 'redux-form';
import users from './UsersReducer';
import gifts from './GiftReducer';
import groups from './GroupReducers';
import prayers from './PrayerReducer';
import events from './EventReducer';
import auth from './AuthReducer';
import church from './ChurchReducer';
import notifications from './NotificationReducer';
import customers from './CustomersReducer';

// Combine all reducers into one root reducer
export default combineReducers({
  users,
  gifts,
  groups,
  prayers,
  events,
  auth,
  church,
  customers,
  notifications,
  form: formReducer,
});
