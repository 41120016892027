import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, ListItemAvatar, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { utils } from 'service';
import { NOTIFICATION_TYPES } from 'config/notification';
import UserAvatar from 'components/UserAvatar';
import CollapsibleParagraph from 'components/CollapsibleParagraph';

import './style.css';

const NotificationItem = React.memo(({ isGroupNotification, user, item, onSelect, onRemove, style }) => {
  const random = Math.ceil(Math.random() * 10000);
  useEffect(() => {
    if (item) {
      item.message.split('\n').map((item, i, arr) => {
        const output = document.getElementById(`output${random}`);
        let parser = new DOMParser();
        const doc = parser.parseFromString(item, 'text/html');
        let links = doc.getElementsByTagName('a');
        [...links].forEach(link => {
          link.setAttribute('target', '_blank');
        });

        output.innerHTML = doc.documentElement.innerHTML;
        // const line = <span key={i}>{item}</span>;

        // if (i === arr.length - 1) {
        //   return line;
        // } else {
        //   return [line, <br key={i + 'br'} />];
        // }
      });
    }
  }, [item, random]);

  return (
    <ListItem
      className="noti-item"
      button
      alignItems="flex-start"
      disableRipple
      onClick={e => onSelect(e, item)}
      style={style}
    >
      {!!isGroupNotification && !!user && (
        <ListItemAvatar
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <UserAvatar user={user} displayName={false} />
        </ListItemAvatar>
      )}
      <ListItemText
        className={`noti-item-text background-${NOTIFICATION_TYPES[item.type] ? NOTIFICATION_TYPES[item.type].color : 'primary'
          }`}
        disableTypography
        primary={
          <div className="noti-header">
            <Typography className="noti-type" component="span" variant="h5" color="textPrimary">
              {!!NOTIFICATION_TYPES[item.type] && !!NOTIFICATION_TYPES[item.type].icon && (
                <img
                  alt="Icon"
                  src={NOTIFICATION_TYPES[item.type].icon}
                  height={20}
                  width={20}
                  style={{ margin: '4px 8px 4px 0px', objectFit: 'contain' }}
                />
              )}
              {!!isGroupNotification &&
                !!user &&
                !!user.profile &&
                `${user.profile.firstName} ${user.profile.lastName}`}
              {!!NOTIFICATION_TYPES[item.type] && NOTIFICATION_TYPES[item.type].label}
            </Typography>
            {item.isRead === false && (
              <Typography className="noti-new-badge" component="span" variant="h5" color="textPrimary">
                New
              </Typography>
            )}
            <IconButton
              className="noti-close"
              onClick={e => {
                e.stopPropagation();
                onRemove(e, item);
              }}
            >
              <CloseIcon fontSize="inherit" fill="grey" style={{ margin: -8 }} />
            </IconButton>
          </div>
        }
        secondary={
          <div className="noti-body">
            <div className="noti-subheader">
              <Typography className="noti-title" component="span" variant="h6" color="textPrimary">
                {item.subject || ''}
              </Typography>
              <Typography className="noti-date" component="span" variant="body1" color="textPrimary">
                {!!item.created && utils.formatDateValueToCalendarValue(item.created)}
              </Typography>
            </div>
            <CollapsibleParagraph more="More" less="Less" id={`output${random}`}>
              {/* {item.message.split('\n').map((item, i, arr) => {
                let parser = new DOMParser();
                const doc = parser.parseFromString(item, 'text/html');
                let links = doc.getElementsByTagName('a');
                [...links].forEach(link => {
                  link.setAttribute('target', '_blank');
                });
                const line = (
                  <span key={i} dangerouslySetInnerHTML={{ __html: doc.documentElement.innerHTML }}></span>
                );

                if (i === arr.length - 1) {
                  return line;
                } else {
                  return [line, <br key={i + 'br'} />];
                }
              })} */}
            </CollapsibleParagraph>
          </div>
        }
      />
    </ListItem>
  );
});

NotificationItem.defaultProps = {
  isGroupNotification: false,
  style: {},
  user: {},
};

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isGroupNotification: PropTypes.bool,
  user: PropTypes.object,
  style: PropTypes.object,
};

export default NotificationItem;
