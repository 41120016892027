import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { api, logger } from 'service';
import { sendNotfication } from '../../utils';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const AUTH_USER_UPDATED = 'AUTH_USER_UPDATED';

const storeDatatoLocalStorage = (email, signUpdata) => {
  const saveData = { ...signUpdata };
  if (saveData.password) delete saveData.password;
  if (saveData.confirmPassword) delete saveData.confirmPassword;
  localStorage.setItem(`unverified_faithmo_signup_data[${email}]`, JSON.stringify(saveData));
};

export const registerUserAmplify = async (email, password, data) => {
  try {
    const { userConfirmed } = await Auth.signUp({
      username: email,
      password,
    });
    if (!userConfirmed) {
      storeDatatoLocalStorage(email, data);
      return false;
    }
    return false;
  } catch (error) {
    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGE === 'development') {
      // enable for development
      if (error.code === 'UsernameExistsException') {
        storeDatatoLocalStorage(email, data);
        return true;
      }
    }
    logger.warn('registerUserAmplify action', error);
    throw error;
  }
};

export const registerChurchRequest = async church => {
  try {
    const churchAdminData = await api('/user', 'post', {
      email: church.personalEmail,
      profile: {
        firstName: church.firstName,
        lastName: church.lastName,
      },
    });
    const churchData = await api('/church', 'post', {
      contacts: {
        address: {
          line1: church.line1,
          // line2: church.line2,
          city: church.city,
          state: church.state,
          zip: church.zip,
          country: church.country,
        },
        phoneNumber: church.phone,
        website: church.website,
        email: church.churchEmail,
      },
      // founders: [church.founder],
      // pastors: [church.pastor],
      pastor: church.pastor,
      title: church.title,
    });
    const nuid = `n${uuidv4().replaceAll('-', '')}`;
    await sendNotfication('church_register', { church: churchData, user: churchAdminData, nftID: nuid });
  } catch (error) {
    logger.warn('registerChurchRequest action', error);
    throw error;
  }
};

export const registerUserRequest = async user => {
  try {
    const userData = await api('/user', 'post', {
      email: user.personalEmail,
      profile: {
        firstName: user.firstName,
        lastName: user.lastName,
      },
    });
    await sendNotfication('user_register', { user: userData });
  } catch (error) {
    logger.warn('registerUserRequest action', error);
    throw error;
  }
};

export const userUpdateSuccess = user => ({
  type: AUTH_USER_UPDATED,
  payload: {
    user,
  },
});

export const currentAuthenticatedUser = () => async dispatch => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { email } = cognitoUser.attributes;
    const resChurch = await api(`/church/email/${email}`);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { cognitoUser, user: resChurch.user, as: resChurch.as },
    });
    return {
      user: resChurch.user,
      church: resChurch.church,
      as: resChurch.as,
    };
  } catch (error) {
    logger.warn('currentAuthenticatedUser action', error);
    throw error;
  }
};

export const logout = () => async dispatch => {
  try {
    await Auth.signOut();
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
  } catch (error) {
    logger.warn('logout action', error);
  }
};
