import React, { Component } from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import {
  RoundedTable,
  Loading,
  Tooltip,
  SubscriberSerchBox,
  DropDownMenu,
  UserAvatar,
  DatePicker,
} from 'components';
import { MANUAL_PAYMENT_METHODS } from 'config/payment-type';
import { UNDEFINED_REGEX } from 'config/regexrs';
import { logger, utils } from 'service';
import { createUserBatchRequest } from 'redux/actions/UsersActions';
import { createGivingHistoryBatchRequest } from 'redux/actions/GiftActions';
import StyledModal from 'components/StyledModal';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ReactComponent as TrashIcon } from 'assets/images/trash-alt.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import GivingIcon from 'assets/images/giving.png';

import './style.css';
import validate from './validate';
import AddNewUser from './AddNewUser';

class UserUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedUsers: [],
      givingEntries: [],
      givingEntry: {},
      givingEntryErrors: {},
      isOpenClearConfrimDlg: false,
      isOpenSubmitConfrimDlg: false,
      isOpenLeaveConfirmDlg: false,
      isOpenEmptyNotiDlg: false,
      isLoading: false,
    };

    this.next = {};
    this.selectedRow = null;
    this.notiText = 'No data has been entered to upload.';
    this.clearRowButtonRef = React.createRef();
    this.searchBox = React.createRef();

    this.headerSettings = utils.generageHeaderSettings({
      Amount: { key: 'total', length: 6 },
      Giver: { key: 'user', length: 18 },
      'Payment Method': { key: 'methodType', length: 12 },
      'Gift Options': { key: 'giftID', length: 12 },
      Date: { key: 'date', length: 8 },
      Notes: { key: 'notes', length: 12 },
    });
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { userID: prevUserID, users: prevUsers } = prevProps;
    const { userID, users } = this.props;

    if (prevUserID !== userID || JSON.stringify(users) !== JSON.stringify(prevUsers)) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  loadData = () => {
    const { givingEntry } = this.state;
    const { users, userID } = this.props;
    if (users.isLoading) return;

    const user = users.data.find(u => u.id === userID) || null;
    if (user) {
      this.setState({
        givingEntry: {
          ...givingEntry,
          user: users.data.find(u => u.id === userID) || null,
        },
      });
    }
  };

  onUnload = e => {
    // the method that will be used for both add and remove event
    const { givingEntries } = this.state;
    if (givingEntries.length) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  handleLeavePage = (location, action) => {
    this.setState({ isOpenLeaveConfirmDlg: true });
    this.next = { location, action };
    return false;
  };

  handleGo = () => {
    const { history } = this.props;
    if (this.next.location) {
      this.setState({ isOpenLeaveConfirmDlg: false, givingEntries: [] }, () => {
        if (this.next.action === 'POP') {
          history.goBack();
        } else if (this.next.action === 'REPLACE') {
          history.replace(
            this.next.location.pathname + this.next.location.search + this.next.location.hash,
            this.next.location.state,
          );
        } else if (this.next.action === 'PUSH') {
          history.push(
            this.next.location.pathname + this.next.location.search + this.next.location.hash,
            this.next.location.state,
          );
        }
        this.next = {};
      });
    }
  };

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard/givings', { from: location.state ? location.state.from : undefined });
    }
  };

  handleSelectUser = suggestion => {
    const { givingEntry } = this.state;
    this.setState({ givingEntry: { ...givingEntry, user: suggestion }, givingEntryErrors: {} });
  };

  handleNewUserAdd = user => {
    const { addedUsers, givingEntry } = this.state;

    this.setState({
      addedUsers: [...addedUsers, user],
      givingEntry: { ...givingEntry, user: { ...user.user, id: user.index } },
      givingEntryErrors: {},
    });
  };

  handleAddNewEntry = () => {
    const { givingEntry, givingEntries } = this.state;

    const errors = validate(givingEntry);
    const errorCnt = Object.keys(errors).length;
    if (errorCnt) {
      this.setState({ givingEntryErrors: errors });
      return;
    }

    if (this.searchBox.current) {
      this.searchBox.current.allClear();
    }

    this.setState({
      givingEntries: [
        ...givingEntries,
        {
          ...givingEntry,
          index: utils.makeStringID(24),
          total: Math.floor(givingEntry.total * 100) * (givingEntry.methodType === 'refund' ? -1 : 1),
        },
      ],
      givingEntry: {},
    });
  };

  handleClearAllClick = () => {
    const { givingEntries } = this.state;

    if (!givingEntries.length) {
      this.notiText = 'No data has been entered to clear.';
      this.setState({ isOpenEmptyNotiDlg: true });
      return;
    }

    this.selectedRow = null;
    this.setState({ isOpenClearConfrimDlg: true });
  };

  handleMouseEnterRow = (e, index) => {
    this.selectedRow = index;
    const rect = e.target.getBoundingClientRect();
    this.clearRowButtonRef.current.classList.add('visible');
    this.clearRowButtonRef.current.style.top = `${rect.y}px`;
  };

  handleMouseLeaveRow = () => {
    this.clearRowButtonRef.current.classList.remove('visible');
  };

  handleClearRowClick = () => {
    this.setState({
      isOpenClearConfrimDlg: true,
    });
  };

  handleClear = () => {
    const { givingEntries } = this.state;
    if (this.selectedRow) {
      this.setState({
        isOpenClearConfrimDlg: false,
        givingEntries: givingEntries.filter(r => r.index !== this.selectedRow),
      });
      this.selectedRow = null;
    } else {
      this.setState({ isOpenClearConfrimDlg: false, givingEntries: [] });
    }
  };

  handleSumitButtonClick = () => {
    const { givingEntries } = this.state;

    if (!givingEntries.length) {
      this.notiText = 'No data has been entered to upload.';
      this.setState({ isOpenEmptyNotiDlg: true });
    } else {
      this.setState({ isOpenSubmitConfrimDlg: true });
    }
  };

  handleSubmit = async () => {
    const { givingEntries, addedUsers } = this.state;
    const { dispatch, churchID, history } = this.props;

    this.setState({ isLoading: true, isOpenSubmitConfrimDlg: false });
    try {
      const { results } = await dispatch(createUserBatchRequest(churchID, addedUsers));
      await dispatch(
        createGivingHistoryBatchRequest(
          churchID,
          givingEntries.map(g => {
            const uGiving = {
              total: g.total,
              giftID: g.giftID,
              methodType: g.methodType,
              date: g.date,
              desc: g.notes,
            };
            if (UNDEFINED_REGEX.test(g.user.id)) {
              const user = results.find(u => u.index === g.user.id);
              if (!user) {
                uGiving.userID = null;
              } else {
                uGiving.userID = user.userID;
              }
            } else {
              uGiving.userID = g.user.id;
            }

            return uGiving;
          }),
        ),
      );

      this.setState(
        () => ({ isLoading: false, givingEntries: [] }),
        () => {
          history.goBack();
        },
      );
    } catch (error) {
      logger.error(error);
    }
  };

  handleNewEntryChange(name, value) {
    const { givingEntry, givingEntryErrors } = this.state;
    let nValue = value;
    if (name === 'total') {
      nValue = utils.normalizeAmountValue(value);
    }
    this.setState({
      givingEntry: { ...givingEntry, [name]: nValue },
      givingEntryErrors: { ...givingEntryErrors, [name]: undefined },
    });
  }

  render() {
    const {
      givingEntries,
      givingEntry,
      givingEntryErrors,
      isLoading: isLoadingUpload,
      isOpenSubmitConfrimDlg,
      isOpenLeaveConfirmDlg,
      isOpenClearConfrimDlg,
      isOpenEmptyNotiDlg,
      addedUsers,
    } = this.state;
    const { users, gifts } = this.props;
    const isLoading = isLoadingUpload || gifts.isLoading || users.isLoading;

    const renderTableRow = row => ({
      props: {
        onMouseEnter: e => this.handleMouseEnterRow(e, row.index),
        onMouseLeave: e => this.handleMouseLeaveRow(e, row.index),
      },
      childs: [
        <div className={row.total < 0 ? 'color-danger' : ''} style={{ fontWeight: 'bold' }}>
          {utils.formatValue(row.total / 100, 'currency')}
        </div>,
        <UserAvatar user={row.user} className="align-left" />,
        <div>
          {(MANUAL_PAYMENT_METHODS.find(g => g.value === row.methodType) || { label: null }).label}
        </div>,
        <div>{(gifts.data.find(g => g.id === row.giftID) || { title: null }).title}</div>,
        <div>{utils.formatValue(row.date, 'date')}</div>,
        <div>{row.notes}</div>,
      ],
    });

    const renderGrandTotal = () => {
      const { total } = givingEntries.reduce(
        (accumulator, currentValue) => ({
          total: accumulator.total + currentValue.total,
        }),
        { total: 0 },
      );

      return (
        <span className={total < 0 ? 'color-danger' : ''} style={{ fontWeight: 'bold' }}>
          Grand Total:&nbsp;&nbsp;
          {utils.formatValue(total / 100, 'currency')}
        </span>
      );
    };

    return (
      <>
        <Helmet title="Giving History - Upload" />
        <Prompt when={!!givingEntries.length} message={this.handleLeavePage} />
        <div className="giving-upload card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={GivingIcon} alt="Import User" />
                <p>Add Gifts</p>
              </div>
            </div>
            <div className="card-block">
              {isLoading ? (
                <div
                  style={{
                    position: 'relative',
                    minHeight: '60px',
                    minWidth: '60px',
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <div className="new-entry-edit my-3">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="mt-2 mx-md-5 mx-sm-3">
                          <div className="mb-4">
                            <SubscriberSerchBox
                              ref={this.searchBox}
                              onSelect={this.handleSelectUser}
                              data={[...users.data, ...addedUsers.map(u => ({ ...u.user, id: u.index }))]}
                              className="mb-1"
                            />
                            <span style={{ display: 'block' }}>
                              Search for a User or&nbsp;
                              <AddNewUser
                                onAdd={this.handleNewUserAdd}
                                users={[
                                  ...users.data,
                                  ...addedUsers.map(u => ({ ...u.user, id: u.index })),
                                ]}
                              />
                            </span>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="manual-entry-payment-method-select">Payment Method</label>
                                <DropDownMenu
                                  id="manual-entry-payment-method-select"
                                  className="form-control"
                                  value={givingEntry.methodType || ''}
                                  onChange={e => this.handleNewEntryChange('methodType', e.target.value)}
                                  menuArray={MANUAL_PAYMENT_METHODS}
                                />
                                <span className="helper-text error">{givingEntryErrors.methodType}</span>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="manual-entry-gift-select">
                                  <Tooltip title="Only ACTIVE gift options are displayed">
                                    <IconButton
                                      centerRipple
                                      color="primary"
                                      aria-label="What is this"
                                      component="div"
                                    >
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  Gift Options
                                </label>
                                <DropDownMenu
                                  id="manual-entry-gift-select"
                                  className="form-control"
                                  value={givingEntry.giftID || ''}
                                  onChange={e => this.handleNewEntryChange('giftID', e.target.value)}
                                  menuArray={gifts.data
                                    .filter(g => g.status === 1)
                                    .map(g => ({ label: g.title, value: g.id }))}
                                />
                                <span className="helper-text error">{givingEntryErrors.giftID}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        {givingEntry.user && (
                          <div className="new-entry-edit-input-wrapper m-3 my-lg-0">
                            <div className="new-entry-edit-input-group">
                              <UserAvatar user={givingEntry.user} style={{ margin: 12, marginTop: 0 }} />
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  placeholder={
                                    givingEntry.methodType === 'refund' ? '$ Refund Amount' : '$ Amount'
                                  }
                                  value={givingEntry.total || ''}
                                  onChange={e => this.handleNewEntryChange('total', e.target.value)}
                                />
                                <span className="helper-text error">{givingEntryErrors.total}</span>
                              </div>
                              <div className="form-group">
                                <label htmlFor="manual-entry-input-date">
                                  {givingEntry.methodType === 'refund' ? 'Refund Date' : 'Gift Date'}
                                </label>
                                <DatePicker
                                  id="manual-entry-input-date"
                                  className="form-control p-0 border-0"
                                  clearIcon={null}
                                  onChange={value =>
                                    this.handleNewEntryChange(
                                      'date',
                                      value.getTime ? value.getTime() : value,
                                    )
                                  }
                                  value={givingEntry.date ? new Date(givingEntry.date) : null}
                                />
                                <span className="helper-text error">{givingEntryErrors.date}</span>
                              </div>
                              <div className="form-group w-100 m-0">
                                <textarea
                                  className="form-control"
                                  placeholder="Notes"
                                  rows={3}
                                  value={givingEntry.notes || ''}
                                  onChange={e => this.handleNewEntryChange('notes', e.target.value)}
                                  maxLength={50}
                                />
                                <span className="helper-text error">{givingEntryErrors.notes}</span>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="button gradient small m-2"
                              onClick={this.handleAddNewEntry}
                            >
                              Add
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="template-table-wrapper">
                    <RoundedTable
                      minHeight={160}
                      maxHeight={360}
                      minWidth={`${this.headerSettings.total}em`}
                      data={givingEntries.map(row => renderTableRow(row))}
                      headings={this.headerSettings.headers.map(hs => ({
                        component: <div key={hs.key}>{hs.name}</div>,
                        percentWidth: hs.percentWidth,
                      }))}
                    />
                    <button
                      ref={this.clearRowButtonRef}
                      type="button"
                      className="button red icon-button clear-row"
                      onClick={this.handleClearRowClick}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                  <div className="action-buttons">
                    <button
                      type="button"
                      className="button neutral bg-white border--gray medium mr-auto"
                      onClick={this.handleClearAllClick}
                    >
                      Clear Data
                    </button>
                  </div>
                  <div className="card-footer">
                    {renderGrandTotal()}
                    <button
                      type="button"
                      className="button gradient medium"
                      onClick={this.handleSumitButtonClick}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <StyledModal
          open={isOpenLeaveConfirmDlg}
          onClose={() => this.setState({ isOpenLeaveConfirmDlg: false })}
          onSubmit={this.handleGo}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          <h4 style={{ marginBottom: '1rem' }}>Leave this page?</h4>
          <p style={{ margin: 0 }}>Changes you made not be saved.</p>
        </StyledModal>
        <StyledModal
          open={isOpenClearConfrimDlg}
          onClose={() => this.setState({ isOpenClearConfrimDlg: false })}
          onSubmit={this.handleClear}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          {this.selectedRow ? 'Clear selected giving entry?' : 'Clear all giving entry?'}
        </StyledModal>
        <StyledModal
          open={isOpenEmptyNotiDlg}
          onClose={() => this.setState({ isOpenEmptyNotiDlg: false })}
          closeBtnLabel="OK"
        >
          {this.notiText}
        </StyledModal>
        <StyledModal
          open={isOpenSubmitConfrimDlg}
          onClose={() => this.setState({ isOpenSubmitConfrimDlg: false })}
          onSubmit={this.handleSubmit}
          closeBtnLabel="No"
          submitBtnLabel="Yes"
        >
          Do you want to upload all these giving entries?
        </StyledModal>
      </>
    );
  }
}

UserUpload.defaultProps = {
  userID: null,
};

UserUpload.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  churchID: PropTypes.string.isRequired,
  userID: PropTypes.string,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        avatar: PropTypes.string,
        profile: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  gifts: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        productID: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
    isLoading: PropTypes.number.isRequired,
  }).isRequired,
};

// Retrieve data from store as props
const mapStateToProps = ({ church, users, gifts }, props) => ({
  churchID: church.id,
  userID: props.match && props.match.params && props.match.params.userId ? props.match.params.userId : null,
  users,
  gifts,
});

export default withRouter(connect(mapStateToProps)(UserUpload));
