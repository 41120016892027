import React, { useRef } from 'react';
import { ASSETS_CDN } from 'config/assets';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper';
import { SLIDE_CONTENTS } from 'config/home-slide-content';
import HomeSlide from 'components/HomeSlide';

const SlidesShow = () => {
  const swiperRef = useRef(null);
  const onBtnClick = index => {
    if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };
  return (
    <div className="SlidesShow" id="feature_target">
      <Swiper
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 500,
          disableOnInteraction: false,
        }}
        effect={'fade'}
        modules={[EffectFade, Autoplay, Pagination]}
        className="mySwiper"
        speed={1}
        ref={swiperRef}
      >
        {SLIDE_CONTENTS.map((slide, index) => (
          <SwiperSlide key={index} style={{ height: 'auto' }}>
            <HomeSlide data={slide} onBtnClick={index => onBtnClick(index)} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SlidesShow;
