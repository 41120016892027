import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import './style.css';

const getSuggestions = (value, users, exceptions = []) => {
  const escapedValue = value.trim().escapeRegexCharacters();

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(`${escapedValue}`, 'i');

  return users.filter(
    user =>
      !exceptions.find(id => id === user.id) &&
      (regex.test(`${user.profile.firstName} ${user.profile.lastName}`) ||
        regex.test(`${user.profile.ID}`)),
  );
};

class SubscriberSerchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      inputValue: '',
    };
  }

  allClear = () => {
    this.setState({ suggestions: [], inputValue: '' });
  };

  handleSuggestionInputChange = (event, { newValue }) => {
    const { onChange, data, exceptionList } = this.props;
    this.setState({ inputValue: newValue }, () => {
      if (onChange) {
        if (newValue) {
          onChange(getSuggestions(newValue, data, exceptionList));
        } else {
          onChange(data);
        }
      }
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { data, exceptionList } = this.props;
    this.setState({ suggestions: getSuggestions(value, data, exceptionList) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { onSelect } = this.props;
    onSelect(suggestion);
  };

  render() {
    const {
      data,
      exceptionList,
      onSelect,
      onChange,
      onSearchClick,
      desc,
      className,
      disabled,
      ...props
    } = this.props;
    const { suggestions, inputValue } = this.state;

    if (!data || !data.length) return <div />;

    const inputProps = {
      placeholder: 'Search by Name / ID',
      value: inputValue,
      onChange: this.handleSuggestionInputChange,
      disabled,
    };

    return (
      <div className={`search-box ${className}`} {...props}>
        <div className="input-group" id="user-suggestion-input">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={suggestion =>
              `${suggestion.profile.firstName} ${suggestion.profile.lastName}`
            }
            renderSuggestion={suggestion => (
              <span>
                {suggestion.profile.firstName} {suggestion.profile.lastName}
              </span>
            )}
            onSuggestionSelected={this.onSuggestionSelected}
            inputProps={inputProps}
          />
          <span className="input-group-btn" htmlFor="user-suggestion-input">
            <button
              disabled={disabled}
              className="button gray small"
              type="button"
              onClick={() => {
                if (onSearchClick) {
                  if (inputValue) {
                    onSearchClick(getSuggestions(inputValue, data, exceptionList));
                  } else {
                    onSearchClick(data);
                  }
                }
              }}
            >
              Search
            </button>
          </span>
        </div>
        {!!desc && <p>{desc}</p>}
      </div>
    );
  }
}

SubscriberSerchBox.defaultProps = {
  exceptionList: [],
  className: '',
  disabled: false,
  onChange: undefined,
  onSearchClick: undefined,
  desc: '',
};

SubscriberSerchBox.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profile: PropTypes.object.isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  exceptionList: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  desc: PropTypes.string,
  className: PropTypes.string,
};

export default SubscriberSerchBox;
