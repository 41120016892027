import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import './style.css';

class CollapsibleParagraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      truncated: false,
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
  }

  handleTruncate(value) {
    const { truncated } = this.state;
    if (truncated !== value) {
      this.setState({ truncated: value });
    }
  }

  toggleLines(event) {
    const { expanded } = this.state;
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      expanded: !expanded,
    });
  }

  render() {
    const { children, more, less, lines, showHandler, ...props } = this.props;

    const { expanded, truncated } = this.state;

    return (
      <div {...props} className="collapsible-paragraph-container">
        <Truncate
          lines={!expanded && lines}
          ellipsis={
            <span>
              ...
              {!!showHandler && (
                <span role="button" className="expand-handler" onClick={this.toggleLines} tabIndex={0}>
                  {more}
                </span>
              )}
            </span>
          }
          onTruncate={this.handleTruncate}
          className="collapsible-paragraph"
        >
          {children}
        </Truncate>
        {!!showHandler && !truncated && expanded && (
          <span>
            <span role="button" className="expand-handler" onClick={this.toggleLines} tabIndex={0}>
              {less}
            </span>
          </span>
        )}
      </div>
    );
  }
}

CollapsibleParagraph.defaultProps = {
  lines: 3,
  more: 'Read more',
  less: 'Show less',
  showHandler: true,
};

CollapsibleParagraph.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  showHandler: PropTypes.bool,
};

export default CollapsibleParagraph;
