import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logger, utils } from 'service';
import { STRIPE_AUTHORIZE_URI } from 'config/stripe';
import { SwitchCheckBox } from 'components';
import StyledModal from 'components/StyledModal';
import { createGiftRequest, removeGiftRequest, updateGiftRequest } from 'redux/actions/GiftActions';
// import { fetchChurchStripeLoginLink } from 'redux/actions/ChurchActions';
import GiftOption from './GiftOption';
import InActiveGiftOption from './InActiveGiftOption';

import './GiftSettings.css';

class GiftSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddModal: false,
      openDeleteModal: false,
      newGift: {
        name: '',
        state: true,
      },
      itemsLoadingStatus: {},
    };
    this.deleteKey = -1;
  }

  handleCreate = () => {
    const { newGift } = this.state;
    const { dispatch } = this.props;
    if (!newGift.name) return;
    dispatch(createGiftRequest(newGift.name, newGift.state));
    this.setState({ openAddModal: false });
  };

  handleClick = key => {
    logger.log('not implemented handleClick in GiftSettings', key);
  };

  handleRemove = () => {
    const { dispatch } = this.props;
    dispatch(removeGiftRequest(this.deleteKey));
    this.deleteKey = -1;
    this.setState({ openDeleteModal: false });
  };

  handleChangeActive = (itemId, newactive) => {
    const { dispatch } = this.props;
    const { itemsLoadingStatus } = this.state;
    this.setState({ itemsLoadingStatus: { ...itemsLoadingStatus, [itemId]: true } }, () => {
      dispatch(updateGiftRequest(itemId, newactive)).then(() => {
        this.setState(state => ({ itemsLoadingStatus: { ...state.itemsLoadingStatus, [itemId]: false } }));
      });
    });
  };

  handleChange = event => {
    const { newGift } = this.state;
    if (event.target.name === 'name') this.setState({ newGift: { ...newGift, name: event.target.value } });
    else if (event.target.name === 'state')
      this.setState({ newGift: { ...newGift, state: event.target.checked } });
  };

  handleUpdateStripe = async id => {
    const {
      history,
      location,
      church: { customer },
    } = this.props;

    try {
      if (
        !customer ||
        !customer.data ||
        !customer.data.subscription ||
        customer.data.subscription.status !== 'active'
      ) {
        history.replace('/dashboard/settings/membership/membership_edit', {
          from: location.state ? location.state.from : undefined,
        });
        return;
      }

      logger.log('stripe merchant id', id);
      // const link = await fetchChurchStripeLoginLink(id);
      // window.open('https://dashboard.stripe.com/login', '_self');
      window.location.href = 'https://dashboard.stripe.com/login';
    } catch (error) {
      // continue regardless of error
    }
  };

  handleAddStripe = async () => {
    const {
      history,
      location,
      user: { profile },
      church: { title, desc, contacts, customer },
    } = this.props;

    if (
      !customer ||
      !customer.data ||
      !customer.data.subscription ||
      customer.data.subscription.status !== 'active'
    ) {
      history.replace('/dashboard/settings/membership/membership_edit', {
        from: location.state ? location.state.from : undefined,
      });
      return;
    }

    let link = `${STRIPE_AUTHORIZE_URI}&stripe_user[business_type]=non_profit&stripe_user[currency]=usd`;
    console.log('link', link)
    if (contacts.email) link += `&stripe_user[email]=${contacts.email}`;
    if (profile.firstName) link += `&stripe_user[first_name]=${profile.firstName}`;
    if (profile.lastName) link += `&stripe_user[last_name]=${profile.lastName}`;
    if (title) link += `&stripe_user[business_name]=${title}`;
    if (desc) link += `&stripe_user[business_desc]=${desc}`;
    if (contacts.website) link += `&stripe_user[url]=${contacts.website}`;
    if (contacts.phoneNumber) {
      const phone = contacts.phoneNumber.replace(/[()-]/g, '');
      link += `&stripe_user[phone_number]=${phone}`;
    }
    if (contacts.address) {
      if (contacts.address.country) link += `&stripe_user[country]=${contacts.address.country}`;
      if (contacts.address.zip) link += `&stripe_user[zip]=${contacts.address.zip}`;
      if (contacts.address.state) link += `&stripe_user[state]=${contacts.address.state}`;
      if (contacts.address.city) link += `&stripe_user[city]=${contacts.address.city}`;
      if (contacts.address.line1) link += `&stripe_user[street_address]=${contacts.address.line1}`;
    }
    // window.open(link, '_self');
    window.location.href = link;
  };

  render() {
    const { openAddModal, openDeleteModal, newGift, itemsLoadingStatus } = this.state;
    const { church, gDatas } = this.props;
    const stripeConnected = !!(
      church.stripe &&
      church.stripe.id &&
      church.stripe.requirements &&
      church.stripe.requirements.disabled_reason === null &&
      church.stripe.capabilities &&
      church.stripe.capabilities.card_payments === 'active' &&
      church.stripe.capabilities.transfers === 'active'
    );

    const mapGiftData = (data, itemsLoadingStatusObj) => {
      const activeData = data.filter(item => item.status === 1 && !item.removed);
      const inactiveData = data.filter(item => item.status === 0 && !item.removed);

      return (
        <>
          <GiftOption
            data={activeData}
            onItemClick={this.handleClick}
            onChangeActive={this.handleChangeActive}
            itemsLoadingStatus={itemsLoadingStatusObj}
          />
          <InActiveGiftOption
            data={inactiveData}
            onItemClick={this.handleClick}
            onChangeActive={this.handleChangeActive}
            itemsLoadingStatus={itemsLoadingStatusObj}
            onRemove={key => {
              this.deleteKey = key;
              this.setState({ openDeleteModal: true });
            }}
          />
        </>
      );
    };

    const renderStatus = stripe => {
      if (!stripe.requirements || !stripe.capabilities) {
        return (
          <div className="pgs-detail">
            <p className="red-large-text">Status: Pending Verification</p>
            <p className="red-large-text">
              {`Account Start Date: ${utils.formatValue(stripe.created, 'date_long')}`}
            </p>
          </div>
        );
      }
      if (
        stripe.requirements.disabled_reason === null &&
        stripe.capabilities.card_payments === 'active' &&
        stripe.capabilities.transfers === 'active'
      ) {
        return (
          <div className="pgs-detail">
            <p className="red-large-text">Status: Connected</p>
            <p className="red-large-text">
              {`Account Start Date: ${utils.formatValue(stripe.created, 'date_long')}`}
            </p>
            <button
              type="button"
              onClick={() => this.handleUpdateStripe(stripe.id)}
              className="button gradient medium"
            >
              Visit your account
            </button>
          </div>
        );
      }
      if (
        stripe.requirements.disabled_reason === 'requirements.pending_verification' ||
        (stripe.requirements.pending_verification && stripe.requirements.pending_verification.length)
      ) {
        return (
          <div className="pgs-detail">
            <p className="red-large-text">Status: Pending Verification</p>
            <p className="red-large-text">
              {`Account Start Date: ${utils.formatValue(stripe.created, 'date_long')}`}
            </p>
          </div>
        );
      }
      return (
        <>
          <div className="pgs-detail">
            <p className="red-large-text">Status: Inactive</p>
            <p className="red-large-text">
              {`Account Start Date: ${utils.formatValue(stripe.created, 'date_long')}`}
            </p>
          </div>
          <button
            type="button"
            onClick={() => this.handleUpdateStripe(stripe.id)}
            className="button gradient medium"
          >
            Update your stripe profile
          </button>
        </>
      );
    };

    return (
      <div className="gift-settings">
        <div className="gs-payments">
          <div className="card transparent-card border-card">
            <div className="card-header">
              <div className="title center">
                <p>Payment Gateway Settings</p>
              </div>
            </div>
            <div className="card-block">
              <p>
                Click the link below to launch the payment gateway site powered by Stripe. Once your account
                is setup, you will see a success message below and you are ready to receive payments. For
                any questions, please contact{' '}
                <a href="mailto:info@faithmo.com" target="_blank" rel="noopener noreferrer">
                  info@faithmo.com
                </a>
                .
              </p>
              {church &&
                church.customer &&
                !church.customer.isLoading &&
                (church.stripe && church.stripe.id ? (
                  renderStatus(church.stripe)
                ) : (
                  <button type="button" className="button gradient medium" onClick={this.handleAddStripe}>
                    Launch Partner Site
                  </button>
                ))}
            </div>
          </div>
        </div>
        {church && church.customer && !church.customer.isLoading && stripeConnected && (
          <>
            <div className="gift-wrapper">
              <div className="gift-add">
                <button
                  type="button"
                  className="button gray medium"
                  onClick={() => this.setState({ openAddModal: true, newGift: { name: '', state: true } })}
                >
                  Enter new gift option
                </button>
              </div>
              {mapGiftData(gDatas, itemsLoadingStatus)}
            </div>
            <StyledModal
              open={openAddModal}
              onClose={() => this.setState({ openAddModal: false })}
              onSubmit={this.handleCreate}
              closeBtnLabel="Cancel"
              submitBtnLabel="Submit"
              style={{
                fontWeight: 'bold',
                color: '#3b3b3b',
                paddingBottom: '0px !important',
              }}
            >
              <div className="form-group">
                <label htmlFor="gift-name">Gift Name:</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  placeholder="Enter New Gift Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="gift-state">Active:</label>
                <SwitchCheckBox
                  type="ios"
                  name="state"
                  checked={newGift.state}
                  onChange={this.handleChange}
                />
              </div>
            </StyledModal>
            <StyledModal
              open={openDeleteModal}
              onClose={() => this.setState({ openDeleteModal: false })}
              onSubmit={this.handleRemove}
              closeBtnLabel="No"
              submitBtnLabel="Yes"
            >
              Are you sure you want to delete permanently?
            </StyledModal>
          </>
        )}
      </div>
    );
  }
}

GiftSettings.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  church: PropTypes.shape({
    stripe: PropTypes.object.isRequired,
    customer: PropTypes.object,
    title: PropTypes.string,
    desc: PropTypes.string,
    contacts: PropTypes.shape({
      website: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      address: PropTypes.shape({
        country: PropTypes.string,
        zip: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string,
        line1: PropTypes.string,
      }),
    }),
  }).isRequired,
  user: PropTypes.object.isRequired,
  gDatas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      churchID: PropTypes.string.isRequired,
      created: PropTypes.number.isRequired,
      productID: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ church, gifts: { data }, auth: { user } }) => ({
  church,
  user,
  gDatas: data || [],
});

export default withRouter(connect(mapStateToProps)(GiftSettings));
