import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Available from './Available';
import Find from './Find';
import { ASSETS_CDN } from '../../config/assets';
import StyledModal from '../../components/StyledModal';
import SlidesShow from './SlidesShow';
import lightGreenCircle from 'assets/images/lightGreenCircle.svg';
import lightPinkCircle from 'assets/images/lightPinkCircle.svg';
import lightLeftGreenCircle from 'assets/images/lightLeftGreenCircle.svg';

const Main = ({ location }) => {
  const [openChurch, setOpenChurch] = useState(false);
  return (
    <div className="main">
      <div className="main-top">
        <div className="main-top-content" id="about_target">
          <div className="main-title">
            <h1>Faithmo is the Church CRM solution that grows with your ministry.</h1>
            <p>
              Whether you are on a desktop, laptop, or tablet, access the Faithmo platform and let it adjust
              to your workspace. We use top-tier user-interface, payment gateway, and data storage
              technologies to provide a dynamic and timeless solution for your church.
            </p>
            <button type="button" className="button white" onClick={() => setOpenChurch(true)}>
              Let's get started
            </button>
          </div>
        </div>
        {/* <ReactPlayer
          style={{
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
            borderRadius: '30px',
            overflow: 'hidden',
            border: '4px solid rgba(255, 255, 255, 0.2)',
            backgroundClip: 'padding-box',
            WebkitBackgroundClip: 'padding-box',
          }}
          className="videoPlayer"
          url="https://www.youtube.com/embed/xKnMAiJ0dCk?autoplay=1&mute=1&playlist=xKnMAiJ0dCk&loop=1&controls=1"
        /> */}
        <iframe
          className="videoPlayer"
          src="https://www.videoask.com/fdvrmn7ku"
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          width="100%"
          height="600px"
          style={{
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
            borderRadius: '30px',
            overflow: 'hidden',
            border: '4px solid rgba(255, 255, 255, 0.2)',
            backgroundClip: 'padding-box',
            WebkitBackgroundClip: 'padding-box',
          }}
        />
      </div>
      <SlidesShow />
      <Find />
      <div className="main-try">
        <img src={lightGreenCircle} alt="lightGreenCircle" className="light-green-circle" />
        <img src={lightPinkCircle} alt="lightPinkCircle" className="light-pink-circle" />
        <img src={lightLeftGreenCircle} alt="lightGreenLeftCircle" className="light-green-left-circle" />
        <h1>Try it for yourself</h1>
        <p>Explore the complete app for 30 days</p>
        <button type="button" className="button blue" onClick={() => setOpenChurch(true)}>
          Start my 30 day risk free trial
        </button>
      </div>
      <StyledModal
        open={openChurch}
        onClose={() => setOpenChurch(false)}
        maxWidth="md"
        fullWidth
        className="header-signup-modal"
        style={{
          minHeight: 372,
        }}
        padding={0}
      >
        <div className="layer--dark" />
        <h6 className="header-title" style={{ zIndex: 1 }}>
          Let&apos;s sign you up! Please complete all 3 steps.
        </h6>
        <Link
          className="button white-font"
          style={{ zIndex: 1, color: 'white !important' }}
          to={{
            pathname: '/signup',
            state: {
              isUserForm: false,
              from: location.pathname,
            },
          }}
        >
          Get started
        </Link>
      </StyledModal>
    </div>
  );
};

Main.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Main);
