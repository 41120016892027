import React, { Component, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { ASSETS_CDN } from 'config/assets';
import { registerUserAmplify } from 'redux/actions/AuthActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Thanks from './Thanks';
import UserSignupForm from './UserSignupForm';
import Phone1 from '../../assets/images/Signup_Flow1.png';
import Phone2 from '../../assets/images/Signup_Flow2.png';
import Phone3 from '../../assets/images/Signup_Flow3.png';

import 'components/SignIn/style.css';
import './style.css';
import { PHONE_REGEXP, WEBSITE_LINK_REGEXP, ZIP_CODE_REGEXP } from '../../config/regexrs';

const steps = [
  {
    id: 1,
    stepTitle: 'Please tell us about your church',
  },
  {
    id: 2,
    stepTitle: 'Tell us more!',
  },
  {
    id: 3,
    stepTitle: 'Great! Now let`s create your admin account.',
  },
];

const renderItem = item => (
  <div className="image-gallery-image">
    <img
      rel="preload"
      is="image"
      src={item.original}
      alt={item.originalAlt}
      srcSet={item.srcSet}
      sizes={item.sizes}
      title={item.originalTitle}
    />
    {item.description && <span className="image-gallery-description">{item.description}</span>}
  </div>
);

const images = [Phone1, Phone2, Phone3].map(item => ({
  original: item,
  renderItem,
}));

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStepIndex: 0,
      isSubmitting: false,
      error: null,
    };
    this.ImageGalleryRef = createRef();
  }

  handleSelectStep = index => {
    const { currentStepIndex } = this.state;
    if (currentStepIndex <= index) {
      return;
    }
    const slide = this.ImageGalleryRef.current;
    this.setState(
      {
        currentStepIndex: index,
      },
      () => {
        slide.slideToIndex(index >= 3 ? 3 : index);
      },
    );
  };

  handleNext = () => {
    const slide = this.ImageGalleryRef.current;
    this.setState(state => {
      const currentStepIndex = state.currentStepIndex + 1;
      slide.slideToIndex(currentStepIndex >= 3 ? 3 : currentStepIndex);
      return { currentStepIndex };
    });
  };

  handleSubmitChurch = values => {
    this.setState({ isSubmitting: true });
    // eslint-disable-next-line no-unused-vars
    const { history, location } = this.props;
    registerUserAmplify(values.personalEmail, values.password, values)
      .then(res => {
        if (res === true) {
          this.setState({ isSubmitting: false, error: null }, () => {
            history.push('/login', { from: location.pathname });
          });
        } else {
          this.setState(state => ({
            isSubmitting: false,
            currentStepIndex: state.currentStepIndex + 1,
            error: null,
          }));
        }
      })
      .catch(err => {
        this.setState({ isSubmitting: false, error: err });
      });
  };

  handleSubmitUser = values => {
    this.setState({ isSubmitting: true });
    const { history, location } = this.props;
    registerUserAmplify(values.personalEmail, values.password, values)
      .then(res => {
        if (res === true) {
          this.setState({ isSubmitting: false, error: null }, () => {
            history.push('/login', { from: location.pathname });
          });
        } else {
          this.setState(state => ({
            isSubmitting: false,
            currentStepIndex: state.currentStepIndex + 3,
            error: null,
          }));
        }
      })
      .catch(err => {
        this.setState({ isSubmitting: false, error: err });
      });
  };

  renderStepContent = (currentStepIndex, isSubmitting) => {
    switch (currentStepIndex) {
      case 0:
        return <StepOne handleSubmit={this.handleNext} />;
      case 1:
        return <StepTwo handleSubmit={this.handleNext} />;
      case 2:
        return <StepThree isSubmitting={isSubmitting} />;
      default:
        return <StepOne handleSubmit={this.handleNext} />;
    }
  };

  renderStepHeader = currentStepIndex => (
    <>
      {steps.map((step, index) => {
        const background = currentStepIndex === index ? { background: '#ff8361' } : {};
        const numstyle = currentStepIndex === index ? { color: '#fff' } : {};
        return (
          <div
            key={step.id}
            role="button"
            className="list-num"
            onClick={() => this.handleSelectStep(index)}
            tabIndex={0}
            style={background}
          >
            <p style={numstyle}>{step.id}</p>
          </div>
        );
      })}
      <p className="title--step-num">{(steps[currentStepIndex] || { stepTitle: '' }).stepTitle}</p>
    </>
  );

  removeError = () => {
    this.setState({ error: null });
  };

  render() {
    const { currentStepIndex, isSubmitting, error } = this.state;
    const { location } = this.props;
    const isUserForm = location.state && location.state.isUserForm;

    return (
      <div className="signup">
        <Helmet title="Signup" />
        {!!error && (
          <div className="Toast__toast_">
            <span>{error.message}</span>
            <span role="button" tabIndex={0} className="Toast__toastClose" onClick={this.removeError}>
              {' '}
            </span>
          </div>
        )}
        <div className="signup_form">
          <div className="back">
            <ChevronLeftIcon />
            <Link to="/">Back</Link>
          </div>
          <Thanks hidden={currentStepIndex !== 3} />
          <div className="form-container" hidden={currentStepIndex === 3}>
            <div className="wrap--step-num">
              {isUserForm ? (
                <p className="title--step-num">Let`s create your account.</p>
              ) : (
                this.renderStepHeader(currentStepIndex)
              )}
            </div>
            <div className="signup_form--bottom">
              <div className="wrap--signup-img">
                {/* <div className="signup-phone" /> */}
                <ImageGallery
                  ref={this.ImageGalleryRef}
                  items={images}
                  showPlayButton={false}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showIndex={false}
                  showBullets={false}
                  showNav={false}
                />
              </div>
              <div className="wrap-form first">
                {isUserForm ? (
                  <UserSignupForm isSubmitting={isSubmitting} onSubmit={this.handleSubmitUser} />
                ) : (
                  <Formik
                    initialValues={{
                      title: '',
                      pastor: '',
                      churchEmail: '',
                      website: '',
                      phone: '',
                      line1: '',
                      city: '',
                      state: '',
                      country: 'US',
                      zip: '',
                      firstName: '',
                      lastName: '',
                      personalEmail: '',
                      password: '',
                      confirmPassword: '',
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string().required('Church/Ministry Name is required'),
                      pastor: Yup.string().required('Pastor/Priest Name is required'),
                      churchEmail: Yup.string().required('Church Email is required').email(),
                      website: Yup.string().matches(
                        WEBSITE_LINK_REGEXP,
                        'Website link is requiring a www. format',
                      ),
                      phone: Yup.string()
                        .required('Phone Number is required')
                        .matches(PHONE_REGEXP, 'Church Phone Number has invalid format'),
                      line1: Yup.string().required('Address is required'),
                      city: Yup.string().required('City is required'),
                      state: Yup.string().required('State is required'),
                      zip: Yup.string()
                        .required('Zipcode is required')
                        .matches(ZIP_CODE_REGEXP, 'Zipcode has invalid format'),
                      country: Yup.string().required('State is required'),
                      firstName: Yup.string().required('First Name is required'),
                      lastName: Yup.string().required('Last Name is required'),
                      personalEmail: Yup.string().required('Email is required').email(),
                      password: Yup.string()
                        .required('Email is required')
                        .min(8, 'Password must have at least 8 characters'),
                      confirmPassword: Yup.string().test(
                        'password-match',
                        'Passwords should match',
                        function (value) {
                          return this.parent.password === value;
                        },
                      ),
                    })}
                    onSubmit={async values => {
                      // alert(JSON.stringify(values, null, 2));
                      await this.handleSubmitChurch(values);
                    }}
                  >
                    {/* {({ validateForm, isValid }) => ( */}
                    <Form>{this.renderStepContent(currentStepIndex, isSubmitting)}</Form>
                    {/* // )} */}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Signup.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Signup);
