import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { ROLES } from 'config/roles';
import { PrayersTable } from 'components';
import { fetchPrayersRequest, deletePrayerRequest } from 'redux/actions/PrayerActions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import './style.css';

import prayerIcon from 'assets/images/PrayerReqIcon.png';

class Prayers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'today',
      prayers: [],
      isLoading: false,
    };

    this.prayerTabs = [
      { label: 'Today', value: 'today' },
      { label: 'This week', value: 'week' },
      { label: 'Older', value: 'unarchived' },
      { label: 'Archived', value: 'archived' },
    ];
  }

  componentDidMount() {
    const { history, location, authUserPermissions } = this.props;

    const isPrayerRequestManager =
      authUserPermissions.isSuperUser ||
      !!authUserPermissions.permissions.find(pm => pm === ROLES.REQUEST_MANAGER.value);

    if (!isPrayerRequestManager) history.replace('/dashboard', { from: location.pathname });

    this.initialLoad(true);
    if (location && location.state && location.state.tab) {
      const tabItem = this.prayerTabs.find(item => item.value === location.state.tab);
      if (tabItem) {
        this.setState({ selectedTab: tabItem.value });
        return;
      }
    }
    this.setState({ selectedTab: 'today' });
  }

  componentDidUpdate(prev) {
    const { prayers, users } = this.props;
    if (
      JSON.stringify(prev.prayers) !== JSON.stringify(prayers) ||
      JSON.stringify(prev.users) !== JSON.stringify(users)
    ) {
      this.initialLoad();
    }
  }

  initialLoad = (isRefech = false) => {
    const { dispatch, users, prayers, churchID } = this.props;

    this.setState({ isLoading: true }, () => {
      if (users.isLoading || prayers.isLoading) return;
      if (isRefech) {
        dispatch(fetchPrayersRequest(churchID));
      } else {
        this.setState({
          isLoading: false,
          prayers: prayers.data.map(item => ({
            prayer: item,
            user: users.data.find(user => user.id === item.userID),
          })),
        });
      }
    });
  };

  handleTabChange = value => {
    this.setState({ selectedTab: value });
  };

  goBack = () => {
    const { history, location } = this.props;
    if (location.state && location.state.from) {
      history.goBack();
    } else {
      history.replace('/dashboard', { from: location.state ? location.state.from : undefined });
    }
  };

  handledeletePrayerRequest = id => {
    const { dispatch } = this.props;
    dispatch(deletePrayerRequest(id));
  };

  render() {
    const { selectedTab, prayers, isLoading } = this.state;

    const filterTable = (data, option) => {
      switch (option) {
        case 'today':
          return data.filter(
            item => moment(item.prayer.created).isSame(new Date(), 'day') && !item.prayer.archived,
          );
        case 'week':
          return data.filter(
            item => moment(item.prayer.created).isSame(new Date(), 'week') && !item.prayer.archived,
          );
        case 'archived':
          return data.filter(item => !!item.prayer.archived);
        case 'unarchived':
          return data.filter(item => !item.prayer.archived);
        default:
          return data;
      }
    };

    return (
      <>
        <Helmet title="Prayers" />
        <div className="prayers card-wrapper customWidth">
          <div className="back">
            <ChevronLeftIcon />
            <button type="button" onClick={this.goBack}>
              Back
            </button>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="title">
                <img src={prayerIcon} alt="" />
                <p>Prayer Request</p>
              </div>
              <div className="title-buttons">
                <button
                  type="button"
                  className="button gradient medium"
                  onClick={() => this.initialLoad(true)}
                >
                  Reload
                </button>
              </div>
            </div>
            <div className="card-block">
              <div className="card-tabs">
                <ul className="nav nav-tabs">
                  {this.prayerTabs.map(tab => (
                    <PrayersTab
                      key={tab.value}
                      tabName={tab.label}
                      isSelected={tab.value === selectedTab}
                      onSelected={() => this.handleTabChange(tab.value)}
                    />
                  ))}
                </ul>
                <div className="tab-content">
                  <PrayersTable
                    data={filterTable(prayers, selectedTab)}
                    removable={selectedTab === 'archived'}
                    onRemove={this.handledeletePrayerRequest}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const PrayersTab = React.memo(({ onSelected, tabName, isSelected }) => (
  <li className={`nav-item ${isSelected ? 'active' : ''}`}>
    <div role="button" className="nav-link" onClick={onSelected} tabIndex={0}>
      <span>{tabName}</span>
    </div>
  </li>
));

PrayersTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

Prayers.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  authUserPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    isSuperUser: PropTypes.bool.isRequired,
  }).isRequired,
  churchID: PropTypes.string.isRequired,
  prayers: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userID: PropTypes.string.isRequired,
        created: PropTypes.number.isRequired,
        archived: PropTypes.number.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ church, auth: { user }, prayers, users }) => ({
  authUserPermissions: church.admins[user.id],
  prayers,
  users,
  churchID: church.id,
});

export default withRouter(connect(mapStateToProps)(Prayers));
