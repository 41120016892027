import Joi from 'joi';

const phoneNumber = Joi.string().regex(/\d{10}/);
const phoneNumberRequired = phoneNumber.required();
const phoneNumberNullable = phoneNumber.allow(null);
const linkPathRequired = Joi.string()
  .pattern(
    /(^(https?:\/\/(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)?[A-Za-z0-9-]+)(\.[A-Za-z0-9-]{2,})((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
  )
  .required();

const virtualLink = Joi.object({
  phone_conference: Joi.object({
    phone_number: phoneNumberRequired,
    passcode: Joi.string().regex(/\d+/).empty('').allow(null),
  }),
  web_link: Joi.object({
    link_path: linkPathRequired,
  }),
  video_conference_link: Joi.object({
    link_path: linkPathRequired,
    meeting_id: Joi.string().empty('').allow(null),
    meeting_password: Joi.string().empty('').allow(null),
    phone_number: phoneNumberNullable.empty(''),
    password: Joi.string().empty('').allow(null),
  }),
  live_video_link: Joi.object({
    link_path: linkPathRequired,
  }),
});

export default virtualLink;
